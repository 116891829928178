import React, { useEffect, useState } from "react";
import { axios } from "utilities";
//import { CircularProgress, Typography } from "@mui/material";
//change10.6.
import MUIDataTable from "mui-datatables";
import { useLocalStorage } from "components";
import { Tooltip, IconButton } from "@mui/material";
import { RefreshCw as RefreshIcon } from "react-feather";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";

function MUITable({
  title,
  endpoint,
  cols,
  onRowClick,
  preloadData,
  name,
  refresh,
  ...rest
}) {
  const customToolbar = () => {
    return (
      <React.Fragment>
        <Tooltip title={"Reload"}>
          <IconButton
            onClick={() =>
              getData(apiData.page, apiData.pagesize, apiData.searchterm)
            }
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  };

  const onColumnOrderChange = (newColumnOrder, columnIndex, newPosition) => {
    setColSequence(newColumnOrder);
  };
  const [colSequence, setColSequence] = useLocalStorage(name, []);

  var initApi = {
    data: [],
    total: 0,
    page: 0,
    pagesize: 10,
    searchterm: "",
    sortcol: "",
    sortdir: "",
    isLoading: true,
  };
  if (preloadData) {
    initApi = preloadData;
    initApi.isLoading = true;
  }

  const [apiData, setApiData] = useState(initApi);

  useEffect(() => {
    getData(apiData.page, apiData.pagesize, apiData.searchterm);
  }, [apiData.isLoading, refresh]);

  // Exel Export - Begin
  const downloadData = (values) => {
    var columns = {};
    cols.forEach((value) => {
      columns[value.name] = value.label;
    });
    var exp = [];
    values.forEach((value) => {
      var tmp = {};
      for (const [key, val] of Object.entries(value)) {
        const label = columns[key] || key;
        tmp[label] = val;
      }
      exp.push(tmp);
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = title ? title.replace(" ", "_") : "Export";
    const ws = utils.json_to_sheet(exp);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };
  //Excel Export - End

  const getData = (
    page,
    pagesize,
    searchterm,
    doDownload,
    sortcol,
    sortdir
  ) => {
    var sort = "";
    if (sortcol && sortdir) {
      sort = `&sortcol=${sortcol}&sortdir=${sortdir}`;
    }
    axios({
      method: "get",
      url:
        endpoint +
        `?page=${page}&pagesize=${pagesize}&searchterm=${searchterm}` +
        sort,
    }).then((res) => {
      if (doDownload) {
        downloadData(res.data.content);
        // console.log(res.data.content)
      } else {
        setApiData({
          data: res.data.content,
          total: res.data.totalElements,
          page: page,
          pagesize: pagesize,
          searchterm: searchterm,
          sortcol: sortcol,
          sortdir: sortdir,
          isLoading: false,
        });
        // console.log("getdata was triggered");
      }
    });
  };
  //if (colSequence.length != cols.length) {
  //change 10.6.
  if (colSequence.length !== cols.length) {
    var arr = [];
    for (var i = 0; i < cols.length; i++) {
      arr.push(i);
    }
    setColSequence(arr);
  }
  return (
    <MUIDataTable
      title={title}
      options={{
        count: apiData.total,
        page: apiData.page,
        rowsPerPage: apiData.pagesize,
        onChangeRowsPerPage: (numberOfRows) => {
          getData(
            0,
            numberOfRows,
            apiData.searchterm,
            false,
            apiData.sortcol,
            apiData.sortdir
          );
        },
        onChangePage: (currentPage) => {
          getData(
            currentPage,
            apiData.pagesize,
            apiData.searchterm,
            false,
            apiData.sortcol,
            apiData.sortdir
          );
        },
        onRowClick: (rowData, rowMeta) => {
          onRowClick(rowData, rowMeta, apiData);
        },
        onColumnSortChange: (changedColumn, direction) => {
          getData(
            apiData.page,
            apiData.pagesize,
            apiData.searchterm,
            false,
            changedColumn,
            direction
          );
        },
        onSearchChange: (searchText) => {
          getData(
            0,
            apiData.pagesize,
            searchText || "",
            false,
            apiData.sortcol,
            apiData.sortdir
          );
        },
        draggableColumns: {
          enabled: true,
        },
        onDownload: (buildHead, buildBody, columns, values) => {
          getData(0, apiData.total, apiData.searchterm, true);
          // cancel default  CSV download from table
          return false;
        },
        textLabels: {
          toolbar: {
            downloadCsv: "Excel Export (All)",
          },
        },
        serverSide: true,
        customToolbar: customToolbar,
        onColumnOrderChange: onColumnOrderChange,
        columnOrder: colSequence,
        selectableRows: "none"
      }}
      columns={cols}
      data={apiData.data}
    />
  );
}

export default MUITable;
