import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

type Props = {
    show: boolean,
    message: string,
    handleResult: any
  };
  
  const Confirm = (props: Props) => {
    const { show, message, handleResult } = props;

      return(
        <>
            <Dialog
                open={show}
                onClose={() => { handleResult(false) }}
            >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent dividers>
            <DialogContentText>
                {message}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => handleResult(false)}>Cancel</Button>
            <Button onClick={() => handleResult(true)}>Confirm</Button>
            </DialogActions>
        </Dialog>
        </>
      );

  }

export default Confirm;