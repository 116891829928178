import MUIDataTable from "mui-datatables";
import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

function MUITableD({ title, data, cols, sortOrder, onRowClick, ...rest }) {

  return (
    <MUIDataTable
      title={title}
      options={{
        onRowClick: (rowData, rowMeta) => {
          onRowClick(rowData, rowMeta);
        },
        draggableColumns: {
          enabled: true,
        },
        serverSide: false,
        download: true,
        print: false,
        selectableRows: 'none',
        viewColumns: false,
        sortOrder: sortOrder,
        onDownload: (buildHead, buildBody, columns, values) => {
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const fileExtension = ".xlsx";

          // console.log(values.forEach(val => console.log(val)));
          const json = values.reduce((result, val) => {
            const temp = {};
            val.data.forEach((v, idx) => {
              temp[columns[idx].label] = v;
            });
            result.push(temp);
            return result;
          }, []);

          const fileName = `DataExport`;
          const ws = utils.json_to_sheet(json);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
          const data = new Blob([excelBuffer], { type: fileType });
          saveAs(data, fileName + fileExtension);
          // cancel default  CSV download from table
          return false;
        },
        textLabels: {
          toolbar: {
            downloadCsv: "Excel Export"
          }
        }
      }}
      columns={cols}
      data={data}
    />
  );
}

export default MUITableD;