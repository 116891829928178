import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { axios } from "utilities";

type Props = {
    show: boolean,
    handlePwdChange: any
  };
  
  const ChangePwd = (props: Props) => {
    const { show, handlePwdChange } = props;
    const [inv, setInv] = useState(false);

    const applyChange = async (pwd1: string, pwd2: string) => {
      if (pwd1 != pwd2) {
        setInv(true);
      } else {
        axios.post("/api/account", pwd1).then((response) => {
          console.log(response.status);
        });
        handlePwdChange(true);
      }
    }

      const { t } = useTranslation("equipment");

      return(
        <>
            <Dialog
                open={show}
                onClose={() => { handlePwdChange(false) }}
                PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries());
                    applyChange(formJson.pwd1, formJson.pwd2);
                },
                }}
            >
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent dividers>
            {inv &&
                <DialogContentText style={{marginBottom: "20px"}}>
                    <Typography color="red">
                      Passwords are not identical
                    </Typography>
                </DialogContentText>
            }
            <Grid container spacing={3}>
                <Grid item xs>
                    <TextField
                        name="pwd1"
                        label={t("Password")}
                        defaultValue=""
                        type="password"
                        required={true}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        name="pwd2"
                        label={t("Repeat Password")}
                        defaultValue=""
                        type="password"
                        required={true}
                    />
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => handlePwdChange(false)}>Cancel</Button>
            <Button type="submit">Change Password</Button>
            </DialogActions>
        </Dialog>
        </>
      );

  }

export default ChangePwd;