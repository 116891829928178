import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { DialogSelectField, DialogTextField } from "components";
import { useTranslation } from "react-i18next";

type Props = {
    show: boolean,
    values: any,
    handleChange: any
  };
  
  const ConfigTLC = (props: Props) => {
    const { show, values, handleChange } = props;

    const options = {
        address: [
          {code: 0, description: "Cashless 1 (0x10)"},
          {code: 1, description: "Cashless 2 (0x60)"},
        ],
        mode: [
          {code: 0, description: "USD"},
          {code: 1, description: "EUR"},
        ],
        level: [
          {code: 0, description: "Level 1"},
          {code: 1, description: "Level 3"},
        ],
        dex: [
          {code: 0, description: "DEX/UCS"},
          {code: 1, description: "DDCMP"},
        ],
        counters: [
          {code: 0, description: "External"},
          {code: 1, description: "Internal"},
        ],
        wait: [
          {code: 0, description: "No delay"},
          {code: 1, description: "Wait 7s"},
        ]
    };

    var newConfig = {
        dialog: show,
        address: 0,
        mode: 0,
        level: 0,
        dex: 0,
        counters: 0,
        wait: 0,
        dexoffset: values.dexoffset
    };

    const readConfig = () => {
        const configStr = values.tlcconfig || "00000000";
        const config = parseInt(configStr, 16);
        var mask = 0x01;
        if (config & mask) {
        newConfig.address = 1;
        }
        mask = 0x02;
        if (config & mask) {
        newConfig.mode = 1;
        }
        mask = 0x04;
        if (config & mask) {
        newConfig.level = 1;
        }
        mask = 0x08;
        if (config & mask) {
        newConfig.dex = 1;
        }
        mask = 0x10;
        if (config & mask) {
        newConfig.counters = 1;
        }
        mask = 0x20;
        if (config & mask) {
        newConfig.wait = 1;
        }
    }

    const applyConfig = (configdata: any) => {
        var config = 0;
        if (configdata.address === "1") {
          config |= 0x01;
        }
        if (configdata.mode === "1") {
          config |= 0x02;
        }
        if (configdata.level === "1") {
          config |= 0x04;
        }
        if (configdata.dex === "1") {
          config |= 0x08;
        }
        if (configdata.counters === "1") {
          config |= 0x10;
        }
        if (configdata.wait === "1") {
          config |= 0x20;
        }
        var configStr = "00000000" + config.toString(16).toUpperCase();
        configStr = configStr.substring(configStr.length - 8);
        handleChange(configStr, configdata.dexoffset, true);
      }

      const { t } = useTranslation("equipment"); // Get tranlation
      readConfig();

      return(
        <>
            <Dialog
                open={show}
                onClose={() => { handleChange("", "", false) }}
                PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries());
                    applyConfig(formJson);
                },
                }}
            >
            <DialogTitle>Configure TLC</DialogTitle>
            <DialogContent dividers>
            <Grid container spacing={3}>
                <Grid item xs>
                    <DialogSelectField
                        name="address"
                        label={t("MDB Address")}
                        defaultValue={newConfig.address}
                        options={options.address}
                    />
                </Grid>
                <Grid item xs>
                    <DialogSelectField
                        name="mode"
                        label={t("MDB Currency")}
                        defaultValue={newConfig.mode}
                        options={options.mode}
                    />
                </Grid>
                <Grid item xs>
                    <DialogSelectField
                        name="level"
                        label={t("MDB Level")}
                        defaultValue={newConfig.level}
                        options={options.level}
                    />
                </Grid>
                <Grid item xs>
                    <DialogSelectField
                        name="dex"
                        label={t("DEX")}
                        defaultValue={newConfig.dex}
                        options={options.dex}
                    />
                </Grid>
                <Grid item xs>
                    <DialogSelectField
                        name="counters"
                        label={t("Counters")}
                        defaultValue={newConfig.counters}
                        options={options.counters}
                    />
                </Grid>
                <Grid item xs>
                    <DialogSelectField
                        name="wait"
                        label={t("Failed Vend Retry")}
                        defaultValue={newConfig.wait}
                        options={options.wait}
                    />
                </Grid>
                <Grid item xs>
                    <DialogTextField
                        name="dexoffset"
                        label={t("DEX selection offset")}
                        defaultValue={newConfig.dexoffset}
                    />
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => handleChange("", "", false)}>Cancel</Button>
            <Button type="submit">Apply Configuration</Button>
            </DialogActions>
        </Dialog>
        </>
      );

  }

export default ConfigTLC;