import { useState } from "react";
import { useLocation } from "react-router";
import {
  Button,
  Card,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { Machinegrid, ContentContainer, MUITable, TField } from "components";
import { useTranslation } from "react-i18next";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";

type Props = {
    columns: any,
    materials: any,
    handleChange: any
  };

  interface SelRowProperties {
    index: number;
    dataIndex: number;
  }  

const Columns = (props: Props) => {
    const { columns, materials, handleChange } = props;
  const [popups, setPopups] = useState({
    form: false,
    material: false,
  });

  const equipmentColInit = {
    clnt: "",
    colnr: "",
    equnr: "",
    guid: "",
    label: "",
    matnr: "",
    maxfill: 0,
    maxcapacity: 0,
    sorts: "",
    init: true,
  };

  const [formdata, setFormdata] = useState(equipmentColInit);
  const { t } = useTranslation("equipment");
  const location = useLocation();
  const [selectedRows, setSelectedRows] = useState<SelRowProperties[]>([]);

  const colcolumns = [
    {
      label: t("Equipment No."),
      name: "equnr",
      options: {
        display: false,
      },
    },
    { label: t("Column No."), name: "colnr" },
    { label: t("Material No."), name: "matnr" },
    { label: t("max. Capacity"), name: "maxcapacity" },
    { label: t("max. Fillrate"), name: "maxfill" },
    { label: t("Label"), name: "label" },
  ];

  const matcolumns = [
    {
      label: t("Image"),
      name: "image",
      options: {
        customBodyRender: (value: string) => (
          <img
            id="mat_img"
            src={value}
            alt=""
            style={{ maxWidth: "25px", maxHeight: "25px" }}
          />
        ),
      },
    },
    { label: t("Material Number"), name: "matnr" },
    { label: t("Description"), name: "description" },
  ];

  const handleAddColumn = () => {
    setFormdata(equipmentColInit);
    setPopups({...popups, form: true});
  }

  const handleAddColumnOk = () => {
    var newCols = columns;
    if (formdata.init) {
      formdata.init = false;
      newCols.push(formdata);
    } else {
      newCols[selectedRows[0].dataIndex] = formdata;
    }
    handleChange(newCols);
    setPopups({...popups, form: false});
  }

  const handleEditColumn = () => {
    const selected = columns[selectedRows[0].dataIndex];
    setFormdata(selected);
    setPopups({...popups, form: true});
  }

  const handleAssignMaterial = () => {
    setPopups({...popups, material: true});
  }

  const handleMaterialSelection = (rowdata: Array<any>) => {
    var newCols = columns;
    for (var i = 0; i < selectedRows.length; i++) {
      newCols[selectedRows[i].dataIndex].matnr = rowdata[1];
    }
    handleChange(newCols, rowdata[1]);
    setPopups({...popups, material: false});
  };

  const handleMaterialAssign = (index: number, matnr: String) => {
    var newCols = columns;
    newCols[index].matnr = matnr;
    handleChange(newCols);
  }

  const handleFormChange = (event: any) => {
    event.persist();
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });
  };

  const handleDelete = () => {
    var newCols = columns;
    for (var i = 0; i < selectedRows.length; i++) {
      newCols.splice(selectedRows[i].dataIndex, 1);
    }
    handleChange(newCols);
  };

  const options:MUIDataTableOptions = {
    filter: false,
    resizableColumns: true,
    download: false,
    print: false,
    responsive: "standard",
    draggableColumns: { enabled: false },
    selectableRows: "multiple",
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 75, 100],
    onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[]) => {
      setSelectedRows(allRowsSelected);
    },
  };

  const matoptions:MUIDataTableOptions = {
    download: false,
    print: false,
    responsive: "standard",
    draggableColumns: { enabled: false },
    selectableRows: "none",
    rowsPerPageOptions: [10, 50, 100],
  };

  var cardStyle = {
    paddingBottom: 20,
    paddingTop: 20,
    spacing: 5,
  };

  return (
    <>
    <ContentContainer py={4}>
        <Machinegrid
          key={materials.length}
          data={columns}
          materiallist={materials}
          handleAssign={handleMaterialAssign}
        />
    </ContentContainer>
    <ContentContainer py={4}>
      <MUIDataTable
              title=""
              data={columns}
              columns={colcolumns}
              options={options}
            />
        </ContentContainer>
        <ContentContainer py={4}>
        <Grid container spacing={3}>
                  <Grid item>
      <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={() => handleAddColumn()}
                  >
                    Add Column
                  </Button>
                  </Grid>
                  <Grid item>
                  <Button
                    disabled={selectedRows.length != 1}
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={() => handleEditColumn()}
                  >
                    Edit Column Definition
                  </Button>
                  </Grid>
                  <Grid item>
                  <Button
                    disabled={selectedRows.length == 0}
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={() => handleAssignMaterial()}
                  >
                    Assign Material
                  </Button>
                  </Grid>
                  <Grid item>
                  <Button
                    disabled={selectedRows.length == 0}
                    color="primary"
                    size="large"
                    variant="outlined"
                    onClick={() => handleDelete()}
                  >
                    Delete Column
                  </Button>
                  </Grid>
                  </Grid>
                </ContentContainer>
      <Dialog aria-labelledby="change-col-title" open={popups.form}>
        <DialogTitle id="change-col-title">
          Column Configuration
        </DialogTitle>
        <DialogContent dividers>
              <Grid
                container
                spacing={3}
              >
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    fullWidth
                    label={t("Col")}
                    name="colnr"
                    value={formdata.colnr || ""}
                    onChange={handleFormChange}
                    ro={!formdata.init}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    fullWidth
                    label={t("Capacity")}
                    name="maxcapacity"
                    value={formdata.maxcapacity || ""}
                    onChange={handleFormChange}
                    ro={false}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    fullWidth
                    label={t("Fillrate")}
                    name="maxfill"
                    value={formdata.maxfill || ""}
                    onChange={handleFormChange}
                    ro={false}
                  />
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={4}>
                  <TField
                    fullWidth
                    label={t("Label")}
                    name="label"
                    value={formdata.label || ""}
                    onChange={handleFormChange}
                    ro={false}
                  />
                </Grid>
              </Grid>
        </DialogContent>
        <DialogActions>
        <Button color="primary" onClick={() => setPopups({...popups, form: false})}>
            Cancel
          </Button>
          <Button autoFocus variant="contained" color="primary" onClick={() => handleAddColumnOk() }>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog aria-labelledby="change-material-title" open={popups.material}>
        <DialogTitle id="change-material-title">
          Select material to be assigned
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Container maxWidth={false}>
              <MUITable
                title=""
                name=""
                refresh={true}
                endpoint="/api/materials"
                cols={matcolumns}
                onRowClick={handleMaterialSelection}
                preloadData={location.state}
              />
            </Container>
          </Typography>
        </DialogContent>
        <DialogActions>
        <Button color="primary" onClick={() => setPopups({...popups, material: false})}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Columns;