import React /* , { useState, useEffect } */ from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Grid, Chip } from "@mui/material";
import { TField } from "components";
import { useTranslation } from "react-i18next";

function Detail({
  data,
  error,
  helper,
  codes,
  handleChange,
  className,
  ...rest
}) {
  const { t } = useTranslation("consumer");

  // const financial = (x) => {
  //   Number.parseFloat(x).toFixed(2);
  // }
  // console.log(financial(data.balance))

  return (
    <div>
      <Card>
        <CardContent>
          <form>
            <Grid
              container
              // className={clsx(classes.root, className)}
              spacing={3}
            //{...rest}
            >
              <Grid item lg={12} md={12} xl={12} xs={12}>
                {data.active ? (
                  <Chip
                    color="primary"
                    label={t("Active")}
                    clickable
                    onClick={handleChange}
                    name="active"
                    value="true"
                  />
                ) : (
                  <Chip
                    color="primary"
                    variant="outlined"
                    label={t("Inactive")}
                    clickable
                    onClick={handleChange}
                    name="active"
                    value="false"
                  />
                )}
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <TField
                  fullWidth
                  label={t("GUID")}
                  name="guid"
                  value={data.guid || ""}
                  onChange={handleChange}
                  ro={true}
                />
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <TField
                  fullWidth
                  label={t("First Name")}
                  name="firstname"
                  value={data.firstname || ""}
                  onChange={handleChange}
                  ro={false}
                />
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <TField
                  fullWidth
                  label={t("Last Name")}
                  name="lastname"
                  value={data.lastname || ""}
                  onChange={handleChange}
                  ro={false}
                />
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <TField
                  fullWidth
                  label={t("E-Mail address")}
                  name="emailaddress"
                  value={data.emailaddress || ""}
                  onChange={handleChange}
                  ro={false}
                />
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <TField
                  fullWidth
                  label={t("Password")}
                  name="password"
                  type="password"
                  value={data.password || ""}
                  onChange={handleChange}
                  ro={false}
                />
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <TField
                  fullWidth
                  label={t("Birthday")}
                  name="birthday"
                  value={data.birthday || ""}
                  onChange={handleChange}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  label={t("Balance")}
                  name="balance"
                  value={
                    data.balance != null
                      ? (data.balance / 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={handleChange}
                  ro={true}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  label={t("Points")}
                  name="points"
                  value={data.points != null ? data.points.toFixed(0) : "0"}
                  onChange={handleChange}
                  ro={true}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  label={t("Last Transaction")}
                  name="lasttransaction"
                  value={data.lasttransaction || ""}
                  onChange={handleChange}
                  ro={true}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

Detail.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Detail;
