import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import {
  //  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  //  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { useTranslation } from "react-i18next";

const PREFIX = 'Header';

const classes = {
  cont: `${PREFIX}-cont`,
  grid: `${PREFIX}-grid`,
  save: `${PREFIX}-save`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`&.${classes.cont}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.grid}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.save}`]: {
    textAlign: "center",
    justify: "center",
  }
}));

export default function Header(props) {
  const { t } = useTranslation("roadplanning");


  const [selectedDate, setSelectedDate] = useState(props.tomorrow);
  const [state, setState] = useState({
    salesOffice: "",
    route: "",
    routes: [],
  });

  const formatDateLabel = (date, invalidLabel) => {
    if (isPastDate(date)) {
      return t("Cannot plan for past days");
    }
    const inpDate = moment(date);
    return date && inpDate.isValid()
      ? inpDate.format("dddd, MMMM Do YYYY")
      : invalidLabel;
  };

  const isPastDate = (date) => {
    const inpDate = moment(date);
    if (inpDate.isBefore(moment(new Date()).add(-1, t("days")))) {
      return true;
    } else {
      return false;
    }
  };

  const handleSOChange = (event) => {
    event.persist();
    let value = event.target.value;
    const rts = props.data.routes.filter((elem) => elem.salesOffice === value);
    setState({ salesOffice: value, route: "", routes: rts });
  };

  const handleRouteChange = (event) => {
    event.persist();
    let value = event.target.value;
    setState({ ...state, route: value });
    props.onSelectionChange(state.salesOffice, value, state.routes);
  };

  const handleDateChange = (event) => {
    const newDate = event._d;
    setSelectedDate(newDate);
    if (!isPastDate(newDate)) {
      props.onDateChange(newDate);
    }
  };

  const salesOffices = props.data.salesOffices;

  return (
    <StyledPaper className={classes.cont}>
      <Grid className={classes.grid} container spacing={3}>
        <Grid item lg={3} md={3} xl={3} xs={3}>
          <TextField
            fullWidth
            color="primary"
            label={t("Sales Office")}
            value={state.salesOffice || ""}
            select
            onChange={handleSOChange}
          >
            {salesOffices.map((option) => (
              <MenuItem key={option.salesOffice} value={option.salesOffice}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={3} md={3} xl={3} xs={3}>
          <TextField
            fullWidth
            color="primary"
            label={t("Route")}
            value={state.route || ""}
            select
            onChange={handleRouteChange}
          >
            {state.routes.map((option) => (
              <MenuItem key={option.route} value={option.route}>
                {option.route}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={4}>
          <LocalizationProvider utils={MomentUtils}>
            <DatePicker
              inputVariant="outlined"
              fullWidth
              value={selectedDate}
              onChange={handleDateChange}
              labelFunc={formatDateLabel}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item className={classes.save} lg={2} md={2} xl={2} xs={2}>
          <div>
            <Typography>{t("Planning Stats")}:</Typography>
            <Tooltip title="Overdue and Proposed / Scheduled Machines / Scheduled Stops / # Cases">
              <Typography>
                {props.data.overdue} / {props.data.scheduled} /{" "}
                {props.data.banked} / {props.data.cases}
              </Typography>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </StyledPaper >
  );
}
