import { Autocomplete, TextField, Box, LinearProgress } from "@mui/material";
import { useField } from 'formik';
import { memo } from "react";
import { useTranslation } from "react-i18next";

// Docs:
// https://formik.org/docs/examples/with-material-ui
// https://formik.org/docs/api/useFormik
// https://formik.org/docs/api/useField

type OptionType = {
  code: string;
  description: string;
};

interface FormikAutocompleteProps {
  name: string;
  label: string;
  options: OptionType[];
  readOnly?: boolean; // Shortened readOnly. To pass readOnly in parent. MUI passes InputProps={{ readOnly }}
  disabled?: boolean;

  // Show autocomplete loading state for big codeDesc arrays. To prevent blocking page UI while loading codes in the background
  // loading prop may be deleted after change backend logic for codeDesc codes fetching
  loading?: boolean;
};

const FormikAutocomplete = (props: FormikAutocompleteProps) => {
  const { name, label, options, readOnly, disabled, loading } = props;
  const { t } = useTranslation("waitscr");

  const [field, meta, helpers] = useField(name);
  const error = meta.touched && meta.error;

  // Find the option object that matches the field value (code)
  const selectedOption = options.find(option => option.code === field.value) || null;

  return (
    <Box position="relative">
      {
        loading &&
        <LinearProgress
          sx={{
            position: "absolute",
            bottom: 28,
            left: 1,
            right: 1,
            height: "2px",
            borderBottomRightRadius: 3,
            borderBottomLeftRadius: 3,
          }}
        />
      }
      <Autocomplete
        {...field} // name, value, onChange, onBlur
        value={selectedOption}
        onChange={(_, newValue) => {
          helpers.setValue((newValue as OptionType)?.code || '');
        }}
        options={options}
        getOptionLabel={option => option.description}
        renderInput={params =>
          <TextField
            {...params as any} // as any is fix: Match FormikAutocomplete label type and i18n translated label type.
            label={label}
            margin="dense"
            helperText={error || loading && t("Loading") + "..."}
            error={Boolean(error)} />
        }
        readOnly={readOnly}
        disabled={disabled || loading}
      />
    </Box>
  );
};

export default memo(FormikAutocomplete);
