const initialValues = {
  "clnt": null,
  "matnr": "",
  "active": true,
  "description": "New Material", // TODO: Add translation
  "mattype": "",
  "matgroup": "",
  "baseuom": null,
  "product": null,
  "taxtype": null,
  "packsize": null,
  "packtype": null,
  "salesunit": null,
  "shelflife": 0,
  "note": null,
  "imageversion": 1,
  "image": "",
  "upc": null,
};

export default initialValues;
