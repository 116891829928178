import { createElement, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CollapsedLinkStrictType } from 'types';

type Props = {
  links: CollapsedLinkStrictType;
};

const SideNavCollapse = (props: Props) => {
  const { links } = props;
  const { title, icon, items } = links;

  const [open, setOpen] = useState(false);
  const toggleCollapse = () => setOpen(!open);

  const location = useLocation();

  return (
    <>
      {/* Toggler */}
      <ListItemButton onClick={toggleCollapse}>
        <ListItemIcon>
          {createElement(icon)}
        </ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLessIcon /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} unmountOnExit>
        <List component="div" disablePadding>
          {items.map((item, index) => (
            <ListItemButton
              key={index}
              component={NavLink}
              to={item.href}
              selected={location.pathname === item.href}
            >
              {/* Icon placeholder for the left ofset. To align text with Toggler text */}
              <ListItemIcon>&nbsp;</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default SideNavCollapse;
