import { createElement } from "react";
import { NavLink } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { SideNavSubLinkStrictType } from 'types';

type Props = {
  link: SideNavSubLinkStrictType;
};

const SideNavSubLink = (props: Props) => {
  const { link } = props;
  const { title, icon, href } = link;

  return (
    <ListItemButton
      component={NavLink}
      to={href}
    >
      <ListItemIcon>
        {createElement(icon)}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

export default SideNavSubLink;
