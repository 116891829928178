import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import { GuestTemplate, UserTemplate } from "templates";

import {
  Index,
  Login,
  ConsumerDetail,
  ConsumerList,
  ConsumerTransList,
  Dashboard,
  ElavonlogDetail,
  ElavonlogList,
  EquipmentDetail,
  EquipmentImport,
  EquipmentList,
  EquipmentSearch,
  EquipmentUnknownList,

  EquipmentsDetail,
  EquipmentsAdd,
  EquipmentsList,
  EquipmentsSearch,
  EquipmentsSearchMac,
  EquipmentsImport,

  MaterialsDetail,
  MaterialsAdd,
  MaterialsList,

  TransactionsList,
  TransactionsDetail,
  TransactionsSearch,

  ElavonUSList,
  ElavonUSDetail,

  OpenSource,
  SelectOption,
  SettlementList,
  TransactionDetail,
  TransactionList,
  TransactionSearch,
  E404,
  Account,

  TelemetryConfiguration,
  TelemetryMonitor
} from 'pages';

import {
  ROUTE_ROOT,
  ROUTE_INDEX,
  ROUTE_LOGIN,
  ROUTE_APP,
  ROUTE_DASHBOARD,
  ROUTE_OPENSOURCE,
  ROUTE_EQUIPMENTS,
  ROUTE_EQUIPMENTS_ADD,
  ROUTE_EQUIPMENTS_SEARCH,
  ROUTE_EQUIPMENTS_SEARCHMAC,
  ROUTE_EQUIPMENTS_UNKNOWN,
  ROUTE_EQUIPMENTS_DETAIL,
  ROUTE_EQUIPMENTS_IMPORT,
  ROUTE_MATERIALS,
  ROUTE_MATERIALS_DETAIL,
  ROUTE_MATERIALS_ADD,
  ROUTE_CONSUMERS,
  ROUTE_CONSUMERS_DETAIL,
  ROUTE_CONSUMERS_TRANSACTIONS,
  ROUTE_CONFIG_OPTIONS,
  ROUTE_CONFIG_OPTIONS_KEY,
  ROUTE_CONFIG_OPTIONS_KEY_DETAIL,
  ROUTE_TRANSACTIONS,
  ROUTE_TRANSACTIONS_SEARCH,
  ROUTE_TRANSACTIONS_DETAIL,
  ROUTE_SETTLEMENT,
  ROUTE_ELAVON_LOG,
  ROUTE_ELAVON_LOG_DETAIL,
  ROUTE_NOT_FOUND,
  ROUTE_ACCOUNT,
  ROUTE_TELEMETRY_CONFIG,
  ROUTE_TELEMETRY_MONITOR
} from 'const';

import { WaitScr } from 'components';

import { GuestRoute, UserRoute } from "./components"

function AppRoutes() {
  return (
    <Suspense fallback={<WaitScr />}>
      <Routes>

        {/* Routes available ONLY for NOT authenticated users */}
        <Route path={ROUTE_ROOT} element={
          <GuestTemplate>
            <GuestRoute />
          </GuestTemplate>
        }>
          <Route index element={<Index />} />
          <Route path={ROUTE_INDEX} element={<Index />} />
          <Route path={ROUTE_LOGIN} element={<Login />} />
        </Route>

        {/* Routes available ONLY for authenticated users */}
        <Route path={ROUTE_APP} element={
          <UserTemplate>
            <UserRoute />
          </UserTemplate>
        }>
          <Route index element={<TransactionsList />} />

          <Route path={ROUTE_MATERIALS} element={<MaterialsList />} />
          <Route path={ROUTE_MATERIALS_ADD} element={<MaterialsAdd />} />
          <Route path={ROUTE_MATERIALS_DETAIL} element={<MaterialsDetail />} />

          <Route path={ROUTE_DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTE_OPENSOURCE} element={<OpenSource />} />

          <Route path={ROUTE_EQUIPMENTS} element={<EquipmentsList />} />
          <Route path={ROUTE_EQUIPMENTS_ADD} element={<EquipmentsAdd />} />
          <Route path={ROUTE_EQUIPMENTS_SEARCH} element={<EquipmentsSearch />} />
          <Route path={ROUTE_EQUIPMENTS_SEARCHMAC} element={<EquipmentsSearchMac />} />
          <Route path={ROUTE_EQUIPMENTS_UNKNOWN} element={<EquipmentUnknownList />} />
          <Route path={ROUTE_EQUIPMENTS_DETAIL} element={<EquipmentsDetail />} />
          <Route path={ROUTE_EQUIPMENTS_IMPORT} element={<EquipmentsImport />} />

          <Route path={ROUTE_CONSUMERS} element={<ConsumerList />} />
          <Route path={ROUTE_CONSUMERS_DETAIL} element={<ConsumerDetail />} />
          <Route path={ROUTE_CONSUMERS_TRANSACTIONS} element={<ConsumerTransList />} />

          {/* TODO: Check are 3 routes needed. When will do config route */}
          <Route path={ROUTE_CONFIG_OPTIONS} element={<SelectOption />} />
          <Route path={ROUTE_CONFIG_OPTIONS_KEY} element={<SelectOption />} />
          <Route path={ROUTE_CONFIG_OPTIONS_KEY_DETAIL} element={<SelectOption />} />

          <Route path={ROUTE_TRANSACTIONS} element={<TransactionsList />} />
          <Route path={ROUTE_TRANSACTIONS_SEARCH} element={<TransactionsSearch />} />
          <Route path={ROUTE_TRANSACTIONS_DETAIL} element={<TransactionsDetail />} />

          <Route path={ROUTE_SETTLEMENT} element={<SettlementList />} />

          <Route path={ROUTE_ELAVON_LOG} element={<ElavonUSList />} />
          <Route path={ROUTE_ELAVON_LOG_DETAIL} element={<ElavonUSDetail />} />
          <Route path={ROUTE_ACCOUNT} element={<Account />} />

          <Route path={ROUTE_TELEMETRY_CONFIG} element={<TelemetryConfiguration />} />
          <Route path={ROUTE_TELEMETRY_MONITOR} element={<TelemetryMonitor />} />

        </Route>

        {/* Routes available for ALL users - authenticated and not authenticated */}
        {/* Add here if such routes exist */}
        {/* <Route path="page-path" element={<PageComponent />} /> */}

        {/* Route Not Found. Should be last */}
        <Route path={ROUTE_NOT_FOUND} element={<E404 />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
