import { object, string, number, setLocale } from "yup";

// Error message localization
setLocale({
  mixed: {
    default: 'invalid',
  },
  string: {
    min: ({ min }) => ({ key: 'min_long', values: { min } }),
    max: ({ max }) => ({ key: 'max_long', values: { max } }),
  },
  number: {
    min: ({ min }) => ({ key: 'min_long', values: { min } }),
    max: ({ max }) => ({ key: 'max_long', values: { max } }),
  },
});

// Schema - AFTER setLocale
const validationSchema = object({
  equnr: string().max(18, "Equipment number cannot have more than 18 characters").required(),
  serialnr: string().max(18, "Serial number cannot have more than 18 characters").nullable(),
  manufacturer: string().max(30, "Manufacturer cannot have more than 30 characters").nullable(),
  model: string().max(20, "Model cannot have more than 20 characters").nullable(),
  factoryserialnr: string().max(30, "Factory serial number cannot have more than 30 characters").nullable(),
  equtype: string().max(8, "Equipment Type cannot have more than 8 characters").nullable(),
  macaddress: string().required(),
  note: string().max(100, "Note cannot have more than 100 characters").nullable(),
  label: string().max(20, "Label cannot have more than 20 characters").nullable(),
  profitcenter: string().max(15, "Profitcenter cannot have more than 15 characters").nullable(),
  outlet: string().max(15, "Outlet cannot have more than 15 characters").nullable(),
  outletname: string().max(40, "Outlet Name cannot have more than 40 characters").nullable(),
  outletaddress: string().max(100, "Outlet Address cannot have more than 100 characters").nullable(),
  paymenttermserialnr: string().max(30, "Payment Terminal Serial cannot have more than 30 characters").nullable(),
  telemetrypath: string().max(30, "Telemetry Routing Path cannot have more than 30 characters").nullable(),
  telemetryid: string().max(30, "Telemetry ID cannot have more than 30 characters").nullable(),
});

export default validationSchema;
