import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Chip, Container } from "@mui/material";
import { codeDesc } from "utilities";

const PREFIX = 'Entries';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  chip: `${PREFIX}-chip`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {},

  [`&.${classes.container}`]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.chip}`]: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
  }
}));

function Entries() {

  const navigate = useNavigate();
  const [context, setContext] = useState([]);

  useEffect(() => {
    codeDesc("en", ["context"]).then((res) => {
      const cont = res.find((elem) => elem.context === "context");
      setContext(cont.items);
    });
  }, []);

  const handleClick = (option) => {
    navigate("/app/config/selectoption/" + option.code);
  };

  return (
    <StyledContainer className={classes.container}>
      {context.map((option) => (
        <Chip
          clickable
          key={option.code}
          className={classes.chip}
          label={option.description}
          onClick={() => handleClick(option)}
          variant="outlined"
          color="primary"
        />
      ))}
    </StyledContainer>
  );
}

export default Entries;
