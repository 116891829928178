import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Container, FormControlLabel } from "@mui/material";
import { Page, Header, MUITable } from "components";
import { codeDesc, getDesc } from "utilities";
import { useTranslation } from "react-i18next";
// import { useLocalStorage } from "components/LocalStorage";
import XLSX from "xlsx";

const PREFIX = 'EquipmentList';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function EquipmentList() {
  //Localstorage
  // const [displayCol, setDisplayCol] = useLocalStorage(
  //   "SETTINGS.PBDisplayableColumns", []
  // );
  // const [colSequence, setColSequence] = useLocalStorage(
  //   "SETTINGS.PBColSequence", []
  // );

  //End Localstorage

  const navigate = useNavigate();
  const location = useLocation();
  const [codes, setCodes] = useState(null);
  const { t } = useTranslation("equipment");
  const [colDefs, setColDefs] = useState();
  const [data, setData] = useState();

  const columns = [
    { label: t("Equipment No."), name: "equnr" },
    {
      label: t("Active"),
      name: "active",
      options: {
        customBodyRender: (value) => (
          <FormControlLabel
            label=""
            control={<Checkbox checked={value} color="primary" />}
          />
        ),
      },
    },
    {
      label: t("Demo Mode"),
      name: "demomode",
      options: {
        customBodyRender: (value) => (
          <FormControlLabel
            label=""
            control={<Checkbox checked={value} color="primary" />}
          />
        ),
      },
    },
    { label: t("Sales Org"), name: "sorg" },
    { label: t("Profit Center"), name: "profitcenter" },
    { label: t("Outlet"), name: "outlet" },
    { label: t("Macaddress"), name: "macaddress" },
    { label: t("TLC"), name: "tlcserialnr" },
    {
      label: t("Type"),
      name: "equtype",
      options: {
        customBodyRender: (value) => getDesc(codes, "equtype", value),
      },
    },
    { label: t("Manufacturer"), name: "manufacturer" },
    { label: t("Model"), name: "model" },
    { label: t("GPU Serial No."), name: "serialnr" },
    { label: t("Factory Serial No."), name: "factoryserialnr" },
    { label: t("Last Transaction"), name: "lasttransaction" },
  ];

  const handleRowClick = (rowdata, rowMeta, apiData) => {
    navigate("/app/equipment/" + rowdata[0], apiData);
  };

  const handleImportClick = () => {
    console.log("Import was clicked");
  };

  const handleAddClick = () => {
    navigate("/app/equipment/-");
  };

  useEffect(() => {
    codeDesc("en", ["sorg", "equtype", "manufacturer"]).then((res) => {
      // console.log(res);
      setCodes(res);
    });
  }, []);

  if (!codes) {
    return null;
  }

  return (
    <StyledPage className={classes.root} title={t("Equipment")}>
      <Container maxWidth={false} className={classes.container}>
        <Header
          title={t("Management")}
          subtitle={t("Equipment")}
          addFunc={handleAddClick}
        />
        <MUITable
          endpoint="/api/equipments"
          cols={columns}
          onRowClick={handleRowClick}
          preloadData={location.state}
        />
      </Container>
    </StyledPage>
  );
}

export default EquipmentList;
