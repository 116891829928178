import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { Page, Header, MUITable } from "components";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ReconnectingEventSource from "reconnecting-eventsource";

const PREFIX = "TransactionList";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledPage = styled(Page)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

function ElavonUSList() {
  const navigate = useNavigate();
  const location = useLocation();
  //const [codes, setCodes] = useState(null);

  const { t } = useTranslation("elavonlog");
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    { name: "guid", label: t("GUID"), options: { display: false } },
    { name: "clnt", label: t("Client") },
    { name: "timestamp", label: t("Timestamp") },
    { name: "ssl_txn_id", label: t("Transaction ID") },
    { name: "ssl_transaction_type", label: t("Transaction Type") },
    { name: "ssl_amount", label: t("Amount"), options: { customBodyRender: (value) => value.toFixed(2) }, },
    { name: "ssl_result_message", label: t("Result Message") },
    { name: "ssl_approval_code", label: t("Approval Code") },
    { name: "ssl_customer_code", label: t("Customer Code") },
    { name: "ssl_description", label: t("Description") },
    { name: "ssl_avs_response", label: t("AVS") },
    { name: "ssl_cvv2_response", label: t("CVV") },
    { name: "error_code", label: t("Error Code") },
  ];

  const handleRowClick = (rowdata, rowMeta, apiData) => {
    console.log(rowdata[0]);
    navigate("/app/elavonlog/" + rowdata[0], apiData);
  };

  const [listening, setListening] = useState(false);
  const [refresh, doRefresh] = useState(0);
  let eventSource = undefined;

  useEffect(() => {
    if (!listening) {
      eventSource = new ReconnectingEventSource(
        process.env.REACT_APP_API_URL + "sse/register"
      );

      eventSource.addEventListener("ElavonLog", (event) => {
        const result = JSON.parse(event.data);
        const date = new Date();
        doRefresh(date.valueOf());
        enqueueSnackbar(result.ssl_transaction_type + ": " + result.ssl_txn_id);
      });

      eventSource.onerror = (event) => {
        console.log(event.target.readyState);
        if (event.target.readyState === EventSource.CLOSED) {
          console.log("SSE closed (" + event.target.readyState + ")");
        }
      };

      eventSource.onopen = (event) => {
        console.log("SSE connection opened");
      };
      setListening(true);
    }
    return () => {
      if (eventSource) {
        eventSource.close();
        console.log("SSE closed");
      }
    };
  }, []);

  return (
    <StyledPage className={classes.root} title={t("Elavon Log")}>
      <Container maxWidth={false} className={classes.container}>
        <Header title={t("Elavon US Log")} />
        <MUITable
          endpoint="/api/logs"
          cols={columns}
          onRowClick={handleRowClick}
          preloadData={location.state}
          refresh={refresh}
        />
      </Container>
    </StyledPage>
  );
}

export default ElavonUSList;
