import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Tabs,
  Tab,
  Theme,
} from "@mui/material";

import Header from "./Header";
import Planning from "./Planning";
import Overview from "./Overview";
import Release from "./Release";
import { useTranslation } from "react-i18next";

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {},

  [`&.${classes.container}`]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  }
}));

const v = {
  matDetails: [
    {
      materialNum: "000000000000101728",
      description: "20Z PT 24LS_PA MTN BRY BLST",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000101891",
      description: "20Z PT 24LS SH_SPRITE",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000102592",
      description: "20Z PT 24LS SH_DT BARQS RTBR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000102603",
      description: "20Z PT 24LS SH_COKE",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000102604",
      description: "20Z PT 24LS SH_DT COKE",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000102605",
      description: "20Z PT 24LS SH_CHRY COKE",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000102659",
      description: "20Z PT 24LS SH_BARQS RTBR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000102660",
      description: "20Z PT 24LS SH_BARQS RED CRM SODA",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000102751",
      description: "20Z PT 24LS_PA LMN LIM",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000102752",
      description: "20Z PT 24LS_PA FRT PNCH",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000102782",
      description: "20Z PT 24LS_MELLO YELLO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000112259",
      description: "20Z PT 24LS_DAS",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000114229",
      description: "20Z PT 24LS SH_FANTA SBRY",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000114230",
      description: "20Z PT 24LS SH_FANTA ORG",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000114306",
      description: "20Z PT 24LS SH_FANTA PAPL",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000114532",
      description: "20Z PT 24LS SH_MM LMND",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000114633",
      description: "20Z PT 24LS SH_MM FRT PNCH NC",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000115313",
      description: "20Z PT 24LS_FANTA GRP",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000115583",
      description: "12Z CN 12FP_COKE",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000115584",
      description: "12Z CN 12FP_DT COKE",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000115586",
      description: "12Z CN 12FP_SPRITE",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000115964",
      description: "20Z PT 24LS SH_MM PNK LMND",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000116149",
      description: "12Z CN 12FP_BARQS RTBR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000116150",
      description: "12Z CN 12FP_MELLO YELLO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000116151",
      description: "12Z CN 12FP_FANTA ORG",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000116308",
      description: "12Z CN 12FP_DT BARQS RTBR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000117603",
      description: "12Z CN 12FP_DR PEP NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000117604",
      description: "12Z CN 12FP_DT DR PEP NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000117635",
      description: "20Z PT 24LS SH_DR PEP NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000117641",
      description: "20Z PT 24LS SH_DT DR PEP NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000117687",
      description: "20Z PT 24LS_PA ORG",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000118283",
      description: "20Z PT 24LS SH_FANTA PEACH",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000119516",
      description: "10.1Z PT 24LS_DAS",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000119790",
      description: "20Z PT 24LS SH_SPRITE ZRO SGR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000119791",
      description: "12Z CN 12FP_SPRITE ZRO SGR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000121023",
      description: "20Z PT 24LS SH_DAS LMN",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000121750",
      description: "12Z CN 12FP_COKE ZRO SGR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000121751",
      description: "20Z PT 24LS SH_COKE ZRO SGR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000123292",
      description: "20Z PT 24LS_PA GRP",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000125491",
      description: "12Z CN 12FP_CHRY COKE ZRO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000125522",
      description: "20Z PT 24LS SH_CHRY COKE ZRO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000129254",
      description: "20Z PT 24LS_SMTWTR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000130470",
      description: "20Z PT 8P HC_PA ZRO GRP",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000133129",
      description: "16Z CN 24LS_MON NRG",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000133131",
      description: "16Z CN 24LS_MON LO CARB NRG",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000133255",
      description: "20Z PT 24LS_DR PEP CHRY NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000134926",
      description: "15Z CN 12LS_JAVA MON MN BN",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000134929",
      description: "15Z CN 12LS_JAVA MON LOCA MOCA",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000135333",
      description: "18.5Z PT 12LS_GLD PK SWT BLK T",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000135334",
      description: "18.5Z PT 12LS_GLD PK DT T",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000135335",
      description: "18.5Z PT 12LS_GLD PK LMN SWT T",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000135336",
      description: "18.5Z PT 12LS_GLD PK SWT GRN T",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000135337",
      description: "18.5Z PT 12LS_GLD PK UNSWTND BLK T",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000137400",
      description: "16Z CN 24LS_MON NRG ZRO SGR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000137699",
      description: "15Z CN 12LS_JAVA MON VAN LT",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000138036",
      description: "15.5Z CN 24LS_MON REHB T AND LMND",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000143932",
      description: "18.5Z PT 12LS_GLD PK LMND T",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000145105",
      description: "16Z CN 24LS_MON NRG ZRO ULTRA",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000150885",
      description: "15.5Z CN 24LS_MON REHB T PLS PEACH",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000151817",
      description: "14Z PT 12LS_COR PWR PRTNCHOCELITE42G",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000151818",
      description: "14Z PT 12LS_COR PWR PRTN VANELITE42G",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000151938",
      description: "20Z PT 24LS_SPRITE TRPCL MIX",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000151978",
      description: "20Z PT 24LS SH_MM WMLN PNCH",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000152013",
      description: "18.5Z PT 12LS_GLD PK EXTRA SWT T",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000152196",
      description: "16Z CN 24LS_NOS NRG",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000152197",
      description: "16Z CN 24LS_NOS GT GRP",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000152202",
      description: "16Z CN 24LS_FUL THR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000152203",
      description: "16Z CN 24LS_FUL THR BLU AG",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000152292",
      description: "20Z PT 24LS_FANTA BRY",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000152921",
      description: "13.7Z PT 12LS_DNKN DNTS FRNCH VAN ICDCOF",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000152922",
      description: "13.7Z PT 12LS_DNKN DNTS MOC ICD COF",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000152923",
      description: "13.7Z PT 12LS_DNKN DNTS ORIG ICD COF",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000153008",
      description: "20Z PT 24LS SH_MM BRY PNCH",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000153225",
      description: "16Z CN 24LS_MON NRG ULTRA VIOLT",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000154898",
      description: "12Z PT 24LS_MM JTG OJ100",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000154900",
      description: "12Z PT 24LS_MM JTG APL J100",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000154914",
      description: "12Z PT 24LS_MM JTG CRNBRY APL RBY",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000156078",
      description: "20Z PT 12LS_VW ZRO XXX",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156083",
      description: "20Z PT 12LS_VW REVIVE",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156084",
      description: "20Z PT 12LS_VW FOC",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156085",
      description: "20Z PT 12LS_VW ESNTL",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156090",
      description: "20Z PT 12LS_VW PWR C",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156091",
      description: "20Z PT 12LS_VW NRG",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156109",
      description: "16Z PT 12LS_BODYARMOR SPRDRNK MXDBRY",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156110",
      description: "16Z PT 12LS_BODYARMOR SPRDRNK ORG MNG",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156111",
      description: "16Z PT 12LS_BODYARMOR SPRDRNK SBRY BAN",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156112",
      description: "16Z PT 12LS_BODYARMOR SPRDRNK FRT PNCH",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156114",
      description: "16Z PT 12LS_BODYARMOR SPRDRNK TRCPL PNCH",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156115",
      description: "16Z PT 12LS_BODYARMOR SPRDRNK BLKOT BRY",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156117",
      description: "16Z PT 12LS_BODYARMOR SPRDRNK WMLN SBRY",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156118",
      description: "16Z PT 12LS_BODYARMOR SPRDRNK PAPL CCNT",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156120",
      description: "16Z PT 12LS_BODYARMOR LYTE PEACH MNG",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156122",
      description: "16Z PT 12LS_BODYARMOR LYTE BLUBRY POM",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156137",
      description: "23.7Z PT 24LS_BODYARMOR SPRT WTR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000156182",
      description: "14Z PT 12LS_COR PWR PRTN VAN 26G",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156184",
      description: "14Z PT 12LS_COR PWR PRTN CHOC 26G",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156188",
      description: "14Z PT 12LS_COR PWR PRTN SBRY BAN26G",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156232",
      description: "20Z PT 24LS_COKE ORG VAN",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000156265",
      description: "20Z PT 24LS_COKE ORG VAN ZRO SGR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000156339",
      description: "16Z PT 12LS_BODYARMOR LYTE BRY PNCH",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156341",
      description: "16Z PT 12LS_BODYARMOR LYTE CCNT",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156347",
      description: "12Z PT 8P HC_BODYARMOR LYTE PCH MNG",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000156530",
      description: "16Z CN 24LS_MON ULT PARADISE",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000156560",
      description: "16Z CN 12LS_RGN TBF PEACH FIZZ",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156561",
      description: "16Z CN 12LS_RGN TBF RAZZBRY",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156563",
      description: "16Z CN 12LS_RGN TBF MLN MNI",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000156574",
      description: "16Z CN 12LS_RGN TBF LMN HDZ",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157041",
      description: "12Z CN SLK 24LS_COKE NRG ZRO SGR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000157042",
      description: "12Z CN SLK 24LS_COKE NRG",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000157112",
      description: "20Z PT 24LS_COKE CHRY VAN",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000157128",
      description: "14Z PT 12LS_COR PWR PRTNSBRYELITE42G",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157135",
      description: "16Z CN 12LS_RGN TBF ORG DRMSCLE",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157168",
      description: "12Z CN SLK 24LS_COKE NRG CHRY",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000157199",
      description: "12Z CN SLK 24LS_COKE NRG CHRY ZRO SGR",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000157249",
      description: "16Z CN 12LS_RGN TBF SBRY SUBLIME",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157253",
      description: "16Z CN 12LS_RGN TBF MNG-O-MATIC",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157269",
      description: "20Z PT 24LS_DR PEP CRM SODA NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000157319",
      description: "20Z PT 12LS_PA PWR WTR BRY CHRY",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157347",
      description: "20Z PT 12LS_PA PWR WTR TRPCL MNG",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157361",
      description: "20Z PT 24LS SH_FANTA PINA COL",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000157399",
      description: "12Z CN SLK 12LS_COKE W COF CARML",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157436",
      description: "12Z CN SLK 12LS_COKE W COF VAN",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157440",
      description: "12Z CN SLK 12LS_COKE W COF DRK BLND",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000157527",
      description: "16Z CN 24LS_MON NRG ULTRA FIESTA",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000158052",
      description: "20Z PT 24LS SH_DR PEP ZRO SGR CHRY NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000158053",
      description: "20Z PT 24LS SH_DR PEP ZRO SGRCRMSODA NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000158054",
      description: "20Z PT 24LS SH_DR PEP ZRO SGR NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000410069",
      description: "12Z CN SLK 12LS_COKE W COF DRKBLNDZROSGR",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000410074",
      description: "12Z CN SLK 12LS_COKE W COF VAN ZRO SGR",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000410339",
      description: "20Z PT 12LS_PA ZRO SGR PWR WTR LMN",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000410384",
      description: "16Z CN 24LS_MON NRG ULTRA WMLN",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000410646",
      description: "12Z CN 12FP PB_DR PEP ZRO SGR CHRY NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000410647",
      description: "12Z CN 12FP PB_DR PEP ZRO SGRCRMSODA NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000410659",
      description: "12Z CN 12FP_DR PEP ZRO SGR NKO",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000410681",
      description: "16Z CN 24LS_MON NRG ULTRA GLD",
      numerator: 1,
      denominator: 24,
    },
    {
      materialNum: "000000000000410703",
      description: "16Z CN 12LS_RGN TBF CHRY LIMAD",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000410704",
      description: "16Z CN 12LS_RGN TBF WHT GUMMY BR",
      numerator: 1,
      denominator: 12,
    },
    {
      materialNum: "000000000000411016",
      description: "20Z PT 24LS_BARQS FRNCH VAN CRM SODA",
      numerator: 1,
      denominator: 24,
    },
  ],
  planning: [
    {
      customerNum: "0500324957",
      equipmentNum: "000000000100600886",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-26T20:51:00.000Z",
      lastInventoryUpdate: "2021-04-26T20:51:00.000Z",
      lastFillRate: 0.3199999928474426,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3400000035762787,
      criticalMaterialNum: "000000000000152922",
      materialInventory: 184,
      materialConsumption: 2.430000066757202,
      dri: 3,
      pfr: 48,
      pfrc: 36,
      pfu: 80,
      numSalesDays: 30,
      ftc: false,
      sort: "110000002021-05-28",
      name: "LOYOLA UNIV MONROE-M01-4TH FL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN16004377",
      location: "Mall 4  ",
      dexErrors: false,
      online: "SLW232000116",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 28,
          refill_qty: 10,
          current_inventory: 18,
          average_based_on_rec: 4,
          average_consumption: 0.3199999928474426,
          average_data: "[0.26,0.14,0.48,0.4]",
          average_trend: 1.25,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000129254",
          consignment: 28,
          refill_qty: 16,
          current_inventory: 12,
          average_based_on_rec: 4,
          average_consumption: 0.5299999713897705,
          average_data: "[0.4,0.64,0.48,0.6]",
          average_trend: 1.1299999952316284,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000133129",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 14,
          average_based_on_rec: 4,
          average_consumption: 0.3199999928474426,
          average_data: "[0.2,0.41,0.48,0.2]",
          average_trend: 0.6299999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 11,
          current_inventory: 1,
          average_based_on_rec: 4,
          average_consumption: 0.20000000298023224,
          average_data: "[0.26,0.18,0.21,0.16]",
          average_trend: 0.800000011920929,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000143932",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000152921",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 4,
          average_consumption: 0.20000000298023224,
          average_data: "[0.11,0.27,0.28,0.12]",
          average_trend: 0.6000000238418579,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000152922",
          consignment: 12,
          refill_qty: 11,
          current_inventory: 1,
          average_based_on_rec: 4,
          average_consumption: 0.3400000035762787,
          average_data: "[0.34,0.18,0.34,0.48]",
          average_trend: 1.409999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000152923",
          consignment: 0,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0.06,0.18,0.21,0.08]",
          average_trend: 0.6200000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000154898",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 4,
          average_consumption: 0.14000000059604645,
          average_data: "[0.23,0.14,0,0.2]",
          average_trend: 1.4299999475479126,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154900",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 4,
          average_consumption: 0.10999999940395355,
          average_data: "[0.23,0,0.14,0.08]",
          average_trend: 0.7300000190734863,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 164,
      inv: 98,
    },
    {
      customerNum: "0500314589",
      equipmentNum: "000000000100523397",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T20:17:00.000Z",
      lastInventoryUpdate: "2021-05-21T20:17:00.000Z",
      lastFillRate: 0.4300000071525574,
      nextDeliveryPlan: "2021-05-21",
      nextDeliveryAct: "2021-05-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 1,
      criticalMaterialConsumption: 2.0199999809265137,
      criticalMaterialNum: "000000000000156530",
      materialInventory: 249,
      materialConsumption: 16.729999542236328,
      dri: -5,
      pfr: 36,
      pfrc: 8,
      pfu: 100,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-05-21",
      name: "MARKET SERVICE CENTER-M18",
      street: "5602 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "B31702",
      location: "OUTSIDE BR   ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000133129",
          consignment: 48,
          refill_qty: 6,
          current_inventory: 42,
          average_based_on_rec: 6,
          average_consumption: 1.149999976158142,
          average_data: "[0.75,1.47,0.53,1.75,0,2.4]",
          average_trend: 2.0899999141693115,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000133131",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000134926",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 1.2100000381469727,
          average_data: "[1,1.6,0.53,2.5,0,1.6]",
          average_trend: 1.3200000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000134929",
          consignment: 32,
          refill_qty: 22,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 4.28000020980835,
          average_data: "[6,4.27,3.73,2.5,6,3.2]",
          average_trend: 0.75,
          init_inventory: 32,
          last_inventory: 32,
        },
        {
          material_num: "000000000000145105",
          consignment: 48,
          refill_qty: 11,
          current_inventory: 37,
          average_based_on_rec: 6,
          average_consumption: 2.130000114440918,
          average_data: "[2,1.87,2,2.5,2,2.4]",
          average_trend: 1.1299999952316284,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000153225",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.93,0,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156530",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 2.0199999809265137,
          average_data: "[2.5,1.07,2.27,2.25,2,2]",
          average_trend: 0.9900000095367432,
          init_inventory: 1,
          last_inventory: 1,
        },
        {
          material_num: "000000000000410384",
          consignment: 48,
          refill_qty: 5,
          current_inventory: 43,
          average_based_on_rec: 6,
          average_consumption: 5.78000020980835,
          average_data: "[6,6.4,5.47,6,6,4.8]",
          average_trend: 0.8299999833106995,
          init_inventory: 72,
          last_inventory: 72,
        },
      ],
      cap: 272,
      inv: 172,
    },
    {
      customerNum: "0500302087",
      equipmentNum: "000000000100598157",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-03T19:38:00.000Z",
      lastInventoryUpdate: "2021-05-03T19:38:00.000Z",
      lastFillRate: 0.3100000023841858,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 72,
      criticalMaterialConsumption: 1.850000023841858,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 337,
      materialConsumption: 4.510000228881836,
      dri: 16,
      pfr: 33,
      pfrc: 53,
      pfu: 116,
      numSalesDays: 23,
      ftc: false,
      sort: "110000002021-06-11",
      name: "XAVIER UNIVERSITY-M1-BUILDING SVCS",
      street: "1041 NORMAN C FRANCIS",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN16003855",
      location: "across fro 1st 8 news ",
      dexErrors: true,
      online: "SLW232000344",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0.13,0.06,0.09,0.11,0.1,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102592",
          consignment: 8,
          refill_qty: 3,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0,0.09,0.28,0.15,0.15]",
          average_trend: 1.3600000143051147,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000102603",
          consignment: 72,
          refill_qty: 43,
          current_inventory: 29,
          average_based_on_rec: 6,
          average_consumption: 1.850000023841858,
          average_data: "[1.87,1.75,1.91,1.33,2.1,2.15]",
          average_trend: 1.159999966621399,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000102604",
          consignment: 32,
          refill_qty: 17,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.7099999785423279,
          average_data: "[1.07,0.19,0.61,0.5,1.22,0.68]",
          average_trend: 0.9599999785423279,
          init_inventory: 32,
          last_inventory: 32,
        },
        {
          material_num: "000000000000102659",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.19,0.26,0,0.1,0.2]",
          average_trend: 1.5399999618530273,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.11999999731779099,
          average_data: "[0,0.25,0.04,0.11,0,0.29]",
          average_trend: 2.4200000762939453,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114306",
          consignment: 16,
          refill_qty: 6,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.4,0.38,0.22,0.17,0.1,0.2]",
          average_trend: 0.800000011920929,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000114532",
          consignment: 16,
          refill_qty: 4,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.14000000059604645,
          average_data: "[0.4,0.06,0.04,0.11,0.05,0.15]",
          average_trend: 1.0700000524520874,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000114633",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0.19,0.3,0.11,0.39,0.29]",
          average_trend: 1.3799999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121751",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.13,0,0.04,0.06,0.15,0.2]",
          average_trend: 2,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0,0.13,0.06,0.24,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0,0.17,0.11,0,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000135335",
          consignment: 7,
          refill_qty: 5,
          current_inventory: 2,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0.2,0,0.09,0.06,0,0.15]",
          average_trend: 1.8799999952316284,
          init_inventory: 4,
          last_inventory: 4,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.13,0.26,0.06,0.24,0.24]",
          average_trend: 1.5,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152013",
          consignment: 16,
          refill_qty: 10,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0.4,0,0.35,0.22,0,0.73]",
          average_trend: 2.609999895095825,
          init_inventory: 13,
          last_inventory: 13,
        },
        {
          material_num: "000000000000154900",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.17000000178813934,
          average_data: "[0,0,0.48,0.17,0.34,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 343,
      inv: 227,
    },
    {
      customerNum: "0500917531",
      equipmentNum: "000000000100716303",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T17:46:00.000Z",
      lastInventoryUpdate: "2021-05-21T17:46:00.000Z",
      lastFillRate: 0.25,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 8,
      criticalMaterialConsumption: 0.800000011920929,
      criticalMaterialNum: "000000000000133255",
      materialInventory: 173,
      materialConsumption: 4.579999923706055,
      dri: 4,
      pfr: 22,
      pfrc: 29,
      pfu: 43,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-05-28",
      name: "MARKET SERVICE CENTER-M20-FRONT GAT",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "DR201630FA21",
      location: "FRONT GATE OUTSIDE  ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000114229",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.6700000166893005,
          average_data: "[0.46,0.8,0.4,0.53,0.77,1.08]",
          average_trend: 1.6100000143051147,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114230",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0.15,0.13,0.27,0.27,0.77,0.46]",
          average_trend: 1.350000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114306",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 1.2899999618530273,
          average_data: "[1.23,1.6,1.6,0.53,1.38,1.38]",
          average_trend: 1.0700000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 46,
          refill_qty: 4,
          current_inventory: 42,
          average_based_on_rec: 6,
          average_consumption: 0.7400000095367432,
          average_data: "[0.46,0.67,1.33,0,0,2]",
          average_trend: 2.700000047683716,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0,0.27,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000133255",
          consignment: 23,
          refill_qty: 20,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.800000011920929,
          average_data: "[0.62,0.53,0.53,0.8,0,2.31]",
          average_trend: 2.890000104904175,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000157269",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.6000000238418579,
          average_data: "[0,0.67,0.8,0.27,0,1.85]",
          average_trend: 3.0799999237060547,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000158052",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.09000000357627869,
          average_data: "[0,0,0.53,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 188,
      inv: 145,
    },
    {
      customerNum: "0500325090",
      equipmentNum: "000000000100715094",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-29T00:57:00.000Z",
      lastInventoryUpdate: "2021-04-29T00:57:00.000Z",
      lastFillRate: 0.3499999940395355,
      nextDeliveryPlan: "2021-05-21",
      nextDeliveryAct: "2021-05-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 14,
      criticalMaterialConsumption: 0.5600000023841858,
      criticalMaterialNum: "000000000000129254",
      materialInventory: 177,
      materialConsumption: 3.1600000858306885,
      dri: -3,
      pfr: 51,
      pfrc: 43,
      pfu: 93,
      numSalesDays: 27,
      ftc: false,
      sort: "100000002021-05-21",
      name: "LOYOLA UNIV MONROE HALL-M02-1ST FL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY07002991",
      location: "   ",
      dexErrors: false,
      online: "SLW232000377",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Tu",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 9,
          current_inventory: 3,
          average_based_on_rec: 5,
          average_consumption: 0.3199999928474426,
          average_data: "[0.23,0.41,0.55,0.18,0.24]",
          average_trend: 0.75,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 5,
          average_consumption: 0.17000000178813934,
          average_data: "[0.17,0.23,0.28,0,0.18]",
          average_trend: 1.059999942779541,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 3,
          current_inventory: 3,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0.11,0,0.14,0.18,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102660",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.2800000011920929,
          average_data: "[0.17,0.27,0.48,0.09,0.41]",
          average_trend: 1.4600000381469727,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 28,
          refill_qty: 18,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.6600000262260437,
          average_data: "[0.69,0.23,0.62,1,0.76]",
          average_trend: 1.149999976158142,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 5,
          average_consumption: 0.15000000596046448,
          average_data: "[0.26,0.18,0.14,0,0.18]",
          average_trend: 1.2000000476837158,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 8,
          refill_qty: 4,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.11999999731779099,
          average_data: "[0.09,0.14,0.07,0.18,0.12]",
          average_trend: 1,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000121751",
          consignment: 6,
          refill_qty: 2,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.07000000029802322,
          average_data: "[0.09,0,0.07,0.09,0.12]",
          average_trend: 1.7100000381469727,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000125522",
          consignment: 8,
          refill_qty: 4,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.11999999731779099,
          average_data: "[0,0.18,0.14,0.18,0.12]",
          average_trend: 1,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000129254",
          consignment: 14,
          refill_qty: 14,
          current_inventory: 0,
          average_based_on_rec: 5,
          average_consumption: 0.5600000023841858,
          average_data: "[0.4,0.59,0.62,0.45,0.76]",
          average_trend: 1.3600000143051147,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000135333",
          consignment: 14,
          refill_qty: 6,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.20000000298023224,
          average_data: "[0.26,0.27,0.21,0.09,0.18]",
          average_trend: 0.8999999761581421,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000152922",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.18000000715255737,
          average_data: "[0.2,0,0.14,0.36,0.18]",
          average_trend: 1,
          init_inventory: 9,
          last_inventory: 9,
        },
        {
          material_num: "000000000000152923",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 5,
          average_consumption: 0.23999999463558197,
          average_data: "[0,0.36,0.21,0.36,0.29]",
          average_trend: 1.2100000381469727,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 180,
      inv: 87,
    },
    {
      customerNum: "0500855269",
      equipmentNum: "000000000103215714",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T23:41:00.000Z",
      lastInventoryUpdate: "2021-05-23T13:40:45.000Z",
      lastFillRate: 0.27000001072883606,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 21,
      criticalMaterialConsumption: 1.0099999904632568,
      criticalMaterialNum: "000000000000117687",
      materialInventory: 313,
      materialConsumption: 7.75,
      dri: 17,
      pfr: 19,
      pfrc: 55,
      pfu: 71,
      numSalesDays: 3,
      ftc: false,
      sort: "110000002021-06-11",
      name: "LOYOLA UNIV-M05-REC PLEX",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "CM19009534",
      location: "gym 6  ",
      dexErrors: false,
      online: "SLW232000406",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102751",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[0.23,1.33,0.27,0.27,0.88,0.81]",
          average_trend: 1.2899999618530273,
          init_inventory: 24,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[0.56,1.33,0.09,0.8,0.53,0.44]",
          average_trend: 0.699999988079071,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 96,
          refill_qty: 8,
          current_inventory: 88,
          average_based_on_rec: 6,
          average_consumption: 1.7000000476837158,
          average_data: "[2.19,3.06,1,1.2,1.71,1.04]",
          average_trend: 0.6100000143051147,
          init_inventory: 96,
          last_inventory: 94,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 1.0099999904632568,
          average_data: "[0.88,1.33,1.09,0.67,1.12,0.96]",
          average_trend: 0.949999988079071,
          init_inventory: 24,
          last_inventory: 21,
        },
        {
          material_num: "000000000000123292",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.47999998927116394,
          average_data: "[0.42,1,0.18,0.53,0.47,0.3]",
          average_trend: 0.6299999952316284,
          init_inventory: 24,
          last_inventory: 23,
        },
        {
          material_num: "000000000000129254",
          consignment: 48,
          refill_qty: 11,
          current_inventory: 37,
          average_based_on_rec: 6,
          average_consumption: 2.059999942779541,
          average_data: "[2.09,2.67,1.73,1.73,2.53,1.63]",
          average_trend: 0.7900000214576721,
          init_inventory: 48,
          last_inventory: 44,
        },
        {
          material_num: "000000000000156083",
          consignment: 24,
          refill_qty: 13,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.14,0.06,0,0.13,0.06,0.22]",
          average_trend: 2.200000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156085",
          consignment: 20,
          refill_qty: 9,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0.14,0.33,0.18,0.4,0.12,0.22]",
          average_trend: 0.9599999785423279,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156110",
          consignment: 24,
          refill_qty: 13,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.19,0.44,0.45,0.13,0.29,0.3]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156112",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0.42,0.56,0.18,0.13,0.29,0.37]",
          average_trend: 1.1200000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156120",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0.47,0.33,0,0.53,0.12,0.22]",
          average_trend: 0.7900000214576721,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 356,
      inv: 285,
    },
    {
      customerNum: "0500275621",
      equipmentNum: "000000000100528499",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T22:46:00.000Z",
      lastInventoryUpdate: "2021-05-12T22:46:00.000Z",
      lastFillRate: 0.41999998688697815,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.6800000071525574,
      criticalMaterialNum: "000000000000114633",
      materialInventory: 168,
      materialConsumption: 5.739999771118164,
      dri: 3,
      pfr: 51,
      pfrc: 58,
      pfu: 87,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-05-28",
      name: "LOYOLA UNIV-M15-MUSIC",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY06011623",
      location: "4th floor  ON 4TH FLO  ",
      dexErrors: false,
      online: "SLW232000227",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.5699999928474426,
          average_data: "[0.22,0.41,0.47,0.41,0.93,1]",
          average_trend: 1.75,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.7200000286102295,
          average_data: "[0.54,0.5,0.74,0.69,1.2,0.67]",
          average_trend: 0.9300000071525574,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.07,0,0,0.14,0.27,0.11]",
          average_trend: 1.100000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102660",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.07,0.14,0.21,0.62,0.8,0.44]",
          average_trend: 1.159999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 25,
          refill_qty: 14,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.949999988079071,
          average_data: "[0.61,0.77,1.32,0.83,0.93,1.22]",
          average_trend: 1.2799999713897705,
          init_inventory: 25,
          last_inventory: 25,
        },
        {
          material_num: "000000000000114306",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0.24,0,0.21,0.69,0.93,1.11]",
          average_trend: 2.0899999141693115,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114633",
          consignment: 12,
          refill_qty: 10,
          current_inventory: 2,
          average_based_on_rec: 6,
          average_consumption: 0.6800000071525574,
          average_data: "[0.17,0.55,0.63,0.48,1.6,0.67]",
          average_trend: 0.9900000095367432,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 7,
          refill_qty: 2,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.09000000357627869,
          average_data: "[0.17,0.05,0.11,0.07,0,0.11]",
          average_trend: 1.2200000286102295,
          init_inventory: 7,
          last_inventory: 7,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0.05,0.09,0.11,0.14,0.27,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 18,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 1.2300000190734863,
          average_data: "[0.39,0.64,1,1.31,2.13,1.89]",
          average_trend: 1.5399999618530273,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135335",
          consignment: 16,
          refill_qty: 6,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.29,0.36,0.68,0.14,0.8,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
      ],
      cap: 168,
      inv: 81,
    },
    {
      customerNum: "0500935033",
      equipmentNum: "000000000100282884",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-03-05T21:33:00.000Z",
      lastInventoryUpdate: "2021-03-05T21:33:00.000Z",
      lastFillRate: 0.10000000149011612,
      nextDeliveryPlan: "9999-12-31T00:00:00.000Z",
      nextDeliveryAct: "9999-12-31T00:00:00.000Z",
      schedulingMethod: "I",
      criticalMaterialInventory: null,
      criticalMaterialConsumption: null,
      criticalMaterialNum: null,
      materialInventory: null,
      materialConsumption: null,
      dri: 0,
      pfr: 100,
      pfrc: 100,
      pfu: 173,
      numSalesDays: 82,
      ftc: false,
      sort: "110253402214400000",
      name: "XAVIER UNIV ART VILLAGE BUILD-M1-#3",
      street: "3520 PINE ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY08001095",
      location: "   ",
      dexErrors: false,
      online: "SLW232000346",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000102603",
          consignment: 10,
          refill_qty: 10,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000112259",
          consignment: 25,
          refill_qty: 25,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000114532",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000114633",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000121751",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000135334",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000135336",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000143932",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000156232",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
      ],
      cap: 173,
      inv: 0,
    },
    {
      customerNum: "0500733246",
      equipmentNum: "000000000100630852",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T19:08:00.000Z",
      lastInventoryUpdate: "2021-05-13T19:08:00.000Z",
      lastFillRate: 0.18000000715255737,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 16,
      criticalMaterialConsumption: 0.5199999809265137,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 250,
      materialConsumption: 2.6500000953674316,
      dri: 16,
      pfr: 17,
      pfrc: 31,
      pfu: 43,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-06-11",
      name: "TULANE-M1-MUSSAFER HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN16006492",
      location: "Mussaffer 1st  ",
      dexErrors: false,
      online: "SLW232000120",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 5,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.14,0.2,0.3,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000102603",
          consignment: 15,
          refill_qty: 5,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.3400000035762787,
          average_data: "[0.2,0.21,0.39,0.39,0.52]",
          average_trend: 1.5299999713897705,
          init_inventory: 15,
          last_inventory: 15,
        },
        {
          material_num: "000000000000102604",
          consignment: 16,
          refill_qty: 7,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.5199999809265137,
          average_data: "[0.47,0.29,0.24,0.65,0.96]",
          average_trend: 1.850000023841858,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102659",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 5,
          average_consumption: 0.10000000149011612,
          average_data: "[0,0.07,0.39,0.04,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000112259",
          consignment: 32,
          refill_qty: 1,
          current_inventory: 31,
          average_based_on_rec: 5,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0.05,0.15,0.04,0]",
          average_trend: 0,
          init_inventory: 32,
          last_inventory: 32,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.03999999910593033,
          average_data: "[0.07,0.05,0.05,0.04,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 5,
          average_consumption: 0.15000000596046448,
          average_data: "[0.13,0.07,0.39,0,0.15]",
          average_trend: 1,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000121751",
          consignment: 8,
          refill_qty: 3,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.20000000298023224,
          average_data: "[0.33,0,0.39,0.13,0.15]",
          average_trend: 0.75,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 5,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0.26,0.15,0.13,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 12,
          average_based_on_rec: 5,
          average_consumption: 0,
          average_data: "[0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 4,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0.05,0.04,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135334",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.029999999329447746,
          average_data: "[0,0,0,0.09,0.07]",
          average_trend: 2.3299999237060547,
          init_inventory: 11,
          last_inventory: 11,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.15000000596046448,
          average_data: "[0.27,0.26,0,0.22,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135337",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 12,
          average_based_on_rec: 5,
          average_consumption: 0,
          average_data: "[0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000137400",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.03999999910593033,
          average_data: "[0.13,0.02,0.05,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000154898",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 5,
          average_consumption: 0.5,
          average_data: "[0.67,0.33,0.44,0,1.04]",
          average_trend: 2.0799999237060547,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154900",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.18000000715255737,
          average_data: "[0.13,0.02,0.24,0.52,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000154914",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0,0.1,0.05,0.22,0.07]",
          average_trend: 0.7799999713897705,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 251,
      inv: 208,
    },
    {
      customerNum: "0600186317",
      equipmentNum: "000000000100514044",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T20:02:00.000Z",
      lastInventoryUpdate: "2021-05-06T20:02:00.000Z",
      lastFillRate: 0.1899999976158142,
      nextDeliveryPlan: "2021-06-03",
      nextDeliveryAct: "2021-06-03",
      schedulingMethod: "D",
      criticalMaterialInventory: 6,
      criticalMaterialConsumption: 0.18000000715255737,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 106,
      materialConsumption: 1.3200000524520874,
      dri: 8,
      pfr: 27,
      pfrc: 34,
      pfu: 29,
      numSalesDays: 20,
      ftc: false,
      sort: "110000002021-06-03",
      name: "XAVIER UNIVERSITY-M06",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "031627",
      location: "ADMIN ANNE 1sr  ",
      dexErrors: false,
      online: "SLW232000244",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 4,
          average_consumption: 0.1899999976158142,
          average_data: "[0.19,0.05,0.46,0.07]",
          average_trend: 0.3700000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0.06,0.2,0.17,0.07]",
          average_trend: 0.5400000214576721,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 4,
          current_inventory: 2,
          average_based_on_rec: 4,
          average_consumption: 0.18000000715255737,
          average_data: "[0.13,0.2,0,0.4]",
          average_trend: 2.2200000286102295,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102659",
          consignment: 6,
          refill_qty: 3,
          current_inventory: 3,
          average_based_on_rec: 4,
          average_consumption: 0.14000000059604645,
          average_data: "[0.09,0.15,0.17,0.13]",
          average_trend: 0.9300000071525574,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 4,
          average_consumption: 0.36000001430511475,
          average_data: "[0.23,0.45,0.29,0.47]",
          average_trend: 1.309999942779541,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 4,
          average_consumption: 0.029999999329447746,
          average_data: "[0.02,0,0.04,0.07]",
          average_trend: 2.3299999237060547,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000151978",
          consignment: 28,
          refill_qty: 6,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.28999999165534973,
          average_data: "[0.28,0.4,0.33,0.13]",
          average_trend: 0.44999998807907104,
          init_inventory: 28,
          last_inventory: 28,
        },
      ],
      cap: 106,
      inv: 77,
    },
    {
      customerNum: "0500315141",
      equipmentNum: "000000000100604863",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T23:10:00.000Z",
      lastInventoryUpdate: "2021-05-21T23:10:00.000Z",
      lastFillRate: 0.33000001311302185,
      nextDeliveryPlan: "2021-06-03",
      nextDeliveryAct: "2021-06-03",
      schedulingMethod: "D",
      criticalMaterialInventory: 40,
      criticalMaterialConsumption: 3.059999942779541,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 286,
      materialConsumption: 11.619999885559082,
      dri: 8,
      pfr: 22,
      pfrc: 52,
      pfu: 65,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-03",
      name: "MARKET SERVICE CENTER-M01",
      street: "5601 CITRUS BLVD",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN16005631",
      location: "Production  1st Floor ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 32,
          refill_qty: 9,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 1.6299999952316284,
          average_data: "[0.77,2.15,1.2,1.2,2.46,2]",
          average_trend: 1.2300000190734863,
          init_inventory: 32,
          last_inventory: 32,
        },
        {
          material_num: "000000000000102592",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0.46,0.46,0,0,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102603",
          consignment: 40,
          refill_qty: 16,
          current_inventory: 24,
          average_based_on_rec: 6,
          average_consumption: 3.059999942779541,
          average_data: "[3.23,3.23,2.67,2.67,3.23,3.33]",
          average_trend: 1.090000033378601,
          init_inventory: 40,
          last_inventory: 40,
        },
        {
          material_num: "000000000000102604",
          consignment: 40,
          refill_qty: 9,
          current_inventory: 31,
          average_based_on_rec: 6,
          average_consumption: 1.7999999523162842,
          average_data: "[1.54,2.15,2.67,0.13,2.15,2.13]",
          average_trend: 1.1799999475479126,
          init_inventory: 40,
          last_inventory: 40,
        },
        {
          material_num: "000000000000102659",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.9800000190734863,
          average_data: "[1.23,1.54,1.07,0.93,0.46,0.67]",
          average_trend: 0.6800000071525574,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102660",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.5199999809265137,
          average_data: "[0.15,0.62,0.67,0.4,0.62,0.67]",
          average_trend: 1.2899999618530273,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.6100000143051147,
          average_data: "[0.62,0.31,1.07,0.4,0.46,0.8]",
          average_trend: 1.309999942779541,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.4300000071525574,
          average_data: "[0.77,0.15,0.4,0.27,0.46,0.53]",
          average_trend: 1.2300000190734863,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 32,
          refill_qty: 8,
          current_inventory: 24,
          average_based_on_rec: 6,
          average_consumption: 1.4600000381469727,
          average_data: "[1.69,1.38,1.2,1.07,1.69,1.73]",
          average_trend: 1.1799999475479126,
          init_inventory: 32,
          last_inventory: 32,
        },
        {
          material_num: "000000000000157041",
          consignment: 8,
          refill_qty: 0,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000157042",
          consignment: 8,
          refill_qty: 1,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0.46,0,0,0,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000157168",
          consignment: 8,
          refill_qty: 0,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000157199",
          consignment: 8,
          refill_qty: 0,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000157436",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.46,0.67,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157440",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0,0.27,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000410069",
          consignment: 8,
          refill_qty: 1,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0,0,0,0.92,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000411016",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.5099999904632568,
          average_data: "[0.77,0,1.2,0.27,0,0.8]",
          average_trend: 1.5700000524520874,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 286,
      inv: 221,
    },
    {
      customerNum: "0600186780",
      equipmentNum: "000000000100518689",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T20:47:00.000Z",
      lastInventoryUpdate: "2021-05-05T20:47:00.000Z",
      lastFillRate: 0.05000000074505806,
      nextDeliveryPlan: "2021-07-02",
      nextDeliveryAct: "2021-07-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.10999999940395355,
      criticalMaterialNum: "000000000000116149",
      materialInventory: 132,
      materialConsumption: 0.30000001192092896,
      dri: 189,
      pfr: 6,
      pfrc: 47,
      pfu: 9,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-07-02",
      name: "LOYOLA UNIV-M08-MARQUETTE",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "6627433",
      location: "MARQUETTE    ",
      dexErrors: false,
      online: "SLW232000103",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 36,
          refill_qty: 36,
          current_inventory: 34,
          average_based_on_rec: 3,
          average_consumption: 0.07000000029802322,
          average_data: "[0.06,0.03,0.11]",
          average_trend: 1.5700000524520874,
          init_inventory: 36,
          last_inventory: 36,
        },
        {
          material_num: "000000000000115584",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 3,
          average_consumption: 0,
          average_data: "[0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000115586",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 23,
          average_based_on_rec: 3,
          average_consumption: 0.03999999910593033,
          average_data: "[0,0.07,0.04]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000116149",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 21,
          average_based_on_rec: 3,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0.34,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 3,
          average_consumption: 0.05000000074505806,
          average_data: "[0.04,0.1,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121750",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 3,
          average_consumption: 0.029999999329447746,
          average_data: "[0.05,0,0.04]",
          average_trend: 1.3300000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 132,
      inv: 123,
    },
    {
      customerNum: "0500381743",
      equipmentNum: "000000000101105810",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T19:46:00.000Z",
      lastInventoryUpdate: "2021-05-11T19:46:00.000Z",
      lastFillRate: 0.25,
      nextDeliveryPlan: "2021-06-21",
      nextDeliveryAct: "2021-06-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 10,
      criticalMaterialConsumption: 0.2199999988079071,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 160,
      materialConsumption: 1.9600000381469727,
      dri: 26,
      pfr: 21,
      pfrc: 50,
      pfu: 35,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-06-21",
      name: "XAVIER CONVOCATION CENTER-M2-ANNEX",
      street: "7900 WASHINGTON AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN17001782",
      location: "front lobb   ",
      dexErrors: false,
      online: "SLW232000307",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 12,
          average_based_on_rec: 3,
          average_consumption: 0.20000000298023224,
          average_data: "[0.14,0.26,0.2]",
          average_trend: 1,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000101891",
          consignment: 10,
          refill_qty: 10,
          current_inventory: 6,
          average_based_on_rec: 3,
          average_consumption: 0.2199999988079071,
          average_data: "[0.23,0.24,0.2]",
          average_trend: 0.9100000262260437,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.11999999731779099,
          average_data: "[0.02,0.19,0.15]",
          average_trend: 1.25,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 10,
          refill_qty: 10,
          current_inventory: 9,
          average_based_on_rec: 3,
          average_consumption: 0.05999999865889549,
          average_data: "[0.05,0.14,0]",
          average_trend: 0,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000102752",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 14,
          average_based_on_rec: 3,
          average_consumption: 0.11999999731779099,
          average_data: "[0.09,0.02,0.25]",
          average_trend: 2.0799999237060547,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 19,
          average_based_on_rec: 3,
          average_consumption: 0.30000001192092896,
          average_data: "[0.3,0.14,0.45]",
          average_trend: 1.5,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114230",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 4,
          average_based_on_rec: 3,
          average_consumption: 0.07000000029802322,
          average_data: "[0.07,0.05,0.1]",
          average_trend: 1.4299999475479126,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000114532",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 9,
          average_based_on_rec: 3,
          average_consumption: 0.18000000715255737,
          average_data: "[0.27,0.28,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 4,
          average_based_on_rec: 3,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0,0.2]",
          average_trend: 2.859999895095825,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 17,
          average_based_on_rec: 3,
          average_consumption: 0.4399999976158142,
          average_data: "[0.45,0.52,0.35]",
          average_trend: 0.800000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 21,
          average_based_on_rec: 3,
          average_consumption: 0.18000000715255737,
          average_data: "[0.18,0.26,0.1]",
          average_trend: 0.5600000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 160,
      inv: 125,
    },
    {
      customerNum: "0600187427",
      equipmentNum: "000000000100279134",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T21:46:00.000Z",
      lastInventoryUpdate: "2021-05-19T21:46:00.000Z",
      lastFillRate: 0.30000001192092896,
      nextDeliveryPlan: "2021-07-09",
      nextDeliveryAct: "2021-07-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 0.9100000262260437,
      criticalMaterialNum: "000000000000115583",
      materialInventory: 190,
      materialConsumption: 2.2799999713897705,
      dri: 45,
      pfr: 10,
      pfrc: 62,
      pfu: 21,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-07-09",
      name: "LOYOLA UNIV ACT CTR-M1",
      street: "501 PINE ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "135243",
      location: "   ",
      dexErrors: false,
      online: "SLW232000281",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 48,
          refill_qty: 7,
          current_inventory: 41,
          average_based_on_rec: 5,
          average_consumption: 0.9100000262260437,
          average_data: "[0.77,1.45,0.34,0.58,1.43]",
          average_trend: 1.5700000524520874,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 5,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.73,0,0,0.22]",
          average_trend: 1.159999966621399,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 2,
          current_inventory: 46,
          average_based_on_rec: 5,
          average_consumption: 0.27000001072883606,
          average_data: "[0,0.91,0.07,0.12,0.26]",
          average_trend: 0.9599999785423279,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.38999998569488525,
          average_data: "[0.39,0.27,0.69,0.19,0.43]",
          average_trend: 1.100000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117604",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 5,
          average_consumption: 0.36000001430511475,
          average_data: "[0.39,0.45,0.9,0.04,0]",
          average_trend: 0,
          init_inventory: 22,
          last_inventory: 22,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 5,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.55,0.14,0.12,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 192,
      inv: 171,
    },
    {
      customerNum: "0600187466",
      equipmentNum: "000000000100507747",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T19:47:00.000Z",
      lastInventoryUpdate: "2021-05-19T19:47:00.000Z",
      lastFillRate: 0.1599999964237213,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.0299999713897705,
      criticalMaterialNum: "000000000000129254",
      materialInventory: 324,
      materialConsumption: 6.460000038146973,
      dri: 16,
      pfr: 16,
      pfrc: 45,
      pfu: 53,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-11",
      name: "TULANE-M12-GOLDRING WOLDENBERG HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN10002196",
      location: "GOLDRING W 1ST FLOOR KITCHEN ",
      dexErrors: true,
      online: "SLW232000428",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 3,
          current_inventory: 45,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.33,0.53,0.2,0.54,0,0.22]",
          average_trend: 0.7300000190734863,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 1.0299999713897705,
          average_data: "[1.67,1.07,0.47,1.85,0.67,0.44]",
          average_trend: 0.4300000071525574,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.8199999928474426,
          average_data: "[0.5,1.07,0.8,1.85,0,0.67]",
          average_trend: 0.8199999928474426,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0.17,0.27,0.4,0,0.33,0.22]",
          average_trend: 0.9599999785423279,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0.17,0.27,0.13,0.54,0.33,0.56]",
          average_trend: 1.7000000476837158,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135336",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0.67,0.4,0.33,0.15,0,0.11]",
          average_trend: 0.38999998569488525,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000152202",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.2199999988079071,
          average_data: "[0.17,0.53,0,0.31,0,0.33]",
          average_trend: 1.5,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152921",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[0.17,0.67,0.8,1,0.67,0.44]",
          average_trend: 0.699999988079071,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152922",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0.17,0,0.4,0.54,0.33,0.44]",
          average_trend: 1.4199999570846558,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152923",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.6000000238418579,
          average_data: "[0.33,0.67,0.47,0.77,1,0.33]",
          average_trend: 0.550000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154898",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.3199999928474426,
          average_data: "[0.33,0.8,0.13,0.23,0,0.44]",
          average_trend: 1.3799999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154900",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.4699999988079071,
          average_data: "[0.67,0.67,0.4,0.62,0,0.44]",
          average_trend: 0.9399999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154914",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.17,0.53,0,0.54,0,0.33]",
          average_trend: 1.2699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410384",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.6600000262260437,
          average_data: "[0.83,2.13,0,0.69,0,0.33]",
          average_trend: 0.5,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 324,
      inv: 271,
    },
    {
      customerNum: "0500936951",
      equipmentNum: "000000000100532006",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T23:59:00.000Z",
      lastInventoryUpdate: "2021-05-22T05:00:22.000Z",
      lastFillRate: 0.2199999988079071,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 9,
      criticalMaterialConsumption: 0.38999998569488525,
      criticalMaterialNum: "000000000000114306",
      materialInventory: 85,
      materialConsumption: 1.3200000524520874,
      dri: 17,
      pfr: 33,
      pfrc: 49,
      pfu: 38,
      numSalesDays: 4,
      ftc: false,
      sort: "110000002021-06-09",
      name: "XAVIER UNIVERSITY NEW DORM-M01-4TH",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY02038132",
      location: "De Porres    ",
      dexErrors: true,
      online: "SLW232000288",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 19,
          current_inventory: 5,
          average_based_on_rec: 4,
          average_consumption: 0.20999999344348907,
          average_data: "[0.03,0.15,0.34,0.3]",
          average_trend: 1.4299999475479126,
          init_inventory: 20,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 4,
          average_consumption: 0.10999999940395355,
          average_data: "[0.11,0,0.34,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 22,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 4,
          average_consumption: 0.05000000074505806,
          average_data: "[0.03,0.07,0.09,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 4,
          average_consumption: 0.3499999940395355,
          average_data: "[0.17,0.22,0.4,0.59]",
          average_trend: 1.690000057220459,
          init_inventory: 24,
          last_inventory: 20,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0.03,0.15,0.17,0.15]",
          average_trend: 1.149999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114306",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.38999998569488525,
          average_data: "[0.11,0.37,0.17,0.89]",
          average_trend: 2.2799999713897705,
          init_inventory: 12,
          last_inventory: 9,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0,0.17,0.15]",
          average_trend: 1.8799999952316284,
          init_inventory: 12,
          last_inventory: 10,
        },
      ],
      cap: 114,
      inv: 76,
    },
    {
      customerNum: "0500716406",
      equipmentNum: "000000000100515552",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T18:40:00.000Z",
      lastInventoryUpdate: "2021-05-05T18:40:00.000Z",
      lastFillRate: 0.20999999344348907,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.30000001192092896,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 247,
      materialConsumption: 3.130000114440918,
      dri: 16,
      pfr: 30,
      pfrc: 47,
      pfu: 76,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-06-11",
      name: "LOYOLA UNIV-M1-FOUNDERS HALL",
      street: "7214 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN08004947",
      location: "Founders H 1sr floor  ",
      dexErrors: false,
      online: "SLW232000357",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 4,
          average_consumption: 0.5600000023841858,
          average_data: "[0.5,0.39,0.17,1.19]",
          average_trend: 2.130000114440918,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 4,
          average_consumption: 0.30000001192092896,
          average_data: "[0.13,0.35,0.17,0.56]",
          average_trend: 1.8700000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0.22,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 4,
          average_consumption: 0.28999999165534973,
          average_data: "[0.17,0.09,0.44,0.44]",
          average_trend: 1.5199999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 4,
          average_consumption: 0.20000000298023224,
          average_data: "[0.19,0.22,0.39,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 4,
          average_consumption: 0.23000000417232513,
          average_data: "[0.4,0.26,0.06,0.19]",
          average_trend: 0.8299999833106995,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.14000000059604645,
          average_data: "[0.17,0.13,0.11,0.13]",
          average_trend: 0.9300000071525574,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 4,
          average_consumption: 0.03999999910593033,
          average_data: "[0.17,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 4,
          average_consumption: 0.3199999928474426,
          average_data: "[0.5,0.22,0.44,0.13]",
          average_trend: 0.4099999964237213,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 4,
          average_consumption: 0.4000000059604645,
          average_data: "[0.5,0.22,0.56,0.31]",
          average_trend: 0.7799999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 4,
          average_consumption: 0.23000000417232513,
          average_data: "[0.5,0.13,0.11,0.19]",
          average_trend: 0.8299999833106995,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000152922",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.14000000059604645,
          average_data: "[0.25,0.3,0,0]",
          average_trend: 0,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000156078",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.2199999988079071,
          average_data: "[0.25,0,0.33,0.31]",
          average_trend: 1.409999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 252,
      inv: 176,
    },
    {
      customerNum: "0501009939",
      equipmentNum: "000000000100289835",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T18:53:00.000Z",
      lastInventoryUpdate: "2021-05-17T18:53:00.000Z",
      lastFillRate: 0.33000001311302185,
      nextDeliveryPlan: "2021-05-27",
      nextDeliveryAct: "2021-05-27",
      schedulingMethod: "D",
      criticalMaterialInventory: 17,
      criticalMaterialConsumption: 1.690000057220459,
      criticalMaterialNum: "000000000000145105",
      materialInventory: 264,
      materialConsumption: 15.220000267028809,
      dri: 1,
      pfr: 58,
      pfrc: 61,
      pfu: 171,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-05-27",
      name: "TULANE LBC-M1",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY07014248",
      location: "LBC-GROUND   ",
      dexErrors: false,
      online: "SLW232000277",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 14,
          refill_qty: 9,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.9800000190734863,
          average_data: "[0.56,1.22,1,0.8,1.38,0.93]",
          average_trend: 0.949999988079071,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000102603",
          consignment: 35,
          refill_qty: 11,
          current_inventory: 24,
          average_based_on_rec: 6,
          average_consumption: 1.1399999856948853,
          average_data: "[1.33,1.11,0.86,1.2,1.38,0.93]",
          average_trend: 0.8199999928474426,
          init_inventory: 35,
          last_inventory: 35,
        },
        {
          material_num: "000000000000102604",
          consignment: 21,
          refill_qty: 14,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 1.5499999523162842,
          average_data: "[2.33,2.11,0.71,0.4,2,1.73]",
          average_trend: 1.1200000047683716,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000102659",
          consignment: 21,
          refill_qty: 14,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0.22,0.22,0.29,0.2,0.46,0.27]",
          average_trend: 0.9599999785423279,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000112259",
          consignment: 28,
          refill_qty: 18,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 1.8899999856948853,
          average_data: "[0.44,1.44,2.86,2.6,1.85,2.13]",
          average_trend: 1.1299999952316284,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000114532",
          consignment: 21,
          refill_qty: 12,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 1.3300000429153442,
          average_data: "[1.22,1.22,1.29,1.6,1.69,0.93]",
          average_trend: 0.699999988079071,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000117635",
          consignment: 14,
          refill_qty: 6,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.5899999737739563,
          average_data: "[0.33,0.67,1.14,0.6,0,0.8]",
          average_trend: 1.3600000143051147,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000121751",
          consignment: 21,
          refill_qty: 5,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.44999998807907104,
          average_data: "[0.33,0.56,0.86,0,0.92,0]",
          average_trend: 0,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000129254",
          consignment: 32,
          refill_qty: 27,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 2.9100000858306885,
          average_data: "[3,3.56,2.86,3.2,4.15,0.67]",
          average_trend: 0.23000000417232513,
          init_inventory: 32,
          last_inventory: 32,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 1.1699999570846558,
          average_data: "[0.89,1.11,1,1.4,2.62,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 21,
          refill_qty: 14,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.7900000214576721,
          average_data: "[1.22,0.56,0.43,0.8,0.92,0.8]",
          average_trend: 1.0099999904632568,
          init_inventory: 15,
          last_inventory: 15,
        },
        {
          material_num: "000000000000135335",
          consignment: 14,
          refill_qty: 7,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.44999998807907104,
          average_data: "[0.56,0.67,0.43,0,0.77,0.27]",
          average_trend: 0.6000000238418579,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 23,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 1.690000057220459,
          average_data: "[1,1.56,0.86,3,2.77,0.93]",
          average_trend: 0.550000011920929,
          init_inventory: 17,
          last_inventory: 17,
        },
      ],
      cap: 290,
      inv: 119,
    },
    {
      customerNum: "0500510344",
      equipmentNum: "000000000101977295",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T21:48:00.000Z",
      lastInventoryUpdate: "2021-05-21T21:48:00.000Z",
      lastFillRate: 0.3199999928474426,
      nextDeliveryPlan: "2021-05-26",
      nextDeliveryAct: "2021-05-26",
      schedulingMethod: "D",
      criticalMaterialInventory: 16,
      criticalMaterialConsumption: 3.2899999618530273,
      criticalMaterialNum: "000000000000117641",
      materialInventory: 333,
      materialConsumption: 24.549999237060547,
      dri: 0,
      pfr: 39,
      pfrc: 36,
      pfu: 130,
      numSalesDays: 5,
      ftc: false,
      sort: "000000002021-05-26",
      name: "MARKET SERVICE CENTER 1RST FL-M07-A",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "DN12000067",
      location: "ADMINISTRA 1ST FLOOR BREAKROOM ",
      dexErrors: true,
      online: "09790664551",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 16,
          refill_qty: 6,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 1.0800000429153442,
          average_data: "[0.75,0.75,0.5,2.5,0.5,1.5]",
          average_trend: 1.3899999856948853,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102592",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0,0.5,0,0,0.75]",
          average_trend: 3.569999933242798,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000102603",
          consignment: 32,
          refill_qty: 28,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 5.5,
          average_data: "[6,5,4,5.75,5.75,6.5]",
          average_trend: 1.1799999475479126,
          init_inventory: 32,
          last_inventory: 32,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 17,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 3.3299999237060547,
          average_data: "[1,4.25,2.75,5.75,1.5,4.75]",
          average_trend: 1.4299999475479126,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 16,
          refill_qty: 8,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 1.4600000381469727,
          average_data: "[1.5,1,1,2.25,1.25,1.75]",
          average_trend: 1.2000000476837158,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000117635",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0,0.5,0.5,0,0,1]",
          average_trend: 3.0299999713897705,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000117641",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 3.2899999618530273,
          average_data: "[3,4,1.75,4,3,4]",
          average_trend: 1.2200000286102295,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000119790",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0,0,0.5,1,0.5,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000121751",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 1.3799999952316284,
          average_data: "[1.5,1.5,0.5,1.5,1.5,1.75]",
          average_trend: 1.2699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157041",
          consignment: 16,
          refill_qty: 1,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.17000000178813934,
          average_data: "[0,0,0,1,0,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000157042",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0,0,1.25,2.25,0,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000157112",
          consignment: 9,
          refill_qty: 9,
          current_inventory: 9,
          average_based_on_rec: 2,
          average_consumption: 0,
          average_data: "[0,0]",
          average_trend: 1,
          init_inventory: 9,
          last_inventory: 9,
        },
        {
          material_num: "000000000000157168",
          consignment: 16,
          refill_qty: 6,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 1.1299999952316284,
          average_data: "[0.75,1.75,1.25,0.75,1.5,0.75]",
          average_trend: 0.6600000262260437,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000157199",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.5400000214576721,
          average_data: "[1.5,0.75,1,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157269",
          consignment: 8,
          refill_qty: 7,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 1.25,
          average_data: "[1.5,1.5,1.25,1.25,1.25,0.75]",
          average_trend: 0.6000000238418579,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000157399",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0,0.5,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157436",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0,0,1.25,0,1.5,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157440",
          consignment: 8,
          refill_qty: 0,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000158052",
          consignment: 8,
          refill_qty: 5,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 1,
          average_data: "[1.5,0.75,0.75,1.75,0.25,1]",
          average_trend: 1,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000158054",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 5,
          average_consumption: 1.100000023841858,
          average_data: "[0,0,4.25,1.25,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410069",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.75,
          average_data: "[0,3,0,1.5,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000410074",
          consignment: 8,
          refill_qty: 3,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0,1.75,0,0,0,1.75]",
          average_trend: 3.0199999809265137,
          init_inventory: 8,
          last_inventory: 8,
        },
      ],
      cap: 333,
      inv: 203,
    },
    {
      customerNum: "0600186329",
      equipmentNum: "000000000100516242",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T21:08:00.000Z",
      lastInventoryUpdate: "2021-05-17T21:08:00.000Z",
      lastFillRate: 0.10000000149011612,
      nextDeliveryPlan: "2021-07-02",
      nextDeliveryAct: "2021-07-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.23000000417232513,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 176,
      materialConsumption: 1.2999999523162842,
      dri: 39,
      pfr: 9,
      pfrc: 35,
      pfu: 16,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-07-02",
      name: "TULANE INSTITUTE OF SPORTS MED-M1",
      street: "202 JANET YULMAN WAY",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY03011995",
      location: "SPORTS INS 1ST FLOOR garage ",
      dexErrors: false,
      online: "SLW232000275",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 5,
          average_consumption: 0.14000000059604645,
          average_data: "[0.29,0.09,0.14,0.18,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.11999999731779099,
          average_data: "[0,0.05,0.17,0.27,0.11]",
          average_trend: 0.9200000166893005,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.28999999165534973,
          average_data: "[0.29,0.26,0.38,0.23,0.28]",
          average_trend: 0.9700000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.23000000417232513,
          average_data: "[0.34,0.28,0.26,0.23,0.06]",
          average_trend: 0.25999999046325684,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 56,
          refill_qty: 2,
          current_inventory: 54,
          average_based_on_rec: 5,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0.24,0,0.23,0.28]",
          average_trend: 1.8700000047683716,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.10000000149011612,
          average_data: "[0.06,0,0.23,0.23,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.10000000149011612,
          average_data: "[0.31,0.05,0.12,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 5,
          average_consumption: 0.17000000178813934,
          average_data: "[0.14,0.28,0.23,0.18,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 176,
      inv: 160,
    },
    {
      customerNum: "0500917280",
      equipmentNum: "000000000100058658",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T17:45:00.000Z",
      lastInventoryUpdate: "2021-05-21T17:45:00.000Z",
      lastFillRate: 0.2199999988079071,
      nextDeliveryPlan: "2021-06-16",
      nextDeliveryAct: "2021-06-16",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 0.8600000143051147,
      criticalMaterialNum: "000000000000135335",
      materialInventory: 276,
      materialConsumption: 5.480000019073486,
      dri: 21,
      pfr: 10,
      pfrc: 51,
      pfu: 30,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-16",
      name: "MARKET SERVICE CENTER-M19-FRONT GAT",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "RY02028938",
      location: "front gate   ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 69,
          refill_qty: 6,
          current_inventory: 63,
          average_based_on_rec: 6,
          average_consumption: 1.159999966621399,
          average_data: "[0.77,3.07,1.33,0.4,0,1.38]",
          average_trend: 1.190000057220459,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000114532",
          consignment: 46,
          refill_qty: 4,
          current_inventory: 42,
          average_based_on_rec: 6,
          average_consumption: 0.6700000166893005,
          average_data: "[0.92,0.4,0.8,0.8,0.31,0.77]",
          average_trend: 1.149999976158142,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000114633",
          consignment: 46,
          refill_qty: 5,
          current_inventory: 41,
          average_based_on_rec: 6,
          average_consumption: 0.949999988079071,
          average_data: "[0.92,0.13,0.8,0.93,0.92,2]",
          average_trend: 2.109999895095825,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000135333",
          consignment: 23,
          refill_qty: 1,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.18000000715255737,
          average_data: "[0.15,0.67,0,0.27,0,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000135335",
          consignment: 23,
          refill_qty: 5,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.8600000143051147,
          average_data: "[0.31,0.13,0,2.4,0,2.31]",
          average_trend: 2.690000057220459,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000151978",
          consignment: 46,
          refill_qty: 6,
          current_inventory: 40,
          average_based_on_rec: 6,
          average_consumption: 1.149999976158142,
          average_data: "[0.46,0.8,1.07,2.4,0.62,1.54]",
          average_trend: 1.340000033378601,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000410339",
          consignment: 23,
          refill_qty: 3,
          current_inventory: 20,
          average_based_on_rec: 5,
          average_consumption: 0.5099999904632568,
          average_data: "[0,0.8,0.53,0,1.23]",
          average_trend: 2.4100000858306885,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 276,
      inv: 246,
    },
    {
      customerNum: "0600187127",
      equipmentNum: "000000000100055714",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T20:41:00.000Z",
      lastInventoryUpdate: "2021-05-12T20:41:00.000Z",
      lastFillRate: 0.23000000417232513,
      nextDeliveryPlan: "2021-06-14",
      nextDeliveryAct: "2021-06-14",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3400000035762787,
      criticalMaterialNum: "000000000000158052",
      materialInventory: 120,
      materialConsumption: 2.4100000858306885,
      dri: 19,
      pfr: 30,
      pfrc: 66,
      pfu: 36,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-06-14",
      name: "DOMINICAN HS-M5",
      street: "2830 PINE ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "231952D",
      location: "outside ca   ",
      dexErrors: false,
      online: "SLW232000388",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 10,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.6700000166893005,
          average_data: "[0.23,1.17,0.83,0.45,0.82,0.5]",
          average_trend: 0.75,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.5,
          average_data: "[0.46,0.29,0.55,0.55,0.55,0.6]",
          average_trend: 1.2000000476837158,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0.23,0.34,0.28,0.18,0.45,0.4]",
          average_trend: 1.2899999618530273,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.23,0.15,0,0,0,0.2]",
          average_trend: 2,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000125522",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.49000000953674316,
          average_data: "[0.38,0.59,0.48,0.45,0.45,0.6]",
          average_trend: 1.2200000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000158052",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 5,
          average_consumption: 0.3400000035762787,
          average_data: "[0,0.41,0.64,0.27,0.4]",
          average_trend: 1.1799999475479126,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 120,
      inv: 84,
    },
    {
      customerNum: "0600186847",
      equipmentNum: "000000000100608434",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T01:32:00.000Z",
      lastInventoryUpdate: "2021-05-13T01:32:00.000Z",
      lastFillRate: 0.699999988079071,
      nextDeliveryPlan: "2021-05-21",
      nextDeliveryAct: "2021-05-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 1.2100000381469727,
      criticalMaterialNum: "000000000000410339",
      materialInventory: 182,
      materialConsumption: 14.729999542236328,
      dri: -3,
      pfr: 93,
      pfrc: 87,
      pfu: 254,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-21",
      name: "TULANE-M14-HOWARD TILTON",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN16006469",
      location: "Howard-Til BASEMENT LOUNGE ",
      dexErrors: false,
      online: "SLW232000378",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 112,
          refill_qty: 112,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 5.659999847412109,
          average_data: "[7.56,4.27,5.07,5.29,0.77,11]",
          average_trend: 1.940000057220459,
          init_inventory: 57,
          last_inventory: 57,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 2.2699999809265137,
          average_data: "[2.67,2.4,2.53,3.29,0.31,2.4]",
          average_trend: 1.059999942779541,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.7900000214576721,
          average_data: "[1.22,0.93,0.67,1.14,0.77,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156137",
          consignment: 32,
          refill_qty: 32,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 2.0899999141693115,
          average_data: "[1.78,0.8,1.6,2.86,2.31,3.2]",
          average_trend: 1.5299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156182",
          consignment: 16,
          refill_qty: 14,
          current_inventory: 2,
          average_based_on_rec: 6,
          average_consumption: 0.4300000071525574,
          average_data: "[0,0,0.4,0.29,1.08,0.8]",
          average_trend: 1.8600000143051147,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000156184",
          consignment: 24,
          refill_qty: 23,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 0.75,
          average_data: "[1,0.4,0.4,0.29,1.08,1.3]",
          average_trend: 1.7300000190734863,
          init_inventory: 11,
          last_inventory: 11,
        },
        {
          material_num: "000000000000156188",
          consignment: 16,
          refill_qty: 14,
          current_inventory: 2,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0.67,0.4,0.27,0.29,1.23,0.6]",
          average_trend: 1.0299999713897705,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000157319",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 0.949999988079071,
          average_data: "[0.11,1.6,0.93,0,1.85,1.2]",
          average_trend: 1.2599999904632568,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000410339",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.2100000381469727,
          average_data: "[1.33,1.07,0.53,1.29,1.85,1.2]",
          average_trend: 0.9900000095367432,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 272,
      inv: 18,
    },
    {
      customerNum: "0600187308",
      equipmentNum: "000000000100527676",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-14T02:19:00.000Z",
      lastInventoryUpdate: "2021-05-14T02:19:00.000Z",
      lastFillRate: 0.25,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 2.1700000762939453,
      criticalMaterialNum: "000000000000129254",
      materialInventory: 352,
      materialConsumption: 4.539999961853027,
      dri: 10,
      pfr: 17,
      pfrc: 28,
      pfu: 62,
      numSalesDays: 12,
      ftc: false,
      sort: "110000002021-06-04",
      name: "TULANE-M43-REILLY CENTER",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN09001849",
      location: "YULMAN STA 2ND FLOOR RECREATION ",
      dexErrors: false,
      online: "SLW232000350",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MWF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 18,
          current_inventory: 30,
          average_based_on_rec: 6,
          average_consumption: 1.4600000381469727,
          average_data: "[0.29,1.25,1.31,2.83,0.84,2.25]",
          average_trend: 1.5399999618530273,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000129254",
          consignment: 48,
          refill_qty: 27,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 2.1700000762939453,
          average_data: "[0.63,2.4,3.25,2.74,1.75,2.25]",
          average_trend: 1.0399999618530273,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0.1,0.31,0,0.04,0.19]",
          average_trend: 1.7300000190734863,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.15,0,0.11,0.11,0.06]",
          average_trend: 0.8600000143051147,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152202",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 24,
          average_based_on_rec: 3,
          average_consumption: 0,
          average_data: "[0,0,0]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154898",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.2199999988079071,
          average_data: "[0.11,0,0.25,0.37,0.15,0.44]",
          average_trend: 2,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154914",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.23999999463558197,
          average_data: "[0.11,0.45,0,0.49,0.18,0.19]",
          average_trend: 0.7900000214576721,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156137",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 45,
          average_based_on_rec: 3,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0.62,0]",
          average_trend: 0,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000156561",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 23,
          average_based_on_rec: 3,
          average_consumption: 0.009999999776482582,
          average_data: "[0,0.04,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410384",
          consignment: 19,
          refill_qty: 19,
          current_inventory: 18,
          average_based_on_rec: 3,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0.15,0]",
          average_trend: 0,
          init_inventory: 19,
          last_inventory: 19,
        },
        {
          material_num: "000000000000410703",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 24,
          average_based_on_rec: 3,
          average_consumption: 0,
          average_data: "[0,0,0]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410704",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 24,
          average_based_on_rec: 3,
          average_consumption: 0,
          average_data: "[0,0,0]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 355,
      inv: 293,
    },
    {
      customerNum: "0600187805",
      equipmentNum: "000000000100524413",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-14T02:21:00.000Z",
      lastInventoryUpdate: "2021-05-14T02:21:00.000Z",
      lastFillRate: 0.38999998569488525,
      nextDeliveryPlan: "2021-06-02",
      nextDeliveryAct: "2021-06-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 1.1200000047683716,
      criticalMaterialNum: "000000000000102659",
      materialInventory: 232,
      materialConsumption: 4.929999828338623,
      dri: 8,
      pfr: 27,
      pfrc: 42,
      pfu: 64,
      numSalesDays: 12,
      ftc: false,
      sort: "110000002021-06-02",
      name: "TULANE-M47-REILLY CENTER",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY02028946",
      location: "YULMAN STA 1ST FLOOR LAGNIAPPE ",
      dexErrors: false,
      online: "SLW232000368",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 46,
          refill_qty: 12,
          current_inventory: 34,
          average_based_on_rec: 6,
          average_consumption: 0.9399999976158142,
          average_data: "[0.83,0.63,1.93,1.19,0.25,0.81]",
          average_trend: 0.8600000143051147,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 16,
          current_inventory: 30,
          average_based_on_rec: 6,
          average_consumption: 1.2599999904632568,
          average_data: "[2,0.88,2.07,0.95,0.4,1.25]",
          average_trend: 0.9900000095367432,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.27000001072883606,
          average_data: "[0,0.19,0.67,0.29,0.22,0.25]",
          average_trend: 0.9300000071525574,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 14,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 1.1200000047683716,
          average_data: "[2.17,1.44,0,1.1,0.69,1.31]",
          average_trend: 1.1699999570846558,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102782",
          consignment: 23,
          refill_qty: 3,
          current_inventory: 20,
          average_based_on_rec: 4,
          average_consumption: 0.17000000178813934,
          average_data: "[0,0.48,0.15,0.06]",
          average_trend: 0.3499999940395355,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.47999998927116394,
          average_data: "[0.5,0.25,0.07,0.86,0.22,1]",
          average_trend: 2.0799999237060547,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117641",
          consignment: 23,
          refill_qty: 5,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0.67,0.25,0.3,0.29,0.29,0.56]",
          average_trend: 1.440000057220459,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.17,0.19,0.22,0.76,0.25,0.19]",
          average_trend: 0.6299999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 232,
      inv: 168,
    },
    {
      customerNum: "0600187893",
      equipmentNum: "000000000100630904",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T19:48:00.000Z",
      lastInventoryUpdate: "2021-05-19T19:48:00.000Z",
      lastFillRate: 0.2800000011920929,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.8999999761581421,
      criticalMaterialNum: "000000000000135333",
      materialInventory: 260,
      materialConsumption: 11.890000343322754,
      dri: 6,
      pfr: 37,
      pfrc: 61,
      pfu: 104,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-01",
      name: "TULANE-M13-GOLDRING WOLDENBERG HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN16006523",
      location: "GOLDRING W 1ST FLOOR KITCHEN ",
      dexErrors: false,
      online: "SLW232000315",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.159999966621399,
          average_data: "[0.93,0.47,1.17,1.57,1.83,1]",
          average_trend: 0.8600000143051147,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102592",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.67,0.2,0.33,0.14,0.17,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102603",
          consignment: 56,
          refill_qty: 12,
          current_inventory: 44,
          average_based_on_rec: 6,
          average_consumption: 1.6699999570846558,
          average_data: "[1.73,1.13,2.17,1.71,2.17,1.11]",
          average_trend: 0.6600000262260437,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000102604",
          consignment: 32,
          refill_qty: 17,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.059999942779541,
          average_data: "[0.93,0.33,0.83,1.57,1.67,1]",
          average_trend: 0.9399999976158142,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102659",
          consignment: 16,
          refill_qty: 4,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.5400000214576721,
          average_data: "[0.67,0.2,0.67,0.71,0.67,0.33]",
          average_trend: 0.6100000143051147,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000112259",
          consignment: 56,
          refill_qty: 25,
          current_inventory: 31,
          average_based_on_rec: 6,
          average_consumption: 3.4700000286102295,
          average_data: "[3.47,1.53,3.83,3.86,6,2.11]",
          average_trend: 0.6100000143051147,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000121751",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.8600000143051147,
          average_data: "[1.07,0.13,0.67,1,1.5,0.78]",
          average_trend: 0.9100000262260437,
          init_inventory: 17,
          last_inventory: 17,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.8999999761581421,
          average_data: "[0.4,0.33,0.83,1.57,1.83,0.44]",
          average_trend: 0.49000000953674316,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 1.5700000524520874,
          average_data: "[1.2,0.53,2.5,2.29,1,1.89]",
          average_trend: 1.2000000476837158,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157269",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0,0,0.5,0.43,0.83,0.67]",
          average_trend: 1.6299999952316284,
          init_inventory: 16,
          last_inventory: 16,
        },
      ],
      cap: 276,
      inv: 172,
    },
    {
      customerNum: "0600188092",
      equipmentNum: "000000000100254372",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-29T22:29:00.000Z",
      lastInventoryUpdate: "2021-04-29T22:29:00.000Z",
      lastFillRate: 0.75,
      nextDeliveryPlan: "2021-06-17",
      nextDeliveryAct: "2021-06-17",
      schedulingMethod: "D",
      criticalMaterialInventory: 28,
      criticalMaterialConsumption: 0.5600000023841858,
      criticalMaterialNum: "000000000000102659",
      materialInventory: 268,
      materialConsumption: 4,
      dri: 22,
      pfr: 41,
      pfrc: 73,
      pfu: 111,
      numSalesDays: 27,
      ftc: false,
      sort: "110000002021-06-17",
      name: "TULANE-M58-UNIVERSITY NEWCOMB HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "309408",
      location: "   ",
      dexErrors: false,
      online: "SLW232000394",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 28,
          refill_qty: 28,
          current_inventory: 16,
          average_based_on_rec: 1,
          average_consumption: 0.4399999976158142,
          average_data: "[0.44]",
          average_trend: 1,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000102603",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 38,
          average_based_on_rec: 1,
          average_consumption: 0.36000001430511475,
          average_data: "[0.36]",
          average_trend: 1,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 16,
          average_based_on_rec: 1,
          average_consumption: 0.2800000011920929,
          average_data: "[0.28]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 28,
          refill_qty: 28,
          current_inventory: 12,
          average_based_on_rec: 1,
          average_consumption: 0.5600000023841858,
          average_data: "[0.56]",
          average_trend: 1,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000112259",
          consignment: 84,
          refill_qty: 84,
          current_inventory: 46,
          average_based_on_rec: 1,
          average_consumption: 1.3799999952316284,
          average_data: "[1.38]",
          average_trend: 1,
          init_inventory: 84,
          last_inventory: 84,
        },
        {
          material_num: "000000000000114230",
          consignment: 28,
          refill_qty: 28,
          current_inventory: 15,
          average_based_on_rec: 1,
          average_consumption: 0.47999998927116394,
          average_data: "[0.48]",
          average_trend: 1,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000121751",
          consignment: 28,
          refill_qty: 28,
          current_inventory: 14,
          average_based_on_rec: 1,
          average_consumption: 0.5,
          average_data: "[0.5]",
          average_trend: 1,
          init_inventory: 28,
          last_inventory: 28,
        },
      ],
      cap: 268,
      inv: 157,
    },
    {
      customerNum: "0600187664",
      equipmentNum: "000000000100508579",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-18T20:29:00.000Z",
      lastInventoryUpdate: "2021-05-18T20:29:00.000Z",
      lastFillRate: 0.36000001430511475,
      nextDeliveryPlan: "2021-06-18",
      nextDeliveryAct: "2021-06-18",
      schedulingMethod: "D",
      criticalMaterialInventory: 8,
      criticalMaterialConsumption: 0.2199999988079071,
      criticalMaterialNum: "000000000000114306",
      materialInventory: 136,
      materialConsumption: 1.7699999809265137,
      dri: 24,
      pfr: 13,
      pfrc: 41,
      pfu: 18,
      numSalesDays: 8,
      ftc: false,
      sort: "110000002021-06-18",
      name: "XAVIER UNIVERSITY-M1",
      street: "1000 S NORMAN C FRANCIS",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY08003179",
      location: "ST. MARTIN 1ST FLOOR KITCHEN ",
      dexErrors: false,
      online: "SLW232000114",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 4,
          average_consumption: 0.28999999165534973,
          average_data: "[0.06,0.24,0.38,0.49]",
          average_trend: 1.690000057220459,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102603",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 4,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0,0.13,0.49]",
          average_trend: 3.059999942779541,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.2199999988079071,
          average_data: "[0,0.12,0.56,0.2]",
          average_trend: 0.9100000262260437,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114306",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 4,
          average_consumption: 0.2199999988079071,
          average_data: "[0,0.12,0.38,0.39]",
          average_trend: 1.7699999809265137,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000115964",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.18000000715255737,
          average_data: "[0.11,0.15,0.25,0.2]",
          average_trend: 1.1100000143051147,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.06,0.31,0.15]",
          average_trend: 1.149999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.23000000417232513,
          average_data: "[0.06,0.15,0.38,0.34]",
          average_trend: 1.4800000190734863,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 4,
          average_consumption: 0.3400000035762787,
          average_data: "[0,0.39,0.44,0.54]",
          average_trend: 1.590000033378601,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 136,
      inv: 118,
    },
    {
      customerNum: "0600188179",
      equipmentNum: "000000000100523788",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T20:55:00.000Z",
      lastInventoryUpdate: "2021-05-11T20:55:00.000Z",
      lastFillRate: 0.30000001192092896,
      nextDeliveryPlan: "2021-06-24",
      nextDeliveryAct: "2021-06-24",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.25,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 204,
      materialConsumption: 1.7999999523162842,
      dri: 29,
      pfr: 16,
      pfrc: 38,
      pfu: 34,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-06-24",
      name: "XAVIER UNIVERSITY-M4",
      street: "801 S NORMAN C FRANCIS",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN11006796",
      location: "I/S JOSEPH 1st fl. 20Z ",
      dexErrors: false,
      online: "SLW232000348",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 8,
          average_based_on_rec: 3,
          average_consumption: 0.25,
          average_data: "[0.12,0.29,0.34]",
          average_trend: 1.3600000143051147,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.10000000149011612,
          average_data: "[0.02,0.15,0.14]",
          average_trend: 1.399999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 3,
          average_consumption: 0.009999999776482582,
          average_data: "[0,0,0.03]",
          average_trend: 3,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 41,
          average_based_on_rec: 3,
          average_consumption: 0.4300000071525574,
          average_data: "[0.31,0.56,0.41]",
          average_trend: 0.949999988079071,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000114229",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.07000000029802322,
          average_data: "[0.02,0.12,0.07]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.11999999731779099,
          average_data: "[0.1,0.06,0.21]",
          average_trend: 1.75,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114306",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 9,
          average_based_on_rec: 3,
          average_consumption: 0.15000000596046448,
          average_data: "[0.05,0.24,0.17]",
          average_trend: 1.1299999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114633",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 9,
          average_based_on_rec: 3,
          average_consumption: 0.18000000715255737,
          average_data: "[0.1,0.26,0.17]",
          average_trend: 0.9399999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000115964",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 9,
          average_based_on_rec: 2,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.31]",
          average_trend: 1.940000057220459,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 3,
          average_consumption: 0.029999999329447746,
          average_data: "[0.02,0.06,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 3,
          average_consumption: 0,
          average_data: "[0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 3,
          average_consumption: 0.05000000074505806,
          average_data: "[0.05,0,0.1]",
          average_trend: 2,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 8,
          average_based_on_rec: 3,
          average_consumption: 0.2199999988079071,
          average_data: "[0.14,0.35,0.17]",
          average_trend: 0.7699999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000153225",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 3,
          average_consumption: 0.029999999329447746,
          average_data: "[0.1,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 204,
      inv: 170,
    },
    {
      customerNum: "0600188028",
      equipmentNum: "000000000100508573",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T21:26:00.000Z",
      lastInventoryUpdate: "2021-05-17T21:26:00.000Z",
      lastFillRate: 0.20000000298023224,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 2.0899999141693115,
      criticalMaterialNum: "000000000000156530",
      materialInventory: 258,
      materialConsumption: 7.670000076293945,
      dri: 3,
      pfr: 29,
      pfrc: 35,
      pfu: 75,
      numSalesDays: 9,
      ftc: false,
      sort: "100000002021-05-28",
      name: "NATIONAL TIRE AND BATTERY-M1",
      street: "7300 WASHINGTON AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY08000587",
      location: "I/S ON LEF 1  ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTuWThF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 7,
          refill_qty: 3,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.6,0.17,0.09,0.14,0.11,0.43]",
          average_trend: 1.649999976158142,
          init_inventory: 7,
          last_inventory: 7,
        },
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0,0.45,0.07,0.44,0.29]",
          average_trend: 1.3799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 28,
          refill_qty: 15,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 1.659999966621399,
          average_data: "[1.2,1.67,1.55,1.52,2.44,1.57]",
          average_trend: 0.949999988079071,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000102604",
          consignment: 4,
          refill_qty: 2,
          current_inventory: 2,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0.2,0.33,0.09,0.28,0.22,0]",
          average_trend: 0,
          init_inventory: 4,
          last_inventory: 4,
        },
        {
          material_num: "000000000000102659",
          consignment: 10,
          refill_qty: 3,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.27000001072883606,
          average_data: "[0.4,0,0.36,0.55,0.33,0]",
          average_trend: 0,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000102660",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[1,0.17,0,0.28,0.11,0.14]",
          average_trend: 0.5,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 14,
          refill_qty: 4,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[0.6,0.17,0.27,0.41,0.33,0.71]",
          average_trend: 1.690000057220459,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000114532",
          consignment: 14,
          refill_qty: 1,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0,0.5,0,0.07,0,0]",
          average_trend: 0,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000114633",
          consignment: 14,
          refill_qty: 2,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0.4,0.17,0,0.21,0.33,0]",
          average_trend: 0,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000135333",
          consignment: 14,
          refill_qty: 4,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.4000000059604645,
          average_data: "[0.8,0,0.73,0.76,0.11,0]",
          average_trend: 0,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000151938",
          consignment: 21,
          refill_qty: 1,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0.17,0.09,0.21,0,0]",
          average_trend: 0,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000151978",
          consignment: 21,
          refill_qty: 5,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.44999998807907104,
          average_data: "[1.4,0.33,0.45,0.21,0.33,0]",
          average_trend: 0,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000153008",
          consignment: 21,
          refill_qty: 21,
          current_inventory: 21,
          average_based_on_rec: 2,
          average_consumption: 0,
          average_data: "[0,0]",
          average_trend: 1,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000156530",
          consignment: 24,
          refill_qty: 19,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 2.0899999141693115,
          average_data: "[2,2.17,2.18,1.66,2.11,2.43]",
          average_trend: 1.159999966621399,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157269",
          consignment: 14,
          refill_qty: 5,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.46000000834465027,
          average_data: "[0,0.18,0.69,0.56,0.86]",
          average_trend: 1.8700000047683716,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000157361",
          consignment: 14,
          refill_qty: 2,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.20000000298023224,
          average_data: "[0,0,0.64,0.07,0.22,0.29]",
          average_trend: 1.4500000476837158,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000411016",
          consignment: 14,
          refill_qty: 4,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.4099999964237213,
          average_data: "[0,0.64,0.41,0.56,0.43]",
          average_trend: 1.0499999523162842,
          init_inventory: 14,
          last_inventory: 14,
        },
      ],
      cap: 258,
      inv: 183,
    },
    {
      customerNum: "0600188343",
      equipmentNum: "000000000100715122",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T23:42:00.000Z",
      lastInventoryUpdate: "2021-05-21T04:42:21.000Z",
      lastFillRate: 0.9599999785423279,
      nextDeliveryPlan: "2021-05-26",
      nextDeliveryAct: "2021-05-25",
      schedulingMethod: "D",
      criticalMaterialInventory: 10,
      criticalMaterialConsumption: 1.7599999904632568,
      criticalMaterialNum: "000000000000151978",
      materialInventory: 234,
      materialConsumption: 18.56999969482422,
      dri: 1,
      pfr: 43,
      pfrc: 49,
      pfu: 106,
      numSalesDays: 5,
      ftc: false,
      sort: "110000002021-05-25",
      name: "LOYOLA UNIV-M09-BIEVER HALL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY07014527",
      location: "1st floor   ",
      dexErrors: false,
      online: "SLW232000264",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 21,
          refill_qty: 15,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 2.6500000953674316,
          average_data: "[3.5,3,2.4,2.53,3.23,1.24]",
          average_trend: 0.4699999988079071,
          init_inventory: 21,
          last_inventory: 20,
        },
        {
          material_num: "000000000000102752",
          consignment: 21,
          refill_qty: 8,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 1.5,
          average_data: "[1,1,2.13,0.4,3.23,1.24]",
          average_trend: 0.8299999833106995,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000112259",
          consignment: 56,
          refill_qty: 11,
          current_inventory: 45,
          average_based_on_rec: 6,
          average_consumption: 2.0199999809265137,
          average_data: "[2.33,1.6,1.6,0.27,3.23,3.06]",
          average_trend: 1.5099999904632568,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000129254",
          consignment: 56,
          refill_qty: 26,
          current_inventory: 30,
          average_based_on_rec: 6,
          average_consumption: 4.840000152587891,
          average_data: "[5.83,4.6,4.13,5.2,6,3.29]",
          average_trend: 0.6800000071525574,
          init_inventory: 56,
          last_inventory: 55,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 2.6500000953674316,
          average_data: "[2.17,2.2,3.2,3.2,3.69,1.41]",
          average_trend: 0.5299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 25,
          refill_qty: 8,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 1.4299999475479126,
          average_data: "[1.5,2.4,1.07,1.33,1.08,1.18]",
          average_trend: 0.8299999833106995,
          init_inventory: 25,
          last_inventory: 25,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.5099999904632568,
          average_data: "[1,0.4,0.27,0.13,0.92,0.35]",
          average_trend: 0.6899999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 14,
          refill_qty: 13,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 1.7599999904632568,
          average_data: "[1.83,2,1.87,1.87,2.15,0.82]",
          average_trend: 0.4699999988079071,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000156111",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 1.2100000381469727,
          average_data: "[1.5,1.2,1.2,0.8,1.85,0.71]",
          average_trend: 0.5899999737739563,
          init_inventory: 12,
          last_inventory: 11,
        },
      ],
      cap: 241,
      inv: 135,
    },
    {
      customerNum: "0600188083",
      equipmentNum: "000000000100537763",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T22:54:00.000Z",
      lastInventoryUpdate: "2021-05-13T22:54:00.000Z",
      lastFillRate: 0.6200000047683716,
      nextDeliveryPlan: "2021-05-21",
      nextDeliveryAct: "2021-05-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 16,
      criticalMaterialConsumption: 1.8700000047683716,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 240,
      materialConsumption: 18.209999084472656,
      dri: -4,
      pfr: 88,
      pfrc: 69,
      pfu: 220,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-21",
      name: "TULANE-M60-WEATHERHEAD",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN16002384",
      location: "WEATHERHEA 1ST FLOOR HALLWAY ",
      dexErrors: false,
      online: "SLW232000397",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.8700000047683716,
          average_data: "[1.78,1.5,2.15,2,1.33,2.46]",
          average_trend: 1.3200000524520874,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102603",
          consignment: 40,
          refill_qty: 40,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 3.0299999713897705,
          average_data: "[2.33,3,3.08,1.83,5,2.92]",
          average_trend: 0.9599999785423279,
          init_inventory: 40,
          last_inventory: 40,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 21,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 1.600000023841858,
          average_data: "[1.22,2,1.23,1.33,1.67,2.15]",
          average_trend: 1.340000033378601,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102660",
          consignment: 16,
          refill_qty: 9,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.6399999856948853,
          average_data: "[0.78,0.13,0.62,1,0.67,0.62]",
          average_trend: 0.9700000286102295,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 4.71999979019165,
          average_data: "[4.44,4.63,4.92,4.83,3.33,6.15]",
          average_trend: 1.2999999523162842,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000114230",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.3600000143051147,
          average_data: "[1.44,1,1.23,1.5,1,2]",
          average_trend: 1.4700000286102295,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000117635",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.6299999952316284,
          average_data: "[1.67,1.13,2.46,1.17,1.67,1.69]",
          average_trend: 1.0399999618530273,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000117641",
          consignment: 8,
          refill_qty: 3,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.17000000178813934,
          average_data: "[0.22,0.13,0,0,0.33,0.31]",
          average_trend: 1.8200000524520874,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0.22,0,0,1,0.33,0.46]",
          average_trend: 1.350000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000125522",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0.22,0.5,0,1,0.67,0.77]",
          average_trend: 1.4500000476837158,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000133129",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 0.9200000166893005,
          average_data: "[0.22,1.38,1.38,1,0,1.54]",
          average_trend: 1.6699999570846558,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000153225",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 0.8700000047683716,
          average_data: "[0.11,1.38,1.38,0,0.67,1.69]",
          average_trend: 1.940000057220459,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000158053",
          consignment: 16,
          refill_qty: 15,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0,0.5,1.23,0.67,0.33,0.46]",
          average_trend: 0.8700000047683716,
          init_inventory: 8,
          last_inventory: 8,
        },
      ],
      cap: 248,
      inv: 28,
    },
    {
      customerNum: "0501027990",
      equipmentNum: "000000000100289932",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T20:48:00.000Z",
      lastInventoryUpdate: "2021-05-19T20:48:00.000Z",
      lastFillRate: 0.05000000074505806,
      nextDeliveryPlan: "2021-07-16",
      nextDeliveryAct: "2021-07-16",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.23999999463558197,
      criticalMaterialNum: "000000000000115586",
      materialInventory: 240,
      materialConsumption: 0.949999988079071,
      dri: 89,
      pfr: 4,
      pfrc: 37,
      pfu: 10,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-07-16",
      name: "LOYOLA UNIV BOBET HALL-M1",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY10007440",
      location: "bobet hall 1  ",
      dexErrors: false,
      online: "SLW232000390",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 96,
          refill_qty: 96,
          current_inventory: 94,
          average_based_on_rec: 2,
          average_consumption: 0.1599999964237213,
          average_data: "[0.31,0]",
          average_trend: 0,
          init_inventory: 96,
          last_inventory: 96,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 2,
          average_consumption: 0.23000000417232513,
          average_data: "[0.46,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115586",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 2,
          average_consumption: 0.23999999463558197,
          average_data: "[0.31,0.16]",
          average_trend: 0.6700000166893005,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000116149",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 46,
          average_based_on_rec: 2,
          average_consumption: 0.1599999964237213,
          average_data: "[0.31,0]",
          average_trend: 0,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 46,
          average_based_on_rec: 2,
          average_consumption: 0.1599999964237213,
          average_data: "[0.31,0]",
          average_trend: 0,
          init_inventory: 48,
          last_inventory: 48,
        },
      ],
      cap: 240,
      inv: 230,
    },
    {
      customerNum: "0600186243",
      equipmentNum: "000000000100519473",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-27T19:47:00.000Z",
      lastInventoryUpdate: "2021-04-27T19:47:00.000Z",
      lastFillRate: 0.25,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.6600000262260437,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 199,
      materialConsumption: 3.680000066757202,
      dri: 6,
      pfr: 55,
      pfrc: 64,
      pfu: 111,
      numSalesDays: 29,
      ftc: false,
      sort: "110000002021-06-01",
      name: "XAVIER UNIVERSITY-M04",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "CH002501",
      location: "SCIENCE BL 1ST FLOOR student lo ",
      dexErrors: false,
      online: "SLW232000284",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 20,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.6600000262260437,
          average_data: "[1.67,0.24,0.42,0.3,0.4,0.93]",
          average_trend: 1.409999966621399,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 13,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.4300000071525574,
          average_data: "[0.33,1,0.58,0.4,0.13,0.13]",
          average_trend: 0.30000001192092896,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 23,
          refill_qty: 18,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.6200000047683716,
          average_data: "[0.33,0.65,1.17,0.65,0.27,0.67]",
          average_trend: 1.0800000429153442,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 16,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.550000011920929,
          average_data: "[2,0.29,0.33,0.2,0.27,0.2]",
          average_trend: 0.36000001430511475,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 46,
          refill_qty: 23,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.7599999904632568,
          average_data: "[0,0.82,0.83,1.2,1.2,0.53]",
          average_trend: 0.699999988079071,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.06,0,0.1,0.13,0.13]",
          average_trend: 1.8600000143051147,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114306",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.36000001430511475,
          average_data: "[0.5,0,0.67,0.3,0.27,0.4]",
          average_trend: 1.1100000143051147,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 7,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0,0.29,0.17,0.35,0.27,0.27]",
          average_trend: 1.1699999570846558,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 199,
      inv: 88,
    },
    {
      customerNum: "0600188714",
      equipmentNum: "000000000100838544",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T18:52:00.000Z",
      lastInventoryUpdate: "2021-05-19T18:52:00.000Z",
      lastFillRate: 0.17000000178813934,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.46000000834465027,
      criticalMaterialNum: "000000000000121751",
      materialInventory: 300,
      materialConsumption: 7.320000171661377,
      dri: 17,
      pfr: 18,
      pfrc: 58,
      pfu: 55,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-11",
      name: "TULANE-M11-GOLDRING BUS SCHOOL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "UN122189",
      location: "GOLDRING W 4TH FLOOR VEND AREA ",
      dexErrors: false,
      online: "SLW232000337",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0.48,0.45,0.31,0.24,0.62,0.33]",
          average_trend: 0.800000011920929,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 48,
          refill_qty: 7,
          current_inventory: 41,
          average_based_on_rec: 6,
          average_consumption: 0.949999988079071,
          average_data: "[1.17,0.55,0.92,0.49,0.92,1.67]",
          average_trend: 1.7599999904632568,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.7900000214576721,
          average_data: "[0.76,0.45,0.46,0.63,1.31,1.11]",
          average_trend: 1.409999966621399,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.21,0.09,0.54,0.24,0.23,0.22]",
          average_trend: 0.8500000238418579,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 72,
          refill_qty: 10,
          current_inventory: 62,
          average_based_on_rec: 6,
          average_consumption: 1.3600000143051147,
          average_data: "[1.72,1.18,1.31,0.83,2.31,0.78]",
          average_trend: 0.5699999928474426,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0.55,0.64,0.46,0.34,0.46,0.33]",
          average_trend: 0.7200000286102295,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000125522",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.9200000166893005,
          average_data: "[1.31,0.82,0.62,0.59,1.85,0.33]",
          average_trend: 0.36000001430511475,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000129254",
          consignment: 72,
          refill_qty: 13,
          current_inventory: 59,
          average_based_on_rec: 6,
          average_consumption: 1.7899999618530273,
          average_data: "[2,1.36,2.08,1.12,4.15,0]",
          average_trend: 0,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.48,0,0.31,0.29,0.77,0.44]",
          average_trend: 1.159999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 300,
      inv: 245,
    },
    {
      customerNum: "0600188664",
      equipmentNum: "000000000100532287",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T19:22:00.000Z",
      lastInventoryUpdate: "2021-05-17T19:22:00.000Z",
      lastFillRate: 0.6600000262260437,
      nextDeliveryPlan: "2021-05-25",
      nextDeliveryAct: "2021-05-25",
      schedulingMethod: "D",
      criticalMaterialInventory: 9,
      criticalMaterialConsumption: 1.0800000429153442,
      criticalMaterialNum: "000000000000121751",
      materialInventory: 163,
      materialConsumption: 8.920000076293945,
      dri: -1,
      pfr: 58,
      pfrc: 51,
      pfu: 110,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-05-25",
      name: "TULANE-M26-LINDY BOGGS",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12015123",
      location: "LINDY BOGG 1ST FLOOR HALLWAY ",
      dexErrors: false,
      online: "SLW232000106",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.47999998927116394,
          average_data: "[0.4,0.53,0.5,0.71,0.43,0.31]",
          average_trend: 0.6499999761581421,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 10,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 1.0099999904632568,
          average_data: "[0.67,1.47,0.88,1.57,0.71,0.77]",
          average_trend: 0.7599999904632568,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 48,
          refill_qty: 41,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 2.819999933242798,
          average_data: "[2.13,3.2,3.38,2.71,2.14,3.38]",
          average_trend: 1.2000000476837158,
          init_inventory: 33,
          last_inventory: 33,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0.53,0.13,0.5,0.14,0.57,0.46]",
          average_trend: 1.1799999475479126,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 46,
          refill_qty: 23,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 1.7000000476837158,
          average_data: "[1.73,2.53,1.13,2,0.29,2.54]",
          average_trend: 1.4900000095367432,
          init_inventory: 39,
          last_inventory: 39,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.36000001430511475,
          average_data: "[0.27,0,0.75,0.14,0.43,0.54]",
          average_trend: 1.5,
          init_inventory: 11,
          last_inventory: 11,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.0800000429153442,
          average_data: "[0.27,1.47,1.25,1.29,1.29,0.92]",
          average_trend: 0.8500000238418579,
          init_inventory: 9,
          last_inventory: 9,
        },
        {
          material_num: "000000000000125522",
          consignment: 23,
          refill_qty: 10,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 1.0800000429153442,
          average_data: "[1.07,0.8,1.25,1.29,0.86,1.23]",
          average_trend: 1.1399999856948853,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 189,
      inv: 79,
    },
    {
      customerNum: "0600186244",
      equipmentNum: "000000000100283235",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-27T19:22:00.000Z",
      lastInventoryUpdate: "2021-04-27T19:22:00.000Z",
      lastFillRate: 0.23000000417232513,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 0.47999998927116394,
      criticalMaterialNum: "000000000000135333",
      materialInventory: 190,
      materialConsumption: 1.4500000476837158,
      dri: 17,
      pfr: 18,
      pfrc: 31,
      pfu: 34,
      numSalesDays: 29,
      ftc: false,
      sort: "110000002021-06-11",
      name: "XAVIER UNIVERSITY-M05",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "V0125530",
      location: "SCIENCE   ",
      dexErrors: true,
      online: "SLW232000360",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 4,
          average_consumption: 0.1899999976158142,
          average_data: "[0.21,0.18,0.25,0.12]",
          average_trend: 0.6299999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 4,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0,0.08,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115964",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0.18,0.18,0.08,0.07]",
          average_trend: 0.5400000214576721,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 4,
          average_consumption: 0.009999999776482582,
          average_data: "[0,0,0,0.02]",
          average_trend: 2,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000123292",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 4,
          average_consumption: 0.30000001192092896,
          average_data: "[0.46,0,0.67,0.05]",
          average_trend: 0.17000000178813934,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 4,
          average_consumption: 0.15000000596046448,
          average_data: "[0.21,0,0.33,0.05]",
          average_trend: 0.33000001311302185,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.47999998927116394,
          average_data: "[0.39,0.55,0.42,0.55]",
          average_trend: 1.149999976158142,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000156530",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 4,
          average_consumption: 0.17000000178813934,
          average_data: "[0.21,0,0.33,0.14]",
          average_trend: 0.8199999928474426,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 179,
      inv: 145,
    },
    {
      customerNum: "0600188386",
      equipmentNum: "000000000100527297",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-27T00:45:00.000Z",
      lastInventoryUpdate: "2021-04-27T00:45:00.000Z",
      lastFillRate: 0.5,
      nextDeliveryPlan: "2021-05-31",
      nextDeliveryAct: "2021-05-31",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3199999928474426,
      criticalMaterialNum: "000000000000102659",
      materialInventory: 131,
      materialConsumption: 2.7300000190734863,
      dri: 6,
      pfr: 64,
      pfrc: 72,
      pfu: 84,
      numSalesDays: 29,
      ftc: false,
      sort: "110000002021-05-31",
      name: "LOYOLA UNIV BUDDIG-M11-12TH FL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "8306509",
      location: "BUDDIG HAL 12TH FLOOR OFC ",
      dexErrors: false,
      online: "SLW232000359",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 17,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.5699999928474426,
          average_data: "[0.62,0.44,0.15,1.05]",
          average_trend: 1.840000033378601,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 16,
          current_inventory: 8,
          average_based_on_rec: 4,
          average_consumption: 0.550000011920929,
          average_data: "[0.62,0.98,0.07,0.53]",
          average_trend: 0.9599999785423279,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 3,
          current_inventory: 3,
          average_based_on_rec: 4,
          average_consumption: 0.07000000029802322,
          average_data: "[0.13,0.1,0,0.05]",
          average_trend: 0.7099999785423279,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 10,
          current_inventory: 2,
          average_based_on_rec: 4,
          average_consumption: 0.3199999928474426,
          average_data: "[0.21,0.54,0.15,0.37]",
          average_trend: 1.159999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 23,
          refill_qty: 18,
          current_inventory: 5,
          average_based_on_rec: 4,
          average_consumption: 0.6000000238418579,
          average_data: "[0.59,1.12,0,0.68]",
          average_trend: 1.1299999952316284,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000114230",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 4,
          average_consumption: 0.3799999952316284,
          average_data: "[0.31,0.54,0.07,0.58]",
          average_trend: 1.5299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121751",
          consignment: 6,
          refill_qty: 3,
          current_inventory: 3,
          average_based_on_rec: 4,
          average_consumption: 0.07999999821186066,
          average_data: "[0.1,0.1,0.07,0.05]",
          average_trend: 0.6299999952316284,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000133129",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.1599999964237213,
          average_data: "[0.31,0,0.15,0.16]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 131,
      inv: 47,
    },
    {
      customerNum: "0600188387",
      equipmentNum: "000000000100519311",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-27T00:45:00.000Z",
      lastInventoryUpdate: "2021-04-27T00:45:00.000Z",
      lastFillRate: 0.30000001192092896,
      nextDeliveryPlan: "2021-05-20",
      nextDeliveryAct: "2021-05-20",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.46000000834465027,
      criticalMaterialNum: "000000000000117641",
      materialInventory: 148,
      materialConsumption: 2.0199999809265137,
      dri: -5,
      pfr: 41,
      pfrc: 32,
      pfu: 62,
      numSalesDays: 29,
      ftc: false,
      sort: "110000002021-05-20",
      name: "LOYOLA UNIV BUDDIG-M12-12TH FL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "8306471",
      location: "BUDDIG HAL 12TH FLOOR OFC ",
      dexErrors: false,
      online: "SLW232000326",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.44999998807907104,
          average_data: "[0.31,0.15,0.67,0.68]",
          average_trend: 1.5099999904632568,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 4,
          average_consumption: 0.2800000011920929,
          average_data: "[0.31,0.15,0.44,0.21]",
          average_trend: 0.75,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.07000000029802322,
          average_data: "[0.18,0.1,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 46,
          refill_qty: 13,
          current_inventory: 33,
          average_based_on_rec: 4,
          average_consumption: 0.4300000071525574,
          average_data: "[0.31,0.2,0.74,0.47]",
          average_trend: 1.090000033378601,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 4,
          average_consumption: 0.46000000834465027,
          average_data: "[0.23,0.59,0.44,0.58]",
          average_trend: 1.2599999904632568,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 4,
          average_consumption: 0.029999999329447746,
          average_data: "[0.1,0,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000125522",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 4,
          average_consumption: 0.18000000715255737,
          average_data: "[0.15,0.24,0.15,0.16]",
          average_trend: 0.8899999856948853,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000158052",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 8,
          average_based_on_rec: 3,
          average_consumption: 0.11999999731779099,
          average_data: "[0,0.15,0.21]",
          average_trend: 1.75,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 148,
      inv: 86,
    },
    {
      customerNum: "0600187310",
      equipmentNum: "000000000100515555",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-14T02:18:00.000Z",
      lastInventoryUpdate: "2021-05-14T02:18:00.000Z",
      lastFillRate: 0.6800000071525574,
      nextDeliveryPlan: "2021-06-24",
      nextDeliveryAct: "2021-06-24",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 1.1399999856948853,
      criticalMaterialNum: "000000000000112259",
      materialInventory: 314,
      materialConsumption: 3.130000114440918,
      dri: 30,
      pfr: 16,
      pfrc: 43,
      pfu: 52,
      numSalesDays: 12,
      ftc: false,
      sort: "110000002021-06-24",
      name: "TULANE-M45-REILLY CENTER",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN09001842",
      location: "YULMAN STA 2ND FLOOR RECREATION ",
      dexErrors: true,
      online: "SLW232000300",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MWF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 48,
          refill_qty: 5,
          current_inventory: 43,
          average_based_on_rec: 6,
          average_consumption: 0.4000000059604645,
          average_data: "[0,0.65,0.13,0.15,0.38,1.07]",
          average_trend: 2.680000066757202,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102751",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.27000001072883606,
          average_data: "[0,0.35,0.25,0.3,0.24,0.45]",
          average_trend: 1.6699999570846558,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0,0.05,0.06,0,0.19,0.3]",
          average_trend: 3,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 14,
          current_inventory: 34,
          average_based_on_rec: 6,
          average_consumption: 1.1399999856948853,
          average_data: "[0.18,0.4,1.69,0.37,3.43,0.77]",
          average_trend: 0.6800000071525574,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0.2,0.25,0.22,0,0.23]",
          average_trend: 1.5299999713897705,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000123292",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0,0.25,0,0.24,0.43]",
          average_trend: 2.869999885559082,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000129254",
          consignment: 48,
          refill_qty: 1,
          current_inventory: 47,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0.05,0,0,0,0,0.41]",
          average_trend: 5.130000114440918,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000156111",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.20000000298023224,
          average_data: "[0,0.1,0.44,0.37,0,0.27]",
          average_trend: 1.350000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156112",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.1,0.31,0,0.1,0.27]",
          average_trend: 2.0799999237060547,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156114",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.27000001072883606,
          average_data: "[0.13,0.2,0.44,0.52,0.1,0.23]",
          average_trend: 0.8500000238418579,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156137",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 45,
          average_based_on_rec: 2,
          average_consumption: 0.23999999463558197,
          average_data: "[0,0.48]",
          average_trend: 2,
          init_inventory: 48,
          last_inventory: 48,
        },
      ],
      cap: 324,
      inv: 272,
    },
    {
      customerNum: "0600188850",
      equipmentNum: "000000000100519787",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T17:52:00.000Z",
      lastInventoryUpdate: "2021-05-19T17:52:00.000Z",
      lastFillRate: 0.14000000059604645,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.8600000143051147,
      criticalMaterialNum: "000000000000129254",
      materialInventory: 340,
      materialConsumption: 11.279999732971191,
      dri: 6,
      pfr: 25,
      pfrc: 43,
      pfu: 87,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-01",
      name: "TULANE GREENBAUM-M1",
      street: "1101 BROADWAY ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN14003926",
      location: "GREENBAUM  1ST FLOOR VEND AREA ",
      dexErrors: false,
      online: "SLW232000112",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 10,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 1.0499999523162842,
          average_data: "[1.1,1.29,1.25,0.8,1.57,0.31]",
          average_trend: 0.30000001192092896,
          init_inventory: 22,
          last_inventory: 22,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 10,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 1.3799999952316284,
          average_data: "[1.6,2,1.08,1.3,1.36,0.92]",
          average_trend: 0.6700000166893005,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 42,
          refill_qty: 7,
          current_inventory: 35,
          average_based_on_rec: 6,
          average_consumption: 0.9100000262260437,
          average_data: "[1.3,1,1.17,0.4,1.57,0]",
          average_trend: 0,
          init_inventory: 42,
          last_inventory: 42,
        },
        {
          material_num: "000000000000102659",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0,0.14,0.67,0.4,0.5,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 72,
          refill_qty: 26,
          current_inventory: 46,
          average_based_on_rec: 6,
          average_consumption: 3.700000047683716,
          average_data: "[4.6,3.57,3.75,2.8,5.14,2.31]",
          average_trend: 0.6200000047683716,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.75,
          average_data: "[1.4,0.86,0.42,0.8,1,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.2,0.29,0,0.3,0.79,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 1.8600000143051147,
          average_data: "[1.7,2.14,2,2.4,1.71,1.23]",
          average_trend: 0.6600000262260437,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.20000000298023224,
          average_data: "[0.1,0,0,0.5,0.57,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[0.3,0.29,0.33,0.1,1.21,0.31]",
          average_trend: 0.7400000095367432,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.36000001430511475,
          average_data: "[0.6,0.71,0.33,0,0.5,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000153225",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.2,0,0.17,0,0.21,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 342,
      inv: 255,
    },
    {
      customerNum: "0600188835",
      equipmentNum: "000000000100280892",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T20:19:00.000Z",
      lastInventoryUpdate: "2021-05-21T20:19:00.000Z",
      lastFillRate: 0.07999999821186066,
      nextDeliveryPlan: "2021-06-15",
      nextDeliveryAct: "2021-06-15",
      schedulingMethod: "D",
      criticalMaterialInventory: 56,
      criticalMaterialConsumption: 2.25,
      criticalMaterialNum: "000000000000102751",
      materialInventory: 332,
      materialConsumption: 7.480000019073486,
      dri: 20,
      pfr: 12,
      pfrc: 56,
      pfu: 41,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-15",
      name: "MARKET SERVICE CENTER-M11",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "RY01018373",
      location: "PRODUCTION 1ST FLOOR OUTSIDE ",
      dexErrors: false,
      online: "09590259587",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 112,
          refill_qty: 6,
          current_inventory: 106,
          average_based_on_rec: 6,
          average_consumption: 1.090000033378601,
          average_data: "[1.69,0.62,1.33,1.33,0.77,0.8]",
          average_trend: 0.7300000190734863,
          init_inventory: 112,
          last_inventory: 112,
        },
        {
          material_num: "000000000000102751",
          consignment: 56,
          refill_qty: 12,
          current_inventory: 44,
          average_based_on_rec: 6,
          average_consumption: 2.25,
          average_data: "[2,2.15,3.2,2.4,2.15,1.6]",
          average_trend: 0.7099999785423279,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000102752",
          consignment: 56,
          refill_qty: 7,
          current_inventory: 49,
          average_based_on_rec: 6,
          average_consumption: 1.2200000286102295,
          average_data: "[2.15,1.38,1.6,0.67,1.54,0]",
          average_trend: 0,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000117687",
          consignment: 28,
          refill_qty: 3,
          current_inventory: 25,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0,0.92,1.07,0.27,0.62,0.27]",
          average_trend: 0.5099999904632568,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000123292",
          consignment: 56,
          refill_qty: 10,
          current_inventory: 46,
          average_based_on_rec: 6,
          average_consumption: 1.8200000524520874,
          average_data: "[2.77,2.46,3.2,0.67,0.77,1.07]",
          average_trend: 0.5899999737739563,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000130470",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.5699999928474426,
          average_data: "[2.46,0,0.93,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 332,
      inv: 291,
    },
    {
      customerNum: "0600187538",
      equipmentNum: "000000000100511615",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T21:14:00.000Z",
      lastInventoryUpdate: "2021-05-11T21:14:00.000Z",
      lastFillRate: 0.09000000357627869,
      nextDeliveryPlan: "2021-05-25",
      nextDeliveryAct: "2021-05-25",
      schedulingMethod: "D",
      criticalMaterialInventory: 8,
      criticalMaterialConsumption: 0.5199999809265137,
      criticalMaterialNum: "000000000000135333",
      materialInventory: 238,
      materialConsumption: 4.599999904632568,
      dri: -1,
      pfr: 33,
      pfrc: 28,
      pfu: 81,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-05-25",
      name: "XAVIER UNIVERSITY-M2",
      street: "801 S NORMAN C FRANCIS",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN11006798",
      location: "BLDG 17 UN 2ND FLOOR GAMEROOM ",
      dexErrors: false,
      online: "SLW232000210",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 9,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0.29,0.75,0.55,0.81,0.83,0.22]",
          average_trend: 0.3799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102592",
          consignment: 4,
          refill_qty: 3,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0.12,0.17,0.05,0.3,0.28,0]",
          average_trend: 0,
          init_inventory: 4,
          last_inventory: 4,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.20000000298023224,
          average_data: "[0,0.25,0.3,0.22,0.34,0.11]",
          average_trend: 0.550000011920929,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.09000000357627869,
          average_data: "[0.29,0,0,0,0.14,0.11]",
          average_trend: 1.2200000286102295,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 6,
          refill_qty: 2,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0.17,0.2,0.3,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102660",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0.18,0.17,0.2,0.3,0.21,0.22]",
          average_trend: 1.0499999523162842,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 5,
          current_inventory: 43,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.53,0.17,0.3,0.22,0.34,0.22]",
          average_trend: 0.7300000190734863,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.23999999463558197,
          average_data: "[0.06,0.25,0.2,0.44,0.14,0.33]",
          average_trend: 1.3799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114306",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0.53,0.25,0.3,0.44,0.62,0.22]",
          average_trend: 0.5600000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0.17,0.25,0.15,0.07,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000117641",
          consignment: 4,
          refill_qty: 1,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0.06,0.08,0.05,0,0,0.11]",
          average_trend: 2.200000047683716,
          init_inventory: 4,
          last_inventory: 4,
        },
        {
          material_num: "000000000000119790",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0.3,0.22,0.55,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.09000000357627869,
          average_data: "[0.06,0,0.05,0.15,0.14,0.11]",
          average_trend: 1.2200000286102295,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 15,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.9700000286102295,
          average_data: "[0.71,1,0.95,1.63,0.83,0.67]",
          average_trend: 0.6899999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 0.5199999809265137,
          average_data: "[0.35,0.5,0.5,0.59,0.76,0.44]",
          average_trend: 0.8500000238418579,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000151978",
          consignment: 32,
          refill_qty: 6,
          current_inventory: 26,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.47,0.25,0.65,0.44,0.34,0.11]",
          average_trend: 0.28999999165534973,
          init_inventory: 32,
          last_inventory: 32,
        },
      ],
      cap: 242,
      inv: 161,
    },
    {
      customerNum: "0600188663",
      equipmentNum: "000000000100532288",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T23:14:00.000Z",
      lastInventoryUpdate: "2021-05-12T23:14:00.000Z",
      lastFillRate: 0.5899999737739563,
      nextDeliveryPlan: "2021-06-14",
      nextDeliveryAct: "2021-06-14",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.7099999785423279,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 199,
      materialConsumption: 3.4100000858306885,
      dri: 19,
      pfr: 46,
      pfrc: 68,
      pfu: 128,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-06-14",
      name: "LOYOLA UNIV-M2-CABRA HALL",
      street: "7214 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12015127",
      location: "I/S FRNT 2   ",
      dexErrors: false,
      online: "SLW232000398",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 46,
          refill_qty: 32,
          current_inventory: 14,
          average_based_on_rec: 4,
          average_consumption: 0.7099999785423279,
          average_data: "[0.64,0.5,0.58,1.12]",
          average_trend: 1.5800000429153442,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 30,
          current_inventory: 16,
          average_based_on_rec: 4,
          average_consumption: 0.550000011920929,
          average_data: "[0.64,0.23,0.58,0.74]",
          average_trend: 1.350000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 23,
          refill_qty: 14,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.1899999976158142,
          average_data: "[0.32,0.08,0.29,0.05]",
          average_trend: 0.25999999046325684,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 4,
          average_consumption: 0.36000001430511475,
          average_data: "[0,0.08,0.43,0.93]",
          average_trend: 2.5799999237060547,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 69,
          refill_qty: 9,
          current_inventory: 60,
          average_based_on_rec: 4,
          average_consumption: 0.6100000143051147,
          average_data: "[0,0.19,1.18,1.07]",
          average_trend: 1.75,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 4,
          average_consumption: 0.38999998569488525,
          average_data: "[0,0.21,0.55,0.79]",
          average_trend: 2.0299999713897705,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 16,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.3199999928474426,
          average_data: "[0.32,0.25,0.29,0.42]",
          average_trend: 1.309999942779541,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000125522",
          consignment: 23,
          refill_qty: 15,
          current_inventory: 8,
          average_based_on_rec: 4,
          average_consumption: 0.2800000011920929,
          average_data: "[0.32,0.19,0.29,0.33]",
          average_trend: 1.1799999475479126,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 276,
      inv: 148,
    },
    {
      customerNum: "0600188287",
      equipmentNum: "000000000100524325",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-18T20:27:00.000Z",
      lastInventoryUpdate: "2021-05-18T20:27:00.000Z",
      lastFillRate: 0.07999999821186066,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.8999999761581421,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 143,
      materialConsumption: 3.619999885559082,
      dri: 18,
      pfr: 23,
      pfrc: 65,
      pfu: 33,
      numSalesDays: 8,
      ftc: false,
      sort: "110000002021-06-11",
      name: "XAVIER UNIVERSITY-M6-SMT",
      street: "801 S NORMAN C FRANCIS",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY00056057",
      location: "NEW DORM 3   ",
      dexErrors: false,
      online: "SLW232000113",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.8999999761581421,
          average_data: "[0,1.41,1.44,1.29,1,0.23]",
          average_trend: 0.25999999046325684,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0.33,0.3,0.25,0.14,0.11,0.15]",
          average_trend: 0.7099999785423279,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 23,
          refill_qty: 5,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.5400000214576721,
          average_data: "[0.74,0.59,1.06,0.57,0.22,0.08]",
          average_trend: 0.15000000596046448,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0.51,0.3,0.5,0.57,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114633",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.8899999856948853,
          average_data: "[0.74,1.11,1,1.57,0.67,0.23]",
          average_trend: 0.25999999046325684,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0.33,0.74,0.56,0.71,1,0.15]",
          average_trend: 0.25999999046325684,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0.56,0,0.31,0.14,0.11,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 143,
      inv: 110,
    },
    {
      customerNum: "0600188304",
      equipmentNum: "000000000100512724",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-10T16:52:00.000Z",
      lastInventoryUpdate: "2021-05-10T16:52:00.000Z",
      lastFillRate: 0.49000000953674316,
      nextDeliveryPlan: "2021-06-08",
      nextDeliveryAct: "2021-06-08",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3799999952316284,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 176,
      materialConsumption: 2.2699999809265137,
      dri: 13,
      pfr: 23,
      pfrc: 37,
      pfu: 41,
      numSalesDays: 16,
      ftc: false,
      sort: "110000002021-06-08",
      name: "TULANE UNIV BROADWAY-M3",
      street: "200 BROADWAY ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY99078352",
      location: "SUITE #230 2ND FLOOR ELEVATOR ",
      dexErrors: false,
      online: "SLW232000313",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.17000000178813934,
          average_data: "[0.09,0.42,0.21,0,0.15]",
          average_trend: 0.8799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 5,
          average_consumption: 0.6299999952316284,
          average_data: "[0.41,0.53,0.88,0.83,0.48]",
          average_trend: 0.7599999904632568,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.3799999952316284,
          average_data: "[0.2,0.47,0.5,0.59,0.13]",
          average_trend: 0.3400000035762787,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.23999999463558197,
          average_data: "[0.23,0.53,0,0.24,0.2]",
          average_trend: 0.8299999833106995,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 64,
          refill_qty: 4,
          current_inventory: 60,
          average_based_on_rec: 5,
          average_consumption: 0.20000000298023224,
          average_data: "[0.29,0.05,0.08,0.34,0.22]",
          average_trend: 1.100000023841858,
          init_inventory: 64,
          last_inventory: 64,
        },
        {
          material_num: "000000000000114633",
          consignment: 28,
          refill_qty: 6,
          current_inventory: 22,
          average_based_on_rec: 5,
          average_consumption: 0.33000001311302185,
          average_data: "[0.09,0.42,0.33,0.44,0.39]",
          average_trend: 1.1799999475479126,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.10000000149011612,
          average_data: "[0.06,0.16,0,0.24,0.04]",
          average_trend: 0.4000000059604645,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.2199999988079071,
          average_data: "[0.12,0.21,0.29,0.2,0.26]",
          average_trend: 1.1799999475479126,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 176,
      inv: 135,
    },
    {
      customerNum: "0600188489",
      equipmentNum: "000000000100524713",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-06T02:09:00.000Z",
      lastInventoryUpdate: "2021-04-06T02:09:00.000Z",
      lastFillRate: 0.05000000074505806,
      nextDeliveryPlan: "2021-06-28",
      nextDeliveryAct: "2021-06-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 6,
      criticalMaterialConsumption: 0.05999999865889549,
      criticalMaterialNum: "000000000000117635",
      materialInventory: 44,
      materialConsumption: 0.07999999821186066,
      dri: 34,
      pfr: 9,
      pfrc: 15,
      pfu: 4,
      numSalesDays: 50,
      ftc: false,
      sort: "110000002021-06-28",
      name: "TULANE PRINTING-M1",
      street: "900 S NORMAN C FRANCIS",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY11004100",
      location: "   ",
      dexErrors: false,
      online: "SLW232000416",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 5,
          refill_qty: 5,
          current_inventory: 5,
          average_based_on_rec: 2,
          average_consumption: 0,
          average_data: "[0,0]",
          average_trend: 1,
          init_inventory: 5,
          last_inventory: 5,
        },
        {
          material_num: "000000000000102603",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 2,
          average_consumption: 0,
          average_data: "[0,0]",
          average_trend: 1,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 2,
          average_consumption: 0,
          average_data: "[0,0]",
          average_trend: 1,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102659",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 5,
          average_based_on_rec: 2,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0.04]",
          average_trend: 2,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000117635",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 3,
          average_based_on_rec: 2,
          average_consumption: 0.05999999865889549,
          average_data: "[0.07,0.04]",
          average_trend: 0.6700000166893005,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000117641",
          consignment: 3,
          refill_qty: 3,
          current_inventory: 3,
          average_based_on_rec: 2,
          average_consumption: 0,
          average_data: "[0,0]",
          average_trend: 1,
          init_inventory: 3,
          last_inventory: 3,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 2,
          average_consumption: 0,
          average_data: "[0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 44,
      inv: 40,
    },
    {
      customerNum: "0500389022",
      equipmentNum: "000000000101090581",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T18:34:00.000Z",
      lastInventoryUpdate: "2021-05-21T18:34:00.000Z",
      lastFillRate: 0.17000000178813934,
      nextDeliveryPlan: "2021-06-02",
      nextDeliveryAct: "2021-06-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.9800000190734863,
      criticalMaterialNum: "000000000000156114",
      materialInventory: 309,
      materialConsumption: 9.260000228881836,
      dri: 7,
      pfr: 18,
      pfrc: 35,
      pfu: 57,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-02",
      name: "MARKET SERVICE CENTER-M18",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "DN17001259",
      location: "COOLER SER 2ND FLOOR BREAKROOM ",
      dexErrors: true,
      online: "22990157411",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 16,
          refill_qty: 1,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0,0,0,0,0.31]",
          average_trend: 6.199999809265137,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102752",
          consignment: 16,
          refill_qty: 1,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0,0,0,0,0.46]",
          average_trend: 5.75,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.029999999329447746,
          average_data: "[0.15,0,0,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 25,
          refill_qty: 3,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0,0.4,1.08,0.53,0.62,0.15]",
          average_trend: 0.33000001311302185,
          init_inventory: 25,
          last_inventory: 25,
        },
        {
          material_num: "000000000000145105",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.4399999976158142,
          average_data: "[0,0.8,0.31,0.27,0.46,0.77]",
          average_trend: 1.75,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000151817",
          consignment: 8,
          refill_qty: 3,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.49000000953674316,
          average_data: "[0,0,1.23,0.8,0.92,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000151818",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0,0.77,0,0.46,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000152921",
          consignment: 8,
          refill_qty: 1,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0,0.46,0,0,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000154898",
          consignment: 16,
          refill_qty: 1,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.53,0.31,0.27,0,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000154900",
          consignment: 16,
          refill_qty: 1,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.029999999329447746,
          average_data: "[0.15,0,0,0,0,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000156114",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.9800000190734863,
          average_data: "[0,0.53,1.85,1.2,1.38,0.92]",
          average_trend: 0.9399999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156137",
          consignment: 48,
          refill_qty: 15,
          current_inventory: 33,
          average_based_on_rec: 6,
          average_consumption: 2.8299999237060547,
          average_data: "[2.77,3.87,4.15,3.73,1.54,0.92]",
          average_trend: 0.33000001311302185,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000156188",
          consignment: 8,
          refill_qty: 1,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0,0.46,0,0,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000156530",
          consignment: 16,
          refill_qty: 0,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000157135",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0,0.15,0,0,0.31]",
          average_trend: 3.880000114440918,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157440",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.6200000047683716,
          average_data: "[0.92,0,1.38,0.67,0,0.77]",
          average_trend: 1.2400000095367432,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000410074",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.18000000715255737,
          average_data: "[1.08,0,0,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000410384",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 1.5,
          average_data: "[1.23,0.67,2.46,0.93,1.54,2.15]",
          average_trend: 1.4299999475479126,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410703",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.9300000071525574,
          average_data: "[0.46,1.33,1.85,1.33,0.62,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 309,
      inv: 252,
    },
    {
      customerNum: "0600188935",
      equipmentNum: "000000000101090512",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T19:16:00.000Z",
      lastInventoryUpdate: "2021-05-21T14:32:18.000Z",
      lastFillRate: 0.07000000029802322,
      nextDeliveryPlan: "2021-06-14",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 5,
      criticalMaterialConsumption: 0.17000000178813934,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 132,
      materialConsumption: 2.0299999713897705,
      dri: 19,
      pfr: 14,
      pfrc: 39,
      pfu: 20,
      numSalesDays: 5,
      ftc: false,
      sort: "110000002021-06-04",
      name: "XAVIER UNIVERSITY LLC-M09",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY17000550",
      location: "5TH FLOOR  5TH FLOOR ",
      dexErrors: true,
      online: "SLW232000419",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 46,
          refill_qty: 5,
          current_inventory: 41,
          average_based_on_rec: 6,
          average_consumption: 0.5899999737739563,
          average_data: "[0,1.33,0.59,0.46,1.13,0]",
          average_trend: 0,
          init_inventory: 46,
          last_inventory: 44,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.20000000298023224,
          average_data: "[0.14,0.11,0.3,0.08,0.31,0.27]",
          average_trend: 1.350000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 2,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.17000000178813934,
          average_data: "[0.08,0,0.22,0.46,0,0.27]",
          average_trend: 1.590000033378601,
          init_inventory: 6,
          last_inventory: 5,
        },
        {
          material_num: "000000000000102605",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0.11,0,0.59,0.38,0.31,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 10,
        },
        {
          material_num: "000000000000102660",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0.11,0,0.22,0,0.19,0.13]",
          average_trend: 1.1799999475479126,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0.89,0.07,0.15,0.13,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114306",
          consignment: 19,
          refill_qty: 2,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.2199999988079071,
          average_data: "[0,0.33,0.44,0.38,0.19,0]",
          average_trend: 0,
          init_inventory: 19,
          last_inventory: 19,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0,0.44,0.44,0.54,0.38,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 137,
      inv: 117,
    },
    {
      customerNum: "0600188934",
      equipmentNum: "000000000100508375",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T19:15:00.000Z",
      lastInventoryUpdate: "2021-05-06T19:15:00.000Z",
      lastFillRate: 0.41999998688697815,
      nextDeliveryPlan: "2021-05-31",
      nextDeliveryAct: "2021-05-31",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.44999998807907104,
      criticalMaterialNum: "000000000000114230",
      materialInventory: 120,
      materialConsumption: 2.4200000762939453,
      dri: 5,
      pfr: 40,
      pfrc: 38,
      pfu: 39,
      numSalesDays: 20,
      ftc: false,
      sort: "110000002021-05-31",
      name: "XAVIER UNIVERSITY LLC-M08",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY02014782",
      location: "I/S FL3 KI 3rd KITCHENETT ",
      dexErrors: false,
      online: "SLW232000219",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 16,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.7699999809265137,
          average_data: "[0.6,0.39,0.62,1.5,0.73]",
          average_trend: 0.949999988079071,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 5,
          average_consumption: 0.5400000214576721,
          average_data: "[0.25,0.44,0.55,0.81,0.67]",
          average_trend: 1.2400000095367432,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 0,
          current_inventory: 37,
          average_based_on_rec: 5,
          average_consumption: 0.5299999713897705,
          average_data: "[0.6,0.33,0,0.31,1.4]",
          average_trend: 2.640000104904175,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 9,
          current_inventory: 3,
          average_based_on_rec: 5,
          average_consumption: 0.44999998807907104,
          average_data: "[0.3,0.28,0.41,0.75,0.53]",
          average_trend: 1.1799999475479126,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.12999999523162842,
          average_data: "[0.23,0,0.21,0.19,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 96,
      inv: 70,
    },
    {
      customerNum: "0600188939",
      equipmentNum: "000000000100527947",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T18:14:00.000Z",
      lastInventoryUpdate: "2021-05-06T18:14:00.000Z",
      lastFillRate: 0.28999999165534973,
      nextDeliveryPlan: "2021-06-21",
      nextDeliveryAct: "2021-06-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 1.0299999713897705,
      criticalMaterialNum: "000000000000151978",
      materialInventory: 189,
      materialConsumption: 2.549999952316284,
      dri: 26,
      pfr: 27,
      pfrc: 62,
      pfu: 52,
      numSalesDays: 20,
      ftc: false,
      sort: "110000002021-06-21",
      name: "XAVIER UNIVERSITY-M03-NEW DORM",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "FL0116308",
      location: "NEW DORM 7 7th  ",
      dexErrors: false,
      online: "SLW232000319",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 48,
          refill_qty: 13,
          current_inventory: 35,
          average_based_on_rec: 4,
          average_consumption: 0.6499999761581421,
          average_data: "[0.51,0.67,0.7,0.73]",
          average_trend: 1.1200000047683716,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 4,
          average_consumption: 0.28999999165534973,
          average_data: "[0.51,0.22,0.33,0.09]",
          average_trend: 0.3100000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 46,
          refill_qty: 5,
          current_inventory: 41,
          average_based_on_rec: 4,
          average_consumption: 0.25,
          average_data: "[0.49,0.07,0.19,0.23]",
          average_trend: 0.9200000166893005,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000114230",
          consignment: 23,
          refill_qty: 7,
          current_inventory: 16,
          average_based_on_rec: 4,
          average_consumption: 0.33000001311302185,
          average_data: "[0.26,0.07,0.42,0.55]",
          average_trend: 1.6699999570846558,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000151978",
          consignment: 48,
          refill_qty: 21,
          current_inventory: 27,
          average_based_on_rec: 4,
          average_consumption: 1.0299999713897705,
          average_data: "[0.66,2.07,0.51,0.86]",
          average_trend: 0.8299999833106995,
          init_inventory: 48,
          last_inventory: 48,
        },
      ],
      cap: 189,
      inv: 137,
    },
    {
      customerNum: "0500917551",
      equipmentNum: "000000000103070261",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T17:44:00.000Z",
      lastInventoryUpdate: "2021-05-21T17:44:00.000Z",
      lastFillRate: 0.5299999713897705,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 2.8299999237060547,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 277,
      materialConsumption: 18.399999618530273,
      dri: 3,
      pfr: 35,
      pfrc: 53,
      pfu: 97,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-05-28",
      name: "MARKET SERVICE CENTER-M21-FRT GATE",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "RY19000136",
      location: "front gate   ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 2.240000009536743,
          average_data: "[1.23,2.53,3.07,2.93,2.15,1.54]",
          average_trend: 0.6899999976158142,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 27,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 5.25,
          average_data: "[4,5.2,5.73,6.13,3.69,6.77]",
          average_trend: 1.2899999618530273,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102604",
          consignment: 23,
          refill_qty: 15,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 2.8299999237060547,
          average_data: "[3.54,2.93,1.87,3.07,2,3.54]",
          average_trend: 1.25,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102660",
          consignment: 46,
          refill_qty: 7,
          current_inventory: 39,
          average_based_on_rec: 6,
          average_consumption: 1.2400000095367432,
          average_data: "[0.62,1.07,0.93,1.6,2,1.23]",
          average_trend: 0.9900000095367432,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 8,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.4900000095367432,
          average_data: "[0.46,0.93,2.4,1.73,1.08,2.31]",
          average_trend: 1.5499999523162842,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000156563",
          consignment: 46,
          refill_qty: 11,
          current_inventory: 35,
          average_based_on_rec: 6,
          average_consumption: 2.1700000762939453,
          average_data: "[1.85,2.4,2.13,2.93,0,3.69]",
          average_trend: 1.7000000476837158,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000156574",
          consignment: 46,
          refill_qty: 8,
          current_inventory: 38,
          average_based_on_rec: 6,
          average_consumption: 1.5299999713897705,
          average_data: "[0,1.6,1.6,2,2.15,1.85]",
          average_trend: 1.2100000381469727,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000157135",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.649999976158142,
          average_data: "[1.85,1.33,1.47,1.07,2.31,1.85]",
          average_trend: 1.1200000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 277,
      inv: 180,
    },
    {
      customerNum: "0600189148",
      equipmentNum: "000000000100516714",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T22:33:00.000Z",
      lastInventoryUpdate: "2021-05-11T22:33:00.000Z",
      lastFillRate: 0.3400000035762787,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 0.7200000286102295,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 151,
      materialConsumption: 2.940000057220459,
      dri: 16,
      pfr: 31,
      pfrc: 60,
      pfu: 48,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-06-11",
      name: "XAVIER UNIVERSITY KD-M1-4TH FLOOR",
      street: "7235 DREXEL DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY99103642",
      location: "KD 4TH FL 4th 0Z ",
      dexErrors: true,
      online: "SLW232000195",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 11,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.7200000286102295,
          average_data: "[0.47,0.1,0.8,0.75,1.38,0.83]",
          average_trend: 1.149999976158142,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.23999999463558197,
          average_data: "[0.35,0.2,0.15,0.19,0.15,0.41]",
          average_trend: 1.7100000381469727,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0.29,0,0,0,0.15,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102660",
          consignment: 23,
          refill_qty: 7,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.4000000059604645,
          average_data: "[0.15,0.34,0.6,0.56,0.31,0.41]",
          average_trend: 1.0299999713897705,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 23,
          refill_qty: 9,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.5899999737739563,
          average_data: "[0.15,0.24,0.3,0.56,1.46,0.83]",
          average_trend: 1.409999966621399,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0.35,0.1,0.25,0.19,0.46,0.41]",
          average_trend: 1.409999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114532",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.23999999463558197,
          average_data: "[0.38,0.49,0.45,0.13,0,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000114633",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0.18,0.2,0.4,0.5,0.46,0.62]",
          average_trend: 1.590000033378601,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 151,
      inv: 103,
    },
    {
      customerNum: "0600187309",
      equipmentNum: "000000000100527675",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-14T02:20:00.000Z",
      lastInventoryUpdate: "2021-05-14T02:20:00.000Z",
      lastFillRate: 0.12999999523162842,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.46000000834465027,
      criticalMaterialNum: "000000000000156090",
      materialInventory: 262,
      materialConsumption: 3.359999895095825,
      dri: 12,
      pfr: 18,
      pfrc: 31,
      pfu: 49,
      numSalesDays: 12,
      ftc: false,
      sort: "110000002021-06-04",
      name: "TULANE-M44-REILLY CENTER",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN09001843",
      location: "YULMAN STA 2ND FLOOR RECREATION ",
      dexErrors: false,
      online: "SLW232000300",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MWF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 72,
          refill_qty: 13,
          current_inventory: 59,
          average_based_on_rec: 6,
          average_consumption: 1.059999942779541,
          average_data: "[1.55,1.63,0.89,0.43,1.26,0.6]",
          average_trend: 0.5699999928474426,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0.35,0.56,0.15,0,0.05,0.04]",
          average_trend: 0.20999999344348907,
          init_inventory: 22,
          last_inventory: 22,
        },
        {
          material_num: "000000000000133131",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0,0,0.1,0,0.04]",
          average_trend: 2,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0,0.22,0,0.05,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.1,0,0.37,0.05,0,0.08]",
          average_trend: 0.800000011920929,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0.4,0,0.22,0.14,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156083",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.05999999865889549,
          average_data: "[0.1,0,0.15,0.1,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156084",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0.2,0.25,0.59,0.33,0.42,0.24]",
          average_trend: 0.7099999785423279,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156085",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0.25,0,0.37,0.05,0,0.08]",
          average_trend: 0.6200000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156090",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0.45,0.5,0.89,0.33,0.37,0.24]",
          average_trend: 0.5199999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156091",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0.25,0.31,0,0.29,0.05,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156112",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0.3,0.31,0.89,0.24,0.53,0.08]",
          average_trend: 0.20999999344348907,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157347",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 20,
          average_based_on_rec: 3,
          average_consumption: 0.2800000011920929,
          average_data: "[0,0.84,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 264,
      inv: 215,
    },
    {
      customerNum: "0600189981",
      equipmentNum: "000000000100507779",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T22:46:00.000Z",
      lastInventoryUpdate: "2021-05-11T22:46:00.000Z",
      lastFillRate: 0.25,
      nextDeliveryPlan: "2021-06-03",
      nextDeliveryAct: "2021-06-03",
      schedulingMethod: "D",
      criticalMaterialInventory: 6,
      criticalMaterialConsumption: 0.2199999988079071,
      criticalMaterialNum: "000000000000102605",
      materialInventory: 210,
      materialConsumption: 4.03000020980835,
      dri: 8,
      pfr: 32,
      pfrc: 44,
      pfu: 69,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-06-03",
      name: "XAVIER UNIVERSITY LLC-M12",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN14003162",
      location: "2ND FLOOR  KITCHENETT 220 ",
      dexErrors: false,
      online: "SLW232000311",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 5,
          average_consumption: 0.36000001430511475,
          average_data: "[0.21,0.13,0.42,0.54,0.52]",
          average_trend: 1.440000057220459,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.2199999988079071,
          average_data: "[0.21,0.13,0.25,0.38,0.15]",
          average_trend: 0.6800000071525574,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102605",
          consignment: 6,
          refill_qty: 4,
          current_inventory: 2,
          average_based_on_rec: 5,
          average_consumption: 0.2199999988079071,
          average_data: "[0.13,0.25,0.18,0.23,0.3]",
          average_trend: 1.3600000143051147,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 4,
          current_inventory: 44,
          average_based_on_rec: 5,
          average_consumption: 0.25,
          average_data: "[0,0.69,0.04,0.31,0.22]",
          average_trend: 0.8799999952316284,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000114229",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 5,
          average_consumption: 0.28999999165534973,
          average_data: "[0,0.63,0.42,0.31,0.07]",
          average_trend: 0.23999999463558197,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.4000000059604645,
          average_data: "[0,0.75,0.42,0.38,0.44]",
          average_trend: 1.100000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114306",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.25,
          average_data: "[0,0.5,0.28,0.31,0.15]",
          average_trend: 0.6000000238418579,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114532",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.25,0.04,0.31,0.22]",
          average_trend: 1.3799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000115964",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 5,
          average_consumption: 0.3499999940395355,
          average_data: "[0.11,0.69,0.25,0.31,0.37]",
          average_trend: 1.059999942779541,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0.25,0.11,0.46,0.22]",
          average_trend: 1.0499999523162842,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 5,
          average_consumption: 0.4699999988079071,
          average_data: "[0.17,0.25,0.35,0.85,0.74]",
          average_trend: 1.5700000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.41999998688697815,
          average_data: "[0,0.75,0.42,0.92,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 5,
          average_consumption: 0.4300000071525574,
          average_data: "[0.19,0.38,0.42,0.62,0.52]",
          average_trend: 1.2100000381469727,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 210,
      inv: 141,
    },
    {
      customerNum: "0600189948",
      equipmentNum: "000000000100516133",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T01:02:00.000Z",
      lastInventoryUpdate: "2021-05-05T01:02:00.000Z",
      lastFillRate: 0.10999999940395355,
      nextDeliveryPlan: "2021-07-02",
      nextDeliveryAct: "2021-07-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 39,
      criticalMaterialConsumption: 0.6499999761581421,
      criticalMaterialNum: "000000000000115583",
      materialInventory: 253,
      materialConsumption: 2.3299999237060547,
      dri: 38,
      pfr: 29,
      pfrc: 59,
      pfu: 83,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-07-02",
      name: "TULANE-M25-LINDY BOGGS",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY01022707",
      location: "LINDSEY BO 2ND FLOOR BACK HALL ",
      dexErrors: false,
      online: "SLW232000154",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 92,
          refill_qty: 24,
          current_inventory: 68,
          average_based_on_rec: 6,
          average_consumption: 0.6200000047683716,
          average_data: "[1.19,0.11,0.43,0.69,0.62,0.68]",
          average_trend: 1.100000023841858,
          init_inventory: 82,
          last_inventory: 82,
        },
        {
          material_num: "000000000000115583",
          consignment: 48,
          refill_qty: 23,
          current_inventory: 25,
          average_based_on_rec: 6,
          average_consumption: 0.6499999761581421,
          average_data: "[0.89,0.78,0.29,0.92,0.69,0.32]",
          average_trend: 0.49000000953674316,
          init_inventory: 39,
          last_inventory: 39,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0.89,0.22,0.36,0,0.33,0.25]",
          average_trend: 0.7400000095367432,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115586",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0.3,0,0,0.46,0.25,0.11]",
          average_trend: 0.5799999833106995,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000116150",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0,0,0,0.11,0.36]",
          average_trend: 4.5,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000116151",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0.3,0,0,0.92,0.11,0.07]",
          average_trend: 0.30000001192092896,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.029999999329447746,
          average_data: "[0,0,0,0,0.04,0.11]",
          average_trend: 3.6700000762939453,
          init_inventory: 20,
          last_inventory: 20,
        },
        {
          material_num: "000000000000117604",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0.89,0,0,0,0.07,0.18]",
          average_trend: 0.949999988079071,
          init_inventory: 19,
          last_inventory: 19,
        },
      ],
      cap: 284,
      inv: 201,
    },
    {
      customerNum: "0600189711",
      equipmentNum: "000000000100519779",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T21:18:00.000Z",
      lastInventoryUpdate: "2021-05-19T21:18:00.000Z",
      lastFillRate: 0.14000000059604645,
      nextDeliveryPlan: "2021-06-15",
      nextDeliveryAct: "2021-06-15",
      schedulingMethod: "D",
      criticalMaterialInventory: 17,
      criticalMaterialConsumption: 0.6000000238418579,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 236,
      materialConsumption: 3.450000047683716,
      dri: 20,
      pfr: 16,
      pfrc: 42,
      pfu: 42,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-15",
      name: "LOYOLA UNIV ACT CTR-M3",
      street: "501 PINE ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN13000179",
      location: "ACTIVITY C 1ST FLOOR BRREAKROOM ",
      dexErrors: false,
      online: "SLW232000282",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.11999999731779099,
          average_data: "[0.1,0.14,0.14,0.15,0.07]",
          average_trend: 0.5799999833106995,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.36000001430511475,
          average_data: "[0.26,0.5,0.21,0.41,0.41]",
          average_trend: 1.1399999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 5,
          average_consumption: 0.6000000238418579,
          average_data: "[0.48,0.73,0.83,0.47,0.48]",
          average_trend: 0.800000011920929,
          init_inventory: 17,
          last_inventory: 17,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.10999999940395355,
          average_data: "[0.29,0,0.07,0.12,0.07]",
          average_trend: 0.6399999856948853,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 3,
          current_inventory: 45,
          average_based_on_rec: 5,
          average_consumption: 0.4099999964237213,
          average_data: "[0.58,0.45,0.41,0.35,0.28]",
          average_trend: 0.6800000071525574,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.30000001192092896,
          average_data: "[0.19,0.14,0.34,0.35,0.48]",
          average_trend: 1.600000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.2199999988079071,
          average_data: "[0.23,0.18,0.34,0.21,0.14]",
          average_trend: 0.6399999856948853,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000119790",
          consignment: 4,
          refill_qty: 2,
          current_inventory: 2,
          average_based_on_rec: 5,
          average_consumption: 0.05000000074505806,
          average_data: "[0.03,0.14,0,0.03,0.07]",
          average_trend: 1.399999976158142,
          init_inventory: 3,
          last_inventory: 3,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 5,
          average_consumption: 0.28999999165534973,
          average_data: "[0.23,0.55,0.28,0.24,0.14]",
          average_trend: 0.47999998927116394,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000129254",
          consignment: 56,
          refill_qty: 5,
          current_inventory: 51,
          average_based_on_rec: 5,
          average_consumption: 0.6899999976158142,
          average_data: "[0.39,0.82,0.62,0.94,0.69]",
          average_trend: 1,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000135333",
          consignment: 16,
          refill_qty: 1,
          current_inventory: 15,
          average_based_on_rec: 5,
          average_consumption: 0.05999999865889549,
          average_data: "[0.06,0,0,0.24,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000151978",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 5,
          average_consumption: 0.23999999463558197,
          average_data: "[0,0.18,0.62,0.21,0.21]",
          average_trend: 0.8799999952316284,
          init_inventory: 16,
          last_inventory: 16,
        },
      ],
      cap: 248,
      inv: 206,
    },
    {
      customerNum: "0600189714",
      equipmentNum: "000000000100512426",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-03T22:40:00.000Z",
      lastInventoryUpdate: "2021-05-03T22:40:00.000Z",
      lastFillRate: 0.4099999964237213,
      nextDeliveryPlan: "2021-06-25",
      nextDeliveryAct: "2021-06-25",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.20999999344348907,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 181,
      materialConsumption: 1.5299999713897705,
      dri: 30,
      pfr: 22,
      pfrc: 44,
      pfu: 41,
      numSalesDays: 23,
      ftc: false,
      sort: "110000002021-06-25",
      name: "LOYOLA UNIV-M16-MUSIC",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY07014626",
      location: "LOUIS J RO 1ST FLOOR LOCKERS ",
      dexErrors: false,
      online: "SLW232000371",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.20999999344348907,
          average_data: "[0.34,0.15,0.1,0.25]",
          average_trend: 1.190000057220459,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 4,
          average_consumption: 0.23999999463558197,
          average_data: "[0,0.27,0.1,0.59]",
          average_trend: 2.4600000381469727,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 10,
          refill_qty: 3,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0.06,0.12,0.13,0.19]",
          average_trend: 1.4600000381469727,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000102660",
          consignment: 14,
          refill_qty: 5,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.32,0.27,0.16]",
          average_trend: 0.8399999737739563,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000112259",
          consignment: 30,
          refill_qty: 6,
          current_inventory: 24,
          average_based_on_rec: 4,
          average_consumption: 0.2199999988079071,
          average_data: "[0,0,0.43,0.44]",
          average_trend: 2,
          init_inventory: 30,
          last_inventory: 30,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.10000000149011612,
          average_data: "[0,0.15,0.13,0.13]",
          average_trend: 1.2999999523162842,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000119790",
          consignment: 7,
          refill_qty: 1,
          current_inventory: 6,
          average_based_on_rec: 4,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0,0,0.06]",
          average_trend: 3,
          init_inventory: 7,
          last_inventory: 7,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 4,
          average_consumption: 0.029999999329447746,
          average_data: "[0,0.05,0.03,0.03]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000154898",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0.1,0.17,0.16]",
          average_trend: 1.4500000476837158,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000154900",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.11999999731779099,
          average_data: "[0,0.22,0.07,0.19]",
          average_trend: 1.5800000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000154914",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0.07,0.07,0.09]",
          average_trend: 1.5,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156530",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.10000000149011612,
          average_data: "[0,0.15,0.17,0.06]",
          average_trend: 0.6000000238418579,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 181,
      inv: 140,
    },
    {
      customerNum: "0600189417",
      equipmentNum: "000000000100528827",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-26T21:27:00.000Z",
      lastInventoryUpdate: "2021-04-26T21:27:00.000Z",
      lastFillRate: 0.23999999463558197,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3100000023841858,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 112,
      materialConsumption: 1.7899999618530273,
      dri: 6,
      pfr: 67,
      pfrc: 71,
      pfu: 112,
      numSalesDays: 30,
      ftc: false,
      sort: "110000002021-06-01",
      name: "LOYOLA UNIV-M03-MONROE HALL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY99074213",
      location: "I/S FL3 VE  Z ",
      dexErrors: false,
      online: "SLW232000285",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 21,
          current_inventory: 3,
          average_based_on_rec: 5,
          average_consumption: 0.27000001072883606,
          average_data: "[0.26,0.18,0.41,0.18,0.34]",
          average_trend: 1.2599999904632568,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 36,
          refill_qty: 34,
          current_inventory: 2,
          average_based_on_rec: 5,
          average_consumption: 0.3100000023841858,
          average_data: "[0.26,0.41,0.48,0,0.41]",
          average_trend: 1.3200000524520874,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 16,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.10999999940395355,
          average_data: "[0.03,0.18,0,0.27,0.07]",
          average_trend: 0.6399999856948853,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.11999999731779099,
          average_data: "[0.31,0.14,0,0,0.14]",
          average_trend: 1.1699999570846558,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 23,
          refill_qty: 8,
          current_inventory: 15,
          average_based_on_rec: 5,
          average_consumption: 0.25,
          average_data: "[0.11,0.55,0.28,0.09,0.21]",
          average_trend: 0.8399999737739563,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.23000000417232513,
          average_data: "[0.06,0.23,0.55,0.18,0.14]",
          average_trend: 0.6100000143051147,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0.05,0.07,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000151978",
          consignment: 23,
          refill_qty: 15,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.47999998927116394,
          average_data: "[0.34,0.73,0.28,0.91,0.14]",
          average_trend: 0.28999999165534973,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 166,
      inv: 54,
    },
    {
      customerNum: "0600189778",
      equipmentNum: "000000000100516577",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-21T19:28:00.000Z",
      lastInventoryUpdate: "2021-04-21T19:28:00.000Z",
      lastFillRate: 0.5099999904632568,
      nextDeliveryPlan: "2021-05-31",
      nextDeliveryAct: "2021-05-31",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.2800000011920929,
      criticalMaterialNum: "000000000000121751",
      materialInventory: 166,
      materialConsumption: 2.0299999713897705,
      dri: 5,
      pfr: 67,
      pfrc: 69,
      pfu: 185,
      numSalesDays: 35,
      ftc: false,
      sort: "110000002021-05-31",
      name: "LOYOLA UNIV-M18-MERCY HALL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12011637",
      location: "MERCY HALL   ",
      dexErrors: false,
      online: "SLW232000410",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 46,
          refill_qty: 33,
          current_inventory: 13,
          average_based_on_rec: 4,
          average_consumption: 0.28999999165534973,
          average_data: "[0.64,0.06,0.09,0.35]",
          average_trend: 1.2100000381469727,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 33,
          current_inventory: 13,
          average_based_on_rec: 4,
          average_consumption: 0.3100000023841858,
          average_data: "[0.64,0.2,0.09,0.29]",
          average_trend: 0.9399999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 23,
          refill_qty: 18,
          current_inventory: 5,
          average_based_on_rec: 4,
          average_consumption: 0.18000000715255737,
          average_data: "[0.32,0,0.05,0.35]",
          average_trend: 1.940000057220459,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 46,
          refill_qty: 36,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.38999998569488525,
          average_data: "[0.64,0.23,0.55,0.12]",
          average_trend: 0.3100000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 46,
          refill_qty: 8,
          current_inventory: 38,
          average_based_on_rec: 4,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0.06,0.32,0.47]",
          average_trend: 2.240000009536743,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000114230",
          consignment: 23,
          refill_qty: 19,
          current_inventory: 4,
          average_based_on_rec: 4,
          average_consumption: 0.20999999344348907,
          average_data: "[0.32,0.09,0.18,0.26]",
          average_trend: 1.2400000095367432,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 17,
          current_inventory: 6,
          average_based_on_rec: 4,
          average_consumption: 0.1599999964237213,
          average_data: "[0.32,0.06,0,0.26]",
          average_trend: 1.6299999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 21,
          current_inventory: 2,
          average_based_on_rec: 4,
          average_consumption: 0.2800000011920929,
          average_data: "[0.32,0.17,0.27,0.35]",
          average_trend: 1.25,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 276,
      inv: 91,
    },
    {
      customerNum: "0600189921",
      equipmentNum: "000000000100531921",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-20T03:55:48.000Z",
      lastInventoryUpdate: "2021-05-20T03:55:48.000Z",
      lastFillRate: 0,
      nextDeliveryPlan: "9999-12-31T00:00:00.000Z",
      nextDeliveryAct: "9999-12-31T00:00:00.000Z",
      schedulingMethod: "C",
      criticalMaterialInventory: null,
      criticalMaterialConsumption: null,
      criticalMaterialNum: null,
      materialInventory: 0,
      materialConsumption: null,
      dri: 0,
      pfr: 100,
      pfrc: 100,
      pfu: 252,
      numSalesDays: 6,
      ftc: false,
      sort: "110253402214400000",
      name: "DOMINICAN HS-M4",
      street: "2830 PINE ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY01019742",
      location: "OUTSIDE BY   ",
      dexErrors: true,
      online: "SLW232000246",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115584",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000116308",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000119791",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000121750",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000125491",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000135334",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000410647",
          consignment: 0,
          refill_qty: 0,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000410659",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
      ],
      cap: 252,
      inv: 0,
    },
    {
      customerNum: "0600189976",
      equipmentNum: "000000000100604819",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T23:13:00.000Z",
      lastInventoryUpdate: "2021-05-21T23:13:00.000Z",
      lastFillRate: 0.3100000023841858,
      nextDeliveryPlan: "2021-06-03",
      nextDeliveryAct: "2021-06-03",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.840000033378601,
      criticalMaterialNum: "000000000000133129",
      materialInventory: 284,
      materialConsumption: 9.350000381469727,
      dri: 8,
      pfr: 18,
      pfrc: 42,
      pfu: 53,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-03",
      name: "MARKET SERVICE CENTER-M13",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "DN16005659",
      location: "PRODUCTION 1ST BREAK ROOM ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 10,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 1.840000033378601,
          average_data: "[0.77,1.54,2.13,0.8,2.62,3.2]",
          average_trend: 1.7400000095367432,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0,0.27,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000150885",
          consignment: 20,
          refill_qty: 2,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.3199999928474426,
          average_data: "[0.15,0.31,0.67,0,0,0.8]",
          average_trend: 2.5,
          init_inventory: 20,
          last_inventory: 20,
        },
        {
          material_num: "000000000000152196",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 1.1799999475479126,
          average_data: "[2.31,1.85,0.27,0.93,0.77,0.93]",
          average_trend: 0.7900000214576721,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152202",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[1.08,0,0.27,0.67,0.77,0.67]",
          average_trend: 1.159999966621399,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152203",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.6399999856948853,
          average_data: "[1.69,1.85,1.2,1.73,1.38,2]",
          average_trend: 1.2200000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156530",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0,0.53,0.4,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156560",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.3199999928474426,
          average_data: "[0.31,0,0.4,0.27,0,0.93]",
          average_trend: 2.9100000858306885,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156563",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156574",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.7200000286102295,
          average_data: "[0.62,0,0.93,0.27,1.54,0.93]",
          average_trend: 1.2899999618530273,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157249",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.1599999964237213,
          average_data: "[0.31,0,0.13,0.27,0,0.27]",
          average_trend: 1.690000057220459,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157253",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.5,
          average_data: "[0.31,0.46,1.07,0.67,0.46,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000410384",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0,0,0.67,0,0.46,0.53]",
          average_trend: 1.8899999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410681",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 1.3899999856948853,
          average_data: "[3.69,0.46,0.8,1.07,1.23,1.07]",
          average_trend: 0.7699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410703",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0.46,0,0.13,0.13,0.15,0.4]",
          average_trend: 1.899999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 284,
      inv: 231,
    },
    {
      customerNum: "0600189779",
      equipmentNum: "000000000100519780",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T22:31:00.000Z",
      lastInventoryUpdate: "2021-05-05T22:31:00.000Z",
      lastFillRate: 0.49000000953674316,
      nextDeliveryPlan: "2021-06-15",
      nextDeliveryAct: "2021-06-15",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.27000001072883606,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 251,
      materialConsumption: 2.0999999046325684,
      dri: 20,
      pfr: 19,
      pfrc: 34,
      pfu: 49,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-06-15",
      name: "LOYOLA UNIV-M20-LIBRARY",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN13000180",
      location: "LIBRARY 2N   ",
      dexErrors: false,
      online: "SLW232000392",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.07,0.14]",
          average_trend: 2,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 8,
          average_based_on_rec: 3,
          average_consumption: 0.18000000715255737,
          average_data: "[0.1,0,0.43]",
          average_trend: 2.390000104904175,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 6,
          average_based_on_rec: 3,
          average_consumption: 0.27000001072883606,
          average_data: "[0.04,0.41,0.36]",
          average_trend: 1.3300000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.05000000074505806,
          average_data: "[0.02,0,0.14]",
          average_trend: 2.799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 72,
          refill_qty: 72,
          current_inventory: 64,
          average_based_on_rec: 3,
          average_consumption: 0.3799999952316284,
          average_data: "[0,0.21,0.93]",
          average_trend: 2.450000047683716,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000114633",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 21,
          average_based_on_rec: 3,
          average_consumption: 0.14000000059604645,
          average_data: "[0,0.1,0.32]",
          average_trend: 2.2899999618530273,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 8,
          average_based_on_rec: 3,
          average_consumption: 0.17000000178813934,
          average_data: "[0.02,0.1,0.39]",
          average_trend: 2.2899999618530273,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0.14,0.11]",
          average_trend: 1.3799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 32,
          average_based_on_rec: 3,
          average_consumption: 0.75,
          average_data: "[0,0.72,1.54]",
          average_trend: 2.049999952316284,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.009999999776482582,
          average_data: "[0,0,0.04]",
          average_trend: 4,
          init_inventory: 11,
          last_inventory: 11,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 24,
          average_based_on_rec: 3,
          average_consumption: 0,
          average_data: "[0,0,0]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 252,
      inv: 203,
    },
    {
      customerNum: "0600189459",
      equipmentNum: "000000000100531483",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T20:00:00.000Z",
      lastInventoryUpdate: "2021-05-11T20:00:00.000Z",
      lastFillRate: 0.25999999046325684,
      nextDeliveryPlan: "2021-06-09",
      nextDeliveryAct: "2021-06-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3799999952316284,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 186,
      materialConsumption: 2.359999895095825,
      dri: 14,
      pfr: 22,
      pfrc: 36,
      pfu: 42,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-06-09",
      name: "XAVIER UNIVERSITY-M1",
      street: "7900 STROELITZ ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN15007357",
      location: "- Xavier-  2nd  Convocati ",
      dexErrors: false,
      online: "SLW232000280",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 5,
          average_consumption: 0.3499999940395355,
          average_data: "[0.42,0.33,0.11,0.39,0.48]",
          average_trend: 1.3700000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 5,
          average_consumption: 0.3799999952316284,
          average_data: "[0.42,0.44,0.26,0.39,0.39]",
          average_trend: 1.0299999713897705,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 5,
          average_consumption: 0.30000001192092896,
          average_data: "[0,0.44,0.37,0.39,0.3]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.17000000178813934,
          average_data: "[0.42,0.19,0.11,0.05,0.09]",
          average_trend: 0.5299999713897705,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102782",
          consignment: 6,
          refill_qty: 2,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0,0.32,0,0.09]",
          average_trend: 1.1299999952316284,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 5,
          average_consumption: 0.27000001072883606,
          average_data: "[0,0.7,0.16,0.29,0.22]",
          average_trend: 0.8100000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114306",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.14000000059604645,
          average_data: "[0.21,0.11,0.16,0.2,0.04]",
          average_trend: 0.28999999165534973,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114532",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.10000000149011612,
          average_data: "[0.35,0,0,0,0.17]",
          average_trend: 1.7000000476837158,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114633",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.11999999731779099,
          average_data: "[0.28,0.07,0.05,0.1,0.09]",
          average_trend: 0.75,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000115964",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.07,0.21,0.15,0.22]",
          average_trend: 1.690000057220459,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 3,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0.1,0.04]",
          average_trend: 0.800000011920929,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.1599999964237213,
          average_data: "[0.28,0.33,0.16,0.05,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 5,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0.37,0,0.2,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 186,
      inv: 144,
    },
    {
      customerNum: "0600189752",
      equipmentNum: "000000000100618516",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-27T20:07:00.000Z",
      lastInventoryUpdate: "2021-04-27T20:07:00.000Z",
      lastFillRate: 0.3499999940395355,
      nextDeliveryPlan: "2021-05-14",
      nextDeliveryAct: "2021-05-14",
      schedulingMethod: "D",
      criticalMaterialInventory: 6,
      criticalMaterialConsumption: 0.27000001072883606,
      criticalMaterialNum: "000000000000121751",
      materialInventory: 159,
      materialConsumption: 1.6299999952316284,
      dri: -10,
      pfr: 30,
      pfrc: 19,
      pfu: 48,
      numSalesDays: 29,
      ftc: false,
      sort: "110000002021-05-14",
      name: "XAVIER UNIVERSITY-M13-CP",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "3017560",
      location: "police 1  ",
      dexErrors: false,
      online: "SLW232000329",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 5,
          average_consumption: 0.27000001072883606,
          average_data: "[0.21,0.17,0.18,0.44,0.36]",
          average_trend: 1.3300000429153442,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 10,
          current_inventory: 14,
          average_based_on_rec: 5,
          average_consumption: 0.33000001311302185,
          average_data: "[0.28,0.5,0.29,0.17,0.39]",
          average_trend: 1.1799999475479126,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 5,
          average_consumption: 0.1899999976158142,
          average_data: "[0.18,0.5,0.12,0,0.17]",
          average_trend: 0.8899999856948853,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 23,
          refill_qty: 3,
          current_inventory: 20,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0.11,0,0,0.11,0.22]",
          average_trend: 2.440000057220459,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.10000000149011612,
          average_data: "[0,0.33,0.06,0.11,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121751",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 0,
          average_based_on_rec: 5,
          average_consumption: 0.27000001072883606,
          average_data: "[0.21,1,0,0.06,0.08]",
          average_trend: 0.30000001192092896,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000151978",
          consignment: 23,
          refill_qty: 10,
          current_inventory: 13,
          average_based_on_rec: 5,
          average_consumption: 0.3199999928474426,
          average_data: "[0.18,0.83,0.18,0.22,0.17]",
          average_trend: 0.5299999713897705,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000158054",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 2,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0.11]",
          average_trend: 1.8300000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 159,
      inv: 111,
    },
    {
      customerNum: "0600189879",
      equipmentNum: "000000000100535455",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T00:29:00.000Z",
      lastInventoryUpdate: "2021-05-13T00:29:00.000Z",
      lastFillRate: 0.3199999928474426,
      nextDeliveryPlan: "2021-05-21",
      nextDeliveryAct: "2021-05-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 8,
      criticalMaterialConsumption: 0.6800000071525574,
      criticalMaterialNum: "000000000000156111",
      materialInventory: 242,
      materialConsumption: 7.659999847412109,
      dri: -2,
      pfr: 43,
      pfrc: 36,
      pfu: 109,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-21",
      name: "LOYOLA UNIV-M21-NEW DORM",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY07004291",
      location: "Claiborne  1st floor  ",
      dexErrors: false,
      online: "SLW232000262",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 28,
          refill_qty: 15,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 1.1200000047683716,
          average_data: "[0,0.89,1.38,0.75,2,1.69]",
          average_trend: 1.5099999904632568,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000102752",
          consignment: 42,
          refill_qty: 12,
          current_inventory: 30,
          average_based_on_rec: 6,
          average_consumption: 0.8999999761581421,
          average_data: "[0,0.48,1.08,0.4,1.1,2.31]",
          average_trend: 2.569999933242798,
          init_inventory: 42,
          last_inventory: 42,
        },
        {
          material_num: "000000000000112259",
          consignment: 28,
          refill_qty: 17,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 1.25,
          average_data: "[0.05,0.89,2,0.4,1.4,2.77]",
          average_trend: 2.2200000286102295,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000129254",
          consignment: 28,
          refill_qty: 28,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 2.200000047683716,
          average_data: "[0,0.89,4.15,1.15,2.7,4.31]",
          average_trend: 1.9600000381469727,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.19,0.46,0.05,0.3,0.15]",
          average_trend: 0.7900000214576721,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.49000000953674316,
          average_data: "[0,1.85,0.6,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.5699999928474426,
          average_data: "[0,0.89,1.54,0.35,0.5,0.15]",
          average_trend: 0.25999999046325684,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0,0.15,0.05,0.2,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156111",
          consignment: 15,
          refill_qty: 15,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 0.6800000071525574,
          average_data: "[0,0.37,1.69,0,0.5,1.54]",
          average_trend: 2.259999990463257,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000156347",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.44,0,0.2,0.5,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156530",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 249,
      inv: 140,
    },
    {
      customerNum: "0600189777",
      equipmentNum: "000000000100527681",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T21:45:00.000Z",
      lastInventoryUpdate: "2021-05-19T21:45:00.000Z",
      lastFillRate: 0.18000000715255737,
      nextDeliveryPlan: "2021-06-08",
      nextDeliveryAct: "2021-06-08",
      schedulingMethod: "D",
      criticalMaterialInventory: 13,
      criticalMaterialConsumption: 0.6200000047683716,
      criticalMaterialNum: "000000000000153225",
      materialInventory: 269,
      materialConsumption: 3.1500000953674316,
      dri: 13,
      pfr: 15,
      pfrc: 26,
      pfu: 42,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-08",
      name: "LOYOLA UNIV ACT CTR-M2",
      street: "501 PINE ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN09002768",
      location: "ACTIVITY C 1ST FLOOR BREAKROOM ",
      dexErrors: false,
      online: "SLW232000162",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.30000001192092896,
          average_data: "[0.16,0.5,0.34,0.44,0.07]",
          average_trend: 0.23000000417232513,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 5,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.09,0.14,0.06,0.07]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 5,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.14,0.14,0,0.07]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000129254",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 5,
          average_consumption: 0.28999999165534973,
          average_data: "[0.29,0.27,0.28,0.41,0.21]",
          average_trend: 0.7200000286102295,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.33000001311302185,
          average_data: "[0.39,0.36,0.41,0.29,0.21]",
          average_trend: 0.6399999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.14000000059604645,
          average_data: "[0.39,0.18,0,0.12,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000152196",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.34,0.18,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152197",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 4,
          average_consumption: 0.029999999329447746,
          average_data: "[0,0.07,0.06,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152921",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.30000001192092896,
          average_data: "[0.26,0.27,0.28,0.09,0.62]",
          average_trend: 2.069999933242798,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000152923",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.20999999344348907,
          average_data: "[0.06,0.23,0.21,0.21,0.34]",
          average_trend: 1.6200000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000153225",
          consignment: 24,
          refill_qty: 16,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.6200000047683716,
          average_data: "[0.26,0.55,0.83,0.71,0.76]",
          average_trend: 1.2300000190734863,
          init_inventory: 13,
          last_inventory: 13,
        },
        {
          material_num: "000000000000154898",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.12999999523162842,
          average_data: "[0.1,0.05,0.07,0.24,0.21]",
          average_trend: 1.6200000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000154900",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.10000000149011612,
          average_data: "[0.1,0.09,0,0.12,0.21]",
          average_trend: 2.0999999046325684,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000154914",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0,0.14,0.21,0.12,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156083",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0.27,0.07,0.29,0.14]",
          average_trend: 0.9300000071525574,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156090",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.09,0.21,0.18,0.48]",
          average_trend: 2.5299999713897705,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 280,
      inv: 238,
    },
    {
      customerNum: "0600190043",
      equipmentNum: "000000000100508784",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T21:17:00.000Z",
      lastInventoryUpdate: "2021-05-11T21:17:00.000Z",
      lastFillRate: 0.2199999988079071,
      nextDeliveryPlan: "2021-07-09",
      nextDeliveryAct: "2021-07-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.3700000047683716,
      criticalMaterialNum: "000000000000115584",
      materialInventory: 304,
      materialConsumption: 1.7599999904632568,
      dri: 48,
      pfr: 11,
      pfrc: 38,
      pfu: 37,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-07-09",
      name: "XAVIER UNIVERSITY-M14-ADM",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY14003574",
      location: "ADMIN ANNE 1st  ",
      dexErrors: false,
      online: "SLW232000174",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 128,
          refill_qty: 128,
          current_inventory: 120,
          average_based_on_rec: 3,
          average_consumption: 0.5099999904632568,
          average_data: "[0.51,0.54,0.48]",
          average_trend: 0.9399999976158142,
          init_inventory: 128,
          last_inventory: 128,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 18,
          average_based_on_rec: 3,
          average_consumption: 0.3700000047683716,
          average_data: "[0.26,0.49,0.36]",
          average_trend: 0.9700000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115586",
          consignment: 104,
          refill_qty: 104,
          current_inventory: 96,
          average_based_on_rec: 3,
          average_consumption: 0.49000000953674316,
          average_data: "[0.51,0.49,0.48]",
          average_trend: 0.9800000190734863,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 45,
          average_based_on_rec: 3,
          average_consumption: 0.14000000059604645,
          average_data: "[0.26,0.15,0]",
          average_trend: 0,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117604",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 3,
          average_consumption: 0.25,
          average_data: "[0.26,0.24,0.24]",
          average_trend: 0.9599999785423279,
          init_inventory: 0,
          last_inventory: 0,
        },
      ],
      cap: 316,
      inv: 279,
    },
    {
      customerNum: "0600190067",
      equipmentNum: "000000000100531461",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T22:34:00.000Z",
      lastInventoryUpdate: "2021-05-11T22:34:00.000Z",
      lastFillRate: 0.17000000178813934,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 16,
      criticalMaterialConsumption: 0.4699999988079071,
      criticalMaterialNum: "000000000000115964",
      materialInventory: 209,
      materialConsumption: 3.390000104904175,
      dri: 17,
      pfr: 27,
      pfrc: 52,
      pfu: 58,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-06-11",
      name: "XAVIER UNIVERSITY-M20-KD",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN14003077",
      location: "KD 1st left s  ",
      dexErrors: false,
      online: "SLW232000293",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0.18,0.19,0.3,0.74,0.47,0.07]",
          average_trend: 0.20999999344348907,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 10,
          refill_qty: 5,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0.18,0.19,0.5,0,0.13,0.69]",
          average_trend: 2.4600000381469727,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000112259",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.14000000059604645,
          average_data: "[0,0.19,0.2,0.3,0.13,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000114306",
          consignment: 16,
          refill_qty: 5,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0.09,0.42,0.45,0.37,0.33,0.21]",
          average_trend: 0.6800000071525574,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000114532",
          consignment: 16,
          refill_qty: 4,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.2199999988079071,
          average_data: "[0.09,0.23,0.25,0.52,0.2,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000114633",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0.06,0.47,0.2,0.22,0.4,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115964",
          consignment: 16,
          refill_qty: 8,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.4699999988079071,
          average_data: "[0.24,0.6,0.05,1.19,0.73,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.23999999463558197,
          average_data: "[0.09,0.56,0.3,0.3,0.2,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 16,
          refill_qty: 4,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.38,0.37,0,0,0.6,0.14]",
          average_trend: 0.5600000023841858,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.5199999809265137,
          average_data: "[0.09,0.7,0.45,0.3,0.73,0.83]",
          average_trend: 1.600000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0.12,0.42,0,0.15,0,0.07]",
          average_trend: 0.5400000214576721,
          init_inventory: 11,
          last_inventory: 11,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.14000000059604645,
          average_data: "[0.09,0,0,0.07,0.13,0.55]",
          average_trend: 3.930000066757202,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157361",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.37,0.13,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 210,
      inv: 152,
    },
    {
      customerNum: "0600190026",
      equipmentNum: "000000000100294241",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T21:39:00.000Z",
      lastInventoryUpdate: "2021-05-05T21:39:00.000Z",
      lastFillRate: 0.14000000059604645,
      nextDeliveryPlan: "2021-06-29",
      nextDeliveryAct: "2021-06-29",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.20000000298023224,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 108,
      materialConsumption: 1.190000057220459,
      dri: 34,
      pfr: 40,
      pfrc: 55,
      pfu: 39,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-06-29",
      name: "LOYOLA UNIV-M23-MILLER HALL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "FL0084708",
      location: "2ND FLOOR  2ND FLOOR ",
      dexErrors: false,
      online: "SLW232000253",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0.03,0.31,0.4,0.35,0.63,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.09000000357627869,
          average_data: "[0.03,0.31,0.07,0.1,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.20000000298023224,
          average_data: "[0.17,0.23,0.13,0.15,0.25,0.26]",
          average_trend: 1.2999999523162842,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0,0.17,0.05,0,0.06]",
          average_trend: 1.2000000476837158,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.14000000059604645,
          average_data: "[0,0.46,0.13,0.1,0.13,0.03]",
          average_trend: 0.20999999344348907,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121023",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.29,0,0.13,0.05,0.13,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 96,
      inv: 62,
    },
    {
      customerNum: "0600190087",
      equipmentNum: "000000000100518927",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T00:36:00.000Z",
      lastInventoryUpdate: "2021-05-12T00:36:00.000Z",
      lastFillRate: 0.2199999988079071,
      nextDeliveryPlan: "2021-06-09",
      nextDeliveryAct: "2021-06-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.8199999928474426,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 90,
      materialConsumption: 1.4500000476837158,
      dri: 15,
      pfr: 25,
      pfrc: 46,
      pfu: 23,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-06-09",
      name: "XAVIER UNIVERSITY-M22",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "6637234",
      location: "SOUTH XAVI   ",
      dexErrors: false,
      online: "SLW232000349",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.8199999928474426,
          average_data: "[0.62,0.75,0.67,1.07,1.25,0.55]",
          average_trend: 0.6700000166893005,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.03999999910593033,
          average_data: "[0.12,0,0,0.05,0,0.07]",
          average_trend: 1.75,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102660",
          consignment: 6,
          refill_qty: 2,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0.09,0,0.17,0.2,0.13,0.21]",
          average_trend: 1.6200000047683716,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.09000000357627869,
          average_data: "[0.06,0,0,0.15,0.19,0.14]",
          average_trend: 1.559999942779541,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114306",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.26,0,0.17,0.39,0.44,0.28]",
          average_trend: 1.0800000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0.18,0,0.06,0.15,0.13,0.14]",
          average_trend: 1.2699999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 90,
      inv: 67,
    },
    {
      customerNum: "0600189979",
      equipmentNum: "000000000100507778",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T23:18:00.000Z",
      lastInventoryUpdate: "2021-05-20T03:01:23.000Z",
      lastFillRate: 0.27000001072883606,
      nextDeliveryPlan: "2021-06-02",
      nextDeliveryAct: "2021-05-25",
      schedulingMethod: "D",
      criticalMaterialInventory: 7,
      criticalMaterialConsumption: 0.4300000071525574,
      criticalMaterialNum: "000000000000135333",
      materialInventory: 278,
      materialConsumption: 5.829999923706055,
      dri: 8,
      pfr: 18,
      pfrc: 31,
      pfu: 53,
      numSalesDays: 6,
      ftc: false,
      sort: "110000002021-05-25",
      name: "XAVIER UNIVERSITY LLC-M11",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN14003151",
      location: "LIVING AND 1st CTR 1ST FL ",
      dexErrors: true,
      online: "SLW232000407",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.4699999988079071,
          average_data: "[0.62,0.31,0.53,0.22,0.62,0.52]",
          average_trend: 1.1100000143051147,
          init_inventory: 24,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 16,
          refill_qty: 6,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.8100000023841858,
          average_data: "[1.69,0.85,0.67,0,1.15,0.52]",
          average_trend: 0.6399999856948853,
          init_inventory: 16,
          last_inventory: 15,
        },
        {
          material_num: "000000000000102604",
          consignment: 8,
          refill_qty: 4,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0.31,0,0,0.22,0.15,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 5,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 1,
          current_inventory: 47,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0.31,0.08,0.13,0,0.15,0.22]",
          average_trend: 1.4700000286102295,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000114229",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.4399999976158142,
          average_data: "[0.31,0.15,0.67,0.22,0.69,0.59]",
          average_trend: 1.340000033378601,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.20000000298023224,
          average_data: "[0.46,0,0,0.33,0.08,0.3]",
          average_trend: 1.5,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114306",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0.46,0.15,0.53,0.33,0.46,0.52]",
          average_trend: 1.2699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114532",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0.46,0.15,0.53,0,0.15,0.07]",
          average_trend: 0.30000001192092896,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114633",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.17000000178813934,
          average_data: "[0.15,0.15,0,0.11,0.23,0.37]",
          average_trend: 2.180000066757202,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000115964",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.9100000262260437,
          average_data: "[0.92,0.38,1.33,0.89,1,0.96]",
          average_trend: 1.0499999523162842,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.2199999988079071,
          average_data: "[0,0.38,0.27,0.22,0.23,0.22]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.17000000178813934,
          average_data: "[0,0.23,0.13,0.67,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.6899999976158142,
          average_data: "[0.77,0.23,0.67,1.11,0.23,1.11]",
          average_trend: 1.6100000143051147,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.4300000071525574,
          average_data: "[0.15,0.69,0.4,0.78,0.54,0]",
          average_trend: 0,
          init_inventory: 7,
          last_inventory: 7,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[1.54,0.31,0.13,0,0.23,0.3]",
          average_trend: 0.7099999785423279,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 288,
      inv: 235,
    },
    {
      customerNum: "0600190130",
      equipmentNum: "000000000100535703",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-21T19:06:00.000Z",
      lastInventoryUpdate: "2021-04-21T19:06:00.000Z",
      lastFillRate: 0.1599999964237213,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.38999998569488525,
      criticalMaterialNum: "000000000000121750",
      materialInventory: 204,
      materialConsumption: 1.850000023841858,
      dri: 24,
      pfr: 36,
      pfrc: 56,
      pfu: 79,
      numSalesDays: 35,
      ftc: false,
      sort: "110000002021-06-04",
      name: "LOYOLA UNIV-M19-MERCY HALL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY14004104",
      location: "mercy hall   ",
      dexErrors: false,
      online: "SLW232000290",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 48,
          refill_qty: 14,
          current_inventory: 34,
          average_based_on_rec: 4,
          average_consumption: 0.38999998569488525,
          average_data: "[0.35,0,0.86,0.35]",
          average_trend: 0.8999999761581421,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 17,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0.35,0,0.05,0.12]",
          average_trend: 0.9200000166893005,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 12,
          current_inventory: 36,
          average_based_on_rec: 4,
          average_consumption: 0.33000001311302185,
          average_data: "[0.35,0.34,0.55,0.06]",
          average_trend: 0.18000000715255737,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000116149",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 4,
          average_consumption: 0.30000001192092896,
          average_data: "[0.35,0,0.55,0.29]",
          average_trend: 0.9700000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 4,
          average_consumption: 0.17000000178813934,
          average_data: "[0,0,0.55,0.12]",
          average_trend: 0.7099999785423279,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117604",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 4,
          average_consumption: 0.14000000059604645,
          average_data: "[0,0,0.55,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.38999998569488525,
          average_data: "[0.7,0,0.86,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 216,
      inv: 137,
    },
    {
      customerNum: "0600189977",
      equipmentNum: "000000000100604817",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T23:11:00.000Z",
      lastInventoryUpdate: "2021-05-21T23:11:00.000Z",
      lastFillRate: 0.46000000834465027,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.7100000381469727,
      criticalMaterialNum: "000000000000156114",
      materialInventory: 286,
      materialConsumption: 12.579999923706055,
      dri: 9,
      pfr: 24,
      pfrc: 61,
      pfu: 69,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-04",
      name: "MARKET SERVICE CENTER-M14",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "DN16005653",
      location: "PRODUCTION  1ST FLOOR ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000114229",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0.77,0.62,0.27,0.4,0,0.27]",
          average_trend: 0.6899999976158142,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0,0.77,0.4,0.4,0,0.27]",
          average_trend: 0.8700000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114306",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 1.190000057220459,
          average_data: "[2,2,0.67,0.67,0.62,1.2]",
          average_trend: 1.0099999904632568,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114532",
          consignment: 14,
          refill_qty: 3,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.4000000059604645,
          average_data: "[0,0.77,0.27,0,1.08,0.27]",
          average_trend: 0.6800000071525574,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000114633",
          consignment: 16,
          refill_qty: 5,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.8999999761581421,
          average_data: "[1.23,0.77,1.33,0.93,0.62,0.53]",
          average_trend: 0.5899999737739563,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000115313",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0.31,0.46,0.67,0,0,0.53]",
          average_trend: 1.6100000143051147,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115964",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.2199999988079071,
          average_data: "[0,0.92,0,0,0,0.4]",
          average_trend: 1.8200000524520874,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000118283",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0,0,0.8,0.53,0.31,0.4]",
          average_trend: 1.1799999475479126,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 20,
          refill_qty: 5,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.9599999785423279,
          average_data: "[1.38,0.62,0.27,0.67,1.23,1.6]",
          average_trend: 1.6699999570846558,
          init_inventory: 20,
          last_inventory: 20,
        },
        {
          material_num: "000000000000152292",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0.92,0,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156111",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 1.3600000143051147,
          average_data: "[0,0.62,1.2,0.53,3.69,2.13]",
          average_trend: 1.5700000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156112",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 1.5099999904632568,
          average_data: "[1.85,1.85,1.07,1.6,1.38,1.33]",
          average_trend: 0.8799999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156114",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.7100000381469727,
          average_data: "[0.92,0.62,1.6,2,3.38,1.73]",
          average_trend: 1.0099999904632568,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156117",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 1.5499999523162842,
          average_data: "[1.85,0.77,1.6,1.6,1.85,1.6]",
          average_trend: 1.0299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157361",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 1.2599999904632568,
          average_data: "[0.92,0.77,1.73,0.93,1.08,2.13]",
          average_trend: 1.690000057220459,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 286,
      inv: 217,
    },
    {
      customerNum: "0600190109",
      equipmentNum: "000000000100524527",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-21T22:23:00.000Z",
      lastInventoryUpdate: "2021-04-21T22:23:00.000Z",
      lastFillRate: 0.18000000715255737,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.3799999952316284,
      criticalMaterialNum: "000000000000115586",
      materialInventory: 215,
      materialConsumption: 2.0299999713897705,
      dri: 26,
      pfr: 34,
      pfrc: 57,
      pfu: 75,
      numSalesDays: 35,
      ftc: false,
      sort: "110000002021-06-04",
      name: "XAVIER UNIVERSITY-M23",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY05006702",
      location: "LIBRARY 2N   ",
      dexErrors: false,
      online: "SLW232000215",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 96,
          refill_qty: 96,
          current_inventory: 50,
          average_based_on_rec: 3,
          average_consumption: 1.2999999523162842,
          average_data: "[1.18,1.64,1.09]",
          average_trend: 0.8399999737739563,
          init_inventory: 96,
          last_inventory: 96,
        },
        {
          material_num: "000000000000115584",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 3,
          average_consumption: 0,
          average_data: "[0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000115586",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.3799999952316284,
          average_data: "[0.71,0,0.43]",
          average_trend: 1.1299999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 42,
          average_based_on_rec: 3,
          average_consumption: 0.1599999964237213,
          average_data: "[0.35,0,0.13]",
          average_trend: 0.8100000023841858,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000119516",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 17,
          average_based_on_rec: 3,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.44,0.04]",
          average_trend: 0.25,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121750",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.029999999329447746,
          average_data: "[0.06,0,0.04]",
          average_trend: 1.3300000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 216,
      inv: 141,
    },
    {
      customerNum: "0600190110",
      equipmentNum: "000000000101979842",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-18T20:32:00.000Z",
      lastInventoryUpdate: "2021-05-18T20:32:00.000Z",
      lastFillRate: 0.28999999165534973,
      nextDeliveryPlan: "2021-07-16",
      nextDeliveryAct: "2021-07-16",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 0.6499999761581421,
      criticalMaterialNum: "000000000000115586",
      materialInventory: 228,
      materialConsumption: 1.9500000476837158,
      dri: 65,
      pfr: 8,
      pfrc: 62,
      pfu: 19,
      numSalesDays: 8,
      ftc: false,
      sort: "110000002021-07-16",
      name: "XAVIER UNIVERSITY-M1",
      street: "1001 JEFFERSON PKWY S",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "572035",
      location: "ST. MARTIN 1ST FLOOR KITCHEN ",
      dexErrors: false,
      online: "SLW232000217",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 5,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0.27,0,0,0.03]",
          average_trend: 0.5,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115583",
          consignment: 72,
          refill_qty: 6,
          current_inventory: 66,
          average_based_on_rec: 5,
          average_consumption: 0.699999988079071,
          average_data: "[1.17,0.4,0.94,0.36,0.63]",
          average_trend: 0.8999999761581421,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 6,
          current_inventory: 42,
          average_based_on_rec: 5,
          average_consumption: 0.6499999761581421,
          average_data: "[1.17,0,0.61,0.36,1.1]",
          average_trend: 1.690000057220459,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 2,
          current_inventory: 46,
          average_based_on_rec: 5,
          average_consumption: 0.23999999463558197,
          average_data: "[0,0,0.67,0.11,0.4]",
          average_trend: 1.6699999570846558,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.2800000011920929,
          average_data: "[0,0.4,1,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410646",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 3,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0.07,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 228,
      inv: 209,
    },
    {
      customerNum: "0600189880",
      equipmentNum: "000000000100520812",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T00:28:00.000Z",
      lastInventoryUpdate: "2021-05-23T05:51:15.000Z",
      lastFillRate: 0.019999999552965164,
      nextDeliveryPlan: "2021-06-03",
      nextDeliveryAct: "2021-05-24",
      schedulingMethod: "D",
      criticalMaterialInventory: 31,
      criticalMaterialConsumption: 2.9100000858306885,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 198,
      materialConsumption: 10.09000015258789,
      dri: 8,
      pfr: 41,
      pfrc: 68,
      pfu: 115,
      numSalesDays: 3,
      ftc: false,
      sort: "110000002021-05-24",
      name: "LOYOLA UNIV-M22-NEW DORM",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY98028348",
      location: "CARROLLTON 1  ",
      dexErrors: false,
      online: "SLW232000104",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 56,
          refill_qty: 35,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 2.740000009536743,
          average_data: "[2.8,2.5,2.33,1.4,4.5,2.92]",
          average_trend: 1.0700000524520874,
          init_inventory: 37,
          last_inventory: 30,
        },
        {
          material_num: "000000000000102603",
          consignment: 56,
          refill_qty: 34,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 2.9100000858306885,
          average_data: "[3.3,3.38,2.83,1.5,3.2,3.23]",
          average_trend: 1.1100000143051147,
          init_inventory: 35,
          last_inventory: 31,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.6600000262260437,
          average_data: "[0.2,1,0.67,0.4,0.9,0.77]",
          average_trend: 1.1699999570846558,
          init_inventory: 19,
          last_inventory: 18,
        },
        {
          material_num: "000000000000117635",
          consignment: 56,
          refill_qty: 21,
          current_inventory: 35,
          average_based_on_rec: 6,
          average_consumption: 1.5700000524520874,
          average_data: "[1.6,2.13,0.83,1.25,1.9,1.69]",
          average_trend: 1.0800000429153442,
          init_inventory: 45,
          last_inventory: 40,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0.7,0.5,0.83,0.25,0.4,0.77]",
          average_trend: 1.3300000429153442,
          init_inventory: 12,
          last_inventory: 9,
        },
        {
          material_num: "000000000000125522",
          consignment: 28,
          refill_qty: 8,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.6000000238418579,
          average_data: "[0.5,1.13,0.67,0.05,0.6,0.62]",
          average_trend: 1.0299999713897705,
          init_inventory: 24,
          last_inventory: 22,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.9200000166893005,
          average_data: "[1.2,1,1.5,0.5,1.3,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000153225",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0.3,0,0.33,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 280,
      inv: 165,
    },
    {
      customerNum: "0600190047",
      equipmentNum: "000000000100523803",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-03T17:53:00.000Z",
      lastInventoryUpdate: "2021-05-03T17:53:00.000Z",
      lastFillRate: 0.3499999940395355,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.38999998569488525,
      criticalMaterialNum: "000000000000117635",
      materialInventory: 300,
      materialConsumption: 4.440000057220459,
      dri: 6,
      pfr: 44,
      pfrc: 50,
      pfu: 156,
      numSalesDays: 23,
      ftc: false,
      sort: "110000002021-06-01",
      name: "XAVIER UNIVERSITY-M15",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN14003164",
      location: "PHARMACY 1ST FLOOR HALL ",
      dexErrors: false,
      online: "SLW232000169",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.3100000023841858,
          average_data: "[0.09,0.29,0.3,0.41,0.44]",
          average_trend: 1.4199999570846558,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.5799999833106995,
          average_data: "[0.35,0.78,0.85,0.34,0.59]",
          average_trend: 1.0199999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 19,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.27000001072883606,
          average_data: "[0.35,0.15,0.3,0.14,0.39]",
          average_trend: 1.440000057220459,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 56,
          refill_qty: 12,
          current_inventory: 44,
          average_based_on_rec: 5,
          average_consumption: 0.5199999809265137,
          average_data: "[0.26,0.73,0.55,0.62,0.44]",
          average_trend: 0.8500000238418579,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000114230",
          consignment: 24,
          refill_qty: 15,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0.15,0.05,0.15,0,0.1]",
          average_trend: 1.1100000143051147,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114306",
          consignment: 24,
          refill_qty: 15,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.10999999940395355,
          average_data: "[0.06,0.2,0.05,0,0.24]",
          average_trend: 2.180000066757202,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114633",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 5,
          average_consumption: 0.1899999976158142,
          average_data: "[0.15,0.2,0.35,0.07,0.2]",
          average_trend: 1.0499999523162842,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115964",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 5,
          average_consumption: 0.23000000417232513,
          average_data: "[0.24,0.29,0.25,0.21,0.15]",
          average_trend: 0.6499999761581421,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 21,
          current_inventory: 3,
          average_based_on_rec: 5,
          average_consumption: 0.38999998569488525,
          average_data: "[0.24,0.59,0.5,0.28,0.34]",
          average_trend: 0.8700000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 48,
          refill_qty: 24,
          current_inventory: 24,
          average_based_on_rec: 5,
          average_consumption: 1.0399999618530273,
          average_data: "[0.94,1.17,0.95,1.31,0.83]",
          average_trend: 0.800000011920929,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 5,
          average_consumption: 0.30000001192092896,
          average_data: "[0.15,0.44,0.25,0.21,0.44]",
          average_trend: 1.4700000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 16,
          refill_qty: 7,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.2800000011920929,
          average_data: "[0.24,0.2,0.2,0,0.78]",
          average_trend: 2.7899999618530273,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.12999999523162842,
          average_data: "[0.09,0.05,0.15,0.14,0.24]",
          average_trend: 1.850000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 348,
      inv: 192,
    },
    {
      customerNum: "0600189919",
      equipmentNum: "000000000100520788",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-20T00:35:00.000Z",
      lastInventoryUpdate: "2021-05-20T00:35:00.000Z",
      lastFillRate: 0.49000000953674316,
      nextDeliveryPlan: "2021-06-02",
      nextDeliveryAct: "2021-06-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.8299999833106995,
      criticalMaterialNum: "000000000000117641",
      materialInventory: 70,
      materialConsumption: 3.5399999618530273,
      dri: 8,
      pfr: 36,
      pfrc: 71,
      pfu: 26,
      numSalesDays: 6,
      ftc: false,
      sort: "110000002021-06-02",
      name: "DOMINICAN HS-M6",
      street: "2830 PINE ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY14009312",
      location: "O/S ALUMNI   ",
      dexErrors: false,
      online: "SLW232000303",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102592",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.7300000190734863,
          average_data: "[0.55,0.82,0.67,0.83,0.6,0.93]",
          average_trend: 1.2699999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.8600000143051147,
          average_data: "[0.69,1.09,0.67,0.83,0.7,1.2]",
          average_trend: 1.399999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.5899999737739563,
          average_data: "[0.14,0.82,0.5,0.33,0.8,0.93]",
          average_trend: 1.5800000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.8299999833106995,
          average_data: "[0.76,0.45,0.67,0.83,0.8,1.47]",
          average_trend: 1.7699999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000119790",
          consignment: 6,
          refill_qty: 3,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.34,0.18,0.17,1,0,0.13]",
          average_trend: 0.4300000071525574,
          init_inventory: 5,
          last_inventory: 5,
        },
        {
          material_num: "000000000000125522",
          consignment: 6,
          refill_qty: 3,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0.07,0.45,0.17,0.33,0.2,0.13]",
          average_trend: 0.5699999928474426,
          init_inventory: 5,
          last_inventory: 5,
        },
      ],
      cap: 72,
      inv: 46,
    },
    {
      customerNum: "0600190049",
      equipmentNum: "000000000100508400",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T21:50:00.000Z",
      lastInventoryUpdate: "2021-05-11T21:50:00.000Z",
      lastFillRate: 0.17000000178813934,
      nextDeliveryPlan: "2021-07-09",
      nextDeliveryAct: "2021-07-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.18000000715255737,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 132,
      materialConsumption: 1.1299999952316284,
      dri: 47,
      pfr: 15,
      pfrc: 53,
      pfu: 20,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-07-09",
      name: "XAVIER UNIVERSITY-M17-PH",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY02034747",
      location: "PHARMACY B 1st AN 27143 ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.18000000715255737,
          average_data: "[0.12,0.29,0.15,0.17]",
          average_trend: 0.9399999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102592",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.10000000149011612,
          average_data: "[0.12,0.1,0.09,0.1]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 4,
          average_consumption: 0.18000000715255737,
          average_data: "[0.18,0.29,0.12,0.14]",
          average_trend: 0.7799999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.07999999821186066,
          average_data: "[0.03,0.15,0.12,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 4,
          average_consumption: 0.25,
          average_data: "[0.26,0.24,0.35,0.14]",
          average_trend: 0.5600000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 4,
          average_consumption: 0.05999999865889549,
          average_data: "[0.03,0.15,0.03,0.03]",
          average_trend: 0.5,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.17000000178813934,
          average_data: "[0.24,0.15,0.18,0.1]",
          average_trend: 0.5899999737739563,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.10999999940395355,
          average_data: "[0.06,0.2,0.09,0.07]",
          average_trend: 0.6399999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 132,
      inv: 112,
    },
    {
      customerNum: "0600190069",
      equipmentNum: "000000000100508364",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T19:40:00.000Z",
      lastInventoryUpdate: "2021-05-06T19:40:00.000Z",
      lastFillRate: 0.23000000417232513,
      nextDeliveryPlan: "2021-05-27",
      nextDeliveryAct: "2021-05-27",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.1200000047683716,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 115,
      materialConsumption: 2.5399999618530273,
      dri: 1,
      pfr: 46,
      pfrc: 46,
      pfu: 54,
      numSalesDays: 20,
      ftc: false,
      sort: "110000002021-05-27",
      name: "XAVIER UNIVERSITY-M2-KD",
      street: "7238 DREXEL DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY02004440",
      location: "6TH FLOOR   ",
      dexErrors: false,
      online: "SLW232000292",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 23,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 1.1200000047683716,
          average_data: "[0.71,0.7,0.85,1.04,1.83,1.56]",
          average_trend: 1.3899999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.35,0,0.3,0.35,0,0.56]",
          average_trend: 2.1500000953674316,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 23,
          refill_qty: 10,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.47999998927116394,
          average_data: "[0.06,0.14,0.2,1,1.5,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0.29,0,0.15,0.48,0,0.22]",
          average_trend: 1.159999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.23999999463558197,
          average_data: "[0.35,0,0.05,0.22,0.5,0.33]",
          average_trend: 1.3799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 4,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0,0,0.33]",
          average_trend: 4.130000114440918,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000152292",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 20,
          average_based_on_rec: 3,
          average_consumption: 0.17000000178813934,
          average_data: "[0,0.5,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 115,
      inv: 61,
    },
    {
      customerNum: "0600190048",
      equipmentNum: "000000000100528400",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-27T21:39:00.000Z",
      lastInventoryUpdate: "2021-04-27T21:39:00.000Z",
      lastFillRate: 0.7799999713897705,
      nextDeliveryPlan: "2021-06-18",
      nextDeliveryAct: "2021-06-18",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 0.41999998688697815,
      criticalMaterialNum: "000000000000115964",
      materialInventory: 186,
      materialConsumption: 2.009999990463257,
      dri: 24,
      pfr: 34,
      pfrc: 58,
      pfu: 65,
      numSalesDays: 29,
      ftc: false,
      sort: "110000002021-06-18",
      name: "XAVIER UNIVERSITY-M16-SM",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY03016106",
      location: "ST MICHAEL 1ST FLOOR LOUNGE ",
      dexErrors: true,
      online: "SLW232000375",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 14,
          average_based_on_rec: 3,
          average_consumption: 0.3400000035762787,
          average_data: "[0.28,0.1,0.64]",
          average_trend: 1.8799999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 17,
          average_based_on_rec: 3,
          average_consumption: 0.23999999463558197,
          average_data: "[0.04,0,0.67]",
          average_trend: 2.7899999618530273,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 14,
          average_based_on_rec: 3,
          average_consumption: 0.33000001311302185,
          average_data: "[0.19,0.15,0.64]",
          average_trend: 1.940000057220459,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115964",
          consignment: 23,
          refill_qty: 23,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.41999998688697815,
          average_data: "[0.17,0.44,0.64]",
          average_trend: 1.5199999809265137,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000123292",
          consignment: 23,
          refill_qty: 23,
          current_inventory: 14,
          average_based_on_rec: 3,
          average_consumption: 0.30000001192092896,
          average_data: "[0.11,0.15,0.64]",
          average_trend: 2.130000114440918,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 3,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0,0.17]",
          average_trend: 2.8299999237060547,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 12,
          average_based_on_rec: 3,
          average_consumption: 0.25999999046325684,
          average_data: "[0.04,0.1,0.64]",
          average_trend: 2.4600000381469727,
          init_inventory: 20,
          last_inventory: 20,
        },
        {
          material_num: "000000000000156530",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 3,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0.05,0.14]",
          average_trend: 2.3299999237060547,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 190,
      inv: 125,
    },
    {
      customerNum: "0600190051",
      equipmentNum: "000000000103188501",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T00:37:00.000Z",
      lastInventoryUpdate: "2021-05-21T14:20:45.000Z",
      lastFillRate: 0.20999999344348907,
      nextDeliveryPlan: "2021-06-08",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 5,
      criticalMaterialConsumption: 0.23000000417232513,
      criticalMaterialNum: "000000000000135333",
      materialInventory: 172,
      materialConsumption: 1.5700000524520874,
      dri: 13,
      pfr: 17,
      pfrc: 25,
      pfu: 34,
      numSalesDays: 5,
      ftc: false,
      sort: "110000002021-05-28",
      name: "XAVIER UNIVERSITY-M18-XS",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "CM19007671",
      location: "XAVIER 2ND 1 XAVIER 2ND ",
      dexErrors: true,
      online: "SLW232000101",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 13,
          average_based_on_rec: 3,
          average_consumption: 0.15000000596046448,
          average_data: "[0.13,0.11,0.2]",
          average_trend: 1.3300000429153442,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 3,
          average_consumption: 0.10999999940395355,
          average_data: "[0.19,0.07,0.07]",
          average_trend: 0.6399999856948853,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 3,
          average_consumption: 0.17000000178813934,
          average_data: "[0,0.44,0.07]",
          average_trend: 0.4099999964237213,
          init_inventory: 12,
          last_inventory: 11,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 5,
          average_based_on_rec: 3,
          average_consumption: 0.07000000029802322,
          average_data: "[0.02,0.18,0.02]",
          average_trend: 0.28999999165534973,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102659",
          consignment: 7,
          refill_qty: 7,
          current_inventory: 6,
          average_based_on_rec: 3,
          average_consumption: 0.05999999865889549,
          average_data: "[0.11,0.07,0]",
          average_trend: 0,
          init_inventory: 7,
          last_inventory: 7,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 23,
          average_based_on_rec: 3,
          average_consumption: 0.05999999865889549,
          average_data: "[0.04,0.15,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 19,
          average_based_on_rec: 3,
          average_consumption: 0.1899999976158142,
          average_data: "[0.02,0.36,0.2]",
          average_trend: 1.0499999523162842,
          init_inventory: 24,
          last_inventory: 20,
        },
        {
          material_num: "000000000000114306",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 15,
          average_based_on_rec: 3,
          average_consumption: 0.07999999821186066,
          average_data: "[0.11,0.11,0.02]",
          average_trend: 0.25,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000117635",
          consignment: 7,
          refill_qty: 7,
          current_inventory: 6,
          average_based_on_rec: 3,
          average_consumption: 0.10000000149011612,
          average_data: "[0.04,0.25,0]",
          average_trend: 0,
          init_inventory: 7,
          last_inventory: 7,
        },
        {
          material_num: "000000000000129254",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 15,
          average_based_on_rec: 3,
          average_consumption: 0.12999999523162842,
          average_data: "[0.17,0.11,0.11]",
          average_trend: 0.8500000238418579,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000135333",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 3,
          average_based_on_rec: 3,
          average_consumption: 0.23000000417232513,
          average_data: "[0,0.44,0.25]",
          average_trend: 1.090000033378601,
          init_inventory: 5,
          last_inventory: 5,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 23,
          average_based_on_rec: 3,
          average_consumption: 0.14000000059604645,
          average_data: "[0.04,0.18,0.2]",
          average_trend: 1.4299999475479126,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157361",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 9,
          average_based_on_rec: 3,
          average_consumption: 0.07999999821186066,
          average_data: "[0.15,0.04,0.05]",
          average_trend: 0.6299999952316284,
          init_inventory: 10,
          last_inventory: 10,
        },
      ],
      cap: 192,
      inv: 158,
    },
    {
      customerNum: "0600190068",
      equipmentNum: "000000000100228002",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-03T18:13:00.000Z",
      lastInventoryUpdate: "2021-05-21T16:20:40.000Z",
      lastFillRate: 0.49000000953674316,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 20,
      criticalMaterialConsumption: 0.8999999761581421,
      criticalMaterialNum: "000000000000114306",
      materialInventory: 180,
      materialConsumption: 5.070000171661377,
      dri: 17,
      pfr: 32,
      pfrc: 69,
      pfu: 72,
      numSalesDays: 5,
      ftc: false,
      sort: "110000002021-05-28",
      name: "XAVIER UNIVERSITY-M21-PP",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "622035",
      location: "St Michael  St Michael ",
      dexErrors: false,
      online: "SLW232000316",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 46,
          refill_qty: 13,
          current_inventory: 33,
          average_based_on_rec: 6,
          average_consumption: 1.6399999856948853,
          average_data: "[1,3.56,0.85,0.96,1.19,2.3]",
          average_trend: 1.399999976158142,
          init_inventory: 46,
          last_inventory: 42,
        },
        {
          material_num: "000000000000102603",
          consignment: 48,
          refill_qty: 29,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.6899999976158142,
          average_data: "[0.71,0.44,0.85,1.11,0.59,0.44]",
          average_trend: 0.6399999856948853,
          init_inventory: 24,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.29,0.22,0.62,0,0,0.44]",
          average_trend: 1.690000057220459,
          init_inventory: 12,
          last_inventory: 11,
        },
        {
          material_num: "000000000000102605",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.27000001072883606,
          average_data: "[0.41,0,0.23,0.37,0.22,0.37]",
          average_trend: 1.3700000047683716,
          init_inventory: 24,
          last_inventory: 20,
        },
        {
          material_num: "000000000000102660",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0.68,0.33,0.38,0.37,0.52,1.19]",
          average_trend: 2.049999952316284,
          init_inventory: 23,
          last_inventory: 22,
        },
        {
          material_num: "000000000000114229",
          consignment: 23,
          refill_qty: 8,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.6100000143051147,
          average_data: "[0.68,0.22,0.77,0.44,0.44,1.11]",
          average_trend: 1.8200000524520874,
          init_inventory: 23,
          last_inventory: 19,
        },
        {
          material_num: "000000000000114306",
          consignment: 23,
          refill_qty: 8,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.8999999761581421,
          average_data: "[0.68,1.22,0.62,0.74,0.89,1.26]",
          average_trend: 1.399999976158142,
          init_inventory: 23,
          last_inventory: 20,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 1,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.11999999731779099,
          average_data: "[0.5,0,0,0.07,0.07,0.07]",
          average_trend: 0.5799999833106995,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 222,
      inv: 150,
    },
    {
      customerNum: "0600188286",
      equipmentNum: "000000000100044816",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-18T20:28:00.000Z",
      lastInventoryUpdate: "2021-05-18T20:28:00.000Z",
      lastFillRate: 0.18000000715255737,
      nextDeliveryPlan: "2021-06-18",
      nextDeliveryAct: "2021-06-18",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3400000035762787,
      criticalMaterialNum: "000000000000102660",
      materialInventory: 120,
      materialConsumption: 2.4100000858306885,
      dri: 25,
      pfr: 19,
      pfrc: 66,
      pfu: 23,
      numSalesDays: 8,
      ftc: false,
      sort: "110000002021-06-18",
      name: "XAVIER-M5-UNIVERSITY SMT",
      street: "801 S NORMAN C FRANCIS",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY01042861",
      location: "DEPORRES 2  DEPORRES 2 ",
      dexErrors: false,
      online: "SLW232000235",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 5,
          average_consumption: 0.5799999833106995,
          average_data: "[0.48,0.75,0.69,0.67,0.31]",
          average_trend: 0.5299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.28999999165534973,
          average_data: "[0.1,0.38,0.13,0.67,0.15]",
          average_trend: 0.5199999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102660",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.3400000035762787,
          average_data: "[0.2,0.38,0.25,0.4,0.46]",
          average_trend: 1.350000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 5,
          average_consumption: 0.5299999713897705,
          average_data: "[0.48,0.22,0.88,0.67,0.38]",
          average_trend: 0.7200000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114229",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 5,
          average_consumption: 0.3199999928474426,
          average_data: "[0.24,0.31,0.5,0.4,0.15]",
          average_trend: 0.4699999988079071,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114306",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.20000000298023224,
          average_data: "[0.04,0.38,0.25,0.2,0.15]",
          average_trend: 0.75,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.15000000596046448,
          average_data: "[0.1,0.13,0.38,0.13,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 120,
      inv: 97,
    },
    {
      customerNum: "0600188456",
      equipmentNum: "000000000100629693",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T19:23:00.000Z",
      lastInventoryUpdate: "2021-05-05T19:23:00.000Z",
      lastFillRate: 0.46000000834465027,
      nextDeliveryPlan: "2021-06-17",
      nextDeliveryAct: "2021-06-17",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.25999999046325684,
      criticalMaterialNum: "000000000000117635",
      materialInventory: 126,
      materialConsumption: 2.009999990463257,
      dri: 22,
      pfr: 36,
      pfrc: 68,
      pfu: 46,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-06-17",
      name: "LOYOLA UNIV LAW SCHOOL-M1",
      street: "526 PINE ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "4006321",
      location: "1ST FL LIB   ",
      dexErrors: false,
      online: "SLW232000404",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 6,
          refill_qty: 2,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0.03,0.09,0.14,0,0.21]",
          average_trend: 2.3299999237060547,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 5,
          average_consumption: 0.25999999046325684,
          average_data: "[0.16,0.36,0.14,0.25,0.41]",
          average_trend: 1.5800000429153442,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 5,
          average_consumption: 0.49000000953674316,
          average_data: "[0.32,0.27,0.07,0.6,1.17]",
          average_trend: 2.390000104904175,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 15,
          current_inventory: 33,
          average_based_on_rec: 5,
          average_consumption: 0.6700000166893005,
          average_data: "[1.55,0.05,0,0.35,1.38]",
          average_trend: 2.059999942779541,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 5,
          average_consumption: 0.25999999046325684,
          average_data: "[0.23,0.27,0.28,0.1,0.41]",
          average_trend: 1.5800000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 5,
          average_consumption: 0.23999999463558197,
          average_data: "[0.19,0.09,0.28,0.25,0.41]",
          average_trend: 1.7100000381469727,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 126,
      inv: 80,
    },
    {
      customerNum: "0600190153",
      equipmentNum: "000000000100520554",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-03T22:45:00.000Z",
      lastInventoryUpdate: "2021-05-03T22:45:00.000Z",
      lastFillRate: 0.17000000178813934,
      nextDeliveryPlan: "2021-07-02",
      nextDeliveryAct: "2021-07-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.3799999952316284,
      criticalMaterialNum: "000000000000129254",
      materialInventory: 230,
      materialConsumption: 1.4500000476837158,
      dri: 38,
      pfr: 17,
      pfrc: 38,
      pfu: 40,
      numSalesDays: 23,
      ftc: false,
      sort: "110000002021-07-02",
      name: "LOYOLA UNIV-M17-MUSIC",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY07014579",
      location: "LOUIS J RO 1ST FLOOR LOCKERS ",
      dexErrors: false,
      online: "SLW232000322",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102751",
          consignment: 21,
          refill_qty: 2,
          current_inventory: 19,
          average_based_on_rec: 4,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0,0.24,0]",
          average_trend: 0,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000102752",
          consignment: 21,
          refill_qty: 5,
          current_inventory: 16,
          average_based_on_rec: 4,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0,0.2,0.23]",
          average_trend: 2.0899999141693115,
          init_inventory: 19,
          last_inventory: 19,
        },
        {
          material_num: "000000000000112259",
          consignment: 21,
          refill_qty: 6,
          current_inventory: 15,
          average_based_on_rec: 4,
          average_consumption: 0.25999999046325684,
          average_data: "[0,0.39,0.51,0.14]",
          average_trend: 0.5400000214576721,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000114532",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.11999999731779099,
          average_data: "[0.08,0.1,0.17,0.14]",
          average_trend: 1.1699999570846558,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117687",
          consignment: 14,
          refill_qty: 0,
          current_inventory: 14,
          average_based_on_rec: 4,
          average_consumption: 0,
          average_data: "[0,0,0,0]",
          average_trend: 1,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000123292",
          consignment: 14,
          refill_qty: 14,
          current_inventory: 14,
          average_based_on_rec: 2,
          average_consumption: 0,
          average_data: "[0,0]",
          average_trend: 1,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 4,
          average_consumption: 0.3799999952316284,
          average_data: "[0,0,0.59,0.93]",
          average_trend: 2.450000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 4,
          average_consumption: 0.03999999910593033,
          average_data: "[0.17,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 21,
          refill_qty: 5,
          current_inventory: 16,
          average_based_on_rec: 4,
          average_consumption: 0.18000000715255737,
          average_data: "[0,0.2,0.22,0.28]",
          average_trend: 1.559999942779541,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000152202",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0,0.27,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000153225",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.05000000074505806,
          average_data: "[0.11,0,0.1,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156083",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 4,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0,0.07,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156085",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 4,
          average_consumption: 0.03999999910593033,
          average_data: "[0,0,0.05,0.09]",
          average_trend: 2.25,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156090",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.11999999731779099,
          average_data: "[0.08,0,0.27,0.14]",
          average_trend: 1.1699999570846558,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 232,
      inv: 192,
    },
    {
      customerNum: "0600190136",
      equipmentNum: "000000000100514715",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T01:08:00.000Z",
      lastInventoryUpdate: "2021-05-06T01:08:00.000Z",
      lastFillRate: 0.18000000715255737,
      nextDeliveryPlan: "2021-05-26",
      nextDeliveryAct: "2021-05-26",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.1299999952316284,
      criticalMaterialNum: "000000000000101728",
      materialInventory: 168,
      materialConsumption: 4.429999828338623,
      dri: 1,
      pfr: 54,
      pfrc: 55,
      pfu: 92,
      numSalesDays: 20,
      ftc: false,
      sort: "010000002021-05-26",
      name: "LOYOLA UNIV-M13-BUDDIG HALL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "6635482",
      location: "BUDDING HA 1ST FLOOR LAUNDRY ",
      dexErrors: false,
      online: "SLW232000166",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 23,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 1.1299999952316284,
          average_data: "[1.08,0.9,1.13,1.69,1.26,0.7]",
          average_trend: 0.6200000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102751",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.44999998807907104,
          average_data: "[0.38,0.3,0.13,0.62,0.84,0.4]",
          average_trend: 0.8899999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 15,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.7099999785423279,
          average_data: "[0.62,0.7,0.88,0.31,1.26,0.5]",
          average_trend: 0.699999988079071,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 15,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.7099999785423279,
          average_data: "[0.38,0.6,0.63,0.92,0.95,0.8]",
          average_trend: 1.1299999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133131",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.3,0.13,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156085",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.44999998807907104,
          average_data: "[0.69,0.6,0.25,0.46,0.68,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156090",
          consignment: 24,
          refill_qty: 19,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.9100000262260437,
          average_data: "[0,2.2,0.38,0.92,1.26,0.7]",
          average_trend: 0.7699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 168,
      inv: 76,
    },
    {
      customerNum: "0600190145",
      equipmentNum: "000000000101621045",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T22:32:00.000Z",
      lastInventoryUpdate: "2021-05-05T22:32:00.000Z",
      lastFillRate: 0.38999998569488525,
      nextDeliveryPlan: "2021-06-08",
      nextDeliveryAct: "2021-06-08",
      schedulingMethod: "D",
      criticalMaterialInventory: 6,
      criticalMaterialConsumption: 0.15000000596046448,
      criticalMaterialNum: "000000000000135335",
      materialInventory: 294,
      materialConsumption: 2.799999952316284,
      dri: 13,
      pfr: 21,
      pfrc: 32,
      pfu: 64,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-06-08",
      name: "LOYOLA UNIV-M24-DANA CENTER",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN07001054",
      location: "basement   ",
      dexErrors: false,
      online: "SLW232000269",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 4,
          average_consumption: 0.27000001072883606,
          average_data: "[0,0.4,0.28,0.39]",
          average_trend: 1.440000057220459,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 4,
          average_consumption: 0.33000001311302185,
          average_data: "[0.06,0.5,0.34,0.43]",
          average_trend: 1.2999999523162842,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.11999999731779099,
          average_data: "[0.03,0,0.1,0.36]",
          average_trend: 3,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 4,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.25,0.14,0.11]",
          average_trend: 0.8500000238418579,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 6,
          current_inventory: 42,
          average_based_on_rec: 4,
          average_consumption: 0.2800000011920929,
          average_data: "[0,0.42,0.28,0.43]",
          average_trend: 1.5399999618530273,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 4,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.25,0.14,0.25]",
          average_trend: 1.559999942779541,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 4,
          average_consumption: 0.07999999821186066,
          average_data: "[0.12,0,0.07,0.11]",
          average_trend: 1.3799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 10,
          current_inventory: 14,
          average_based_on_rec: 4,
          average_consumption: 0.46000000834465027,
          average_data: "[0,0.44,0.55,0.86]",
          average_trend: 1.8700000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 4,
          average_consumption: 0.3100000023841858,
          average_data: "[0,0.48,0.31,0.46]",
          average_trend: 1.4800000190734863,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0.23,0.07,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135335",
          consignment: 6,
          refill_qty: 4,
          current_inventory: 2,
          average_based_on_rec: 4,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0.38,0.1,0.11]",
          average_trend: 0.7300000190734863,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000154898",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 4,
          average_consumption: 0.18000000715255737,
          average_data: "[0,0.1,0.28,0.32]",
          average_trend: 1.7799999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154900",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 4,
          average_consumption: 0.18000000715255737,
          average_data: "[0,0.31,0.21,0.21]",
          average_trend: 1.1699999570846558,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154914",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.08,0.1,0.11]",
          average_trend: 1.5700000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 294,
      inv: 230,
    },
    {
      customerNum: "0600190151",
      equipmentNum: "000000000100930876",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T20:48:00.000Z",
      lastInventoryUpdate: "2021-05-05T20:48:00.000Z",
      lastFillRate: 0.36000001430511475,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 1.2599999904632568,
      criticalMaterialNum: "000000000000117603",
      materialInventory: 288,
      materialConsumption: 4.179999828338623,
      dri: 17,
      pfr: 31,
      pfrc: 55,
      pfu: 91,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-06-11",
      name: "LOYOLA UNIV-M04-MONROE HALL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "FL0088329",
      location: "5th floor   ",
      dexErrors: false,
      online: "SLW232000123",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 96,
          refill_qty: 22,
          current_inventory: 74,
          average_based_on_rec: 5,
          average_consumption: 1.0399999618530273,
          average_data: "[1.37,1.09,0.97,0.55,1.21]",
          average_trend: 1.159999966621399,
          init_inventory: 96,
          last_inventory: 96,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 5,
          average_consumption: 0.2800000011920929,
          average_data: "[0.34,0.27,0.41,0.27,0.13]",
          average_trend: 0.46000000834465027,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 24,
          current_inventory: 24,
          average_based_on_rec: 5,
          average_consumption: 1.100000023841858,
          average_data: "[1.37,1.09,0.41,0.64,2]",
          average_trend: 1.8200000524520874,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 27,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 1.2599999904632568,
          average_data: "[1.37,2.18,1.38,1,0.38]",
          average_trend: 0.30000001192092896,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117604",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 5,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0.55,0,0.18,0.33]",
          average_trend: 1.5700000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 5,
          average_consumption: 0.15000000596046448,
          average_data: "[0.34,0,0.07,0.27,0.08]",
          average_trend: 0.5299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.14000000059604645,
          average_data: "[0.11,0,0.28,0.09,0.21]",
          average_trend: 1.5,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 288,
      inv: 197,
    },
    {
      customerNum: "0600190144",
      equipmentNum: "000000000100535473",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T23:43:00.000Z",
      lastInventoryUpdate: "2021-05-19T23:43:00.000Z",
      lastFillRate: 0.4699999988079071,
      nextDeliveryPlan: "2021-06-03",
      nextDeliveryAct: "2021-06-03",
      schedulingMethod: "D",
      criticalMaterialInventory: 4,
      criticalMaterialConsumption: 0.20999999344348907,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 192,
      materialConsumption: 4.539999961853027,
      dri: 8,
      pfr: 18,
      pfrc: 35,
      pfu: 36,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-03",
      name: "LOYOLA UNIV-M10-BIEVER HALL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY07016267",
      location: "1st floor   ",
      dexErrors: false,
      online: "SLW232000254",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 16,
          refill_qty: 6,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.800000011920929,
          average_data: "[1.59,1.4,0.4,0.34,0.06,1]",
          average_trend: 1.25,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[1.24,1.35,0.27,0.14,0.06,0.7]",
          average_trend: 1.1100000143051147,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 4,
          refill_qty: 2,
          current_inventory: 2,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0.06,0.35,0.27,0.14,0,0.43]",
          average_trend: 2.049999952316284,
          init_inventory: 4,
          last_inventory: 4,
        },
        {
          material_num: "000000000000102659",
          consignment: 13,
          refill_qty: 4,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0.82,0.75,0.4,0.14,0,0.65]",
          average_trend: 1.409999966621399,
          init_inventory: 13,
          last_inventory: 13,
        },
        {
          material_num: "000000000000102782",
          consignment: 16,
          refill_qty: 4,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.5600000023841858,
          average_data: "[0,1.2,0,1.24,0,0.91]",
          average_trend: 1.6299999952316284,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000112259",
          consignment: 56,
          refill_qty: 5,
          current_inventory: 51,
          average_based_on_rec: 6,
          average_consumption: 0.6399999856948853,
          average_data: "[0.65,0.8,1.07,0.21,0,1.09]",
          average_trend: 1.7000000476837158,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000117635",
          consignment: 21,
          refill_qty: 3,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.3499999940395355,
          average_data: "[0.71,0.55,0,0.34,0,0.48]",
          average_trend: 1.3700000047683716,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000117641",
          consignment: 16,
          refill_qty: 1,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0.12,0.2,0,0.14,0.03,0.17]",
          average_trend: 1.5499999523162842,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000121751",
          consignment: 14,
          refill_qty: 2,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.29,0.3,0.67,0.07,0,0.22]",
          average_trend: 0.8500000238418579,
          init_inventory: 14,
          last_inventory: 14,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.5199999809265137,
          average_data: "[0.88,0.85,0.53,0.07,0.03,0.78]",
          average_trend: 1.5,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 192,
      inv: 156,
    },
    {
      customerNum: "0600190156",
      equipmentNum: "000000000100507306",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T20:10:00.000Z",
      lastInventoryUpdate: "2021-05-05T20:10:00.000Z",
      lastFillRate: 0.12999999523162842,
      nextDeliveryPlan: "2021-07-02",
      nextDeliveryAct: "2021-07-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 6,
      criticalMaterialConsumption: 0.029999999329447746,
      criticalMaterialNum: "000000000000121751",
      materialInventory: 54,
      materialConsumption: 0.15000000596046448,
      dri: 146,
      pfr: 12,
      pfrc: 46,
      pfu: 7,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-07-02",
      name: "LOYOLA UNIV-M25-STALLINGS",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "8307370",
      location: "   ",
      dexErrors: false,
      online: "SLW232000181",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 5,
          average_based_on_rec: 3,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0,0.07]",
          average_trend: 3.5,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102603",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 5,
          average_based_on_rec: 3,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0,0.07]",
          average_trend: 3.5,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 5,
          average_based_on_rec: 3,
          average_consumption: 0.009999999776482582,
          average_data: "[0,0,0.04]",
          average_trend: 4,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 3,
          average_consumption: 0.05999999865889549,
          average_data: "[0.04,0.07,0.07]",
          average_trend: 1.1699999570846558,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 5,
          average_based_on_rec: 3,
          average_consumption: 0.009999999776482582,
          average_data: "[0,0.03,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000121751",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 5,
          average_based_on_rec: 3,
          average_consumption: 0.029999999329447746,
          average_data: "[0.02,0.07,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
      ],
      cap: 54,
      inv: 47,
    },
    {
      customerNum: "0600188905",
      equipmentNum: "000000000100515599",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T20:19:00.000Z",
      lastInventoryUpdate: "2021-05-06T20:19:00.000Z",
      lastFillRate: 0.20000000298023224,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.9700000286102295,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 320,
      materialConsumption: 3.490000009536743,
      dri: 4,
      pfr: 25,
      pfrc: 29,
      pfu: 84,
      numSalesDays: 20,
      ftc: false,
      sort: "110000002021-05-28",
      name: "XAVIER UNIVERSITY-M07",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN14003159",
      location: "I/S 1ST FL   ",
      dexErrors: false,
      online: "SLW232000261",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 5,
          average_consumption: 0.23000000417232513,
          average_data: "[0,0.44,0.3,0.15,0.28]",
          average_trend: 1.2200000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 20,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.9700000286102295,
          average_data: "[0.84,0.89,0.8,1.12,1.22]",
          average_trend: 1.2599999904632568,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 5,
          average_consumption: 0.1899999976158142,
          average_data: "[0.21,0.11,0.25,0.2,0.17]",
          average_trend: 0.8899999856948853,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 5,
          current_inventory: 43,
          average_based_on_rec: 5,
          average_consumption: 0.23000000417232513,
          average_data: "[0,0.48,0.15,0.29,0.22]",
          average_trend: 0.9599999785423279,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 5,
          average_consumption: 0.30000001192092896,
          average_data: "[0.28,0.11,0.3,0.24,0.56]",
          average_trend: 1.8700000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0.19,0.15,0.15,0.06]",
          average_trend: 0.550000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 12,
          average_based_on_rec: 5,
          average_consumption: 0,
          average_data: "[0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000138036",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.10000000149011612,
          average_data: "[0,0,0.35,0.15,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 5,
          average_consumption: 0.17000000178813934,
          average_data: "[0,0.48,0.15,0,0.22]",
          average_trend: 1.2899999618530273,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152197",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0,0,0.3,0,0.17]",
          average_trend: 1.8899999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152921",
          consignment: 12,
          refill_qty: 10,
          current_inventory: 2,
          average_based_on_rec: 5,
          average_consumption: 0.25999999046325684,
          average_data: "[0.42,0.19,0.3,0.39,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000152923",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 5,
          average_consumption: 0.4300000071525574,
          average_data: "[0.42,0.15,0.3,0.59,0.67]",
          average_trend: 1.559999942779541,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000154898",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0.11,0,0.3,0,0.06]",
          average_trend: 0.6700000166893005,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154900",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.12999999523162842,
          average_data: "[0.14,0,0.15,0.2,0.17]",
          average_trend: 1.309999942779541,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154914",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 5,
          average_consumption: 0.1899999976158142,
          average_data: "[0.11,0,0.35,0.2,0.28]",
          average_trend: 1.4700000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 336,
      inv: 252,
    },
    {
      customerNum: "0600190400",
      equipmentNum: "000000000100506036",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T23:46:00.000Z",
      lastInventoryUpdate: "2021-05-12T23:46:00.000Z",
      lastFillRate: 0.47999998927116394,
      nextDeliveryPlan: "2021-05-25",
      nextDeliveryAct: "2021-05-25",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.8799999952316284,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 250,
      materialConsumption: 12.359999656677246,
      dri: -1,
      pfr: 67,
      pfrc: 64,
      pfu: 171,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-05-25",
      name: "LOYOLA UNIV-M14-BUDDIG HALL",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "01V25772",
      location: "BUDDIG HAL 1ST FLOOR LAUNDRY ",
      dexErrors: false,
      online: "SLW232000107",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 28,
          refill_qty: 13,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.8799999952316284,
          average_data: "[0.25,1.54,0.92,0.4,0.77,1.4]",
          average_trend: 1.590000033378601,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000102603",
          consignment: 56,
          refill_qty: 27,
          current_inventory: 29,
          average_based_on_rec: 6,
          average_consumption: 1.9199999570846558,
          average_data: "[2.75,2.92,1.75,2.53,1.54,0]",
          average_trend: 0,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.8799999952316284,
          average_data: "[1.5,1.38,1.75,2,2.92,1.7]",
          average_trend: 0.8999999761581421,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 24,
          refill_qty: 16,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 1.1200000047683716,
          average_data: "[0.88,0.92,0.75,1.07,1.69,1.4]",
          average_trend: 1.25,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 3.7100000381469727,
          average_data: "[3.5,3.54,2,3.2,5.23,4.8]",
          average_trend: 1.2899999618530273,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 20,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 1.399999976158142,
          average_data: "[0.25,1.08,1.83,0.8,2.92,1.5]",
          average_trend: 1.0700000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121751",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0.25,0.15,0.83,1.33,0,0.2]",
          average_trend: 0.4300000071525574,
          init_inventory: 22,
          last_inventory: 22,
        },
        {
          material_num: "000000000000125522",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.9900000095367432,
          average_data: "[0.63,0.92,0.33,1.2,1.54,1.3]",
          average_trend: 1.309999942779541,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 252,
      inv: 81,
    },
    {
      customerNum: "0600190401",
      equipmentNum: "000000000101090524",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-05T19:45:00.000Z",
      lastInventoryUpdate: "2021-05-05T19:45:00.000Z",
      lastFillRate: 0.30000001192092896,
      nextDeliveryPlan: "2021-05-31",
      nextDeliveryAct: "2021-05-31",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.4399999976158142,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 90,
      materialConsumption: 1.3300000429153442,
      dri: 5,
      pfr: 50,
      pfrc: 52,
      pfu: 59,
      numSalesDays: 21,
      ftc: false,
      sort: "110000002021-05-31",
      name: "LOYOLA UNIV LAW SCHOOL-M2",
      street: "526 PINE ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY17000537",
      location: "4th floor   ",
      dexErrors: false,
      online: "SLW232000427",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.009999999776482582,
          average_data: "[0,0,0,0,0.07]",
          average_trend: 7,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0.05,0,0,0.07]",
          average_trend: 3.5,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 10,
          current_inventory: 2,
          average_based_on_rec: 5,
          average_consumption: 0.4399999976158142,
          average_data: "[0.39,0.45,0.21,0.55,0.62]",
          average_trend: 1.409999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 5,
          average_consumption: 0.3400000035762787,
          average_data: "[0.23,0.36,0.28,0.2,0.62]",
          average_trend: 1.8200000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0.1,0.09,0,0.2,0.07]",
          average_trend: 0.7799999713897705,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 21,
          refill_qty: 11,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.07000000029802322,
          average_data: "[0.13,0.05,0,0.1,0.07]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.36000001430511475,
          average_data: "[0.29,0.5,0.14,0.55,0.34]",
          average_trend: 0.9399999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 117,
      inv: 58,
    },
    {
      customerNum: "0600190601",
      equipmentNum: "000000000100524819",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T20:32:00.000Z",
      lastInventoryUpdate: "2021-05-13T20:32:00.000Z",
      lastFillRate: 0.10000000149011612,
      nextDeliveryPlan: "2021-05-24",
      nextDeliveryAct: "2021-05-24",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 2.119999885559082,
      criticalMaterialNum: "000000000000102752",
      materialInventory: 273,
      materialConsumption: 17.110000610351562,
      dri: -2,
      pfr: 78,
      pfrc: 69,
      pfu: 218,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-24",
      name: "TULANE-M52-SHARP HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY13000977",
      location: "SHARP HALL 4TH FLOOR ELEVATOR H ",
      dexErrors: true,
      online: "SLW232000355",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 46,
          refill_qty: 46,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 3.880000114440918,
          average_data: "[5.54,4,3,5.33,3.71,1.67]",
          average_trend: 0.4300000071525574,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 23,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.840000033378601,
          average_data: "[3.38,3.83,0.5,2.33,1,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 1.5299999713897705,
          average_data: "[3.38,0.67,0,2.5,1.29,1.33]",
          average_trend: 0.8700000047683716,
          init_inventory: 42,
          last_inventory: 42,
        },
        {
          material_num: "000000000000102752",
          consignment: 23,
          refill_qty: 23,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 2.119999885559082,
          average_data: "[3.54,2.17,1.75,1.5,1.43,2.33]",
          average_trend: 1.100000023841858,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 92,
          refill_qty: 89,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 6.769999980926514,
          average_data: "[4.92,8.17,6,8,8.86,4.67]",
          average_trend: 0.6899999976158142,
          init_inventory: 92,
          last_inventory: 92,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 7,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.5099999904632568,
          average_data: "[1.38,0.5,0.75,0,0.43,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0.92,0.33,0,0.5,1,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 277,
      inv: 59,
    },
    {
      customerNum: "0600188933",
      equipmentNum: "000000000100512667",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-18T19:34:00.000Z",
      lastInventoryUpdate: "2021-05-18T19:34:00.000Z",
      lastFillRate: 0.10000000149011612,
      nextDeliveryPlan: "2021-06-18",
      nextDeliveryAct: "2021-06-18",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 0.6800000071525574,
      criticalMaterialNum: "000000000000117635",
      materialInventory: 165,
      materialConsumption: 3.2100000381469727,
      dri: 25,
      pfr: 17,
      pfrc: 64,
      pfu: 29,
      numSalesDays: 8,
      ftc: false,
      sort: "110000002021-06-18",
      name: "XAVIER UNIVERSITY-M02-NEW DORM",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY98017983",
      location: "St Martin  5th  ",
      dexErrors: false,
      online: "SLW232000150",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 46,
          refill_qty: 7,
          current_inventory: 39,
          average_based_on_rec: 6,
          average_consumption: 0.8600000143051147,
          average_data: "[0.92,0.85,0.89,1.29,1,0.23]",
          average_trend: 0.27000001072883606,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.5699999928474426,
          average_data: "[0.33,0.9,0.33,0.71,1,0.15]",
          average_trend: 0.25999999046325684,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 0,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.25,0.25,0.22,0.43,0.11,0.23]",
          average_trend: 0.9200000166893005,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0.33,0.1,0.11,0.57,1.11,0.23]",
          average_trend: 0.5600000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.6800000071525574,
          average_data: "[0.58,1.15,1.44,0.64,0.11,0.15]",
          average_trend: 0.2199999988079071,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.009999999776482582,
          average_data: "[0,0,0,0.07,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.4300000071525574,
          average_data: "[0.33,0.2,0.56,0.79,0.44,0.23]",
          average_trend: 0.5299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 165,
      inv: 136,
    },
    {
      customerNum: "0600190607",
      equipmentNum: "000000000100528737",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T22:06:00.000Z",
      lastInventoryUpdate: "2021-05-12T22:06:00.000Z",
      lastFillRate: 0.38999998569488525,
      nextDeliveryPlan: "2021-05-21",
      nextDeliveryAct: "2021-05-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 2.7100000381469727,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 265,
      materialConsumption: 16.25,
      dri: -5,
      pfr: 73,
      pfrc: 55,
      pfu: 195,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-05-21",
      name: "TULANE-M34-MONROE HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12015256",
      location: "MONROE HAL 8TH FLOOR LOUNGE ",
      dexErrors: false,
      online: "SLW232000212",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 46,
          refill_qty: 37,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 2.640000104904175,
          average_data: "[2.15,3.08,1.6,3,3.88,2.14]",
          average_trend: 0.8100000023841858,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 25,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 1.7400000095367432,
          average_data: "[1.77,0.62,2,1.75,1.75,2.57]",
          average_trend: 1.4800000190734863,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102604",
          consignment: 23,
          refill_qty: 23,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 2.7100000381469727,
          average_data: "[1.77,3.54,3.07,2.88,2.88,2.14]",
          average_trend: 0.7900000214576721,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.36000001430511475,
          average_data: "[0.69,0,0.27,0.63,0.13,0.43]",
          average_trend: 1.190000057220459,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 69,
          refill_qty: 69,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 6.420000076293945,
          average_data: "[5.31,5.69,7.73,7.75,6.63,5.43]",
          average_trend: 0.8500000238418579,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 16,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 1.1399999856948853,
          average_data: "[1.23,0.31,1.33,1.63,0.88,1.43]",
          average_trend: 1.25,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.800000011920929,
          average_data: "[0.77,0.62,1.07,0.63,1.13,0.57]",
          average_trend: 0.7099999785423279,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000145105",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.4399999976158142,
          average_data: "[0.31,0.92,0.4,0,1,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 265,
      inv: 70,
    },
    {
      customerNum: "0600190605",
      equipmentNum: "000000000100520768",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T00:39:00.000Z",
      lastInventoryUpdate: "2021-05-11T00:39:00.000Z",
      lastFillRate: 0.20000000298023224,
      nextDeliveryPlan: "2021-05-31",
      nextDeliveryAct: "2021-05-31",
      schedulingMethod: "D",
      criticalMaterialInventory: 10,
      criticalMaterialConsumption: 0.44999998807907104,
      criticalMaterialNum: "000000000000119516",
      materialInventory: 235,
      materialConsumption: 6.360000133514404,
      dri: 6,
      pfr: 48,
      pfrc: 61,
      pfu: 127,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-05-31",
      name: "TULANE-M50-SHARP HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY13000992",
      location: "SHARP HALL 4TH FLOOR ELEVATOR H ",
      dexErrors: false,
      online: "SLW232000336",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 48,
          refill_qty: 12,
          current_inventory: 36,
          average_based_on_rec: 6,
          average_consumption: 0.7400000095367432,
          average_data: "[0.68,0.83,0,0.92,1,1]",
          average_trend: 1.350000023841858,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115584",
          consignment: 48,
          refill_qty: 27,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 1.7899999618530273,
          average_data: "[1.42,1.83,2.29,1.92,1.8,1.5]",
          average_trend: 0.8399999737739563,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 27,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.7799999713897705,
          average_data: "[0.47,0.92,0.57,0.67,0.8,1.25]",
          average_trend: 1.600000023841858,
          init_inventory: 33,
          last_inventory: 33,
        },
        {
          material_num: "000000000000116151",
          consignment: 48,
          refill_qty: 30,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 1.9700000286102295,
          average_data: "[0.79,2.08,3.43,2,2,1.5]",
          average_trend: 0.7599999904632568,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.53,0.17,0,0.42,0.4,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119516",
          consignment: 24,
          refill_qty: 21,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.44999998807907104,
          average_data: "[0.42,0.25,0.57,0.17,0.1,1.17]",
          average_trend: 2.5999999046325684,
          init_inventory: 10,
          last_inventory: 10,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.47,0.08,0.57,0.75,0.4,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 264,
      inv: 137,
    },
    {
      customerNum: "0600190612",
      equipmentNum: "000000000100600376",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T20:16:00.000Z",
      lastInventoryUpdate: "2021-05-17T20:16:00.000Z",
      lastFillRate: 0.33000001311302185,
      nextDeliveryPlan: "2021-06-10",
      nextDeliveryAct: "2021-06-10",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.9700000286102295,
      criticalMaterialNum: "000000000000116149",
      materialInventory: 183,
      materialConsumption: 4.860000133514404,
      dri: 15,
      pfr: 29,
      pfrc: 65,
      pfu: 57,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-06-10",
      name: "TULANE-M28-MAYER RESIDENCE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY16002532",
      location: "2nd floor    ",
      dexErrors: false,
      online: "SLW232000423",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 48,
          refill_qty: 8,
          current_inventory: 40,
          average_based_on_rec: 6,
          average_consumption: 0.7799999713897705,
          average_data: "[0.3,0.92,1,0.83,1,0.62]",
          average_trend: 0.7900000214576721,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.8999999761581421,
          average_data: "[0.4,0.54,1.25,1,0.89,1.31]",
          average_trend: 1.4600000381469727,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115586",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.8100000023841858,
          average_data: "[0.2,1.31,0.63,0.67,1.11,0.92]",
          average_trend: 1.1399999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000116149",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.9700000286102295,
          average_data: "[1.2,0.92,0.75,0.92,0.78,1.23]",
          average_trend: 1.2699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.9399999976158142,
          average_data: "[1.2,0.69,1.38,0.75,1,0.62]",
          average_trend: 0.6600000262260437,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119516",
          consignment: 48,
          refill_qty: 14,
          current_inventory: 34,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0.2,0.23,0.88,0.75,0,0.69]",
          average_trend: 1.5,
          init_inventory: 39,
          last_inventory: 39,
        },
      ],
      cap: 192,
      inv: 135,
    },
    {
      customerNum: "0600190604",
      equipmentNum: "000000000100520766",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T21:16:00.000Z",
      lastInventoryUpdate: "2021-05-13T21:16:00.000Z",
      lastFillRate: 0.029999999329447746,
      nextDeliveryPlan: "2021-05-21",
      nextDeliveryAct: "2021-05-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 46,
      criticalMaterialConsumption: 5.510000228881836,
      criticalMaterialNum: "000000000000101728",
      materialInventory: 275,
      materialConsumption: 18.719999313354492,
      dri: -4,
      pfr: 71,
      pfrc: 61,
      pfu: 199,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-21",
      name: "TULANE-M51-SHARP HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY13000983",
      location: "SHARP HALL 2ND FLOOR ELEVATOR H ",
      dexErrors: false,
      online: "SLW232000415",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 46,
          refill_qty: 46,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 5.510000228881836,
          average_data: "[7.08,7.33,6.5,6.83,4.33,1]",
          average_trend: 0.18000000715255737,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 23,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.940000057220459,
          average_data: "[3.54,3.17,0.75,3.33,0.83,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 69,
          refill_qty: 20,
          current_inventory: 49,
          average_based_on_rec: 6,
          average_consumption: 1.5,
          average_data: "[2.15,1.17,1,2.67,2,0]",
          average_trend: 0,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000112259",
          consignment: 69,
          refill_qty: 69,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 6.940000057220459,
          average_data: "[9.23,8.33,8.25,6.33,9.5,0]",
          average_trend: 0,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 20,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 1.3300000429153442,
          average_data: "[1.38,0.67,1.25,1.67,2.33,0.67]",
          average_trend: 0.5,
          init_inventory: 21,
          last_inventory: 21,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 14,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 1.0099999904632568,
          average_data: "[1.23,1.5,0.5,0.5,0.67,1.67]",
          average_trend: 1.649999976158142,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.49000000953674316,
          average_data: "[0.46,0,0,1.33,1.17,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 277,
      inv: 78,
    },
    {
      customerNum: "0600190609",
      equipmentNum: "000000000100516598",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T22:07:00.000Z",
      lastInventoryUpdate: "2021-05-12T22:07:00.000Z",
      lastFillRate: 0.1899999976158142,
      nextDeliveryPlan: "2021-05-25",
      nextDeliveryAct: "2021-05-25",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 1.8300000429153442,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 265,
      materialConsumption: 9.5,
      dri: -1,
      pfr: 50,
      pfrc: 46,
      pfu: 133,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-05-25",
      name: "TULANE-M32-MONROE HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12015255",
      location: "MONROE HAL 3RD FLOOR LOUNGE ",
      dexErrors: false,
      online: "SLW232000206",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 23,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.8300000429153442,
          average_data: "[1.77,1.73,1.73,0.5,2.75,2.5]",
          average_trend: 1.3700000047683716,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 69,
          refill_qty: 25,
          current_inventory: 44,
          average_based_on_rec: 6,
          average_consumption: 1.75,
          average_data: "[2.31,1.87,1.47,1.75,1.75,1.33]",
          average_trend: 0.7599999904632568,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000102604",
          consignment: 23,
          refill_qty: 8,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[1.62,0.27,0.4,0,0.88,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0.69,0.13,0.4,0.13,0.75,0.33]",
          average_trend: 0.800000011920929,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 92,
          refill_qty: 64,
          current_inventory: 28,
          average_based_on_rec: 6,
          average_consumption: 4.519999980926514,
          average_data: "[4.77,4.27,4,5.88,4.38,3.83]",
          average_trend: 0.8500000238418579,
          init_inventory: 92,
          last_inventory: 92,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[0.38,0.13,0.27,0,1.38,0.33]",
          average_trend: 0.7900000214576721,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.03999999910593033,
          average_data: "[0,0,0.13,0,0.13,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 265,
      inv: 132,
    },
    {
      customerNum: "0600190603",
      equipmentNum: "000000000100523793",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T21:17:00.000Z",
      lastInventoryUpdate: "2021-05-13T21:17:00.000Z",
      lastFillRate: 0.3499999940395355,
      nextDeliveryPlan: "2021-06-18",
      nextDeliveryAct: "2021-06-18",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3100000023841858,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 252,
      materialConsumption: 1.7300000190734863,
      dri: 23,
      pfr: 11,
      pfrc: 24,
      pfu: 29,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-06-18",
      name: "TULANE-M53-SHARP HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN13000176",
      location: "SHARP HALL 3RD FLOOR ELEVATOR H ",
      dexErrors: false,
      online: "SLW232000117",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.03999999910593033,
          average_data: "[0,0,0,0,0,0.22]",
          average_trend: 5.5,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.37,0,0.43,0,0.17]",
          average_trend: 1.059999942779541,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0.31,0.42,0.17,0,0,0.35]",
          average_trend: 1.6699999570846558,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0.62,0.05,0.33,0.57,0,0.26]",
          average_trend: 0.8399999737739563,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0,0,0.29,0,0.04]",
          average_trend: 0.6700000166893005,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 72,
          refill_qty: 5,
          current_inventory: 67,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0,0.05,0.08,0,0,1.91]",
          average_trend: 5.619999885559082,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.18000000715255737,
          average_data: "[0.62,0.21,0.08,0.14,0,0.04]",
          average_trend: 0.2199999988079071,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114532",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0,0,0,0.08,0.35]",
          average_trend: 5,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114633",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.09000000357627869,
          average_data: "[0.15,0,0,0,0,0.39]",
          average_trend: 4.329999923706055,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.62,0,0,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119790",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.05999999865889549,
          average_data: "[0.15,0,0.08,0,0,0.13]",
          average_trend: 2.1700000762939453,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0.31,0.26,0.08,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 252,
      inv: 223,
    },
    {
      customerNum: "0600190616",
      equipmentNum: "000000000100017939",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T18:24:00.000Z",
      lastInventoryUpdate: "2021-05-19T18:24:00.000Z",
      lastFillRate: 0.03999999910593033,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 2.5299999713897705,
      criticalMaterialNum: "000000000000129254",
      materialInventory: 336,
      materialConsumption: 12.970000267028809,
      dri: 3,
      pfr: 28,
      pfrc: 38,
      pfu: 97,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-05-28",
      name: "TULANE-M59-WARREN HOUSE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN10005659",
      location: "WARREN HOU 1ST FLOOR BREAKROOM ",
      dexErrors: false,
      online: "SLW232000327",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.8500000238418579,
          average_data: "[0.88,0.52,0.46,2.13,0.92,0.17]",
          average_trend: 0.20000000298023224,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.7099999785423279,
          average_data: "[1.13,0.3,0.92,0.8,1.08,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 1.0399999618530273,
          average_data: "[0.63,0.59,1.23,1.6,1.38,0.83]",
          average_trend: 0.800000011920929,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.14000000059604645,
          average_data: "[0.13,0,0.15,0.53,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 28,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 3.9700000286102295,
          average_data: "[3.38,2.15,5.54,6.4,6.15,0.17]",
          average_trend: 0.03999999910593033,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0,0.15,0.46,0.67,0.77,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0.13,0.22,0.15,0.53,0.46,0.17]",
          average_trend: 0.6100000143051147,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000125522",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0.38,0.07,0.46,0.67,0.15,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 18,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 2.5299999713897705,
          average_data: "[2.63,1.78,3.69,3.2,3.69,0.17]",
          average_trend: 0.07000000029802322,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.38,0.07,0.62,0.4,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0.38,0.3,1.08,0.67,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[0.38,0.67,1.38,0.4,0.92,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154898",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.5,
          average_data: "[0.63,0.3,1.23,0.4,0.46,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154900",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.8799999952316284,
          average_data: "[0.63,0.07,2,1.47,0.92,0.17]",
          average_trend: 0.1899999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000158053",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0.15,0.62,0.13,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 336,
      inv: 239,
    },
    {
      customerNum: "0600190613",
      equipmentNum: "000000000100542347",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T20:15:00.000Z",
      lastInventoryUpdate: "2021-05-20T11:05:23.000Z",
      lastFillRate: 0.4099999964237213,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 11,
      criticalMaterialConsumption: 0.6299999952316284,
      criticalMaterialNum: "000000000000119516",
      materialInventory: 163,
      materialConsumption: 5.690000057220459,
      dri: 10,
      pfr: 37,
      pfrc: 62,
      pfu: 72,
      numSalesDays: 6,
      ftc: false,
      sort: "110000002021-06-04",
      name: "TULANE-M27-MAYER RESIDENCE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY16001699",
      location: "laundry ro 1st fl  ",
      dexErrors: false,
      online: "SLW232000301",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 48,
          refill_qty: 23,
          current_inventory: 25,
          average_based_on_rec: 6,
          average_consumption: 1.8700000047683716,
          average_data: "[1.4,0.69,1.63,1.92,2.67,2.92]",
          average_trend: 1.559999942779541,
          init_inventory: 48,
          last_inventory: 37,
        },
        {
          material_num: "000000000000115584",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.75,
          average_data: "[1.6,1,0.88,0.25,0.56,0.23]",
          average_trend: 0.3100000023841858,
          init_inventory: 24,
          last_inventory: 23,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 18,
          current_inventory: 30,
          average_based_on_rec: 6,
          average_consumption: 1.7000000476837158,
          average_data: "[1.2,1.54,1.75,2.08,1.78,1.85]",
          average_trend: 1.090000033378601,
          init_inventory: 48,
          last_inventory: 41,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 8,
          current_inventory: 40,
          average_based_on_rec: 6,
          average_consumption: 0.6100000143051147,
          average_data: "[0.9,0.54,0,0.42,0.89,0.92]",
          average_trend: 1.5099999904632568,
          init_inventory: 48,
          last_inventory: 44,
        },
        {
          material_num: "000000000000119516",
          consignment: 24,
          refill_qty: 17,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[0.6,0.77,0.88,0.25,0.44,0.85]",
          average_trend: 1.350000023841858,
          init_inventory: 13,
          last_inventory: 11,
        },
        {
          material_num: "000000000000153225",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.38,0,0,0.11,0.31]",
          average_trend: 2.380000114440918,
          init_inventory: 7,
          last_inventory: 7,
        },
      ],
      cap: 192,
      inv: 126,
    },
    {
      customerNum: "0600190608",
      equipmentNum: "000000000100508765",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T23:01:00.000Z",
      lastInventoryUpdate: "2021-05-06T23:01:00.000Z",
      lastFillRate: 0.3100000023841858,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 2.0799999237060547,
      criticalMaterialNum: "000000000000115584",
      materialInventory: 512,
      materialConsumption: 8.109999656677246,
      dri: 3,
      pfr: 32,
      pfrc: 36,
      pfu: 166,
      numSalesDays: 20,
      ftc: false,
      sort: "110000002021-05-28",
      name: "TULANE-M33-MONROE HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY13000969",
      location: "MONROE HAL 5TH FLOOR LOUNGE ",
      dexErrors: false,
      online: "SLW232000199",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 104,
          refill_qty: 32,
          current_inventory: 72,
          average_based_on_rec: 6,
          average_consumption: 1.559999942779541,
          average_data: "[1.25,1,1.66,1.22,1.66,2.56]",
          average_trend: 1.6399999856948853,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000115584",
          consignment: 48,
          refill_qty: 42,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 2.0799999237060547,
          average_data: "[3,2,1.66,0.89,2.48,2.44]",
          average_trend: 1.1699999570846558,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115586",
          consignment: 104,
          refill_qty: 27,
          current_inventory: 77,
          average_based_on_rec: 6,
          average_consumption: 1.3200000524520874,
          average_data: "[1.5,2,1.66,1.11,0.9,0.75]",
          average_trend: 0.5699999928474426,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000116149",
          consignment: 104,
          refill_qty: 20,
          current_inventory: 84,
          average_based_on_rec: 6,
          average_consumption: 0.9800000190734863,
          average_data: "[0,1,1.24,0.93,1.17,1.56]",
          average_trend: 1.590000033378601,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000116151",
          consignment: 104,
          refill_qty: 27,
          current_inventory: 77,
          average_based_on_rec: 6,
          average_consumption: 1.309999942779541,
          average_data: "[1.5,2,0.83,1,1.38,1.13]",
          average_trend: 0.8600000143051147,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 4,
          average_consumption: 0.5099999904632568,
          average_data: "[0,0.81,0.48,0.75]",
          average_trend: 1.4700000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.3499999940395355,
          average_data: "[0,0,0.34,0.59,0.55,0.63]",
          average_trend: 1.7999999523162842,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 512,
      inv: 346,
    },
    {
      customerNum: "0600190615",
      equipmentNum: "000000000100524794",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-07T01:26:00.000Z",
      lastInventoryUpdate: "2021-05-07T01:26:00.000Z",
      lastFillRate: 0.28999999165534973,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.4699999988079071,
      criticalMaterialNum: "000000000000121751",
      materialInventory: 188,
      materialConsumption: 3.6600000858306885,
      dri: 5,
      pfr: 38,
      pfrc: 46,
      pfu: 73,
      numSalesDays: 19,
      ftc: false,
      sort: "110000002021-05-28",
      name: "TULANE-M63-WILLOW BLDG",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12011651",
      location: "WILLOW HAL 2ND FLOOR VEND AREA ",
      dexErrors: false,
      online: "SLW232000308",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 11,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.5600000023841858,
          average_data: "[0.42,0.7,0.48,0.44,1,0.34]",
          average_trend: 0.6100000143051147,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.17,0.1,0.43,0.3,0.44,0.07]",
          average_trend: 0.2800000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.25,0.3,0.57,0.22,0.11,0.07]",
          average_trend: 0.2800000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0.42,0.5,0.24,0.07,0.22,0.41]",
          average_trend: 1.3200000524520874,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 69,
          refill_qty: 25,
          current_inventory: 44,
          average_based_on_rec: 6,
          average_consumption: 1.2699999809265137,
          average_data: "[1.25,0.7,0.76,2,1.33,1.59]",
          average_trend: 1.25,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.2199999988079071,
          average_data: "[0.08,0.2,0.1,0.22,0.44,0.28]",
          average_trend: 1.2699999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 9,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.4699999988079071,
          average_data: "[0.33,1.1,0.24,0.22,0.22,0.69]",
          average_trend: 1.4700000286102295,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000153225",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0.25,0.8,0.43,0.22,0,0.28]",
          average_trend: 0.8500000238418579,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 188,
      inv: 115,
    },
    {
      customerNum: "0600190614",
      equipmentNum: "000000000100528722",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-07T01:26:00.000Z",
      lastInventoryUpdate: "2021-05-07T01:26:00.000Z",
      lastFillRate: 0.550000011920929,
      nextDeliveryPlan: "2021-05-25",
      nextDeliveryAct: "2021-05-25",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 1.159999966621399,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 232,
      materialConsumption: 6.269999980926514,
      dri: 0,
      pfr: 53,
      pfrc: 51,
      pfu: 124,
      numSalesDays: 19,
      ftc: false,
      sort: "110000002021-05-25",
      name: "TULANE-M62-WILLOW BLDG",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12011644",
      location: "WILLOWS BU 1ST FLOOR VEND AREA ",
      dexErrors: false,
      online: "SLW232000297",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 23,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.159999966621399,
          average_data: "[0.92,0.7,1.1,1.7,1.11,1.45]",
          average_trend: 1.25,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 37,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 1.899999976158142,
          average_data: "[2.25,1.4,1.81,1.93,1.44,2.55]",
          average_trend: 1.340000033378601,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.14000000059604645,
          average_data: "[0.17,0,0.14,0.07,0.11,0.34]",
          average_trend: 2.430000066757202,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 16,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.800000011920929,
          average_data: "[0.42,0.6,0.95,1.33,0.89,0.62]",
          average_trend: 0.7799999713897705,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 69,
          refill_qty: 35,
          current_inventory: 34,
          average_based_on_rec: 6,
          average_consumption: 1.7999999523162842,
          average_data: "[2,1.5,0.95,1.56,1.67,3.1]",
          average_trend: 1.7200000286102295,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0.17,0.2,0.33,0.37,0.22,0.55]",
          average_trend: 1.7699999809265137,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0.25,0,0.05,0,0.33,0.14]",
          average_trend: 1.0800000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000133129",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.029999999329447746,
          average_data: "[0,0,0,0,0.11,0.07]",
          average_trend: 2.3299999237060547,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 232,
      inv: 108,
    },
    {
      customerNum: "0600190610",
      equipmentNum: "000000000100520699",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T21:06:00.000Z",
      lastInventoryUpdate: "2021-05-12T21:06:00.000Z",
      lastFillRate: 0.5600000023841858,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 138,
      criticalMaterialConsumption: 5.920000076293945,
      criticalMaterialNum: "000000000000112259",
      materialInventory: 282,
      materialConsumption: 6.96999979019165,
      dri: 10,
      pfr: 35,
      pfrc: 59,
      pfu: 100,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-06-04",
      name: "TULANE-M30-MONROE HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY11015084",
      location: "MONROE HAL 11TH FLOOR LOUNGE ",
      dexErrors: false,
      online: "SLW232000168",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 138,
          refill_qty: 83,
          current_inventory: 55,
          average_based_on_rec: 6,
          average_consumption: 5.920000076293945,
          average_data: "[6.31,4.88,6.54,8.48,0.25,9.04]",
          average_trend: 1.5299999713897705,
          init_inventory: 138,
          last_inventory: 138,
        },
        {
          material_num: "000000000000133129",
          consignment: 48,
          refill_qty: 6,
          current_inventory: 42,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[1.08,0.25,0.23,0.21,0,0.52]",
          average_trend: 1.3700000047683716,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000133131",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0.25,0,0.28,0,0.37]",
          average_trend: 2.4700000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[0.15,0.25,0.08,0.28,0,1.78]",
          average_trend: 4.239999771118164,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152196",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0,0.15,0.21,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152197",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.03999999910593033,
          average_data: "[0.15,0,0.08,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 282,
      inv: 182,
    },
    {
      customerNum: "0600190620",
      equipmentNum: "000000000100721053",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-10T20:15:00.000Z",
      lastInventoryUpdate: "2021-05-10T20:15:00.000Z",
      lastFillRate: 0.6399999856948853,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 1.4700000286102295,
      criticalMaterialNum: "000000000000115584",
      materialInventory: 300,
      materialConsumption: 4.75,
      dri: 16,
      pfr: 26,
      pfrc: 50,
      pfu: 79,
      numSalesDays: 16,
      ftc: false,
      sort: "110000002021-06-11",
      name: "TULANE-M20-JOSEPHINE LOUISE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY01026836",
      location: "JOSEPHINE  1ST FLOOR LAUNDRY ",
      dexErrors: false,
      online: "SLW232000294",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 96,
          refill_qty: 15,
          current_inventory: 81,
          average_based_on_rec: 4,
          average_consumption: 0.9200000166893005,
          average_data: "[1.26,0.65,0.63,1.13]",
          average_trend: 1.2300000190734863,
          init_inventory: 96,
          last_inventory: 96,
        },
        {
          material_num: "000000000000115584",
          consignment: 48,
          refill_qty: 24,
          current_inventory: 24,
          average_based_on_rec: 4,
          average_consumption: 1.4700000286102295,
          average_data: "[1.68,1.53,1.53,1.13]",
          average_trend: 0.7699999809265137,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115586",
          consignment: 84,
          refill_qty: 15,
          current_inventory: 69,
          average_based_on_rec: 4,
          average_consumption: 0.8999999761581421,
          average_data: "[0.84,1.12,0.63,0.99]",
          average_trend: 1.100000023841858,
          init_inventory: 84,
          last_inventory: 84,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 4,
          average_consumption: 0.4000000059604645,
          average_data: "[0.42,0.56,0.47,0.14]",
          average_trend: 0.3499999940395355,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117604",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 4,
          average_consumption: 0.36000001430511475,
          average_data: "[0.42,0,0.47,0.56]",
          average_trend: 1.559999942779541,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 4,
          average_consumption: 0.699999988079071,
          average_data: "[0.84,0.7,0.68,0.56]",
          average_trend: 0.800000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 300,
      inv: 221,
    },
    {
      customerNum: "0600190621",
      equipmentNum: "000000000100520541",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T18:11:00.000Z",
      lastInventoryUpdate: "2021-05-19T18:11:00.000Z",
      lastFillRate: 0.029999999329447746,
      nextDeliveryPlan: "2021-06-02",
      nextDeliveryAct: "2021-06-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 1.659999966621399,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 230,
      materialConsumption: 10.359999656677246,
      dri: 7,
      pfr: 33,
      pfrc: 63,
      pfu: 76,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-02",
      name: "TULANE-M19-JOSEPHINE LOUISE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY07009370",
      location: "JOSEPHINE  1ST FLOOR LOUNGE ",
      dexErrors: false,
      online: "SLW232000193",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 1.659999966621399,
          average_data: "[2.46,1,2.13,2.4,1.5,0.46]",
          average_trend: 0.2800000011920929,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 7,
          current_inventory: 39,
          average_based_on_rec: 6,
          average_consumption: 0.8799999952316284,
          average_data: "[1.38,0.78,0.67,1.4,0.75,0.31]",
          average_trend: 0.3499999940395355,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102604",
          consignment: 23,
          refill_qty: 2,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.17000000178813934,
          average_data: "[0.92,0.11,0,0,0,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 92,
          refill_qty: 46,
          current_inventory: 46,
          average_based_on_rec: 6,
          average_consumption: 6.570000171661377,
          average_data: "[8.31,6.44,9.6,6.2,8.75,0.15]",
          average_trend: 0.019999999552965164,
          init_inventory: 92,
          last_inventory: 92,
        },
        {
          material_num: "000000000000114230",
          consignment: 23,
          refill_qty: 3,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.3499999940395355,
          average_data: "[0.62,0.33,0.13,0.4,0.63,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.7300000190734863,
          average_data: "[0.15,0.44,1.33,1.2,1.13,0.15]",
          average_trend: 0.20999999344348907,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 230,
      inv: 154,
    },
    {
      customerNum: "0600190618",
      equipmentNum: "000000000100512232",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T22:05:00.000Z",
      lastInventoryUpdate: "2021-05-13T22:05:00.000Z",
      lastFillRate: 0.5400000214576721,
      nextDeliveryPlan: "2021-06-02",
      nextDeliveryAct: "2021-06-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.1699999570846558,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 144,
      materialConsumption: 3.3499999046325684,
      dri: 7,
      pfr: 32,
      pfrc: 41,
      pfu: 43,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-06-02",
      name: "TULANE-M38-PATTERSON HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY02017938",
      location: "Paterson H 1st Floor  ",
      dexErrors: false,
      online: "SLW232000240",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.8299999833106995,
          average_data: "[1.19,0.85,0.46,0.82,0.55,1.12]",
          average_trend: 1.350000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102592",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0.15,0,0.08,0,0,0.2]",
          average_trend: 2.859999895095825,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 16,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 1.1699999570846558,
          average_data: "[1.63,1.38,0.85,1.36,0.62,1.17]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0,0,0.62,0.55,0.41,0.29]",
          average_trend: 0.9399999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.5699999928474426,
          average_data: "[0.3,0.69,0.62,0.73,0.62,0.44]",
          average_trend: 0.7699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.3199999928474426,
          average_data: "[0.15,0.62,0.31,0,0.34,0.49]",
          average_trend: 1.5299999713897705,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.03999999910593033,
          average_data: "[0.07,0,0,0.09,0,0.1]",
          average_trend: 2.5,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.03999999910593033,
          average_data: "[0,0,0,0.18,0.07,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 132,
      inv: 96,
    },
    {
      customerNum: "0600190606",
      equipmentNum: "000000000100512613",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T20:00:00.000Z",
      lastInventoryUpdate: "2021-05-19T20:00:00.000Z",
      lastFillRate: 0.1599999964237213,
      nextDeliveryPlan: "2021-06-29",
      nextDeliveryAct: "2021-06-29",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 1.1699999570846558,
      criticalMaterialNum: "000000000000115584",
      materialInventory: 592,
      materialConsumption: 6.309999942779541,
      dri: 34,
      pfr: 8,
      pfrc: 43,
      pfu: 48,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-29",
      name: "TULANE-M31-MONROE HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12011655",
      location: "MONROE HAL 1ST FLOOR LAUNDRY ",
      dexErrors: false,
      online: "SLW232000160",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 156,
          refill_qty: 12,
          current_inventory: 144,
          average_based_on_rec: 6,
          average_consumption: 1.6100000143051147,
          average_data: "[1.5,1.07,2.41,0.83,1.75,2.08]",
          average_trend: 1.2899999618530273,
          init_inventory: 156,
          last_inventory: 156,
        },
        {
          material_num: "000000000000115584",
          consignment: 48,
          refill_qty: 9,
          current_inventory: 39,
          average_based_on_rec: 6,
          average_consumption: 1.1699999570846558,
          average_data: "[1.5,0.89,0.89,0.83,1.75,1.15]",
          average_trend: 0.9800000190734863,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115586",
          consignment: 104,
          refill_qty: 9,
          current_inventory: 95,
          average_based_on_rec: 6,
          average_consumption: 1.149999976158142,
          average_data: "[0.75,0.67,2.37,0.55,1.5,1.08]",
          average_trend: 0.9399999976158142,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000116149",
          consignment: 104,
          refill_qty: 4,
          current_inventory: 100,
          average_based_on_rec: 6,
          average_consumption: 0.550000011920929,
          average_data: "[0,0.22,1.33,0.55,0.56,0.62]",
          average_trend: 1.1299999952316284,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000116151",
          consignment: 104,
          refill_qty: 6,
          current_inventory: 98,
          average_based_on_rec: 6,
          average_consumption: 0.7900000214576721,
          average_data: "[0,0.67,1.33,0.83,0.69,1.23]",
          average_trend: 1.559999942779541,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000117603",
          consignment: 52,
          refill_qty: 5,
          current_inventory: 47,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[0.75,0.33,1.41,0,0.5,0.77]",
          average_trend: 1.2200000286102295,
          init_inventory: 52,
          last_inventory: 52,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0.63,0.56,0.33,0.07,0.63,0.23]",
          average_trend: 0.5600000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 592,
      inv: 544,
    },
    {
      customerNum: "0600190624",
      equipmentNum: "000000000100528721",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T19:58:00.000Z",
      lastInventoryUpdate: "2021-05-13T19:58:00.000Z",
      lastFillRate: 0.2800000011920929,
      nextDeliveryPlan: "2021-05-31",
      nextDeliveryAct: "2021-05-31",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 1.2400000095367432,
      criticalMaterialNum: "000000000000121751",
      materialInventory: 276,
      materialConsumption: 5.369999885559082,
      dri: 5,
      pfr: 26,
      pfrc: 35,
      pfu: 74,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-31",
      name: "TULANE-M57-STERN HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12011641",
      location: "STERN HALL 5TH FLOOR HALLWAY ",
      dexErrors: false,
      online: "SLW232000127",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.28,0.36,0.4,0,0.22,0.22]",
          average_trend: 0.8799999952316284,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 69,
          refill_qty: 15,
          current_inventory: 54,
          average_based_on_rec: 6,
          average_consumption: 1.1100000143051147,
          average_data: "[1.22,1.55,1.35,0.92,0.7,0.89]",
          average_trend: 0.800000011920929,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000102604",
          consignment: 46,
          refill_qty: 9,
          current_inventory: 37,
          average_based_on_rec: 6,
          average_consumption: 0.6600000262260437,
          average_data: "[0.83,0.64,1.1,0.15,0.57,0.67]",
          average_trend: 1.0199999809265137,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000112259",
          consignment: 69,
          refill_qty: 21,
          current_inventory: 48,
          average_based_on_rec: 6,
          average_consumption: 1.600000023841858,
          average_data: "[1.33,1.09,3.05,2.92,1.22,0]",
          average_trend: 0,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000114230",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.17,0.18,0.45,0.31,0.13,0.3]",
          average_trend: 1.149999976158142,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.28,0.36,0.25,0.15,0.13,0.3]",
          average_trend: 1.2000000476837158,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 17,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 1.2400000095367432,
          average_data: "[0.78,1.27,1.15,1.85,1,1.41]",
          average_trend: 1.1399999856948853,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 276,
      inv: 202,
    },
    {
      customerNum: "0600190622",
      equipmentNum: "000000000100508626",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T21:27:00.000Z",
      lastInventoryUpdate: "2021-05-13T21:27:00.000Z",
      lastFillRate: 0.4000000059604645,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 72,
      criticalMaterialConsumption: 4.429999828338623,
      criticalMaterialNum: "000000000000112259",
      materialInventory: 215,
      materialConsumption: 8.010000228881836,
      dri: 4,
      pfr: 50,
      pfrc: 63,
      pfu: 108,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-28",
      name: "TULANE-M18-IRBY HOUSE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY09009329",
      location: "IRBY HOUSE 2ND FLOOR GAMEROOM ",
      dexErrors: false,
      online: "SLW232000225",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 18,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 1.3200000524520874,
          average_data: "[0.93,1.46,0.67,1.85,2,1]",
          average_trend: 0.7599999904632568,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102751",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.8500000238418579,
          average_data: "[0.27,0.92,0.6,1.38,1.92,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.8700000047683716,
          average_data: "[0.67,0.46,0.67,0.77,1,1.67]",
          average_trend: 1.9199999570846558,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 72,
          refill_qty: 58,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 4.429999828338623,
          average_data: "[2.93,4.46,2.93,6.62,6,3.67]",
          average_trend: 0.8299999833106995,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000117687",
          consignment: 23,
          refill_qty: 1,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.009999999776482582,
          average_data: "[0,0,0,0,0.08,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000130470",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0,0.08,0,3.08,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000138036",
          consignment: 24,
          refill_qty: 0,
          current_inventory: 24,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 215,
      inv: 107,
    },
    {
      customerNum: "0600190631",
      equipmentNum: "000000000100512632",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T21:20:00.000Z",
      lastInventoryUpdate: "2021-05-17T21:20:00.000Z",
      lastFillRate: 0.12999999523162842,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 46,
      criticalMaterialConsumption: 3.0799999237060547,
      criticalMaterialNum: "000000000000112259",
      materialInventory: 258,
      materialConsumption: 10.680000305175781,
      dri: 6,
      pfr: 42,
      pfrc: 64,
      pfu: 119,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-06-01",
      name: "TULANE-M09-DIBOLL CENTER",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY13000989",
      location: "DIBOLL CEN 1ST FLOOR OUTSIDE BY ",
      dexErrors: false,
      online: "SLW232000291",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 46,
          refill_qty: 11,
          current_inventory: 35,
          average_based_on_rec: 6,
          average_consumption: 1.1200000047683716,
          average_data: "[0.8,1.45,1.62,1.11,0.83,0.92]",
          average_trend: 0.8199999928474426,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102592",
          consignment: 23,
          refill_qty: 11,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 1.2000000476837158,
          average_data: "[0.6,0.91,1.62,1.89,2.17,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 13,
          current_inventory: 33,
          average_based_on_rec: 6,
          average_consumption: 1.409999966621399,
          average_data: "[0.8,1.36,2.08,1.78,1.67,0.77]",
          average_trend: 0.550000011920929,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102604",
          consignment: 46,
          refill_qty: 31,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 2.0199999809265137,
          average_data: "[1.8,1.36,1.46,2.67,3,1.85]",
          average_trend: 0.9200000166893005,
          init_inventory: 34,
          last_inventory: 34,
        },
        {
          material_num: "000000000000112259",
          consignment: 46,
          refill_qty: 28,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 3.0799999237060547,
          average_data: "[1.8,2.55,3.54,4.44,3.67,2.46]",
          average_trend: 0.800000011920929,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 13,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 1.4299999475479126,
          average_data: "[1.2,0.91,1.54,1.33,2.5,1.08]",
          average_trend: 0.7599999904632568,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 5,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.1599999964237213,
          average_data: "[0.1,0.18,0.08,0.11,0,0.46]",
          average_trend: 2.880000114440918,
          init_inventory: 20,
          last_inventory: 20,
        },
        {
          material_num: "000000000000153225",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.3,0.09,0.54,0,0,0.62]",
          average_trend: 2.380000114440918,
          init_inventory: 20,
          last_inventory: 20,
        },
      ],
      cap: 277,
      inv: 158,
    },
    {
      customerNum: "0600190623",
      equipmentNum: "000000000100508633",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T01:38:00.000Z",
      lastInventoryUpdate: "2021-05-11T01:38:00.000Z",
      lastFillRate: 0.2800000011920929,
      nextDeliveryPlan: "2021-05-18",
      nextDeliveryAct: "2021-05-18",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 1.5299999713897705,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 155,
      materialConsumption: 5.510000228881836,
      dri: -7,
      pfr: 54,
      pfrc: 37,
      pfu: 97,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-05-18",
      name: "TULANE-M17-IRBY HOUSE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY09009638",
      location: "IRBY HOUSE 2ND FLOOR GAMEROOM ",
      dexErrors: false,
      online: "SLW232000367",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 23,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 1.4800000190734863,
          average_data: "[2.46,1.47,1,0.73,1.2,2]",
          average_trend: 1.350000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.7599999904632568,
          average_data: "[1.08,0.27,1,0.73,0.8,0.67]",
          average_trend: 0.8799999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.5299999713897705,
          average_data: "[1.38,2.13,1.54,0.67,2.13,1.33]",
          average_trend: 0.8700000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 8,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0.15,0.53,0.69,0.2,1.2,0.42]",
          average_trend: 0.7900000214576721,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000114230",
          consignment: 23,
          refill_qty: 6,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.36000001430511475,
          average_data: "[0.46,0.4,0.15,0.33,0.8,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0.92,0.4,0.46,0.4,0.13,0.17]",
          average_trend: 0.4099999964237213,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119790",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.27,0.08,0.27,0.53,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 14,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.15,0,0.23,0.27,0,0.83]",
          average_trend: 3.319999933242798,
          init_inventory: 13,
          last_inventory: 13,
        },
      ],
      cap: 177,
      inv: 80,
    },
    {
      customerNum: "0600190627",
      equipmentNum: "000000000100516720",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-14T02:21:00.000Z",
      lastInventoryUpdate: "2021-05-14T02:21:00.000Z",
      lastFillRate: 0.18000000715255737,
      nextDeliveryPlan: "2021-07-12",
      nextDeliveryAct: "2021-07-12",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.3499999940395355,
      criticalMaterialNum: "000000000000130470",
      materialInventory: 203,
      materialConsumption: 1.9299999475479126,
      dri: 54,
      pfr: 12,
      pfrc: 62,
      pfu: 25,
      numSalesDays: 12,
      ftc: false,
      sort: "110000002021-07-12",
      name: "TULANE-M46-REILLY CENTER",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY99114424",
      location: "YULMAN STA 1ST FLOOR LAGNIAPPE ",
      dexErrors: false,
      online: "SLW232000353",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MWF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 48,
          refill_qty: 6,
          current_inventory: 42,
          average_based_on_rec: 6,
          average_consumption: 0.4699999988079071,
          average_data: "[0.3,0.13,0.15,0.48,0.84,0.94]",
          average_trend: 2,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102752",
          consignment: 39,
          refill_qty: 3,
          current_inventory: 36,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0,0.06,0,0.38,0.44,0.38]",
          average_trend: 1.809999942779541,
          init_inventory: 39,
          last_inventory: 39,
        },
        {
          material_num: "000000000000112259",
          consignment: 92,
          refill_qty: 11,
          current_inventory: 81,
          average_based_on_rec: 6,
          average_consumption: 0.8999999761581421,
          average_data: "[0.85,0.81,0.96,0.86,1.09,0.81]",
          average_trend: 0.8999999761581421,
          init_inventory: 92,
          last_inventory: 92,
        },
        {
          material_num: "000000000000130470",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.3499999940395355,
          average_data: "[0,0.13,0.74,0.67,0.58,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 203,
      inv: 178,
    },
    {
      customerNum: "0600190644",
      equipmentNum: "000000000100524653",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T20:50:00.000Z",
      lastInventoryUpdate: "2021-05-17T20:50:00.000Z",
      lastFillRate: 0.07999999821186066,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 3.1500000953674316,
      criticalMaterialNum: "000000000000115584",
      materialInventory: 549,
      materialConsumption: 15.430000305175781,
      dri: 6,
      pfr: 25,
      pfrc: 38,
      pfu: 131,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-06-01",
      name: "TULANE-M03-ARON RESIDENCE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY09009591",
      location: "Aron Resid 1st Floor Breezeway ",
      dexErrors: false,
      online: "SLW232000335",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 156,
          refill_qty: 30,
          current_inventory: 126,
          average_based_on_rec: 6,
          average_consumption: 3.2300000190734863,
          average_data: "[4.8,1,0.67,11.07,0,1.85]",
          average_trend: 0.5699999928474426,
          init_inventory: 156,
          last_inventory: 156,
        },
        {
          material_num: "000000000000115584",
          consignment: 48,
          refill_qty: 29,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 3.1500000953674316,
          average_data: "[6.4,0.67,0.67,2.07,8,1.08]",
          average_trend: 0.3400000035762787,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115586",
          consignment: 104,
          refill_qty: 32,
          current_inventory: 72,
          average_based_on_rec: 6,
          average_consumption: 3.509999990463257,
          average_data: "[6.4,0.83,1.33,5.5,4.83,2.15]",
          average_trend: 0.6100000143051147,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000117603",
          consignment: 104,
          refill_qty: 28,
          current_inventory: 76,
          average_based_on_rec: 6,
          average_consumption: 3.009999990463257,
          average_data: "[4.53,0,0.78,3.07,8,1.69]",
          average_trend: 0.5600000023841858,
          init_inventory: 104,
          last_inventory: 104,
        },
        {
          material_num: "000000000000117604",
          consignment: 24,
          refill_qty: 0,
          current_inventory: 37,
          average_based_on_rec: 6,
          average_consumption: 1.2200000286102295,
          average_data: "[2.4,0,0.33,2.79,1.5,0.31]",
          average_trend: 0.25,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000119516",
          consignment: 48,
          refill_qty: 12,
          current_inventory: 36,
          average_based_on_rec: 6,
          average_consumption: 0.44999998807907104,
          average_data: "[0.4,0,1.22,0,0.33,0.77]",
          average_trend: 1.7100000381469727,
          init_inventory: 41,
          last_inventory: 41,
        },
        {
          material_num: "000000000000121750",
          consignment: 36,
          refill_qty: 0,
          current_inventory: 40,
          average_based_on_rec: 6,
          average_consumption: 0.8600000143051147,
          average_data: "[1.6,0.67,0.33,1.57,1,0]",
          average_trend: 0,
          init_inventory: 48,
          last_inventory: 48,
        },
      ],
      cap: 520,
      inv: 406,
    },
    {
      customerNum: "0600190626",
      equipmentNum: "000000000100517582",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T19:05:00.000Z",
      lastInventoryUpdate: "2021-05-12T19:05:00.000Z",
      lastFillRate: 0.20000000298023224,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.6800000071525574,
      criticalMaterialNum: "000000000000114230",
      materialInventory: 84,
      materialConsumption: 2.930000066757202,
      dri: 3,
      pfr: 50,
      pfrc: 59,
      pfu: 42,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-05-28",
      name: "TULANE-M29-MC WILLIAMS",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "TS0002853",
      location: "MCWILLIAMS 1ST FLOOR HALLWAY ",
      dexErrors: false,
      online: "SLW232000171",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.8500000238418579,
          average_data: "[0.54,0.88,0.56,1.67,0.29,1.13]",
          average_trend: 1.3300000429153442,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[0.38,0.63,0.11,0.83,0.43,0.13]",
          average_trend: 0.3100000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 10,
          current_inventory: 2,
          average_based_on_rec: 6,
          average_consumption: 0.6800000071525574,
          average_data: "[0.62,0.75,0.89,1,0.29,0.5]",
          average_trend: 0.7400000095367432,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[0.54,0.88,0.22,0.33,0.29,0.25]",
          average_trend: 0.6000000238418579,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.5600000023841858,
          average_data: "[0.31,0.75,0.78,0.67,0.71,0.13]",
          average_trend: 0.23000000417232513,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 84,
      inv: 42,
    },
    {
      customerNum: "0600190633",
      equipmentNum: "000000000100598951",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T18:55:00.000Z",
      lastInventoryUpdate: "2021-05-13T18:55:00.000Z",
      lastFillRate: 0.2800000011920929,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3700000047683716,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 215,
      materialConsumption: 2.130000114440918,
      dri: 17,
      pfr: 20,
      pfrc: 33,
      pfu: 46,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-06-11",
      name: "TULANE-M10-GIBSON HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN16004090",
      location: "GIBSON HAL 1ST FLOOR VEND AREA ",
      dexErrors: false,
      online: "SLW232000191",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0.11,0,0.11,0.13,0,0.09]",
          average_trend: 1.2899999618530273,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102592",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.019999999552965164,
          average_data: "[0,0,0,0.06,0,0.04]",
          average_trend: 2,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.17,0.1,0.47,0.25,0.32,0.26]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 17,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.3700000047683716,
          average_data: "[0.03,0,0.32,0.38,0.42,1.04]",
          average_trend: 2.809999942779541,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.09000000357627869,
          average_data: "[0,0,0.08,0.13,0.21,0.09]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 5,
          current_inventory: 43,
          average_based_on_rec: 6,
          average_consumption: 0.3199999928474426,
          average_data: "[0.09,0.25,1.18,0.19,0.05,0.13]",
          average_trend: 0.4099999964237213,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0.06,0.2,0.13,0.13,0.05,0.22]",
          average_trend: 1.690000057220459,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.029999999329447746,
          average_data: "[0.03,0,0.11,0,0,0.04]",
          average_trend: 1.3300000429153442,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.1,0.11,0.13,0.05,0.04]",
          average_trend: 0.5699999928474426,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0.03,0.05,0.08,0,0.05,0.22]",
          average_trend: 3.140000104904175,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000125522",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0.06,0.4,0.21,0.44,0.47,0.48]",
          average_trend: 1.409999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.11,0.45,0.63,0,0.11,0.48]",
          average_trend: 1.600000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.05999999865889549,
          average_data: "[0.11,0.05,0.08,0.06,0,0.04]",
          average_trend: 0.6700000166893005,
          init_inventory: 11,
          last_inventory: 11,
        },
      ],
      cap: 228,
      inv: 182,
    },
    {
      customerNum: "0600190637",
      equipmentNum: "000000000100516597",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T20:02:00.000Z",
      lastInventoryUpdate: "2021-05-17T20:02:00.000Z",
      lastFillRate: 0.18000000715255737,
      nextDeliveryPlan: "2021-05-27",
      nextDeliveryAct: "2021-05-27",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 2.259999990463257,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 276,
      materialConsumption: 12.539999961853027,
      dri: 1,
      pfr: 42,
      pfrc: 45,
      pfu: 117,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-05-27",
      name: "TULANE-M08-BUTLER",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12015253",
      location: "BUTLER 1ST FLOOR BACK HALL ",
      dexErrors: false,
      online: "SLW232000324",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 21,
          current_inventory: 2,
          average_based_on_rec: 6,
          average_consumption: 2.259999990463257,
          average_data: "[2.56,2,2.63,2.29,3.14,0.92]",
          average_trend: 0.4099999964237213,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 21,
          current_inventory: 25,
          average_based_on_rec: 6,
          average_consumption: 2.25,
          average_data: "[2.89,2.93,1.88,2.29,2.29,1.23]",
          average_trend: 0.550000011920929,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102604",
          consignment: 46,
          refill_qty: 23,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 2.4700000286102295,
          average_data: "[2.33,2.67,1.88,2.71,4,1.23]",
          average_trend: 0.5,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 1.25,
          average_data: "[1.33,1.2,1.38,1.43,1.71,0.46]",
          average_trend: 0.3700000047683716,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000112259",
          consignment: 92,
          refill_qty: 22,
          current_inventory: 70,
          average_based_on_rec: 6,
          average_consumption: 2.359999895095825,
          average_data: "[1.78,1.73,1.75,3.14,3.43,2.31]",
          average_trend: 0.9800000190734863,
          init_inventory: 92,
          last_inventory: 92,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 15,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 1.6200000047683716,
          average_data: "[2.56,1.6,1.88,1.57,1,1.08]",
          average_trend: 0.6700000166893005,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000151978",
          consignment: 23,
          refill_qty: 3,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0,1.07,0,0.29,0,0.62]",
          average_trend: 1.8799999952316284,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 276,
      inv: 159,
    },
    {
      customerNum: "0600190625",
      equipmentNum: "000000000100512611",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-07T00:41:00.000Z",
      lastInventoryUpdate: "2021-05-07T00:41:00.000Z",
      lastFillRate: 0.3400000035762787,
      nextDeliveryPlan: "2021-06-09",
      nextDeliveryAct: "2021-06-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.6899999976158142,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 188,
      materialConsumption: 3.7899999618530273,
      dri: 15,
      pfr: 40,
      pfrc: 68,
      pfu: 77,
      numSalesDays: 19,
      ftc: false,
      sort: "110000002021-06-09",
      name: "TULANE-M61-WILLOW BLDG",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12011638",
      location: "WILLOW HAL 1ST FLOOR VEND AREA ",
      dexErrors: false,
      online: "SLW232000361",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.6899999976158142,
          average_data: "[0.58,0.3,0.9,0.74,0.78,0.83]",
          average_trend: 1.2000000476837158,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0.1,0,0,0,0.21]",
          average_trend: 4.199999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.3700000047683716,
          average_data: "[0,0.2,0.29,0.44,0.33,0.97]",
          average_trend: 2.619999885559082,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 69,
          refill_qty: 30,
          current_inventory: 39,
          average_based_on_rec: 6,
          average_consumption: 1.5299999713897705,
          average_data: "[2.58,1.2,1.33,1.26,2,0.83]",
          average_trend: 0.5400000214576721,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 12,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[0.33,0.3,0.57,0.81,0.89,0.9]",
          average_trend: 1.4299999475479126,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.009999999776482582,
          average_data: "[0.08,0,0,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0.08,0.4,0.33,0.37,0.44,0.34]",
          average_trend: 1.0299999713897705,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000153225",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.18000000715255737,
          average_data: "[0.08,0.3,0.19,0.15,0,0.34]",
          average_trend: 1.8899999856948853,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 188,
      inv: 111,
    },
    {
      customerNum: "0600190645",
      equipmentNum: "000000000100057606",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T17:44:00.000Z",
      lastInventoryUpdate: "2021-05-12T17:44:00.000Z",
      lastFillRate: 0.23999999463558197,
      nextDeliveryPlan: "2021-05-31",
      nextDeliveryAct: "2021-05-31",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.5899999737739563,
      criticalMaterialNum: "000000000000156118",
      materialInventory: 280,
      materialConsumption: 6.369999885559082,
      dri: 5,
      pfr: 35,
      pfrc: 43,
      pfu: 99,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-05-31",
      name: "TULANE-M36-NEWCOMB HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN13002920",
      location: "NEWCOMB HA basement HALLWAY ",
      dexErrors: false,
      online: "SLW232000373",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0.62,0.44,0.09,0.62,0.2,0.38]",
          average_trend: 0.9700000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.5400000214576721,
          average_data: "[0.77,0.44,0.27,0.62,0.7,0.46]",
          average_trend: 0.8500000238418579,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 16,
          refill_qty: 9,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.31,0.56,0.18,0.21,0.4,0.15]",
          average_trend: 0.5,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102751",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0.31,0,0,0.34,1,0.38]",
          average_trend: 1.1200000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.18000000715255737,
          average_data: "[0.31,0.11,0.27,0.07,0.3,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 9,
          current_inventory: 39,
          average_based_on_rec: 6,
          average_consumption: 0.6200000047683716,
          average_data: "[0.92,1,0.27,0.48,0.8,0.23]",
          average_trend: 0.3700000047683716,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117635",
          consignment: 16,
          refill_qty: 0,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.4399999976158142,
          average_data: "[0.62,0.33,0.36,0.69,0.4,0.23]",
          average_trend: 0.5199999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.44999998807907104,
          average_data: "[0.46,0.22,0.73,0.34,0.7,0.23]",
          average_trend: 0.5099999904632568,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000125522",
          consignment: 16,
          refill_qty: 8,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.5400000214576721,
          average_data: "[1.54,0,0.27,0.55,0.5,0.38]",
          average_trend: 0.699999988079071,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.7699999809265137,
          average_data: "[0.77,0.78,0.36,0.62,1.4,0.69]",
          average_trend: 0.8999999761581421,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.4000000059604645,
          average_data: "[0.31,0.56,0.36,0.55,0.3,0.31]",
          average_trend: 0.7799999713897705,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156111",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.8100000023841858,
          average_data: "[1.69,1,0.55,0.41,0.5,0.69]",
          average_trend: 0.8500000238418579,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156118",
          consignment: 12,
          refill_qty: 9,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.5899999737739563,
          average_data: "[1.23,0.22,0.36,0.21,0.8,0.69]",
          average_trend: 1.1699999570846558,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156265",
          consignment: 6,
          refill_qty: 6,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
      ],
      cap: 282,
      inv: 184,
    },
    {
      customerNum: "0600190646",
      equipmentNum: "000000000100528694",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T23:14:00.000Z",
      lastInventoryUpdate: "2021-05-06T23:14:00.000Z",
      lastFillRate: 0.23999999463558197,
      nextDeliveryPlan: "2021-05-21",
      nextDeliveryAct: "2021-05-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 2.880000114440918,
      criticalMaterialNum: "000000000000115586",
      materialInventory: 360,
      materialConsumption: 14.149999618530273,
      dri: -3,
      pfr: 76,
      pfrc: 66,
      pfu: 277,
      numSalesDays: 20,
      ftc: false,
      sort: "110000002021-05-21",
      name: "TULANE-M64-WILLOW BLDG",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY11022700",
      location: "WOLDENBERG 1ST FLOOR BACK HALL ",
      dexErrors: false,
      online: "SLW232000413",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 72,
          refill_qty: 57,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 2.8299999237060547,
          average_data: "[3.29,2.67,2.46,3.33,3.23,2]",
          average_trend: 0.7099999785423279,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000115584",
          consignment: 48,
          refill_qty: 20,
          current_inventory: 28,
          average_based_on_rec: 6,
          average_consumption: 1,
          average_data: "[1.29,0.8,0.92,1.11,1.23,0.67]",
          average_trend: 0.6700000166893005,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 48,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 2.880000114440918,
          average_data: "[2.21,2.27,3.85,2.78,3.23,2.93]",
          average_trend: 1.0199999809265137,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000116151",
          consignment: 48,
          refill_qty: 45,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 2.2100000381469727,
          average_data: "[2.29,1.73,2,3.11,2,2.13]",
          average_trend: 0.9599999785423279,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 33,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.6200000047683716,
          average_data: "[1.36,1.47,1.23,1.33,2.62,1.73]",
          average_trend: 1.0700000524520874,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117604",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0.57,0.53,0.92,0.56,0.62,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119516",
          consignment: 48,
          refill_qty: 44,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 2.1500000953674316,
          average_data: "[1.64,3.33,3.08,1.67,1.85,1.33]",
          average_trend: 0.6200000047683716,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 19,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.9300000071525574,
          average_data: "[0.5,1.47,1.23,0.78,0.92,0.67]",
          average_trend: 0.7200000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 360,
      inv: 83,
    },
    {
      customerNum: "0600190628",
      equipmentNum: "000000000100287410",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T23:49:00.000Z",
      lastInventoryUpdate: "2021-05-13T23:49:00.000Z",
      lastFillRate: 0.6399999856948853,
      nextDeliveryPlan: "2021-05-21",
      nextDeliveryAct: "2021-05-21",
      schedulingMethod: "D",
      criticalMaterialInventory: 46,
      criticalMaterialConsumption: 4.760000228881836,
      criticalMaterialNum: "000000000000112259",
      materialInventory: 166,
      materialConsumption: 10.3100004196167,
      dri: -3,
      pfr: 72,
      pfrc: 62,
      pfu: 121,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-21",
      name: "TULANE-M39-PHELPS HOUSE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "01V72918",
      location: "2nd floor    ",
      dexErrors: true,
      online: "SLW232000153",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.7799999713897705,
          average_data: "[1.6,2.46,1.43,1.63,1.14,2.4]",
          average_trend: 1.350000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.8999999761581421,
          average_data: "[1.33,0.31,1,0.88,1.29,0.6]",
          average_trend: 0.6700000166893005,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 0.8899999856948853,
          average_data: "[0.4,1.38,1.71,0.5,0.57,0.8]",
          average_trend: 0.8999999761581421,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0,0.62,0.86,0.5,0.57,0.6]",
          average_trend: 1.1299999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 46,
          refill_qty: 46,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 4.760000228881836,
          average_data: "[2.67,7.08,2.14,5.5,6.57,4.6]",
          average_trend: 0.9700000286102295,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.5,
          average_data: "[0.13,0.77,0.29,0.13,0.86,0.8]",
          average_trend: 1.600000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119790",
          consignment: 12,
          refill_qty: 10,
          current_inventory: 2,
          average_based_on_rec: 6,
          average_consumption: 0.7200000286102295,
          average_data: "[1.33,0.77,0.86,0.13,0.43,0.8]",
          average_trend: 1.1100000143051147,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0.4,0.46,0,0.38,0.14,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 166,
      inv: 45,
    },
    {
      customerNum: "0600190647",
      equipmentNum: "000000000100527710",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T18:08:00.000Z",
      lastInventoryUpdate: "2021-05-13T18:08:00.000Z",
      lastFillRate: 0.23999999463558197,
      nextDeliveryPlan: "2021-06-03",
      nextDeliveryAct: "2021-06-03",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.100000023841858,
      criticalMaterialNum: "000000000000101728",
      materialInventory: 336,
      materialConsumption: 7.139999866485596,
      dri: 8,
      pfr: 29,
      pfrc: 44,
      pfu: 98,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-06-03",
      name: "TULANE-M49-ICHARDSON MEMORIAL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN11003805",
      location: "RICHARDSON 1ST FLOOR LOBBY ",
      dexErrors: false,
      online: "SLW232000175",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 15,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 1.100000023841858,
          average_data: "[0.55,0.95,0,4,0.48,0.6]",
          average_trend: 0.550000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.4399999976158142,
          average_data: "[0.36,0.32,0.36,1.33,0.28,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.2199999988079071,
          average_data: "[0,0.37,0,0.33,0.62,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000129254",
          consignment: 48,
          refill_qty: 27,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 2.0399999618530273,
          average_data: "[1.18,2,2.27,1.5,3.17,2.1]",
          average_trend: 1.0299999713897705,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000133129",
          consignment: 48,
          refill_qty: 8,
          current_inventory: 40,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0.55,0.63,0.27,0,0.62,1.4]",
          average_trend: 2.4100000858306885,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000133131",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.03999999910593033,
          average_data: "[0,0.21,0,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000138036",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.49000000953674316,
          average_data: "[0.27,0.58,0.36,0.33,0.41,1]",
          average_trend: 2.0399999618530273,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.27,0.53,0.36,0,0.62,0.5]",
          average_trend: 1.3200000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152196",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0,0.21,0.55,0,0,0.9]",
          average_trend: 3.2100000381469727,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156083",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0,0.16,0,1.17,0.28,0.1]",
          average_trend: 0.3400000035762787,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156084",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.5099999904632568,
          average_data: "[1.09,0.32,0.18,0.83,0.55,0.1]",
          average_trend: 0.20000000298023224,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156090",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0,0.63,1.36,0,0.34,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156530",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.36,0.26,0.36,0.5,0.48,0.3]",
          average_trend: 0.7900000214576721,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 336,
      inv: 238,
    },
    {
      customerNum: "0600190636",
      equipmentNum: "000000000100524796",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T19:50:00.000Z",
      lastInventoryUpdate: "2021-05-13T19:50:00.000Z",
      lastFillRate: 0.25,
      nextDeliveryPlan: "2021-07-08",
      nextDeliveryAct: "2021-07-08",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 0.8500000238418579,
      criticalMaterialNum: "000000000000117603",
      materialInventory: 329,
      materialConsumption: 4.239999771118164,
      dri: 43,
      pfr: 19,
      pfrc: 72,
      pfu: 66,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-07-08",
      name: "TULANE-M56-STANLEY THOMAS",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12011658",
      location: "STANLEY TH 1ST FLOOR VEND AREA ",
      dexErrors: false,
      online: "SLW232000131",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 48,
          refill_qty: 9,
          current_inventory: 39,
          average_based_on_rec: 6,
          average_consumption: 0.6700000166893005,
          average_data: "[0.3,0.77,1.39,0,0.69,0.84]",
          average_trend: 1.25,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115584",
          consignment: 48,
          refill_qty: 10,
          current_inventory: 38,
          average_based_on_rec: 6,
          average_consumption: 0.7300000190734863,
          average_data: "[0.9,0.77,0.87,0.83,0.28,0.74]",
          average_trend: 1.0099999904632568,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 3,
          current_inventory: 45,
          average_based_on_rec: 6,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.77,0,0,0.21,0]",
          average_trend: 0,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000116149",
          consignment: 48,
          refill_qty: 11,
          current_inventory: 37,
          average_based_on_rec: 6,
          average_consumption: 0.8100000023841858,
          average_data: "[0.9,0.32,1.17,1,0.55,0.93]",
          average_trend: 1.149999976158142,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 12,
          current_inventory: 36,
          average_based_on_rec: 6,
          average_consumption: 0.8500000238418579,
          average_data: "[0.6,1.03,0.96,1,0.83,0.65]",
          average_trend: 0.7599999904632568,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117604",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0.3,0.23,0.35,0,0.48,0.37]",
          average_trend: 1.2799999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119516",
          consignment: 48,
          refill_qty: 12,
          current_inventory: 36,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0,0,1.39,0,0.55,0.33]",
          average_trend: 0.8700000047683716,
          init_inventory: 41,
          last_inventory: 41,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.3499999940395355,
          average_data: "[0,0.52,0.22,0.67,0.34,0.33]",
          average_trend: 0.9399999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 336,
      inv: 270,
    },
    {
      customerNum: "0600190642",
      equipmentNum: "000000000100520851",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-04T22:18:00.000Z",
      lastInventoryUpdate: "2021-05-04T22:18:00.000Z",
      lastFillRate: 0.14000000059604645,
      nextDeliveryPlan: "2021-06-02",
      nextDeliveryAct: "2021-06-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.8199999928474426,
      criticalMaterialNum: "000000000000145105",
      materialInventory: 248,
      materialConsumption: 2.5899999141693115,
      dri: 7,
      pfr: 44,
      pfrc: 30,
      pfu: 110,
      numSalesDays: 22,
      ftc: false,
      sort: "110000002021-06-02",
      name: "TULANE-M02-ARON RESIDENCE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY99055616",
      location: "Anon Resid 1st Floor Breezeway ",
      dexErrors: false,
      online: "SLW232000325",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000133129",
          consignment: 96,
          refill_qty: 11,
          current_inventory: 85,
          average_based_on_rec: 6,
          average_consumption: 0.5,
          average_data: "[0.06,0.11,0.65,0.89,0.78,0.5]",
          average_trend: 1,
          init_inventory: 96,
          last_inventory: 96,
        },
        {
          material_num: "000000000000133131",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000134926",
          consignment: 48,
          refill_qty: 13,
          current_inventory: 35,
          average_based_on_rec: 6,
          average_consumption: 0.550000011920929,
          average_data: "[0.06,0.28,0.44,0.52,1.33,0.64]",
          average_trend: 1.159999966621399,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000138036",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.10999999940395355,
          average_data: "[0,0,0.25,0.19,0,0.21]",
          average_trend: 1.909999966621399,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 19,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.8199999928474426,
          average_data: "[0.12,0.39,0.73,0.89,2,0.79]",
          average_trend: 0.9599999785423279,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000153225",
          consignment: 32,
          refill_qty: 32,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
      ],
      cap: 248,
      inv: 138,
    },
    {
      customerNum: "0600190643",
      equipmentNum: "000000000100512481",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T20:27:00.000Z",
      lastInventoryUpdate: "2021-05-17T20:27:00.000Z",
      lastFillRate: 0.3700000047683716,
      nextDeliveryPlan: "2021-05-27",
      nextDeliveryAct: "2021-05-27",
      schedulingMethod: "D",
      criticalMaterialInventory: 92,
      criticalMaterialConsumption: 9.649999618530273,
      criticalMaterialNum: "000000000000112259",
      materialInventory: 276,
      materialConsumption: 19.06999969482422,
      dri: 1,
      pfr: 62,
      pfrc: 69,
      pfu: 173,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-05-27",
      name: "TULANE-M01-ARON RESIDENCE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY09009331",
      location: "Anon Resid 1st Floor Breezeway ",
      dexErrors: false,
      online: "SLW232000298",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "W",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 46,
          refill_qty: 38,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 4.21999979019165,
          average_data: "[5.11,1,5.47,3.14,7.67,2.92]",
          average_trend: 0.6899999976158142,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102751",
          consignment: 46,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 2.569999933242798,
          average_data: "[3.78,1.22,3.07,2.14,3.67,1.54]",
          average_trend: 0.6000000238418579,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102752",
          consignment: 46,
          refill_qty: 15,
          current_inventory: 31,
          average_based_on_rec: 6,
          average_consumption: 1.6399999856948853,
          average_data: "[2.78,0.67,2,1.29,2.17,0.92]",
          average_trend: 0.5600000023841858,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000112259",
          consignment: 92,
          refill_qty: 87,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 9.649999618530273,
          average_data: "[8.44,2.33,12.27,13.14,12.33,9.38]",
          average_trend: 0.9700000286102295,
          init_inventory: 92,
          last_inventory: 92,
        },
        {
          material_num: "000000000000117687",
          consignment: 46,
          refill_qty: 9,
          current_inventory: 37,
          average_based_on_rec: 6,
          average_consumption: 0.9900000095367432,
          average_data: "[0.89,0.56,1.07,1.29,1.33,0.77]",
          average_trend: 0.7799999713897705,
          init_inventory: 46,
          last_inventory: 46,
        },
      ],
      cap: 276,
      inv: 103,
    },
    {
      customerNum: "0600188961",
      equipmentNum: "000000000100520390",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-06T18:31:00.000Z",
      lastInventoryUpdate: "2021-05-06T18:31:00.000Z",
      lastFillRate: 0.28999999165534973,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3700000047683716,
      criticalMaterialNum: "000000000000101891",
      materialInventory: 96,
      materialConsumption: 2.2899999618530273,
      dri: 10,
      pfr: 60,
      pfrc: 77,
      pfu: 72,
      numSalesDays: 20,
      ftc: false,
      sort: "110000002021-06-04",
      name: "XAVIER UNIVERSITY LLC-M10",
      street: "7325 PALMETTO ST",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY02036085",
      location: "I/S 4TH FL  Z ",
      dexErrors: false,
      online: "SLW232000164",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 20,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.3700000047683716,
          average_data: "[0.05,0.17,0.52,0.77,0.33]",
          average_trend: 0.8899999856948853,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 20,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.3700000047683716,
          average_data: "[0,0.11,0.43,0.77,0.56]",
          average_trend: 1.5099999904632568,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102660",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 5,
          average_consumption: 0.23999999463558197,
          average_data: "[0.05,0.17,0.26,0.62,0.11]",
          average_trend: 0.46000000834465027,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 5,
          average_consumption: 0.3499999940395355,
          average_data: "[0.03,0.06,0.7,0.62,0.33]",
          average_trend: 0.9399999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114230",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 5,
          average_consumption: 0.23999999463558197,
          average_data: "[0,0.22,0.43,0.23,0.33]",
          average_trend: 1.3799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 8,
          current_inventory: 4,
          average_based_on_rec: 5,
          average_consumption: 0.3700000047683716,
          average_data: "[0,0.11,0.48,0.38,0.89]",
          average_trend: 2.4100000858306885,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 5,
          average_consumption: 0.3499999940395355,
          average_data: "[0.05,0.39,0.52,0.23,0.56]",
          average_trend: 1.600000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 120,
      inv: 48,
    },
    {
      customerNum: "0600189149",
      equipmentNum: "000000000100508378",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T22:07:00.000Z",
      lastInventoryUpdate: "2021-05-11T22:07:00.000Z",
      lastFillRate: 0.10000000149011612,
      nextDeliveryPlan: "2021-07-09",
      nextDeliveryAct: "2021-07-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 18,
      criticalMaterialConsumption: 0.1599999964237213,
      criticalMaterialNum: "000000000000119516",
      materialInventory: 270,
      materialConsumption: 0.9900000095367432,
      dri: 92,
      pfr: 8,
      pfrc: 40,
      pfu: 24,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-07-09",
      name: "XAVIER UNIVERSITY KD-M2-5TH FLOOR",
      street: "7235 DREXEL DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY02017034",
      location: "I/S 5TH FL 5th 2Z ",
      dexErrors: false,
      online: "SLW232000354",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 72,
          refill_qty: 0,
          current_inventory: 72,
          average_based_on_rec: 4,
          average_consumption: 0,
          average_data: "[0,0,0,0]",
          average_trend: 1,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000115584",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 12,
          average_based_on_rec: 4,
          average_consumption: 0,
          average_data: "[0,0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 7,
          current_inventory: 41,
          average_based_on_rec: 4,
          average_consumption: 0.41999998688697815,
          average_data: "[0.06,0.28,0.7,0.64]",
          average_trend: 1.5199999809265137,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000116149",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 4,
          average_consumption: 0.09000000357627869,
          average_data: "[0.18,0,0.17,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000116151",
          consignment: 48,
          refill_qty: 4,
          current_inventory: 44,
          average_based_on_rec: 4,
          average_consumption: 0.23000000417232513,
          average_data: "[0.35,0,0.35,0.21]",
          average_trend: 0.9100000262260437,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 2,
          current_inventory: 46,
          average_based_on_rec: 4,
          average_consumption: 0.09000000357627869,
          average_data: "[0,0,0.23,0.14]",
          average_trend: 1.559999942779541,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000119516",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 4,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0,0.43,0.21]",
          average_trend: 1.309999942779541,
          init_inventory: 18,
          last_inventory: 18,
        },
      ],
      cap: 276,
      inv: 252,
    },
    {
      customerNum: "0600189338",
      equipmentNum: "000000000100527746",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T23:53:00.000Z",
      lastInventoryUpdate: "2021-05-11T23:53:00.000Z",
      lastFillRate: 0.6299999952316284,
      nextDeliveryPlan: "2021-07-09",
      nextDeliveryAct: "2021-07-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.10000000149011612,
      criticalMaterialNum: "000000000000135335",
      materialInventory: 356,
      materialConsumption: 1.7300000190734863,
      dri: 95,
      pfr: 8,
      pfrc: 53,
      pfu: 29,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-07-09",
      name: "XAVIER UNIVERSITY-M1",
      street: "801 S NORMAN C FRANCIS",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN15003826",
      location: "I/S STUDEN 1 CTR MULTI ",
      dexErrors: false,
      online: "SLW232000209",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 21,
          average_based_on_rec: 2,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.38]",
          average_trend: 2,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102751",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 21,
          average_based_on_rec: 2,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.38]",
          average_trend: 2,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 2,
          average_consumption: 0.09000000357627869,
          average_data: "[0,0.17]",
          average_trend: 1.8899999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 144,
          refill_qty: 144,
          current_inventory: 139,
          average_based_on_rec: 2,
          average_consumption: 0.33000001311302185,
          average_data: "[0.05,0.6]",
          average_trend: 1.8200000524520874,
          init_inventory: 144,
          last_inventory: 144,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 2,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0.25]",
          average_trend: 1.9199999570846558,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000123292",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 2,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0.16]",
          average_trend: 2,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000129254",
          consignment: 72,
          refill_qty: 72,
          current_inventory: 63,
          average_based_on_rec: 2,
          average_consumption: 0.5699999928474426,
          average_data: "[0,1.13]",
          average_trend: 1.9800000190734863,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 10,
          average_based_on_rec: 2,
          average_consumption: 0.10000000149011612,
          average_data: "[0,0.19]",
          average_trend: 1.899999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000138036",
          consignment: 8,
          refill_qty: 8,
          current_inventory: 7,
          average_based_on_rec: 2,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0.1]",
          average_trend: 2,
          init_inventory: 8,
          last_inventory: 8,
        },
      ],
      cap: 356,
      inv: 327,
    },
    {
      customerNum: "0600190653",
      equipmentNum: "000000000100528584",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T19:18:00.000Z",
      lastInventoryUpdate: "2021-05-17T19:18:00.000Z",
      lastFillRate: 0.25,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 2.059999942779541,
      criticalMaterialNum: "000000000000115584",
      materialInventory: 144,
      materialConsumption: 5.420000076293945,
      dri: 3,
      pfr: 36,
      pfrc: 45,
      pfu: 52,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-05-28",
      name: "TULANE LAW SCHOOL-M21",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY09009588",
      location: "TULANE LAW 1ST FLOOR LOUNGE ",
      dexErrors: false,
      online: "SLW232000190",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 1.4600000381469727,
          average_data: "[0.83,0.97,2,0.93,2.18,1.85]",
          average_trend: 1.2699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 19,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 2.059999942779541,
          average_data: "[2,1.66,2.67,2,2.18,1.85]",
          average_trend: 0.8999999761581421,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115586",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.8100000023841858,
          average_data: "[0.25,1.66,0,0.53,1.64,0.77]",
          average_trend: 0.949999988079071,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.75,
          average_data: "[0.08,1.59,0,0.4,1.64,0.77]",
          average_trend: 1.0299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119791",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 0,
          current_inventory: 24,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410384",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.3400000035762787,
          average_data: "[0,0.48,1,0.27,0,0.31]",
          average_trend: 0.9100000262260437,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 144,
      inv: 92,
    },
    {
      customerNum: "0600190656",
      equipmentNum: "000000000100519748",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T19:18:00.000Z",
      lastInventoryUpdate: "2021-05-17T19:18:00.000Z",
      lastFillRate: 0.17000000178813934,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.6200000047683716,
      criticalMaterialNum: "000000000000152921",
      materialInventory: 336,
      materialConsumption: 5.139999866485596,
      dri: 9,
      pfr: 21,
      pfrc: 27,
      pfu: 72,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-06-04",
      name: "TULANE LAW SCHOOL-M24",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN10005599",
      location: "TULANE LAW 1ST FLOOR LOUNGE ",
      dexErrors: true,
      online: "SLW232000208",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0.3,0.3,0.11,0.07,0.49,0.46]",
          average_trend: 1.590000033378601,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102751",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[0.3,0.7,0.26,0.34,0.59,0.31]",
          average_trend: 0.7400000095367432,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 72,
          refill_qty: 9,
          current_inventory: 63,
          average_based_on_rec: 6,
          average_consumption: 0.949999988079071,
          average_data: "[1.11,0.3,0.84,0.76,1.46,1.23]",
          average_trend: 1.2899999618530273,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0.44,0.3,0.21,0.14,0.29,0.15]",
          average_trend: 0.5799999833106995,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.8399999737739563,
          average_data: "[1.41,1.1,0.53,0.83,1.17,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.3,0.5,0.21,0.21,0.29,0.31]",
          average_trend: 1.0299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000138036",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 0,
          average_consumption: 0,
          average_data: "",
          average_trend: 0,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000152921",
          consignment: 12,
          refill_qty: 6,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.6200000047683716,
          average_data: "[0.89,1.2,0.63,0.83,0,0.15]",
          average_trend: 0.23999999463558197,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000154898",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0.3,0.05,0.14,0.1,0.31]",
          average_trend: 2.069999933242798,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154900",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.3,0.5,0.32,0,0.34,0.31]",
          average_trend: 1.0299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154914",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.05999999865889549,
          average_data: "[0,0.1,0.11,0.07,0.05,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156112",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.25999999046325684,
          average_data: "[0,0.4,0,0.41,0.59,0.15]",
          average_trend: 0.5799999833106995,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156188",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0,0.9,0.11,0.28,0.29,0.15]",
          average_trend: 0.5199999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 336,
      inv: 264,
    },
    {
      customerNum: "0600190655",
      equipmentNum: "000000000100507775",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T19:19:00.000Z",
      lastInventoryUpdate: "2021-05-17T19:19:00.000Z",
      lastFillRate: 0.25999999046325684,
      nextDeliveryPlan: "2021-06-09",
      nextDeliveryAct: "2021-06-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 17,
      criticalMaterialConsumption: 0.699999988079071,
      criticalMaterialNum: "000000000000157319",
      materialInventory: 305,
      materialConsumption: 5.889999866485596,
      dri: 14,
      pfr: 18,
      pfrc: 45,
      pfu: 59,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-06-09",
      name: "TULANE LAW SCHOOL-M22",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN13004121",
      location: "TULANE LAW 1ST FLOOR LOUNGE ",
      dexErrors: false,
      online: "SLW232000238",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 144,
          refill_qty: 20,
          current_inventory: 124,
          average_based_on_rec: 6,
          average_consumption: 2.2100000381469727,
          average_data: "[0.86,2.13,1.32,1.86,3.71,3.38]",
          average_trend: 1.5299999713897705,
          init_inventory: 144,
          last_inventory: 144,
        },
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0,0.38,0.32,0.48,0.49,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.3499999940395355,
          average_data: "[0,0.42,0.37,0.69,0.59,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 24,
          average_based_on_rec: 1,
          average_consumption: 0,
          average_data: "[0]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156563",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0,0.29,0.21,0.48,0.59,0.15]",
          average_trend: 0.5199999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157135",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.20000000298023224,
          average_data: "[0,0,0.11,0.48,0.59,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157253",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.20999999344348907,
          average_data: "[0.21,0.21,0,0.07,0.59,0.15]",
          average_trend: 0.7099999785423279,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157319",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.699999988079071,
          average_data: "[0.14,0.63,0.26,1.17,0.93,1.08]",
          average_trend: 1.5399999618530273,
          init_inventory: 17,
          last_inventory: 17,
        },
        {
          material_num: "000000000000157347",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.6600000262260437,
          average_data: "[0,0.13,0.47,0.34,1.17,1.85]",
          average_trend: 2.799999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157527",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.5199999809265137,
          average_data: "[0,0.46,0.21,0.34,1.17,0.92]",
          average_trend: 1.7699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 312,
      inv: 253,
    },
    {
      customerNum: "0600190654",
      equipmentNum: "000000000100532184",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-17T19:21:00.000Z",
      lastInventoryUpdate: "2021-05-17T19:21:00.000Z",
      lastFillRate: 0.009999999776482582,
      nextDeliveryPlan: "2021-06-02",
      nextDeliveryAct: "2021-06-02",
      schedulingMethod: "D",
      criticalMaterialInventory: 21,
      criticalMaterialConsumption: 1.3200000524520874,
      criticalMaterialNum: "000000000000125522",
      materialInventory: 207,
      materialConsumption: 6.71999979019165,
      dri: 7,
      pfr: 35,
      pfrc: 55,
      pfu: 78,
      numSalesDays: 9,
      ftc: false,
      sort: "110000002021-06-02",
      name: "TULANE LAW SCHOOL-M23",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY09009602",
      location: "TULANE LAW 1ST FLOOR LOUNGE ",
      dexErrors: false,
      online: "SLW232000243",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 12,
          current_inventory: 34,
          average_based_on_rec: 6,
          average_consumption: 0.7300000190734863,
          average_data: "[0,1,0.97,0.64,1,0.77]",
          average_trend: 1.0499999523162842,
          init_inventory: 41,
          last_inventory: 41,
        },
        {
          material_num: "000000000000102604",
          consignment: 48,
          refill_qty: 27,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 2.2899999618530273,
          average_data: "[1.08,2,1.66,3.91,4.18,0.92]",
          average_trend: 0.4000000059604645,
          init_inventory: 42,
          last_inventory: 42,
        },
        {
          material_num: "000000000000102660",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.3499999940395355,
          average_data: "[0.46,0.83,0.07,0.55,0.18,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 46,
          refill_qty: 6,
          current_inventory: 40,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[0,0.67,0.62,0.82,1.36,0.31]",
          average_trend: 0.49000000953674316,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 7,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.6499999761581421,
          average_data: "[0.62,0.58,0.55,1.09,0.91,0.15]",
          average_trend: 0.23000000417232513,
          init_inventory: 22,
          last_inventory: 22,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 7,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.75,
          average_data: "[0.77,0.5,0.41,1.64,1.18,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000125522",
          consignment: 24,
          refill_qty: 15,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 1.3200000524520874,
          average_data: "[0.92,1.08,1.1,2.18,2.18,0.46]",
          average_trend: 0.3499999940395355,
          init_inventory: 21,
          last_inventory: 21,
        },
      ],
      cap: 222,
      inv: 144,
    },
    {
      customerNum: "0600190632",
      equipmentNum: "000000000100538265",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-14T00:32:00.000Z",
      lastInventoryUpdate: "2021-05-14T00:32:00.000Z",
      lastFillRate: 0.5600000023841858,
      nextDeliveryPlan: "2021-05-24",
      nextDeliveryAct: "2021-05-24",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 2.109999895095825,
      criticalMaterialNum: "000000000000116151",
      materialInventory: 177,
      materialConsumption: 6.71999979019165,
      dri: -1,
      pfr: 48,
      pfrc: 42,
      pfu: 87,
      numSalesDays: 12,
      ftc: false,
      sort: "110000002021-05-24",
      name: "TULANE-M40-PHELPS HOUSE",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY11020144",
      location: "PHELPS HOU 2ND FLOOR GAMEROOM ",
      dexErrors: false,
      online: "SLW232000382",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 24,
          refill_qty: 15,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 1.2100000381469727,
          average_data: "[1.07,0.92,0.14,1.63,1.71,1.8]",
          average_trend: 1.4900000095367432,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.6800000071525574,
          average_data: "[0.27,1.23,0.14,0.88,0.86,0.7]",
          average_trend: 1.0299999713897705,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115586",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.4399999976158142,
          average_data: "[0.67,0.62,0.14,0,0,1.2]",
          average_trend: 2.7300000190734863,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000116149",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[0,0.31,0.71,0.5,0.71,0.3]",
          average_trend: 0.7099999785423279,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000116151",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 2.109999895095825,
          average_data: "[0.8,3.23,1.57,2.25,2.43,2.4]",
          average_trend: 1.1399999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117603",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.7099999785423279,
          average_data: "[0.8,0.77,0.43,1.25,0,1]",
          average_trend: 1.409999966621399,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119791",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0,0.31,0,0,0,0.8]",
          average_trend: 4.210000038146973,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 15,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.9599999785423279,
          average_data: "[0.67,1.08,0.86,1,0.14,2]",
          average_trend: 2.0799999237060547,
          init_inventory: 21,
          last_inventory: 21,
        },
      ],
      cap: 180,
      inv: 93,
    },
    {
      customerNum: "0600190635",
      equipmentNum: "000000000100516578",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T19:49:00.000Z",
      lastInventoryUpdate: "2021-05-13T19:49:00.000Z",
      lastFillRate: 0.4300000071525574,
      nextDeliveryPlan: "2021-06-10",
      nextDeliveryAct: "2021-06-10",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.8399999737739563,
      criticalMaterialNum: "000000000000101728",
      materialInventory: 120,
      materialConsumption: 1.940000057220459,
      dri: 15,
      pfr: 21,
      pfrc: 45,
      pfu: 26,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-06-10",
      name: "TULANE-M55-STANLEY THOMAS",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY12011642",
      location: "STANLEY TH 1ST FLOOR VEND AREA ",
      dexErrors: false,
      online: "SLW232000180",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.8399999737739563,
          average_data: "[0.9,0.68,0.74,0.67,1.03,1.02]",
          average_trend: 1.2100000381469727,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.2199999988079071,
          average_data: "[0.3,0.13,0.13,0.17,0,0.56]",
          average_trend: 2.549999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 8,
          current_inventory: 40,
          average_based_on_rec: 6,
          average_consumption: 0.6100000143051147,
          average_data: "[0.4,0.55,0.7,0.83,0.62,0.56]",
          average_trend: 0.9200000166893005,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.27000001072883606,
          average_data: "[0.2,0.55,0.35,0.17,0.07,0.28]",
          average_trend: 1.0399999618530273,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 120,
      inv: 94,
    },
    {
      customerNum: "0600190651",
      equipmentNum: "000000000100050671",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T01:31:00.000Z",
      lastInventoryUpdate: "2021-05-13T01:31:00.000Z",
      lastFillRate: 0.5099999904632568,
      nextDeliveryPlan: "2021-05-27",
      nextDeliveryAct: "2021-05-27",
      schedulingMethod: "D",
      criticalMaterialInventory: 34,
      criticalMaterialConsumption: 2.240000009536743,
      criticalMaterialNum: "000000000000133129",
      materialInventory: 228,
      materialConsumption: 6.650000095367432,
      dri: 2,
      pfr: 51,
      pfrc: 56,
      pfu: 153,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-27",
      name: "TULANE-M15-HOWARD TILTON",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY99053627",
      location: "Howard-Til BASEMENT LOUNGE ",
      dexErrors: false,
      online: "SLW232000365",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000133129",
          consignment: 52,
          refill_qty: 48,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 2.240000009536743,
          average_data: "[2.89,2,1,2.27,2.14,3.13]",
          average_trend: 1.399999976158142,
          init_inventory: 34,
          last_inventory: 34,
        },
        {
          material_num: "000000000000133131",
          consignment: 52,
          refill_qty: 27,
          current_inventory: 25,
          average_based_on_rec: 6,
          average_consumption: 0.949999988079071,
          average_data: "[0.89,0.62,0.67,0.8,0.64,2.06]",
          average_trend: 2.1700000762939453,
          init_inventory: 38,
          last_inventory: 38,
        },
        {
          material_num: "000000000000134926",
          consignment: 24,
          refill_qty: 21,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.6899999976158142,
          average_data: "[0.67,0,1,0.53,0.43,1.5]",
          average_trend: 2.1700000762939453,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000134929",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.5199999809265137,
          average_data: "[0.22,1.08,0.56,0.53,0.43,0.31]",
          average_trend: 0.6000000238418579,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000137400",
          consignment: 48,
          refill_qty: 26,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 2,
          average_data: "[3.56,0,2.78,1.47,1.21,3]",
          average_trend: 1.5,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000137699",
          consignment: 24,
          refill_qty: 0,
          current_inventory: 24,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000138036",
          consignment: 48,
          refill_qty: 0,
          current_inventory: 48,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0,0,0,0,0,1.5]",
          average_trend: 6,
          init_inventory: 0,
          last_inventory: 0,
        },
      ],
      cap: 296,
      inv: 143,
    },
    {
      customerNum: "0600190652",
      equipmentNum: "000000000100540542",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T00:39:00.000Z",
      lastInventoryUpdate: "2021-05-13T00:39:00.000Z",
      lastFillRate: 0.6299999952316284,
      nextDeliveryPlan: "2021-05-24",
      nextDeliveryAct: "2021-05-24",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.9199999570846558,
      criticalMaterialNum: "000000000000112259",
      materialInventory: 330,
      materialConsumption: 15.5,
      dri: -1,
      pfr: 62,
      pfrc: 57,
      pfu: 211,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-05-24",
      name: "TULANE-M16-HOWARD TILTON",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN16002502",
      location: "Howard-Til BASEMENT LOUNGE ",
      dexErrors: false,
      online: "SLW232000405",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 16,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 1.2000000476837158,
          average_data: "[1.11,0.67,1.07,1.17,0.53,2.67]",
          average_trend: 2.2300000190734863,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 48,
          refill_qty: 36,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 2.700000047683716,
          average_data: "[2.44,1.87,1.2,2.67,2.8,5.22]",
          average_trend: 1.9299999475479126,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102604",
          consignment: 48,
          refill_qty: 37,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 2.7699999809265137,
          average_data: "[2.78,2.27,2.27,3.67,2.93,2.67]",
          average_trend: 0.9599999785423279,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102659",
          consignment: 24,
          refill_qty: 13,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.9599999785423279,
          average_data: "[1.56,1.2,0.67,1,1.2,0.11]",
          average_trend: 0.10999999940395355,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.9199999570846558,
          average_data: "[1.67,1.33,1.2,1.83,2.8,2.67]",
          average_trend: 1.3899999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114230",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.6800000071525574,
          average_data: "[0.44,0.27,0.4,1.67,0.4,0.89]",
          average_trend: 1.309999942779541,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114306",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0.67,0.4,0.8,0.33,0.67,0.33]",
          average_trend: 0.6200000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.9100000262260437,
          average_data: "[0.78,0.8,0.67,1.33,0.53,1.33]",
          average_trend: 1.4600000381469727,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117641",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.11,0.13,0.4,0.33,0.27,0.56]",
          average_trend: 1.8700000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121751",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.840000033378601,
          average_data: "[2.44,1.33,1.47,1.5,1.6,2.67]",
          average_trend: 1.4500000476837158,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 20,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 1.0399999618530273,
          average_data: "[1,0.53,0.13,0.67,1.47,2.44]",
          average_trend: 2.3499999046325684,
          init_inventory: 18,
          last_inventory: 18,
        },
        {
          material_num: "000000000000158052",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.6499999761581421,
          average_data: "[0.44,0,0.67,0.5,1.07,1.22]",
          average_trend: 1.8799999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 336,
      inv: 125,
    },
    {
      customerNum: "0600190811",
      equipmentNum: "000000000100720166",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T18:33:00.000Z",
      lastInventoryUpdate: "2021-05-21T18:33:00.000Z",
      lastFillRate: 0.3199999928474426,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 2.130000114440918,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 154,
      materialConsumption: 7.400000095367432,
      dri: 6,
      pfr: 25,
      pfrc: 52,
      pfu: 40,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-01",
      name: "MARKET SERVICE CTR-M02",
      street: "5601 CITRUS BLVD",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY16004875",
      location: "BUILDING C 1ST FLOOR WAREHOUSE ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.6399999856948853,
          average_data: "[0.77,0.4,0.62,0.53,0.77,0.77]",
          average_trend: 1.2000000476837158,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 2.130000114440918,
          average_data: "[1.85,2.4,2.62,1.6,2.15,2.15]",
          average_trend: 1.0099999904632568,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.67,0,0,0.31,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102660",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 1.399999976158142,
          average_data: "[1.23,1.6,1.69,0.93,1.85,1.08]",
          average_trend: 0.7699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.12999999523162842,
          average_data: "[0,0,0.15,0,0.15,0.46]",
          average_trend: 3.5399999618530273,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114633",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.6800000071525574,
          average_data: "[0.77,0.53,0.92,1.07,0.15,0.62]",
          average_trend: 0.9100000262260437,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121751",
          consignment: 28,
          refill_qty: 7,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 1.2799999713897705,
          average_data: "[1.23,1.33,1.85,0.93,1.23,1.08]",
          average_trend: 0.8399999737739563,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000151978",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.9800000190734863,
          average_data: "[1.08,0.27,1.54,0.4,1.23,1.38]",
          average_trend: 1.409999966621399,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 154,
      inv: 114,
    },
    {
      customerNum: "0600190658",
      equipmentNum: "000000000100510902",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T22:21:00.000Z",
      lastInventoryUpdate: "2021-05-13T22:21:00.000Z",
      lastFillRate: 0.41999998688697815,
      nextDeliveryPlan: "2021-06-11",
      nextDeliveryAct: "2021-06-11",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.3799999952316284,
      criticalMaterialNum: "000000000000102659",
      materialInventory: 120,
      materialConsumption: 2.140000104904175,
      dri: 16,
      pfr: 25,
      pfrc: 51,
      pfu: 30,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-06-11",
      name: "TULANE-M41-PHYSICAL PLANT",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "6640576",
      location: "FACILITIES 2ND FLOOR BREAKROOM ",
      dexErrors: false,
      online: "SLW232000176",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 24,
          refill_qty: 7,
          current_inventory: 17,
          average_based_on_rec: 6,
          average_consumption: 0.5199999809265137,
          average_data: "[0.89,0.48,0.42,0.17,0.74,0.43]",
          average_trend: 0.8299999833106995,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.6800000071525574,
          average_data: "[0.61,0.41,0.58,0.72,1,0.78]",
          average_trend: 1.149999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.4000000059604645,
          average_data: "[0.5,0.48,0.42,0.33,0.32,0.35]",
          average_trend: 0.8799999952316284,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.39,0.48,0.42,0.11,0.42,0.48]",
          average_trend: 1.2599999904632568,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.1599999964237213,
          average_data: "[0.11,0.28,0.42,0.06,0,0.09]",
          average_trend: 0.5600000023841858,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000153225",
          consignment: 24,
          refill_qty: 0,
          current_inventory: 24,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 120,
      inv: 90,
    },
    {
      customerNum: "0600190659",
      equipmentNum: "000000000100515974",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-10T17:23:00.000Z",
      lastInventoryUpdate: "2021-05-10T17:23:00.000Z",
      lastFillRate: 0.25999999046325684,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 23,
      criticalMaterialConsumption: 1.0099999904632568,
      criticalMaterialNum: "000000000000125522",
      materialInventory: 232,
      materialConsumption: 5.650000095367432,
      dri: 6,
      pfr: 40,
      pfrc: 53,
      pfu: 94,
      numSalesDays: 16,
      ftc: false,
      sort: "110000002021-06-01",
      name: "TULANE-M37-NORMAN MAYER",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "M051209",
      location: "NORMAN MAY 1ST FLOOR LOBBY ",
      dexErrors: false,
      online: "SLW232000186",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 23,
          refill_qty: 10,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.6000000238418579,
          average_data: "[0.42,1,0.75,0.42,0.33,0.67]",
          average_trend: 1.1200000047683716,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102603",
          consignment: 46,
          refill_qty: 16,
          current_inventory: 30,
          average_based_on_rec: 6,
          average_consumption: 0.949999988079071,
          average_data: "[1.05,1.5,1,0.75,0.58,0.83]",
          average_trend: 0.8700000047683716,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000102604",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.6899999976158142,
          average_data: "[0.58,1.5,0.5,1.08,0.29,0.17]",
          average_trend: 0.25,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102782",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.18000000715255737,
          average_data: "[0,0,0.5,0,0.33,0.25]",
          average_trend: 1.3899999856948853,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 69,
          refill_qty: 28,
          current_inventory: 41,
          average_based_on_rec: 6,
          average_consumption: 1.7300000190734863,
          average_data: "[2.84,2.3,1.63,1.17,1.29,1.17]",
          average_trend: 0.6800000071525574,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.63,0.3,0.38,0.17,0.04,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.23999999463558197,
          average_data: "[0.37,0.3,0.13,0.08,0.25,0.33]",
          average_trend: 1.3799999952316284,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000125522",
          consignment: 23,
          refill_qty: 17,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 1.0099999904632568,
          average_data: "[1.21,1.2,1.13,0.92,0.58,1]",
          average_trend: 0.9900000095367432,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 232,
      inv: 138,
    },
    {
      customerNum: "0600190826",
      equipmentNum: "000000000101634473",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T20:18:00.000Z",
      lastInventoryUpdate: "2021-05-21T20:18:00.000Z",
      lastFillRate: 0.5699999928474426,
      nextDeliveryPlan: "2021-05-27",
      nextDeliveryAct: "2021-05-27",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 3.9700000286102295,
      criticalMaterialNum: "000000000000152197",
      materialInventory: 264,
      materialConsumption: 20.350000381469727,
      dri: 1,
      pfr: 39,
      pfrc: 46,
      pfu: 105,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-05-27",
      name: "MARKET SERVICE CENTER-M10",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "FL0108096",
      location: "PRODUCTION 1ST FLOOR OUTSIDE ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000114229",
          consignment: 36,
          refill_qty: 6,
          current_inventory: 30,
          average_based_on_rec: 6,
          average_consumption: 1.0499999523162842,
          average_data: "[2.25,0.8,1.07,0.25,1.5,0.4]",
          average_trend: 0.3799999952316284,
          init_inventory: 36,
          last_inventory: 36,
        },
        {
          material_num: "000000000000114230",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.9700000286102295,
          average_data: "[0,4.27,0.67,0.25,0.5,0.13]",
          average_trend: 0.12999999523162842,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000114306",
          consignment: 56,
          refill_qty: 14,
          current_inventory: 42,
          average_based_on_rec: 6,
          average_consumption: 2.690000057220459,
          average_data: "[2.5,2.53,1.73,3.25,2.5,3.6]",
          average_trend: 1.340000033378601,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000115313",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0,0.4,0,0,0.75,0.53]",
          average_trend: 1.8899999856948853,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000152196",
          consignment: 48,
          refill_qty: 25,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 4.980000019073486,
          average_data: "[3,6.4,6.4,2.75,6,5.33]",
          average_trend: 1.0700000524520874,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000152197",
          consignment: 24,
          refill_qty: 20,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 3.9700000286102295,
          average_data: "[4.25,3.2,3.2,4.75,5.25,3.2]",
          average_trend: 0.8100000023841858,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000152202",
          consignment: 32,
          refill_qty: 17,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 3.2799999713897705,
          average_data: "[3.5,2.93,2.53,3.5,4,3.2]",
          average_trend: 0.9800000190734863,
          init_inventory: 32,
          last_inventory: 32,
        },
        {
          material_num: "000000000000152203",
          consignment: 32,
          refill_qty: 16,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 3.130000114440918,
          average_data: "[2,4.27,4.27,1.5,3,3.73]",
          average_trend: 1.190000057220459,
          init_inventory: 32,
          last_inventory: 32,
        },
      ],
      cap: 264,
      inv: 159,
    },
    {
      customerNum: "0600190827",
      equipmentNum: "000000000100002713",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T20:18:00.000Z",
      lastInventoryUpdate: "2021-05-21T20:18:00.000Z",
      lastFillRate: 0.5899999737739563,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 28,
      criticalMaterialConsumption: 3.630000114440918,
      criticalMaterialNum: "000000000000121751",
      materialInventory: 336,
      materialConsumption: 25.18000030517578,
      dri: 3,
      pfr: 38,
      pfrc: 59,
      pfu: 130,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-05-28",
      name: "MARKET SERVICE CENTER-M12",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "216831",
      location: "PRODUCTION 1ST FLOOR OUTSIDE ",
      dexErrors: false,
      online: "01790352896",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 56,
          refill_qty: 28,
          current_inventory: 28,
          average_based_on_rec: 6,
          average_consumption: 5.519999980926514,
          average_data: "[5.08,4.93,5.33,4.5,6.5,6.8]",
          average_trend: 1.2300000190734863,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000102603",
          consignment: 112,
          refill_qty: 25,
          current_inventory: 87,
          average_based_on_rec: 6,
          average_consumption: 4.949999809265137,
          average_data: "[5.54,5.47,3.73,6.5,5,3.47]",
          average_trend: 0.699999988079071,
          init_inventory: 112,
          last_inventory: 112,
        },
        {
          material_num: "000000000000102604",
          consignment: 28,
          refill_qty: 8,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 1.5099999904632568,
          average_data: "[1.38,0.53,1.33,0.75,2,3.07]",
          average_trend: 2.0299999713897705,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000102660",
          consignment: 28,
          refill_qty: 14,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 2.799999952316284,
          average_data: "[3.38,1.73,1.73,2,4.25,3.73]",
          average_trend: 1.3300000429153442,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000117635",
          consignment: 28,
          refill_qty: 6,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 1.0499999523162842,
          average_data: "[1.38,0.8,0.93,1.25,0.75,1.2]",
          average_trend: 1.1399999856948853,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000121751",
          consignment: 28,
          refill_qty: 19,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 3.630000114440918,
          average_data: "[4.31,3.2,3.73,4.25,3.5,2.8]",
          average_trend: 0.7699999809265137,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000151978",
          consignment: 28,
          refill_qty: 16,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 3.0299999713897705,
          average_data: "[3.54,3.73,2.93,3.5,2.75,1.73]",
          average_trend: 0.5699999928474426,
          init_inventory: 28,
          last_inventory: 28,
        },
        {
          material_num: "000000000000157269",
          consignment: 28,
          refill_qty: 14,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 2.690000057220459,
          average_data: "[2.62,2.93,2.13,2.25,2.5,3.73]",
          average_trend: 1.3899999856948853,
          init_inventory: 28,
          last_inventory: 28,
        },
      ],
      cap: 336,
      inv: 206,
    },
    {
      customerNum: "0600190131",
      equipmentNum: "000000000100284403",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T23:41:00.000Z",
      lastInventoryUpdate: "2021-05-23T13:40:49.000Z",
      lastFillRate: 0.10999999940395355,
      nextDeliveryPlan: "2021-07-16",
      nextDeliveryAct: "2021-06-14",
      schedulingMethod: "D",
      criticalMaterialInventory: 0,
      criticalMaterialConsumption: 0.1599999964237213,
      criticalMaterialNum: "000000000000156182",
      materialInventory: 214,
      materialConsumption: 1.6299999952316284,
      dri: 0,
      pfr: 16,
      pfrc: 100,
      pfu: 41,
      numSalesDays: 3,
      ftc: false,
      sort: "110000002021-06-14",
      name: "LOYOLA UNIV-M06-REC PLEX",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN14002567",
      location: "   ",
      dexErrors: true,
      online: "SLW232000220",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 5,
          average_consumption: 0.3400000035762787,
          average_data: "[0,0,0.44,0.69,0.59]",
          average_trend: 1.7400000095367432,
          init_inventory: 24,
          last_inventory: 23,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 5,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0,0.56,0.11,0.07]",
          average_trend: 0.4699999988079071,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000112259",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 5,
          average_consumption: 0.11999999731779099,
          average_data: "[0,0,0.5,0.09,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 5,
          average_consumption: 0.09000000357627869,
          average_data: "[0,0,0.33,0.14,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000129254",
          consignment: 40,
          refill_qty: 1,
          current_inventory: 39,
          average_based_on_rec: 5,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0,0.61,0.11,0.07]",
          average_trend: 0.4399999976158142,
          init_inventory: 40,
          last_inventory: 40,
        },
        {
          material_num: "000000000000135333",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0,0.28,0.06,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135334",
          consignment: 12,
          refill_qty: 0,
          current_inventory: 12,
          average_based_on_rec: 5,
          average_consumption: 0,
          average_data: "[0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 5,
          average_consumption: 0.029999999329447746,
          average_data: "[0,0,0.06,0.03,0.07]",
          average_trend: 2.3299999237060547,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135336",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 5,
          average_consumption: 0.029999999329447746,
          average_data: "[0,0,0,0.06,0.07]",
          average_trend: 2.3299999237060547,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156090",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 5,
          average_consumption: 0.029999999329447746,
          average_data: "[0,0.14,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 11,
        },
        {
          material_num: "000000000000156182",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 5,
          average_consumption: 0.1599999964237213,
          average_data: "[0,0.28,0,0,0.52]",
          average_trend: 3.25,
          init_inventory: 5,
          last_inventory: 0,
        },
        {
          material_num: "000000000000156184",
          consignment: 12,
          refill_qty: 12,
          current_inventory: 0,
          average_based_on_rec: 5,
          average_consumption: 0.3700000047683716,
          average_data: "[0,0.28,0.33,0.34,0.89]",
          average_trend: 2.4100000858306885,
          init_inventory: 12,
          last_inventory: 0,
        },
        {
          material_num: "000000000000156188",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 5,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0.14,0.06,0.06,0.15]",
          average_trend: 1.8799999952316284,
          init_inventory: 10,
          last_inventory: 8,
        },
      ],
      cap: 244,
      inv: 203,
    },
    {
      customerNum: "0600190838",
      equipmentNum: "000000000100519725",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T21:45:00.000Z",
      lastInventoryUpdate: "2021-05-21T21:45:00.000Z",
      lastFillRate: 0.25,
      nextDeliveryPlan: "2021-05-26",
      nextDeliveryAct: "2021-05-26",
      schedulingMethod: "D",
      criticalMaterialInventory: 8,
      criticalMaterialConsumption: 1.5800000429153442,
      criticalMaterialNum: "000000000000157135",
      materialInventory: 338,
      materialConsumption: 23.75,
      dri: 0,
      pfr: 40,
      pfrc: 38,
      pfu: 144,
      numSalesDays: 5,
      ftc: false,
      sort: "000000002021-05-26",
      name: "MARKET SERVICE CENTER-M04",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "DN08001831",
      location: "ADMINISTRA 1ST BREAKROOM ",
      dexErrors: false,
      online: "SLW232000426",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000133129",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.8299999833106995,
          average_data: "[1,0.75,0.5,0.75,1.25,0.75]",
          average_trend: 0.8999999761581421,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000134929",
          consignment: 8,
          refill_qty: 5,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.8299999833106995,
          average_data: "[1.25,0.75,0,1.5,0,1.5]",
          average_trend: 1.809999942779541,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000137400",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.25,0,0,0.5,0,1.5]",
          average_trend: 3.950000047683716,
          init_inventory: 18,
          last_inventory: 18,
        },
        {
          material_num: "000000000000145105",
          consignment: 24,
          refill_qty: 19,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 3.630000114440918,
          average_data: "[2.75,5,2.75,4.5,3.25,3.5]",
          average_trend: 0.9599999785423279,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000151818",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 7,
          average_based_on_rec: 3,
          average_consumption: 1,
          average_data: "[0,0,3]",
          average_trend: 3,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000152197",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.75,0,0,0.75,0,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000152203",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0,0.75,0,0.75,0,1.25]",
          average_trend: 2.7200000286102295,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156184",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 1.5800000429153442,
          average_data: "[1.75,0,3,0,3.5,1.25]",
          average_trend: 0.7900000214576721,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156530",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.5400000214576721,
          average_data: "[0.5,0,1,1,0.75,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156563",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0,1.5,0,0.25,1.75,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157128",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 19,
          average_based_on_rec: 3,
          average_consumption: 1,
          average_data: "[0,3,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157135",
          consignment: 8,
          refill_qty: 8,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 1.5800000429153442,
          average_data: "[1.5,2,1,2,1.5,1.5]",
          average_trend: 0.949999988079071,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000157249",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.7099999785423279,
          average_data: "[0,1.5,0,2,0,0.75]",
          average_trend: 1.059999942779541,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000157527",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.6700000166893005,
          average_data: "[1.25,0,0,1.25,0.75,0.75]",
          average_trend: 1.1200000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410384",
          consignment: 24,
          refill_qty: 18,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 3.5,
          average_data: "[2,4.25,1.75,6,2.5,4.5]",
          average_trend: 1.2899999618530273,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410681",
          consignment: 24,
          refill_qty: 23,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 4.579999923706055,
          average_data: "[6,6,3.5,6,2,4]",
          average_trend: 0.8700000047683716,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410704",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.6299999952316284,
          average_data: "[1.25,2,1.25,2,2,1.25]",
          average_trend: 0.7699999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 356,
      inv: 212,
    },
    {
      customerNum: "0600190895",
      equipmentNum: "000000000101656142",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T21:51:00.000Z",
      lastInventoryUpdate: "2021-05-21T21:51:00.000Z",
      lastFillRate: 0.20000000298023224,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 14,
      criticalMaterialConsumption: 1.6699999570846558,
      criticalMaterialNum: "000000000000158054",
      materialInventory: 116,
      materialConsumption: 5.150000095367432,
      dri: 3,
      pfr: 31,
      pfrc: 40,
      pfu: 40,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-05-28",
      name: "MARKET SERVICE CENTER-M05",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "255206",
      location: "A 2  ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.11999999731779099,
          average_data: "[0.31,0.13,0.27,0,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000102603",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0,0.13,0.27,0.62,0,0.5]",
          average_trend: 2,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102604",
          consignment: 48,
          refill_qty: 13,
          current_inventory: 35,
          average_based_on_rec: 6,
          average_consumption: 2.4200000762939453,
          average_data: "[1.85,3.2,1.87,1.85,1.5,4.25]",
          average_trend: 1.7599999904632568,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102659",
          consignment: 6,
          refill_qty: 0,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000117635",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.31,0.27,0,0,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000117641",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.20000000298023224,
          average_data: "[0.46,0,0,0.46,0.25,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0,0,0.13,1.23,0,1]",
          average_trend: 2.559999942779541,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000158054",
          consignment: 24,
          refill_qty: 19,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 1.6699999570846558,
          average_data: "[2.46,1.2,0,3.38,0.5,2.5]",
          average_trend: 1.5,
          init_inventory: 14,
          last_inventory: 14,
        },
      ],
      cap: 126,
      inv: 86,
    },
    {
      customerNum: "0600190839",
      equipmentNum: "000000000100604818",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T23:15:00.000Z",
      lastInventoryUpdate: "2021-05-21T23:15:00.000Z",
      lastFillRate: 0.2199999988079071,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 2.630000114440918,
      criticalMaterialNum: "000000000000154914",
      materialInventory: 360,
      materialConsumption: 13.149999618530273,
      dri: 4,
      pfr: 20,
      pfrc: 32,
      pfu: 73,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-05-28",
      name: "MARKET SERVICE CENTER-M15",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "DN16005661",
      location: "PRODUCTION 1ST FLOOR PRODUCTION ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101728",
          consignment: 48,
          refill_qty: 4,
          current_inventory: 44,
          average_based_on_rec: 6,
          average_consumption: 0.7900000214576721,
          average_data: "[0.77,0.77,0.8,1.2,0.77,0.4]",
          average_trend: 0.5099999904632568,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102751",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.9599999785423279,
          average_data: "[0.77,1.38,0.8,1.33,0.92,0.53]",
          average_trend: 0.550000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102752",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.14000000059604645,
          average_data: "[0.15,0,0,0.67,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117687",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.7300000190734863,
          average_data: "[0.77,0.77,1.6,0.8,0.46,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000123292",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.600000023841858,
          average_data: "[0.92,1.23,2.93,1.6,1.69,1.2]",
          average_trend: 0.75,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 24,
          refill_qty: 2,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.23000000417232513,
          average_data: "[0.77,0.62,0,0,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135335",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 1.100000023841858,
          average_data: "[1.69,0,3.2,0,0.92,0.8]",
          average_trend: 0.7300000190734863,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000151818",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.550000011920929,
          average_data: "[0,0,0.4,0,2.92,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154898",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.9900000095367432,
          average_data: "[1.08,1.38,1.33,0.27,0.92,0.93]",
          average_trend: 0.9399999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154900",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 2.259999990463257,
          average_data: "[2.46,1.38,3.2,2.4,1.85,2.27]",
          average_trend: 1,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154914",
          consignment: 24,
          refill_qty: 14,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 2.630000114440918,
          average_data: "[2.92,2.92,3.2,2.53,2.31,1.87]",
          average_trend: 0.7099999785423279,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156182",
          consignment: 16,
          refill_qty: 1,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.07999999821186066,
          average_data: "[0,0.46,0,0,0,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000156184",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.28999999165534973,
          average_data: "[0,0,0.67,0,1.08,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000156188",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.36000001430511475,
          average_data: "[0,0,1.33,0,0,0.8]",
          average_trend: 2.2200000286102295,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000157347",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.4399999976158142,
          average_data: "[1.38,0,0.8,0,0.46,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 360,
      inv: 287,
    },
    {
      customerNum: "0600190896",
      equipmentNum: "000000000103083220",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T21:50:00.000Z",
      lastInventoryUpdate: "2021-05-21T21:50:00.000Z",
      lastFillRate: 0.07000000029802322,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 6,
      criticalMaterialConsumption: 0.3199999928474426,
      criticalMaterialNum: "000000000000156115",
      materialInventory: 194,
      materialConsumption: 1.8600000143051147,
      dri: 11,
      pfr: 8,
      pfrc: 15,
      pfu: 17,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-04",
      name: "MARKET SERVICE CENTER-M03",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "CM19001335",
      location: "2nd Fl Adm   ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 72,
          refill_qty: 3,
          current_inventory: 69,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0.62,0.31,0.8,0.27,0.46,0.31]",
          average_trend: 0.6700000166893005,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0,0,0,0,0.31]",
          average_trend: 6.199999809265137,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000156109",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.31,0.13,0,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000156112",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0.31,0.15,0,0.27,0.15,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000156114",
          consignment: 6,
          refill_qty: 0,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000156115",
          consignment: 6,
          refill_qty: 2,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.3199999928474426,
          average_data: "[0.31,0.46,0.53,0,0.31,0.31]",
          average_trend: 0.9700000286102295,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000156117",
          consignment: 8,
          refill_qty: 0,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0,
          average_data: "[0,0,0,0,0,0]",
          average_trend: 1,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000156118",
          consignment: 8,
          refill_qty: 1,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0.31,0,0,0,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000156120",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.05000000074505806,
          average_data: "[0,0.31,0,0,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000156122",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.07000000029802322,
          average_data: "[0,0.31,0.13,0,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000156137",
          consignment: 16,
          refill_qty: 2,
          current_inventory: 14,
          average_based_on_rec: 6,
          average_consumption: 0.3199999928474426,
          average_data: "[0.46,0.46,0.4,0.27,0,0.31]",
          average_trend: 0.9700000286102295,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000156339",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0,0.31,0.13,0.13,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000156341",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.03999999910593033,
          average_data: "[0,0,0.13,0.13,0,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000157319",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0,0,0,0,0,0.92]",
          average_trend: 6.130000114440918,
          init_inventory: 6,
          last_inventory: 6,
        },
        {
          material_num: "000000000000157347",
          consignment: 6,
          refill_qty: 1,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.029999999329447746,
          average_data: "[0,0,0,0,0.15,0]",
          average_trend: 0,
          init_inventory: 6,
          last_inventory: 6,
        },
      ],
      cap: 194,
      inv: 177,
    },
    {
      customerNum: "0600190840",
      equipmentNum: "000000000100865029",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T18:35:00.000Z",
      lastInventoryUpdate: "2021-05-21T18:35:00.000Z",
      lastFillRate: 0.23999999463558197,
      nextDeliveryPlan: "2021-06-04",
      nextDeliveryAct: "2021-06-04",
      schedulingMethod: "D",
      criticalMaterialInventory: 9,
      criticalMaterialConsumption: 0.5799999833106995,
      criticalMaterialNum: "000000000000135333",
      materialInventory: 252,
      materialConsumption: 8.350000381469727,
      dri: 9,
      pfr: 23,
      pfrc: 50,
      pfu: 65,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-04",
      name: "MARKET SERVICE CENTER M17",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "DN16010898",
      location: "BUILDING C 2ND FLOOR BREAKROOM ",
      dexErrors: true,
      online: "48390197456",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 16,
          refill_qty: 1,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0.15,0,0.15,0.4,0.46,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102592",
          consignment: 8,
          refill_qty: 1,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.15000000596046448,
          average_data: "[0.15,0,0.15,0.13,0,0.46]",
          average_trend: 3.069999933242798,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000102603",
          consignment: 48,
          refill_qty: 9,
          current_inventory: 39,
          average_based_on_rec: 6,
          average_consumption: 1.7300000190734863,
          average_data: "[2.15,2,1.38,1.6,1.23,2]",
          average_trend: 1.159999966621399,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000102604",
          consignment: 27,
          refill_qty: 7,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 1.25,
          average_data: "[1.69,1.07,0.77,1.2,1.54,1.23]",
          average_trend: 0.9800000190734863,
          init_inventory: 27,
          last_inventory: 27,
        },
        {
          material_num: "000000000000102659",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.8199999928474426,
          average_data: "[1.23,1.6,0.46,0.53,0.31,0.77]",
          average_trend: 0.9399999976158142,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.5,
          average_data: "[0.62,0,0.92,0.53,0.31,0.62]",
          average_trend: 1.2400000095367432,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000121751",
          consignment: 24,
          refill_qty: 5,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.8600000143051147,
          average_data: "[0.92,0.67,0.77,0.93,0.92,0.92]",
          average_trend: 1.0700000524520874,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 1,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 0.18000000715255737,
          average_data: "[0.46,0,0,0,0,0.62]",
          average_trend: 3.440000057220459,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135333",
          consignment: 16,
          refill_qty: 10,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0,0,2.15,0.27,0.77,0.31]",
          average_trend: 0.5299999713897705,
          init_inventory: 9,
          last_inventory: 9,
        },
        {
          material_num: "000000000000135334",
          consignment: 16,
          refill_qty: 16,
          current_inventory: 0,
          average_based_on_rec: 6,
          average_consumption: 0.5400000214576721,
          average_data: "[0.77,0,0,0,2.31,0.15]",
          average_trend: 0.2800000011920929,
          init_inventory: 0,
          last_inventory: 0,
        },
        {
          material_num: "000000000000135335",
          consignment: 12,
          refill_qty: 1,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.10000000149011612,
          average_data: "[0.15,0,0.15,0.27,0,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000157269",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.4300000071525574,
          average_data: "[0,0.8,0.62,0.4,0,0.77]",
          average_trend: 1.7899999618530273,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000158052",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0.62,0,0.92,0,0,1.23]",
          average_trend: 2.6700000762939453,
          init_inventory: 12,
          last_inventory: 12,
        },
      ],
      cap: 275,
      inv: 210,
    },
    {
      customerNum: "0600190897",
      equipmentNum: "000000000100607262",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T21:43:00.000Z",
      lastInventoryUpdate: "2021-05-21T21:43:00.000Z",
      lastFillRate: 0.18000000715255737,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 48,
      criticalMaterialConsumption: 5.630000114440918,
      criticalMaterialNum: "000000000000156137",
      materialInventory: 360,
      materialConsumption: 20.690000534057617,
      dri: 4,
      pfr: 31,
      pfrc: 51,
      pfu: 113,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-05-28",
      name: "MARKET SERVICE CENTER-M06",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "DN16004127",
      location: "ADMINISTRA 1ST FLOOR BREAKROOM ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000112259",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0,0.75,0,0.75,0,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000129254",
          consignment: 16,
          refill_qty: 5,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.9200000166893005,
          average_data: "[0.75,0,1.5,1,1.5,0.75]",
          average_trend: 0.8199999928474426,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000130470",
          consignment: 16,
          refill_qty: 5,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.8299999833106995,
          average_data: "[0,1.75,0.75,1.5,0,1]",
          average_trend: 1.2000000476837158,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000135333",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 1.5,
          average_data: "[3.5,2,0,2.75,0.75,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000135334",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.33000001311302185,
          average_data: "[0,0,0,1.75,0.25,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000135335",
          consignment: 24,
          refill_qty: 9,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.7899999618530273,
          average_data: "[4.25,0.5,1,2,1.5,1.5]",
          average_trend: 0.8399999737739563,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154898",
          consignment: 72,
          refill_qty: 12,
          current_inventory: 60,
          average_based_on_rec: 6,
          average_consumption: 2.2100000381469727,
          average_data: "[3,3,1.25,4,2,0]",
          average_trend: 0,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000154900",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0,1.25,0.75,0,0.75,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000156111",
          consignment: 16,
          refill_qty: 4,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.6700000166893005,
          average_data: "[0,1.75,0,0,0.75,1.5]",
          average_trend: 2.240000009536743,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000156112",
          consignment: 16,
          refill_qty: 4,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.7900000214576721,
          average_data: "[0.5,1,0.5,1,1.75,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000156114",
          consignment: 16,
          refill_qty: 9,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 1.6699999570846558,
          average_data: "[1.25,2.75,0.25,2.75,1,2]",
          average_trend: 1.2000000476837158,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000156117",
          consignment: 8,
          refill_qty: 3,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.5799999833106995,
          average_data: "[0,0,1.25,1.25,1,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000156118",
          consignment: 8,
          refill_qty: 2,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0,0,0.75,1.5,0,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000156120",
          consignment: 8,
          refill_qty: 4,
          current_inventory: 4,
          average_based_on_rec: 6,
          average_consumption: 0.7099999785423279,
          average_data: "[0.5,0,1.75,1,0,1]",
          average_trend: 1.409999966621399,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000156137",
          consignment: 48,
          refill_qty: 29,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 5.630000114440918,
          average_data: "[5.75,4.5,4.75,10.25,1.75,6.75]",
          average_trend: 1.2000000476837158,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000157319",
          consignment: 16,
          refill_qty: 3,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.41999998688697815,
          average_data: "[0.5,0,0.5,0,1.5,0]",
          average_trend: 0,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000157347",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.6299999952316284,
          average_data: "[1,0,1.25,0.25,0,1.25]",
          average_trend: 1.9800000190734863,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000410339",
          consignment: 16,
          refill_qty: 5,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.9200000166893005,
          average_data: "[0.5,1.25,0.75,0,2.25,0.75]",
          average_trend: 0.8199999928474426,
          init_inventory: 16,
          last_inventory: 16,
        },
      ],
      cap: 360,
      inv: 247,
    },
    {
      customerNum: "0600190957",
      equipmentNum: "000000000100508432",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T23:40:00.000Z",
      lastInventoryUpdate: "2021-05-19T23:40:00.000Z",
      lastFillRate: 0.3700000047683716,
      nextDeliveryPlan: "2021-06-10",
      nextDeliveryAct: "2021-06-10",
      schedulingMethod: "D",
      criticalMaterialInventory: 9,
      criticalMaterialConsumption: 0.3700000047683716,
      criticalMaterialNum: "000000000000153225",
      materialInventory: 241,
      materialConsumption: 4.699999809265137,
      dri: 15,
      pfr: 19,
      pfrc: 46,
      pfu: 50,
      numSalesDays: 7,
      ftc: false,
      sort: "110000002021-06-10",
      name: "LOYOLA UNIV-M07-REC PLEX",
      street: "6363 SAINT CHARLES AVE",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY03016635",
      location: "REC PLEX G   ",
      dexErrors: false,
      online: "SLW232000395",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MTh",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 56,
          refill_qty: 6,
          current_inventory: 50,
          average_based_on_rec: 6,
          average_consumption: 0.8100000023841858,
          average_data: "[0.65,1.26,1.06,0.58,0.59,0.74]",
          average_trend: 0.9100000262260437,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000102603",
          consignment: 56,
          refill_qty: 9,
          current_inventory: 47,
          average_based_on_rec: 6,
          average_consumption: 1.1799999475479126,
          average_data: "[0.95,1.44,1.06,0.84,1.35,1.41]",
          average_trend: 1.190000057220459,
          init_inventory: 56,
          last_inventory: 56,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0.22,0.42,0.39,0.42,0.59,0.44]",
          average_trend: 1.0700000524520874,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 84,
          refill_qty: 9,
          current_inventory: 75,
          average_based_on_rec: 6,
          average_consumption: 1.2599999904632568,
          average_data: "[0.95,0.74,1.33,1.05,1.71,1.78]",
          average_trend: 1.409999966621399,
          init_inventory: 84,
          last_inventory: 84,
        },
        {
          material_num: "000000000000117635",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.38999998569488525,
          average_data: "[0.25,0.37,0.39,0.37,0.24,0.74]",
          average_trend: 1.899999976158142,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.2800000011920929,
          average_data: "[0.11,0.23,0.33,0.21,0.18,0.59]",
          average_trend: 2.109999895095825,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000153225",
          consignment: 24,
          refill_qty: 18,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.3700000047683716,
          average_data: "[0.29,0.23,0.28,0.42,0.35,0.67]",
          average_trend: 1.809999942779541,
          init_inventory: 9,
          last_inventory: 9,
        },
      ],
      cap: 256,
      inv: 206,
    },
    {
      customerNum: "0600190160",
      equipmentNum: "000000000100507791",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-12T00:38:00.000Z",
      lastInventoryUpdate: "2021-05-12T00:38:00.000Z",
      lastFillRate: 0.20000000298023224,
      nextDeliveryPlan: "2021-06-03",
      nextDeliveryAct: "2021-06-03",
      schedulingMethod: "D",
      criticalMaterialInventory: 12,
      criticalMaterialConsumption: 0.47999998927116394,
      criticalMaterialNum: "000000000000121751",
      materialInventory: 242,
      materialConsumption: 3.990000009536743,
      dri: 9,
      pfr: 25,
      pfrc: 37,
      pfu: 62,
      numSalesDays: 14,
      ftc: false,
      sort: "110000002021-06-03",
      name: "XAVIER UNIVERSITY-M1",
      street: "1 DREXEL DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN15003145",
      location: "4TH FLOOR  4th lounge ",
      dexErrors: false,
      online: "SLW232000128",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "MF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.30000001192092896,
          average_data: "[0.26,0,0.3,0.63,0.21,0.41]",
          average_trend: 1.3700000047683716,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.5099999904632568,
          average_data: "[0.34,0.33,0.5,1.13,0.5,0.28]",
          average_trend: 0.550000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.11999999731779099,
          average_data: "[0.17,0,0.1,0,0.21,0.21]",
          average_trend: 1.75,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102659",
          consignment: 12,
          refill_qty: 3,
          current_inventory: 9,
          average_based_on_rec: 6,
          average_consumption: 0.18000000715255737,
          average_data: "[0.23,0.11,0.2,0.44,0.07,0]",
          average_trend: 0,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000112259",
          consignment: 72,
          refill_qty: 10,
          current_inventory: 62,
          average_based_on_rec: 6,
          average_consumption: 0.6899999976158142,
          average_data: "[0.45,0.89,0.8,1,0.43,0.55]",
          average_trend: 0.800000011920929,
          init_inventory: 72,
          last_inventory: 72,
        },
        {
          material_num: "000000000000114230",
          consignment: 9,
          refill_qty: 4,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.13,0,0.6,0.31,0.29,0.14]",
          average_trend: 0.5600000023841858,
          init_inventory: 9,
          last_inventory: 9,
        },
        {
          material_num: "000000000000114633",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.4099999964237213,
          average_data: "[0.28,0.44,0.55,0.56,0.29,0.34]",
          average_trend: 0.8299999833106995,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115964",
          consignment: 9,
          refill_qty: 2,
          current_inventory: 7,
          average_based_on_rec: 4,
          average_consumption: 0.11999999731779099,
          average_data: "[0,0,0.21,0.28]",
          average_trend: 2.3299999237060547,
          init_inventory: 9,
          last_inventory: 9,
        },
        {
          material_num: "000000000000119790",
          consignment: 8,
          refill_qty: 3,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.1899999976158142,
          average_data: "[0.17,0,0.4,0.44,0.14,0]",
          average_trend: 0,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.47999998927116394,
          average_data: "[0.26,0.78,0.3,0.44,0.64,0.48]",
          average_trend: 1,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.3799999952316284,
          average_data: "[0.45,0,0.65,0.56,0.36,0.28]",
          average_trend: 0.7400000095367432,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000154914",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.36000001430511475,
          average_data: "[0.3,0.56,0.35,0.56,0.36,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 242,
      inv: 180,
    },
    {
      customerNum: "0600190505",
      equipmentNum: "000000000100226421",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-19T20:24:00.000Z",
      lastInventoryUpdate: "2021-05-19T20:24:00.000Z",
      lastFillRate: 0.3100000023841858,
      nextDeliveryPlan: "2021-06-03",
      nextDeliveryAct: "2021-06-03",
      schedulingMethod: "D",
      criticalMaterialInventory: 46,
      criticalMaterialConsumption: 3.1700000762939453,
      criticalMaterialNum: "000000000000102604",
      materialInventory: 230,
      materialConsumption: 8.050000190734863,
      dri: 8,
      pfr: 25,
      pfrc: 52,
      pfu: 59,
      numSalesDays: 7,
      ftc: false,
      sort: "100000002021-06-03",
      name: "HOLY NAME OF JESUS SCHOOL-M1",
      street: "6325 CROMWELL PL",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "619180",
      location: "   ",
      dexErrors: false,
      online: "SLW232000221",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Tu",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102603",
          consignment: 69,
          refill_qty: 14,
          current_inventory: 55,
          average_based_on_rec: 6,
          average_consumption: 1.9199999570846558,
          average_data: "[1.35,1.16,2.69,2.1,1.6,2.62]",
          average_trend: 1.3600000143051147,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000102604",
          consignment: 46,
          refill_qty: 23,
          current_inventory: 23,
          average_based_on_rec: 6,
          average_consumption: 3.1700000762939453,
          average_data: "[2.29,2.42,3.17,3.3,3.2,4.62]",
          average_trend: 1.4600000381469727,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000112259",
          consignment: 46,
          refill_qty: 6,
          current_inventory: 40,
          average_based_on_rec: 6,
          average_consumption: 0.8100000023841858,
          average_data: "[0.47,1.05,0.41,0.6,0.93,1.38]",
          average_trend: 1.7000000476837158,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 8,
          current_inventory: 15,
          average_based_on_rec: 6,
          average_consumption: 1.0700000524520874,
          average_data: "[1,0.63,1.1,1.4,0.93,1.38]",
          average_trend: 1.2899999618530273,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117641",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.5299999713897705,
          average_data: "[0.65,0.26,0.55,0.7,0.53,0.46]",
          average_trend: 0.8700000047683716,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.550000011920929,
          average_data: "[0.53,0.42,0.9,0.4,0.4,0.62]",
          average_trend: 1.1299999952316284,
          init_inventory: 23,
          last_inventory: 23,
        },
      ],
      cap: 230,
      inv: 171,
    },
    {
      customerNum: "0600190602",
      equipmentNum: "000000000100512631",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-11T00:58:00.000Z",
      lastInventoryUpdate: "2021-05-11T00:58:00.000Z",
      lastFillRate: 0.33000001311302185,
      nextDeliveryPlan: "2021-05-28",
      nextDeliveryAct: "2021-05-28",
      schedulingMethod: "D",
      criticalMaterialInventory: 46,
      criticalMaterialConsumption: 2.609999895095825,
      criticalMaterialNum: "000000000000115584",
      materialInventory: 255,
      materialConsumption: 9.109999656677246,
      dri: 3,
      pfr: 62,
      pfrc: 69,
      pfu: 186,
      numSalesDays: 15,
      ftc: false,
      sort: "110000002021-05-28",
      name: "TULANE-M54-SHARP HALL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "RY13000982",
      location: "SHARP HALL 2ND FLOOR ELEVATOR H ",
      dexErrors: false,
      online: "SLW232000343",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 48,
          refill_qty: 29,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 1.8700000047683716,
          average_data: "[2.18,1.67,1.29,2,1.6,2.5]",
          average_trend: 1.340000033378601,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000115584",
          consignment: 48,
          refill_qty: 42,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 2.609999895095825,
          average_data: "[4.18,2.33,0,4,2.5,2.67]",
          average_trend: 1.0199999809265137,
          init_inventory: 46,
          last_inventory: 46,
        },
        {
          material_num: "000000000000115586",
          consignment: 48,
          refill_qty: 29,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 1,
          average_data: "[0.82,0.83,0.57,1.42,1.2,1.17]",
          average_trend: 1.1699999570846558,
          init_inventory: 34,
          last_inventory: 34,
        },
        {
          material_num: "000000000000116149",
          consignment: 36,
          refill_qty: 11,
          current_inventory: 25,
          average_based_on_rec: 6,
          average_consumption: 0.699999988079071,
          average_data: "[0.36,0.42,0.57,0.67,1.2,1]",
          average_trend: 1.4299999475479126,
          init_inventory: 36,
          last_inventory: 36,
        },
        {
          material_num: "000000000000117603",
          consignment: 48,
          refill_qty: 42,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 1.7799999713897705,
          average_data: "[2,1.75,1.29,2.17,1.2,2.25]",
          average_trend: 1.2599999904632568,
          init_inventory: 33,
          last_inventory: 33,
        },
        {
          material_num: "000000000000119516",
          consignment: 48,
          refill_qty: 27,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.800000011920929,
          average_data: "[0,0.42,1,0.83,1.4,1.17]",
          average_trend: 1.4600000381469727,
          init_inventory: 34,
          last_inventory: 34,
        },
        {
          material_num: "000000000000121750",
          consignment: 24,
          refill_qty: 6,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.3499999940395355,
          average_data: "[0.55,0.17,0.57,0.5,0.3,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 300,
      inv: 114,
    },
    {
      customerNum: "0600190648",
      equipmentNum: "000000000100531444",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-13T18:54:00.000Z",
      lastInventoryUpdate: "2021-05-13T18:54:00.000Z",
      lastFillRate: 0.10999999940395355,
      nextDeliveryPlan: "2021-06-01",
      nextDeliveryAct: "2021-06-01",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 1.2699999809265137,
      criticalMaterialNum: "000000000000129254",
      materialInventory: 327,
      materialConsumption: 8.520000457763672,
      dri: 6,
      pfr: 38,
      pfrc: 52,
      pfu: 133,
      numSalesDays: 13,
      ftc: false,
      sort: "110000002021-06-01",
      name: "TULANE-M48-RICHARDSON MEMORIAL",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "DN11003806",
      location: "RICHARDSON 1ST FLOOR LOBBY ",
      dexErrors: false,
      online: "SLW232000172",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "M",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000101891",
          consignment: 16,
          refill_qty: 5,
          current_inventory: 11,
          average_based_on_rec: 6,
          average_consumption: 0.3700000047683716,
          average_data: "[0.73,0.21,0.18,0.17,0.62,0.3]",
          average_trend: 0.8100000023841858,
          init_inventory: 16,
          last_inventory: 16,
        },
        {
          material_num: "000000000000102592",
          consignment: 12,
          refill_qty: 5,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0.45,0.16,0.18,0.33,0.21,0.5]",
          average_trend: 1.6100000143051147,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 24,
          refill_qty: 11,
          current_inventory: 13,
          average_based_on_rec: 6,
          average_consumption: 0.8399999737739563,
          average_data: "[0.64,0.95,1.27,1,0.48,0.7]",
          average_trend: 0.8299999833106995,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000102604",
          consignment: 48,
          refill_qty: 18,
          current_inventory: 30,
          average_based_on_rec: 6,
          average_consumption: 1.3600000143051147,
          average_data: "[1.36,2.53,1.55,0.5,2.21,0]",
          average_trend: 0,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000112259",
          consignment: 48,
          refill_qty: 17,
          current_inventory: 31,
          average_based_on_rec: 6,
          average_consumption: 1.2799999713897705,
          average_data: "[1.64,1.53,0,1.33,2.76,0.4]",
          average_trend: 0.3100000023841858,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117635",
          consignment: 24,
          refill_qty: 8,
          current_inventory: 16,
          average_based_on_rec: 6,
          average_consumption: 0.6000000238418579,
          average_data: "[0.91,0.63,0.64,0.67,0.62,0.1]",
          average_trend: 0.17000000178813934,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000117641",
          consignment: 8,
          refill_qty: 5,
          current_inventory: 3,
          average_based_on_rec: 6,
          average_consumption: 0.3499999940395355,
          average_data: "[0.18,0.42,0.36,0.5,0.34,0.3]",
          average_trend: 0.8600000143051147,
          init_inventory: 8,
          last_inventory: 8,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 7,
          current_inventory: 5,
          average_based_on_rec: 6,
          average_consumption: 0.5099999904632568,
          average_data: "[0.64,0.53,0.55,0,0.83,0.5]",
          average_trend: 0.9800000190734863,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000129254",
          consignment: 24,
          refill_qty: 17,
          current_inventory: 7,
          average_based_on_rec: 6,
          average_consumption: 1.2699999809265137,
          average_data: "[1.73,1.05,0.45,2,1.66,0.7]",
          average_trend: 0.550000011920929,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000151938",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 5,
          average_consumption: 0.28999999165534973,
          average_data: "[0,0.45,0.33,0.69,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000151978",
          consignment: 24,
          refill_qty: 4,
          current_inventory: 20,
          average_based_on_rec: 6,
          average_consumption: 0.25,
          average_data: "[0.55,0.53,0.27,0.17,0,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000153008",
          consignment: 24,
          refill_qty: 24,
          current_inventory: 22,
          average_based_on_rec: 1,
          average_consumption: 0,
          average_data: "[0]",
          average_trend: 1,
          init_inventory: 22,
          last_inventory: 22,
        },
        {
          material_num: "000000000000156184",
          consignment: 24,
          refill_qty: 12,
          current_inventory: 12,
          average_based_on_rec: 6,
          average_consumption: 0.46000000834465027,
          average_data: "[0.36,0.42,0.27,0.67,0.41,0.6]",
          average_trend: 1.2999999523162842,
          init_inventory: 18,
          last_inventory: 18,
        },
        {
          material_num: "000000000000157269",
          consignment: 16,
          refill_qty: 8,
          current_inventory: 8,
          average_based_on_rec: 4,
          average_consumption: 0.25999999046325684,
          average_data: "[0,0,0.62,0.4]",
          average_trend: 1.5399999618530273,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000411016",
          consignment: 16,
          refill_qty: 10,
          current_inventory: 6,
          average_based_on_rec: 4,
          average_consumption: 0.3700000047683716,
          average_data: "[0,0.5,0.48,0.5]",
          average_trend: 1.350000023841858,
          init_inventory: 11,
          last_inventory: 11,
        },
      ],
      cap: 344,
      inv: 211,
    },
    {
      customerNum: "0600190657",
      equipmentNum: "000000000100511189",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-04-29T18:40:00.000Z",
      lastInventoryUpdate: "2021-04-29T18:40:00.000Z",
      lastFillRate: 0.1599999964237213,
      nextDeliveryPlan: "2021-05-27",
      nextDeliveryAct: "2021-05-27",
      schedulingMethod: "D",
      criticalMaterialInventory: 24,
      criticalMaterialConsumption: 0.8399999737739563,
      criticalMaterialNum: "000000000000115584",
      materialInventory: 568,
      materialConsumption: 6.949999809265137,
      dri: 1,
      pfr: 33,
      pfrc: 34,
      pfu: 191,
      numSalesDays: 27,
      ftc: false,
      sort: "110000002021-05-27",
      name: "TULANE-M42-PHYSICAL PLANT",
      street: "31 MCALISTER DR",
      city: "NEW ORLEANS",
      activity: "NORM",
      assetNum: "9109665",
      location: "FACILITIES 1ST FLOOR GARAGE ",
      dexErrors: false,
      online: "SLW232000207",
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "Th",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000115583",
          consignment: 128,
          refill_qty: 45,
          current_inventory: 83,
          average_based_on_rec: 6,
          average_consumption: 1.649999976158142,
          average_data: "[0.63,2.76,2.08,1.69,1.55,1.17]",
          average_trend: 0.7099999785423279,
          init_inventory: 128,
          last_inventory: 128,
        },
        {
          material_num: "000000000000115584",
          consignment: 24,
          refill_qty: 23,
          current_inventory: 1,
          average_based_on_rec: 6,
          average_consumption: 0.8399999737739563,
          average_data: "[0,2.48,0.92,0.69,0.82,0.1]",
          average_trend: 0.11999999731779099,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000115586",
          consignment: 128,
          refill_qty: 22,
          current_inventory: 106,
          average_based_on_rec: 6,
          average_consumption: 0.7900000214576721,
          average_data: "[0,0.59,2.15,0.92,1.09,0]",
          average_trend: 0,
          init_inventory: 128,
          last_inventory: 128,
        },
        {
          material_num: "000000000000116149",
          consignment: 64,
          refill_qty: 31,
          current_inventory: 33,
          average_based_on_rec: 6,
          average_consumption: 1.1200000047683716,
          average_data: "[0.32,0.83,2.23,1.23,0.91,1.17]",
          average_trend: 1.0399999618530273,
          init_inventory: 64,
          last_inventory: 64,
        },
        {
          material_num: "000000000000116151",
          consignment: 48,
          refill_qty: 26,
          current_inventory: 22,
          average_based_on_rec: 6,
          average_consumption: 0.9599999785423279,
          average_data: "[0.47,1.24,1.58,0.38,1.55,0.54]",
          average_trend: 0.5600000023841858,
          init_inventory: 48,
          last_inventory: 48,
        },
        {
          material_num: "000000000000117603",
          consignment: 128,
          refill_qty: 23,
          current_inventory: 105,
          average_based_on_rec: 6,
          average_consumption: 0.8399999737739563,
          average_data: "[0.32,1.24,1.19,1.15,0.45,0.68]",
          average_trend: 0.8100000023841858,
          init_inventory: 128,
          last_inventory: 128,
        },
        {
          material_num: "000000000000117604",
          consignment: 24,
          refill_qty: 18,
          current_inventory: 6,
          average_based_on_rec: 6,
          average_consumption: 0.6600000262260437,
          average_data: "[0.53,0.41,0.92,0.77,0.73,0.59]",
          average_trend: 0.8899999856948853,
          init_inventory: 24,
          last_inventory: 24,
        },
        {
          material_num: "000000000000119516",
          consignment: 24,
          refill_qty: 3,
          current_inventory: 21,
          average_based_on_rec: 6,
          average_consumption: 0.09000000357627869,
          average_data: "[0,0.31,0.15,0,0.09,0]",
          average_trend: 0,
          init_inventory: 24,
          last_inventory: 24,
        },
      ],
      cap: 568,
      inv: 377,
    },
    {
      customerNum: "0600190832",
      equipmentNum: "000000000100288069",
      status: "P",
      routeAct: "410",
      lastDelivery: "2021-05-21T18:56:00.000Z",
      lastInventoryUpdate: "2021-05-21T18:56:00.000Z",
      lastFillRate: 0.20999999344348907,
      nextDeliveryPlan: "2021-06-09",
      nextDeliveryAct: "2021-06-09",
      schedulingMethod: "D",
      criticalMaterialInventory: 69,
      criticalMaterialConsumption: 3.690000057220459,
      criticalMaterialNum: "000000000000102603",
      materialInventory: 254,
      materialConsumption: 9.890000343322754,
      dri: 14,
      pfr: 20,
      pfrc: 73,
      pfu: 53,
      numSalesDays: 5,
      ftc: false,
      sort: "100000002021-06-09",
      name: "MARKET SERVICE CENTER-M09",
      street: "5601 CITRUS BLVD",
      city: "HARAHAN",
      activity: "NORM",
      assetNum: "8304897",
      location: "FLEET   ",
      dexErrors: false,
      bank: "",
      suspended: "",
      accessDays: "MTuWThF",
      prefDays: "TuF",
      salesDays: "MTuWThFSaSu",
      details: [
        {
          material_num: "000000000000102592",
          consignment: 12,
          refill_qty: 4,
          current_inventory: 8,
          average_based_on_rec: 6,
          average_consumption: 0.6100000143051147,
          average_data: "[0,0,1.08,0.27,1.54,0.77]",
          average_trend: 1.2599999904632568,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000102603",
          consignment: 69,
          refill_qty: 19,
          current_inventory: 50,
          average_based_on_rec: 6,
          average_consumption: 3.690000057220459,
          average_data: "[3.69,4,3.69,4.27,3.69,2.77]",
          average_trend: 0.75,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000102604",
          consignment: 69,
          refill_qty: 15,
          current_inventory: 54,
          average_based_on_rec: 6,
          average_consumption: 2.8399999141693115,
          average_data: "[2.77,3.2,1.85,3.2,3.85,2.15]",
          average_trend: 0.7599999904632568,
          init_inventory: 69,
          last_inventory: 69,
        },
        {
          material_num: "000000000000102659",
          consignment: 23,
          refill_qty: 5,
          current_inventory: 18,
          average_based_on_rec: 6,
          average_consumption: 0.9100000262260437,
          average_data: "[0.62,0.93,0.62,0.8,1.38,1.08]",
          average_trend: 1.190000057220459,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000117635",
          consignment: 23,
          refill_qty: 4,
          current_inventory: 19,
          average_based_on_rec: 6,
          average_consumption: 0.7599999904632568,
          average_data: "[1.23,0.8,1.08,0.53,0.92,0]",
          average_trend: 0,
          init_inventory: 23,
          last_inventory: 23,
        },
        {
          material_num: "000000000000121751",
          consignment: 12,
          refill_qty: 2,
          current_inventory: 10,
          average_based_on_rec: 6,
          average_consumption: 0.3100000023841858,
          average_data: "[0,0,0.15,0,0.31,1.38]",
          average_trend: 4.449999809265137,
          init_inventory: 12,
          last_inventory: 12,
        },
        {
          material_num: "000000000000135333",
          consignment: 46,
          refill_qty: 4,
          current_inventory: 42,
          average_based_on_rec: 6,
          average_consumption: 0.7699999809265137,
          average_data: "[0.62,0,4,0,0,0]",
          average_trend: 0,
          init_inventory: 46,
          last_inventory: 46,
        },
      ],
      cap: 254,
      inv: 201,
    },
  ],
  planMode: "STATIC",
};

export default function RoutePlanning() {
  const { t } = useTranslation("roadplanning");

  const [currentTab, setCurrentTab] = useState("planning");
  const tabs = [
    { value: "planning", label: t("Route Planning") },
    { value: "overview", label: t("Sales Office & Route Overview") },
    { value: "release", label: t("Adjust and Release") },
  ];

  const today = new Date();
  const tomorrow = new Date(today.valueOf() + 1000 * 3600 * 24);

  const [state, setState] = React.useState({
    isLoaded: false,
    isLoading: false,
    planMode: "",
    salesOffices: [],
    routes: [],
    selectedSalesOffice: "",
    selectedRoute: "",
    filteredRoutes: [],
    planningData: [],
    planningDate: tomorrow,
    activityIndex: -1,
    matDetails: [],
    overdue: 0,
    scheduled: 0,
    banked: 0,
    cases: 0,
  });

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleSelectionChange = (salesOffice, route, filteredRoutes) => {
    setState({ ...state, isLoading: true });
    console.log(salesOffice, route);
    const planningDateStr = state.planningDate.toISOString().split("T")[0];

    //getPlanningList(salesOrg.toString(), salesOffice, route, planningDateStr).then(
    //  (v) => {
    const planningData = v.planning;
    const matDetails = v.matDetails;
    const planMode = v.planMode;

    const hs = calcHeaderStats(planningData, state.planningDate, matDetails);

    setState({
      ...state,
      isLoading: false,
      selectedSalesOffice: salesOffice,
      selectedRoute: route,
      filteredRoutes: filteredRoutes,
      planningData: planningData,
      matDetails: matDetails,
      planMode: planMode,
      overdue: hs.overdue,
      scheduled: hs.scheduled,
      banked: hs.banked,
      cases: hs.cases,
    });
    //  });
  };

  const handleItemChange = (item, index) => {
    var planningData = state.planningData;
    planningData[index] = item;

    const hs = calcHeaderStats(
      planningData,
      state.planningDate,
      state.matDetails
    );

    setState({
      ...state,
      planningData: planningData,
      overdue: hs.overdue,
      scheduled: hs.scheduled,
      banked: hs.banked,
      cases: hs.cases,
    });
  };

  const handleItemsChange = (items) => {
    const hs = calcHeaderStats(items, state.planningDate, state.matDetails);

    setState({
      ...state,
      planningData: items,
      overdue: hs.overdue,
      scheduled: hs.scheduled,
      banked: hs.banked,
      cases: hs.cases,
    });
  };

  const calcHeaderStats = (items, date, matDetails) => {
    var hs = {
      overdue: 0,
      scheduled: 0,
      banked: 0,
      cases: 0,
    };

    var bankedMap = new Map();
    var matMap = new Map();
    if (items && date) {
      items.map((item) => {
        if (item.nextDeliveryPlan) {
          if (
            Date.parse(item.nextDeliveryAct) <= date.valueOf() &&
            item.status === "P"
          ) {
            hs.overdue++;
          }
        }
        if (
          item.status === "S" &&
          item.nextDeliveryAct === date.toISOString().split("T")[0]
        ) {
          hs.scheduled++;
          if (item.bank) {
            bankedMap.set(item.bank, true);
          } else {
            bankedMap.set(item.customerNum, true);
          }
          if (item.details) {
            item.details.map((detail) => {
              var units = matMap.get(detail.material_num) || 0;
              units += detail.refill_qty;
              matMap.set(detail.material_num, units);
            });
          }
        }
      });
      const materialKeys = Array.from(matMap.keys());
      materialKeys.map((matKey) => {
        const units = matMap.get(matKey) || 0;
        const matDetail = matDetails.find(
          (elem) => elem.materialNum === matKey
        );
        if (matDetail && matDetail.denominator && matDetail.numerator) {
          hs.cases += Math.ceil(
            units / (matDetail.denominator * matDetail.numerator)
          );
        }
      });

      const bankedKeys = Array.from(bankedMap.keys());
      hs.banked = bankedKeys.length;
    }
    return hs;
  };

  const handleTotalCSUpdate = (totalCS) => {
    setState({ ...state, cases: totalCS });
  };

  const handleBackendConfirm = () => {
    const selectedSalesOffice = state.selectedSalesOffice;
    const selectedRoute = state.selectedRoute;
    const filteredRoutes = state.filteredRoutes;

    handleSelectionChange(selectedSalesOffice, selectedRoute, filteredRoutes);
  };

  const handleDateChange = (newDate) => {
    setState({ ...state, isLoading: true });
    const planningDateStr = newDate.toISOString().split("T")[0];

    //getPlanningList(salesOrg.toString(), state.selectedSalesOffice, state.selectedRoute, planningDateStr).then(
    //  (v) => {
    const planningData = v.planning;
    const matDetails = v.matDetails;
    const planMode = v.planMode;
    // const planningData = recalculateDRI(planningDataSource, state.planningDate);

    const hs = calcHeaderStats(planningData, state.planningDate, matDetails);

    setState({
      ...state,
      isLoading: false,
      planningData: planningData,
      planningDate: newDate,
      matDetails: matDetails,
      planMode: planMode,
      overdue: hs.overdue,
      scheduled: hs.scheduled,
      banked: hs.banked,
      cases: hs.cases,
    });
    //  });
  };

  useEffect(() => {
    if (state.isLoaded) return;

    const salesOffices = [
      { salesOffice: "A020", name: "Hattiesburg" },
      { salesOffice: "A024", name: "New Orleans" },
    ];
    const r_rt = [
      { salesOffice: "A020", route: "401" },
      { salesOffice: "A024", route: "401" },
      { salesOffice: "A024", route: "402" },
      { salesOffice: "A024", route: "403" },
      { salesOffice: "A024", route: "404" },
      { salesOffice: "A024", route: "405" },
      { salesOffice: "A024", route: "406" },
      { salesOffice: "A024", route: "407" },
      { salesOffice: "A024", route: "408" },
      { salesOffice: "A024", route: "409" },
      { salesOffice: "A024", route: "410" },
      { salesOffice: "A024", route: "411" },
      { salesOffice: "A024", route: "412" },
      { salesOffice: "A024", route: "413" },
      { salesOffice: "A024", route: "414" },
      { salesOffice: "A024", route: "415" },
      { salesOffice: "A024", route: "416" },
      { salesOffice: "A024", route: "417" },
      { salesOffice: "A024", route: "418" },
      { salesOffice: "A024", route: "419" },
      { salesOffice: "A024", route: "420" },
    ];

    setState({
      ...state,
      isLoaded: true,
      salesOffices: salesOffices,
      routes: r_rt,
    });
  }, [state]);

  return (
    <StyledContainer maxWidth={false} className={classes.container}>
      <Header
        data={state}
        onSelectionChange={handleSelectionChange}
        onDateChange={handleDateChange}
        tomorrow={tomorrow}
      />

      <Box mt={1}>
        <Tabs
          onChange={handleTabsChange}
          variant="fullWidth"
          value={currentTab}
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </Box>
      <Divider />
      {state.isLoading && <CircularProgress />}
      {currentTab === "planning" && (
        <Planning
          data={state}
          change={handleItemChange}
          changeAll={handleItemsChange}
        />
      )}
      {currentTab === "overview" && <Overview data={state} />}
      {currentTab === "release" && (
        <Release
          data={state}
          change={handleBackendConfirm}
          updateCS={handleTotalCSUpdate}
        />
      )}
    </StyledContainer>
  );
}
