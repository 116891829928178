import { TextField } from "@mui/material";
import PropTypes from "prop-types";

function TField({ ro, ...rest }) {
  return (
    <TextField
      {...rest}
      color="primary"
      InputProps={{ readOnly: ro }}
    />
  );
}

TField.propTypes = {
  ro: PropTypes.bool.isRequired,
};

export default TField;
