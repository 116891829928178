import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { configureStore } from "redux-store";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import App from "./App";

// Roboto font - MUI default
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css'; // Uncomment if will be used

const store = configureStore();

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
