class CodeCache {
    static _instance = null;
    codeCache = [];

    static getInstance() {
        if (CodeCache._instance == null) {
            CodeCache._instance = new CodeCache();
        }
        return this._instance;
    }

    getCodeCache() {
        return this.codeCache;
    }

    setCodeCache(codeCache) {
        this.codeCache = codeCache;
    }

    clearCodeCache() {
        this.codeCache = [];
    }
}

export default CodeCache;
