import { useParams } from "react-router";
import { LoadingPage } from "components";
import { useAxios } from "hooks";
import { Display, NotFound } from "./components";

const ElavonUSDetail = () => {
  const { id } = useParams(); // Get item id

  // Fetch form data
  const { loading, data, error } = useAxios({
    method: 'get',
    url: `/api/log?guid=${id}`,
  });

  if (loading) return <LoadingPage />;

  if (data.guid === "") return <NotFound />;

  return (
    <Display
      data={data}
      title={data.ssl_txn_id + ": " + data.ssl_transaction_type}
      loadingSave={loading}
    />
  );
};

export default ElavonUSDetail;
