import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Container, FormControlLabel, Checkbox } from "@mui/material";

import { Page, Header, MUITable } from "components";

import { codeDesc /* , getDesc */ } from "utilities";
import { useTranslation } from "react-i18next";
const PREFIX = 'ConsumerList';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function ConsumerList() {
  //Localstorage
  /*   const [displayCol, setDisplayCol] = useLocalStorage(
    "SETTINGS.PBDisplayableColumns", []
  );
  const [colSequence, setColSequence] = useLocalStorage(
    "SETTINGS.PBColSequence", []
  ); */
  //End Localstorage


  const navigate = useNavigate();
  const location = useLocation();
  const [codes, setCodes] = useState(null);

  const { t /* , i18n */ } = useTranslation("material");

  // const getDescription = (value) => {
  //   var desc = "";
  //   if (value && value.length > 0) {
  //     const trans = value.find(elem => elem.lang === i18n.language.toUpperCase());
  //     if (trans) {
  //       desc = trans.description;
  //     }
  //   }
  //   return desc;
  // }

  const columns = [
    {
      label: t("GUID"),
      name: "guid",
      options: {
        display: false,
      },
    },
    {
      label: t("Client"),
      name: "clnt",
      options: {
        display: false,
      },
    },
    { label: t("Identifier"), name: "guid" },
    { label: t("Email / Login"), name: "emailaddress" },
    {
      label: t("Active"),
      name: "active",
      options: {
        customBodyRender: (value) => (
          <FormControlLabel
            label=""
            control={<Checkbox checked={value} color="primary" />}
          />
        ),
      },
    },
    { label: t("First Name"), name: "firstname" },
    { label: t("Last Name"), name: "lastname" },
    { label: t("Birthday"), name: "birthday" },
    {
      label: t("Balance"),
      name: "balance",
      options: {
        customBodyRender: (value) => {
          return (value / 100).toFixed(2);
        },
      },
    },
    { label: t("Points"), name: "points" },
  ];

  const handleRowClick = (rowdata, rowMeta, apiData) => {
    navigate("/app/consumer/" + rowdata[0], apiData);
    //console.log(rowdata[0])
  };

  const handleAddClick = () => {
    //navigate("/app/mterial/-");
  };

  useEffect(() => {
    codeDesc("en", ["sorg"]).then((res) => {
      // console.log(res);
      setCodes(res);
    });
  }, []);

  if (!codes) {
    return null;
  }

  return (
    <StyledPage className={classes.root} title={t("Consumers")}>
      <Container maxWidth={false} className={classes.container}>
        <Header
          title={t("Management")}
          subtitle={t("Consumers")}
          addFunc={handleAddClick}
        />
        <MUITable
          endpoint="/api/consumers"
          cols={columns}
          onRowClick={handleRowClick}
          preloadData={location.state}
        />
      </Container>
    </StyledPage>
  );
}

export default ConsumerList;
