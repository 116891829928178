import { axios, CodeCache } from 'utilities';

async function codeDesc(lang, contexts) {
	var foundContexts = [];
	var missingContextKeys = [];
	let codeCache = CodeCache.getInstance();
	let cacheContent = codeCache.getCodeCache();
	contexts.forEach(function (context) {
		const filtered = cacheContent.filter(function (item) {
			return (item.context === context);
		});
		if (filtered.length > 0) {
			foundContexts = foundContexts.concat(filtered);
		} else {
			missingContextKeys.push(context);
		}
	});
	let successRate = (foundContexts.length / contexts.length) * 100;
	console.log("CodesCache: " + successRate + "% Hit");
	if (missingContextKeys.length > 0) {
		let missingContexts = await getCodesFromBackend(lang, missingContextKeys);
		if (missingContexts && missingContexts.length > 0) {
			console.log("CodesCache: Loaded " + missingContextKeys.toString())
			cacheContent = cacheContent.concat(missingContexts);
			codeCache.setCodeCache(cacheContent);
			foundContexts = foundContexts.concat(missingContexts);
		}
	}
	return foundContexts;
}

// Get items from context. Used to find context from large dataset (27000 lines) and sort items alphabetically. Used in form pages to render select/autocomplete options
function selOptions(data, context) {
	// Early return if no data
	if (!data) return [];

	// Loop is faster then .find because doesnt execute callback function as .find
	let filtered = null;
	for (let i = 0; i < data.length; i++) {
		if (data[i].context === context) {
			filtered = data[i].items;
			break;
		};
	};

	// Early return if no context or no items found
	if (!filtered) return [];

	// Sort in the end if items found + null description check
	return filtered.sort((a, b) => (a.description || '').localeCompare(b.description));
};

function lookup(data, context) {
	if (data) {
		const filtered = selOptions(data, context);
		var lk = {};
		filtered.map((option) => (
			lk[option.code] = option.description
		));
		return lk;
	} else {
		return null;
	}
}

function getDesc(data, context, value) {
	const filtered = selOptions(data, context);
	var desc = "";
	if (filtered && filtered.length > 0) {
		const ft = filtered.find(elem => elem.code === value);
		if (ft) {
			desc = ft.description;
		}
	}
	return desc;
}

async function getCodesFromBackend(lang, contexts) {
	return axios.post('/api/selectoptions', { lang: lang, contexts: contexts })
		.then(res => res.data)
		.catch((error) => {
			console.log(error)
		});
}

export { codeDesc, selOptions, lookup, getDesc }