import { MenuItem, TextField } from "@mui/material";

type Props = {
    name: string,
    label: string,
    defaultValue: any,
    options: any
  };

const DialogSelectField = (props: Props) => {

    const { name, label, defaultValue, options, ...rest } = props;

    return (
        <TextField
        select
        id={name}
        name={name}
        label={label}
        defaultValue={defaultValue}
        margin="dense"
        type="text"
        variant="standard"
        fullWidth
        {...rest}
        >
            {options.map((option: any) => (
                <MenuItem key={option.code} value={option.code}>
                {option.description}
                </MenuItem>
          ))}
        </TextField>
    )

}

export default DialogSelectField;