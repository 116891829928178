import { useState } from "react";
import { Box, Button, Grid, Breadcrumbs, Tooltip, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { ContentContainer, FormSubsection, DisplayField, MUITableD } from "components";
import { DisplayFieldConversion } from "components/DisplayField";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any;
  title: string;
  loadingSave: boolean;
  refund: any;
};

const TransactionDetailForm = (props: Props) => {
  const { data, title, loadingSave, refund } = props;
  const { t } = useTranslation("transaction"); // Get translation
  const navigate = useNavigate();
  const [refundVisible, setRefundVisible] = useState(false);

  const cT = data ? data.cardTransaction : { transactionid: "" };

  const handleRowClick = () => { };

  const handleRelRowClick = (rowdata: Array<String>) => {
    navigate("/app/transactions/" + rowdata[0]);
  };

  const navigateMac = (macaddress: string) => {
    navigate("/app/equipments/searchmac/" + macaddress);
  }

  const handleRefundState = (isOpen: boolean) => {
    setRefundVisible(isOpen);
  }

  const columns = [
    { name: "lineitem", label: t("Line Item") },
    { name: "sku", label: t("SKU") },
    { name: "qty", label: t("Sold Qty") },
    { name: "freeQty", label: t("Free Qty") },
    { name: "percentdiscount", label: t("% Discount") },
    { name: "price", label: t("Price") },
  ];

  const relColumns = [
    { name: "guid", label: t("GUID"), options: { display: false } },
    { name: "transactionts", label: t("Timestamp") },
    { name: "transactiontype", label: t("Type") },
    {
      name: "amount",
      label: t("Amount"),
      options: { customBodyRender: (value: number) => value.toFixed(2) },
    },
    { name: "result", label: t("Result Code") },
    { name: "resultmessage", label: t("Result Message") },
    { name: "sorg", label: t("Sales Org") },
    { name: "profitcenter", label: t("Profit Center") },
    { name: "customernr", label: t("Customer") },
    { name: "outlet", label: t("Outlet") },
    { name: "description", label: t("Description") },
  ];

  return (
    <>

      {/* Fixed Header */}
      <Box sx={{
        py: 1.5,
        borderBottom: 1,
        borderBottomColor: "grey.300",
        backgroundColor: "grey.50",
      }}>
        <ContentContainer>
          <Grid container spacing={3} flexWrap="nowrap" alignItems="center">
            <Grid item xs>
              <Breadcrumbs />
              <Typography variant="h3">
                {title}
              </Typography>
            </Grid>
            {(cT.transactiontype === "Sale" || cT.transactiontype === "CACharge") &&
              <Grid item xs={1}>
                <Tooltip title="Refund">
                  <Button
                    variant="outlined"
                    onClick={() => handleRefundState(true)}
                  >
                    <AttachMoneyIcon />
                  </Button>
                </Tooltip>
              </Grid>
            }
          </Grid>
        </ContentContainer>
      </Box>


      <ContentContainer py={4}>

        <FormSubsection title={t("Transaction")}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DisplayField
                data={cT}
                name="transactionid"
                label={t("Transaction ID")}
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayField
                data={cT}
                name="transactionts"
                label={t("Timestamp")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="transactiontype"
                label={t("Type")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="amount"
                label={t("Amount")}
                conversion={DisplayFieldConversion.Num2Digits}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="freeamount"
                label={t("Free Amount")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="currency"
                label={t("Currency")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="transactionnumber"
                label={t("STAN")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="description"
                label={t("Description")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="merchantinitiated"
                label={t("Merchant Init.")}
              />
            </Grid>
          </Grid>
        </FormSubsection>

        <FormSubsection title={t("Result")}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="result"
                label={t("Result")}
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayField
                data={cT}
                name="resultmessage"
                label={t("Message")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="confirmationid"
                label={t("Confirmation")}
              />
            </Grid>
          </Grid>
        </FormSubsection>

        <FormSubsection title={t("Related Party")}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="sorg"
                label={t("Sales Org")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="profitcenter"
                label={t("Profit Center")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="outlet"
                label={t("Outlet")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="customernr"
                label={t("Customer")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="deviceid"
                label={t("Mac Address")}
                onClick={() => navigateMac(cT.deviceid)}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="equtype"
                label={t("Type")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="serialnr"
                label={t("Serial")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="invoicenumber"
                label={t("Invoice")}
              />
            </Grid>
          </Grid>
        </FormSubsection>

        <FormSubsection title={t("Payment Media")}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="entry"
                label={t("Entry Mode")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="cardtype"
                label={t("Type")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="lastfour"
                label={t("Last 4")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="expiration"
                label={t("Expiration")}
              />
            </Grid>
          </Grid>
        </FormSubsection>

        <FormSubsection title={t("Processing")}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="status"
                label={t("Status")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="processingtime"
                label={t("Processing Time (ms)")}
              />
            </Grid>
            <Grid item xs={4}>
              <DisplayField
                data={cT}
                name="hostname"
                label={t("Host")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="backend"
                label={t("Backend")}
              />
            </Grid>
            <Grid item xs={2}>
              <DisplayField
                data={cT}
                name="batch"
                label={t("Batch")}
              />
            </Grid>
          </Grid>
        </FormSubsection>

        {data.cardTransactionItems &&
          data.cardTransactionItems.length > 0 && (
            <FormSubsection title={t("Line Items")}>
              <MUITableD
                title=""
                data={data.cardTransactionItems}
                cols={columns}
                sortOrder={{ name: "lineitem", direction: "asc" }}
                onRowClick={handleRowClick}
              />
            </FormSubsection>
          )}
        <FormSubsection title={t("Related Transactions")}>
          <MUITableD
            title=""
            data={data.relatedCardTransactions}
            cols={relColumns}
            sortOrder={{ name: "transactionts", direction: "desc" }}
            onRowClick={handleRelRowClick}
          />
        </FormSubsection>
      </ContentContainer>

      <Dialog
        open={refundVisible}
        onClose={() => handleRefundState(false)}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const amount = formJson.amt;
            let refundData = {
              transactionID: cT.transactionid,
              amount: formJson.amt,
              description: formJson.reason
            };
            refund(refundData);
            handleRefundState(false);
          },
        }}
      >
        <DialogTitle>Process Refund</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Please enter a refund amount up to {cT.currency} {cT.amount}
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs>
              <TextField
                autoFocus
                required
                margin="dense"
                id="amt"
                name="amt"
                label="Refund Amount"
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs>
              <TextField
                margin="dense"
                id="reason"
                name="reason"
                label="Refund Reason"
                type="text"
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleRefundState(false)}>Cancel</Button>
          <Button type="submit">Process Refund</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransactionDetailForm;
