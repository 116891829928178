import React, { useEffect, useState, useCallback } from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Card,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
  Chip,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  TextField,
} from "@mui/material";
import { TField, TSelect, } from "components";
import { selOptions, axios } from "utilities";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const PREFIX = 'Detail';

const classes = {
  root: `${PREFIX}-root`,
  fields: `${PREFIX}-fields`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  container: `${PREFIX}-container`,
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    marginTop: theme.spacing(3),
  },
  [`& .${classes.fields}`]: {},

  [`& .${classes.input}`]: {
    display: "none",
  },
  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.container}`]: {
    marginTop: theme.spacing(3),
  },
}));

function Detail({ data, error, helper, codes, handleChange, handleAssign, handleCopyConf, className, ...rest }) {

  const { t } = useTranslation("equipment");
  const [devicedata, setDevicedata] = useState({ dialog: false, identity: "", message: "" });
  const [configdata, setConfigdata] = useState({ dialog: false });
  const [dexdata, setDexdata] = useState({ dialog: false, dex: "" });
  const [copydata, setCopydata] = useState({ dialog: false, equnr: "", message: "" });

  const handleDialogOpen = () => {
    setDevicedata({ dialog: true, identity: "", message: "" })
  }
  const handleDialogCancel = () => {
    setDevicedata({ dialog: false, identity: "", message: "" })
  }

  const handleLocalChange = (event) => {
    event.persist();
    let name = event.target.name;
    let value = event.target.value;

    setDevicedata({
      ...devicedata,
      [name]: value
    });
  };

  const handleConfigOpen = () => {
    var newConfig = {
      dialog: true,
      address: 0,
      mode: 0,
      level: 0,
      dex: 0,
      counters: 0,
      wait: 0,
    };
    const configStr = data.tlcconfig || "00000000";
    const config = parseInt(configStr, 16);
    var mask = 0x01;
    if (config & mask) {
      newConfig.address = 1;
    }
    mask = 0x02;
    if (config & mask) {
      newConfig.mode = 1;
    }
    mask = 0x04;
    if (config & mask) {
      newConfig.level = 1;
    }
    mask = 0x08;
    if (config & mask) {
      newConfig.dex = 1;
    }
    mask = 0x10;
    if (config & mask) {
      newConfig.counters = 1;
    }
    mask = 0x20;
    if (config & mask) {
      newConfig.wait = 1;
    }
    setConfigdata(newConfig);
  }

  const handleConfigChange = (event) => {
    //event.persist();
    let name = event.target.name;
    let value = event.target.value;

    setConfigdata({
      ...configdata,
      [name]: value
    });
  }

  const handleConfigApply = () => {
    var config = 0;
    if (configdata.address == 1) {
      config |= 0x01;
    }
    if (configdata.mode == 1) {
      config |= 0x02;
    }
    if (configdata.level == 1) {
      config |= 0x04;
    }
    if (configdata.dex == 1) {
      config |= 0x08;
    }
    if (configdata.counters == 1) {
      config |= 0x10;
    }
    if (configdata.wait == 1) {
      config |= 0x20;
    }
    var configStr = "00000000" + config.toString(16).toUpperCase();
    configStr = configStr.substring(configStr.length - 8);
    var event = { persist: () => { }, target: {} };
    event.target.name = "tlcconfig";
    event.target.value = configStr;
    event.persist = () => { };
    handleChange(event);
    setConfigdata({
      dialog: false,
    });
  }

  const options = {
    address: [
      { code: 0, description: "Cashless 1 (0x10)" },
      { code: 1, description: "Cashless 2 (0x60)" },
    ],
    mode: [
      { code: 0, description: "USD" },
      { code: 1, description: "EUR" },
    ],
    level: [
      { code: 0, description: "Level 1" },
      { code: 1, description: "Level 3" },
    ],
    dex: [
      { code: 0, description: "DEX/UCS" },
      { code: 1, description: "DDCMP" },
    ],
    counters: [
      { code: 0, description: "External" },
      { code: 1, description: "Internal" },
    ],
    wait: [
      { code: 0, description: "No delay" },
      { code: 1, description: "Wait 7s" },
    ]
  }

  const { enqueueSnackbar } = useSnackbar();

  const handleDialog = async () => {
    axios.get("/api/equipmentdevice?identity=" + devicedata.identity).then((response) => {
      if (response.data) {
        if (response.data.currentUsage) {
          setDevicedata({ ...devicedata, message: "Already used by " + response.data.currentUsage + "!" });
        } else {
          var macaddress = response.data.macaddress;
          if (macaddress.length == 12) {
            macaddress = macaddress.substr(0, 2) + ":"
              + macaddress.substr(2, 2) + ":"
              + macaddress.substr(4, 2) + ":"
              + macaddress.substr(6, 2) + ":"
              + macaddress.substr(8, 2) + ":"
              + macaddress.substr(10, 2);
          }
          handleAssign(response.data.identifier, response.data.identity, macaddress);
          handleDialogCancel();
        }
      }
    });
  }

  const handleDexDownload = async () => {
    axios.get("/api/equipmentdex?equnr=" + data.equnr).then((response) => {
      if (response.data) {
        setDexdata({ dialog: true, dex: response.data });
      }
    });
  }

  const handleDexClose = () => {
    setDexdata({ dialog: false, dex: "" });
  }

  const handleDexUpload = (event) => {
    if (event.target.files[0]) {
      event.target.files[0].text()
        .then(txt => {
          console.log(txt);
          let url = "/api/equipmentuploaddex";
          let request = { equnr: data.equnr, dexfile: txt, reconfiguration: false };
          axios.post(url, request)
            .then(response => {
              if (response.status === 200) {
                enqueueSnackbar("Update successful", { variant: "success" });
              }
            })
            .catch(reason => {
              console.log(reason);
              enqueueSnackbar("Error processing DEX: " + reason, { variant: "error" });
            })
        })
    }
  }

  const handleCopyChange = (event) => {
    event.persist();
    let name = event.target.name;
    let value = event.target.value;

    setCopydata({
      ...copydata,
      [name]: value
    });
  }

  const handleCopy = () => {
    handleCopyConf(copydata.equnr);
    setCopydata({ dialog: false });
  }

  return (
    <Root>
      <Card>
        <CardContent>
          <form>
            <Grid
              container
              className={clsx(classes.root, className)}
              spacing={3}
              {...rest}
            >
              <Grid item lg={12} md={12} xl={12} xs={12}>
                {data.active ?
                  <Chip
                    color='primary'
                    label={t('Active')}
                    clickable
                    onClick={handleChange}
                    name="active"
                    value="true"
                  />
                  :
                  <Chip
                    color='primary'
                    variant='outlined'
                    label={t('Inactive')}
                    clickable
                    onClick={handleChange}
                    name="active"
                    value="false"
                  />
                }
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <TField
                  fullWidth
                  error={error.equnr || false}
                  helperText={helper.equnr || ""}
                  label={t("Equipment Number") + "/" + t("Asset Number")}
                  name="equnr"
                  required
                  onChange={handleChange}
                  value={data.equnr || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TSelect
                  label={t("Sales Org")}
                  name="sorg"
                  value={data.sorg || ""}
                  onChange={handleChange}
                  opt={selOptions(codes, "sorg")}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.profitcenter || false}
                  helperText={helper.profitcenter || ""}
                  label={t("Profit Center")}
                  name="profitcenter"
                  onChange={handleChange}
                  value={data.profitcenter || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.macaddress || false}
                  helperText={helper.macaddress || ""}
                  label={t("Mac-Address")}
                  name="macaddress"
                  onChange={handleChange}
                  value={data.macaddress || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.paymenttermserialnr || false}
                  helperText={helper.paymenttermserialnr || ""}
                  label={t("Payment Terminal Serial No")}
                  name="paymenttermserialnr"
                  onChange={handleChange}
                  value={data.paymenttermserialnr || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TSelect
                  label={t("Equipment Type")}
                  name="equtype"
                  value={data.equtype || ""}
                  onChange={handleChange}
                  opt={selOptions(codes, "equtype")}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.manufacturer || false}
                  helperText={helper.manufacturer || ""}
                  label={t("Manufacturer")}
                  name="manufacturer"
                  onChange={handleChange}
                  value={data.manufacturer || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.model || false}
                  helperText={helper.model || ""}
                  label={t("Model")}
                  name="model"
                  onChange={handleChange}
                  value={data.model || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.serialnr || false}
                  helperText={helper.serialnr || ""}
                  label={t("GPU Serial Number")}
                  name="serialnr"
                  onChange={handleChange}
                  value={data.serialnr || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.factoryserialnr || false}
                  helperText={helper.factoryserialnr || ""}
                  label={t("Factory Serial Number")}
                  name="factoryserialnr"
                  onChange={handleChange}
                  value={data.factoryserialnr || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <FormControlLabel
                  control={<Switch checked={data.demomode || false} onChange={handleChange} name="demomode" color="primary" />}
                  label="Demo Mode (Caution: Free Vends!)"
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.outlet || false}
                  helperText={helper.outlet || ""}
                  label={t("Outlet")}
                  name="outlet"
                  onChange={handleChange}
                  value={data.outlet || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.outletname || false}
                  helperText={helper.outletname || ""}
                  label={t("Outlet Name")}
                  name="outletname"
                  onChange={handleChange}
                  value={data.outletname || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <TField
                  fullWidth
                  error={error.outletaddress || false}
                  helperText={helper.outletaddress || ""}
                  label={t("Outlet Address")}
                  name="outletaddress"
                  onChange={handleChange}
                  value={data.outletaddress || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.label || false}
                  helperText={helper.label || ""}
                  label={t("Label")}
                  name="label"
                  onChange={handleChange}
                  value={data.label || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.campus || false}
                  helperText={helper.campus || ""}
                  label={t("Campus")}
                  name="campus"
                  onChange={handleChange}
                  value={data.campus || ''}
                  ro={false}
                />
              </Grid>
              <Grid item lg={4} md={4} xl={4} xs={4}>
                <TField
                  fullWidth
                  error={error.lasttransaction || false}
                  helperText={helper.lasttransaction || ""}
                  label={t("Last Transaction")}
                  name="lasttransaction"
                  onChange={handleChange}
                  value={data.lasttransaction || ''}
                  ro={true}
                />
              </Grid>
              <Grid item lg={2} md={2} xl={2} xs={2}>
                <TField
                  fullWidth
                  error={error.lasterror || false}
                  helperText={helper.lasterror || ""}
                  label={t("Last Error")}
                  name="lasterror"
                  onChange={handleChange}
                  value={data.lasterror || ''}
                  ro={true}
                />
              </Grid>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <TField
                  fullWidth
                  error={error.note || false}
                  helperText={helper.note || ""}
                  label={t("Notes")}
                  name="note"
                  onChange={handleChange}
                  value={data.note || ''}
                  ro={false}
                />
              </Grid>
            </Grid>
            {data.equtype == "VM" &&
              <Grid
                container
                className={clsx(classes.root, className)}
                spacing={3}
                {...rest}
              >
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    fullWidth
                    error={error.tlcserialnr || false}
                    helperText={helper.tlcserialnr || ""}
                    label={t("TLC Serial No")}
                    name="tlcserialnr"
                    onChange={handleChange}
                    value={data.tlcserialnr || ''}
                    ro={true}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    fullWidth
                    error={error.tlcconfig || false}
                    helperText={helper.tlcconfig || ""}
                    label={t("Config")}
                    name="config"
                    onChange={handleChange}
                    value={data.tlcconfig || ''}
                    ro={true}
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={6}>
                  <TField
                    fullWidth
                    error={error.tlcidentity || false}
                    helperText={helper.tlcidentity || ""}
                    label={t("TLC Identity")}
                    name="tlcidentity"
                    onChange={handleChange}
                    value={data.tlcidentity || ''}
                    ro={true}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    fullWidth
                    error={error.tlcfirmware || false}
                    helperText={helper.tlcfirmware || ""}
                    label={t("Firmware")}
                    name="tlcfirmware"
                    onChange={handleChange}
                    value={data.tlcfirmware || ''}
                    ro={true}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    fullWidth
                    error={error.meter || false}
                    helperText={helper.meter || ""}
                    label={t("Meter")}
                    name="meter"
                    onChange={handleChange}
                    value={data.meter || ''}
                    ro={true}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    fullWidth
                    error={error.totalizer || false}
                    helperText={helper.totalizer || ""}
                    label={t("Totalizer")}
                    name="totalizer"
                    onChange={handleChange}
                    value={data.totalizer || ''}
                    ro={true}
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={6}>
                  <TField
                    fullWidth
                    error={error.tlcinfo || false}
                    helperText={helper.tlcinfo || ""}
                    label={t("Info")}
                    name="tlcinfo"
                    onChange={handleChange}
                    value={data.tlcinfo || ''}
                    ro={true}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <FormControlLabel
                    control={<Switch checked={data.tlcoos || false} onChange={handleChange} name="tlcoos" color="primary" />}
                    label="OOS detection"
                  />
                </Grid>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <TField
                    fullWidth
                    error={error.counters || false}
                    helperText={helper.counters || ""}
                    label={t("Counters")}
                    name="counters"
                    onChange={handleChange}
                    value={data.counters || ''}
                    ro={true}
                  />
                </Grid>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <TField
                    fullWidth
                    error={error.countersrefill || false}
                    helperText={helper.countersrefill || ""}
                    label={t("Counters@Refill")}
                    name="countersrefill"
                    onChange={handleChange}
                    value={data.countersrefill || ''}
                    ro={true}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    fullWidth
                    error={error.dexoffset || false}
                    helperText={helper.dexoffset || ""}
                    label={t("DEX Offset")}
                    name="dexoffset"
                    onChange={handleChange}
                    value={data.dexoffset || ''}
                    ro={false}
                  />
                </Grid>
              </Grid>
            }
          </form>
        </CardContent>
      </Card>
      <Container maxWidth={false} className={classes.container}>
        <Button color="primary" size="large" variant="contained" className={classes.button} onClick={() => handleDialogOpen()}>
          Assign Device
        </Button>
        <Button color="primary" size="large" variant="contained" className={classes.button} onClick={() => handleConfigOpen()}>
          Configure Device
        </Button>
        <Button color="primary" size="large" variant="contained" className={classes.button} onClick={() => handleDexDownload()}>
          Generate DEX
        </Button>
        <input accept="*" className={classes.input} id="icon-button-file" type="file" onChange={handleDexUpload} />
        <label htmlFor="icon-button-file">
          <Button color="primary" size="large" variant="contained" className={classes.button} aria-label="Upload DEX" component="span">
            Configure using DEX
          </Button>
        </label>
        <Button color="primary" size="large" variant="contained" className={classes.button} onClick={() => setCopydata({ dialog: true })}>
          Configure using Equipment
        </Button>
      </Container>

      <Dialog aria-labelledby="leave-popup-title" open={devicedata.dialog}>
        <DialogTitle id="leave-popup-title">
          Change device assignment
        </DialogTitle>
        <DialogContent dividers>
          {devicedata.message &&
            <Typography gutterBottom className={classes.dialogtypo} color="error">
              {devicedata.message}
            </Typography>
          }
          <Grid
            container
            className={clsx(classes.root, className)}
            spacing={3}
            {...rest}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <TField
                label={t("Device Serial")}
                name="identity"
                value={devicedata.identity}
                onChange={handleLocalChange}
                ro={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogCancel()} color="primary">Cancel</Button>
          <Button onClick={() => handleDialog()} color="primary" variant="outlined">Assign Device</Button>
        </DialogActions>
      </Dialog>

      <Dialog aria-labelledby="copy-popup-title" open={copydata.dialog}>
        <DialogTitle id="copy-popup-title">
          Copy configuration
        </DialogTitle>
        <DialogContent dividers>
          {copydata.message &&
            <Typography gutterBottom className={classes.dialogtypo} color="error">
              {copydata.message}
            </Typography>
          }
          <Grid
            container
            className={clsx(classes.root, className)}
            spacing={3}
            {...rest}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <TField
                label={t("Equipment Number")}
                name="equnr"
                value={copydata.equnr}
                onChange={handleCopyChange}
                ro={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCopydata({ dialog: false })} color="primary">Cancel</Button>
          <Button onClick={() => handleCopy()} color="primary" variant="outlined">Copy Configuration</Button>
        </DialogActions>
      </Dialog>

      <Dialog aria-labelledby="config-popup-title" open={configdata.dialog}>
        <DialogTitle id="config-popup-title">
          Change device configuration
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            className={clsx(classes.root, className)}
            spacing={3}
            {...rest}
          >
            <Grid item lg={6} md={6} xl={6} xs={6}>
              <TSelect
                label={t("MDB Address")}
                name="address"
                value={configdata.address || 0}
                onChange={handleConfigChange}
                opt={options.address}
                ro={false}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={6}>
              <TSelect
                label={t("MDB Currency")}
                name="mode"
                value={configdata.mode || 0}
                onChange={handleConfigChange}
                opt={options.mode}
                ro={false}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={6}>
              <TSelect
                label={t("MDB Level")}
                name="level"
                value={configdata.level || 0}
                onChange={handleConfigChange}
                opt={options.level}
                ro={false}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={6}>
              <TSelect
                label={t("DEX")}
                name="dex"
                value={configdata.dex || 0}
                onChange={handleConfigChange}
                opt={options.dex}
                ro={false}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={6}>
              <TSelect
                label={t("Counters")}
                name="counters"
                value={configdata.counters || 0}
                onChange={handleConfigChange}
                opt={options.counters}
                ro={false}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={6}>
              <TSelect
                label={t("Failed Vend Retry")}
                name="wait"
                value={configdata.wait || 0}
                onChange={handleConfigChange}
                opt={options.wait}
                ro={false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfigdata({ dialog: false })} color="primary">Cancel</Button>
          <Button onClick={() => handleConfigApply()} color="primary" variant="outlined">Apply Config</Button>
        </DialogActions>
      </Dialog>

      <Dialog aria-labelledby="dex-popup-title" open={dexdata.dialog}>
        <DialogTitle id="dex-popup-title">
          Generated DEX file
        </DialogTitle>
        <DialogContent dividers>
          <TextField multiline={true} value={dexdata.dex} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDexClose()} color="primary">OK</Button>
        </DialogActions>
      </Dialog>

    </Root>
  );
}

Detail.propTypes = {
  data: PropTypes.object.isRequired,
  error: PropTypes.object,
  helper: PropTypes.object,
  codes: PropTypes.array,
  handleChange: PropTypes.func,
  handleAssign: PropTypes.func,
  handleCopyConf: PropTypes.func,
  className: PropTypes.string,
};

export default Detail;