import React from "react";
import { Card, Grid, CardContent, Typography } from "@mui/material";

function Volume({ volume }) {
  var cardStyle = {
    //display: 'block',
    //width: '30vw',
    //transitionDuration: '0.3s',
    //height: "10vw",
    height: 140,
  };

  return (
    <Card style={cardStyle}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs="12">
            <Typography variant="h5" color="textSecondary">
              Total Volume
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Typography variant="h4" color="inherit">
              {volume && volume.toFixed(2)} USD
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Typography variant="caption" color="textSecondary">
              for the selected timeframe
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Volume;
