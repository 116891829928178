import { TextField, TextFieldProps } from "@mui/material";
import { useField } from 'formik';
import { memo } from "react";

// Docs:
// https://formik.org/docs/examples/with-material-ui
// https://formik.org/docs/api/useFormik
// https://formik.org/docs/api/useField
type FormikTextFieldProps = TextFieldProps & {
  type: string;
  name: string;
  label: string;
  readOnly?: boolean; // Shortened readOnly. To pass readOnly in parent. MUI passes InputProps={{ readOnly }}
};

const FormikTextField = (props: FormikTextFieldProps) => {
  const { type, name, label, readOnly, disabled, multiline, ...rest } = props;

  const [field, meta, helpers] = useField(name);
  const { value: fieldValue } = field;
  const error = meta.touched && meta.error;

  // Fallback for null and undefined values
  const value = fieldValue !== null ? fieldValue : "";

  return (
    <TextField
      {...field} // name, value, onChange, onBlur
      value={value}
      type={type}
      label={label}
      error={Boolean(error)}
      helperText={error}
      margin="dense"
      fullWidth

      // Optional
      InputProps={{ readOnly }}
      {...rest} // handles MUI props - disabled, rows, multiline, required etc
    />
  );
};

export default memo(FormikTextField);
