import { object, string, number, setLocale } from "yup";

// Error message localization
setLocale({
  mixed: {
    default: 'invalid',
  },
  string: {
    min: ({ min }) => ({ key: 'min_long', values: { min } }),
    max: ({ max }) => ({ key: 'max_long', values: { max } }),
  },
  number: {
    min: ({ min }) => ({ key: 'min_long', values: { min } }),
    max: ({ max }) => ({ key: 'max_long', values: { max } }),
  },
});

// Schema - AFTER setLocale
const validationSchema = object({
  matnr: string().max(18, "Material number cannot be longer than 18 characters").required(),
  description: string().max(100, "Description cannot be longer than 100 characters").nullable(),
  baseuom: string().max(5, "BaseUOM cannot be longer than 5 characters").nullable(),
  matgroup: string().max(10, "Material Group cannot be longer than 10 characters").nullable(),
  mattype: string().max(5, "Material type cannot be longer than 5 characters").nullable(),
  note: string().max(100, "Note cannot be longer than 100 characters").nullable(),
  packsize: string().max(5, "Packsize cannot be longer than 5 characters").nullable(),
  packtype: string().max(5, "Packtype cannot be longer than 5 characters").nullable(),
  product: string().max(5, "Product cannot be longer than 5 characters").nullable(),
  salesunit: string().max(5, "Sales unit cannot be longer than 5 characters").nullable(),
  shelflife: number().default(0),
  taxtype: string().max(5, "Tax type cannot be longer than 5 characters").nullable(),
});

export default validationSchema;
