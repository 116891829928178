import React, { useCallback, useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useParams, useLocation } from "react-router";
import {
  Box /* Button, */,
  Container,
  Divider /* Dialog, DialogTitle, DialogContent, DialogActions, */,
  //Tab,
  Tabs,
  // Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { Page, Header } from "components";

import { axios, useIsMountedRef, codeDesc } from "utilities";
import Detail from "./Detail";
//import Description from './Description';
//import Uom from './Uom';
//import Bom from './Bom';
import { useTranslation } from "react-i18next";
const PREFIX = 'TransactionDetail';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function TransactionDetail() {
  const { t /* , i18n */ } = useTranslation("transaction");
  // const navigate = useNavigate();

  const location = useLocation();
  const params = useParams();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({});
  // const [error, setError] = useState({});
  // const [helper, setHelper] = useState({});
  const [codes, setCodes] = useState(null);
  const [refundPopup, setRefundPopup] = useState({ display: false });
  const [currentTab, setCurrentTab] = useState("detail");
  /* const tabs = [
      { value: 'detail', label: t('Details') },
      { value: 'description', label: t('Descriptions') },
      { value: 'uom', label: t('Unit of Measures') },
      { value: 'bom', label: t('Bill of Materials') },
    ]; */

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleDataChange = (event) => {
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    if (event.type === "click") {
      if (event.target.innerHTML === "Active") {
        name = "active";
        value = false;
      } else if (event.target.innerHTML === "Inactive") {
        name = "active";
        value = true;
      }
    }

    setData({
      ...data,
      [name]: value,
    });
    console.log(data);
  };

  const handleLeaveRefundPopupCancel = () => {
    setRefundPopup({ ...refundPopup, display: false });
  };

  const handleLeaveRefundPopupSave = () => {
    let url = "/api/elavon-v1/refund";
    let refund = {
      transactionID: data.transactionid,
      amount: data.refundamount,
      description: data.refunddescription,
    };
    axios
      .post(url, refund)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          if (response.data && response.data.returnCode === 0) {
            enqueueSnackbar("Refund issued successfully", {
              variant: "success",
            });
            getData();
          } else {
            enqueueSnackbar("Error creating refund: " + response.data.message, {
              variant: "error",
            });
          }
        }
      })
      .catch((reason) => {
        console.log(reason);
        enqueueSnackbar("Error creating refund: " + reason, {
          variant: "error",
        });
      });
    setRefundPopup({ ...refundPopup, display: false });
  };

  const getData = useCallback(() => {
    axios
      .get("/api/transaction?guid=" + params.guid)
      .then((response) => {
        if (isMountedRef.current) {
          // console.log(response.data);
          var td = response.data.cardTransaction;
          td["cardTransactionItems"] = response.data.cardTransactionItems;
          td["relatedCardTransactions"] = response.data.relatedCardTransactions;
          td["refundamount"] = 0;
          td["refunddescription"] = "";
          setData(td);
        }
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  }, [isMountedRef, params]);

  useEffect(() => {
    codeDesc("en", ["sorg"]).then((res) => {
      setCodes(res);
    });
    // if (params.matnr === '-') {
    //   // new material
    //   setData({});
    // } else {
    getData();
    // }
  }, [getData]);

  const showRefund = () => {
    setRefundPopup({ ...refundPopup, display: true });
  };

  if (!(data /* && codes */)) {
    return null;
  }

  let showRefundFunc = null;
  if (data.transactiontype === "Sale") {
    showRefundFunc = showRefund;
  }

  return (
    <StyledPage className={classes.root} title={t("Transaction Details")}>
      <Container maxWidth={false}>
        <Header
          area="Management"
          breadcrumb="/app/transaction"
          apiData={location.state}
          title={t("Transactions")}
          subtitle={data.transactionid}
          refundFunc={showRefundFunc}
        />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
            className={classes.tabs}
          >
            {/* {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))} */}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === "detail" && (
            <Detail
              data={data}
              refundPopup={refundPopup}
              codes={codes}
              handleChange={handleDataChange}
              handleLeaveRefundPopupCancel={handleLeaveRefundPopupCancel}
              handleLeaveRefundPopupSave={handleLeaveRefundPopupSave}
            />
          )}
          {/*            {currentTab === 'description' && <Description data={data.materialT} error={error} helper={helper} codes={codes} handleChange={handleDescriptionsChange}/>}
            {currentTab === 'uom' && <Uom data={data.materialUom} error={error} helper={helper} codes={codes} handleChange={handleUomsChange}/>}
            {currentTab === 'bom' && <Bom data={data.materialComp} error={error} helper={helper} codes={codes} handleChange={handleBomsChange}/>}                  
  */}{" "}
        </Box>
      </Container>
    </StyledPage>
  );
}

export default TransactionDetail;
