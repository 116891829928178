import React, { useEffect, useState /* , useCallback */ } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Container, Grid } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Page, Header, MUITableD, TField, TSelect } from 'components';

import { codeDesc, selOptions, axios } from "utilities";
import { useTranslation } from "react-i18next";
const PREFIX = 'TransactionList';

const classes = {
  root: `${PREFIX}-root`,
  searchbox: `${PREFIX}-searchbox`,
  button: `${PREFIX}-button`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.searchbox}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.button}`]: {
    marginRight: theme.spacing(3),
  }
}));

function TransactionList() {
  const ONE_WEEK = 3600 * 24 * 7 * 1000;

  const initData = {
    sorg: " ",
    profitcenter: "",
    outlet: "",
    transactionid: "",
    transactiontype: "*",
    serialnr: "",
    deviceid: "",
    cardtype: "",
    lastfour: "",
    expiration: "",
    customernr: "",
    invoicenr: "",
    status: "*",
    dateFrom: new Date(new Date().valueOf() - ONE_WEEK),
    dateTo: new Date(),
  };


  const navigate = useNavigate();
  const [codes, setCodes] = useState(null);
  const [data, setData] = useState(initData);
  const [result, setResult] = useState([]);
  const { t } = useTranslation("transaction");

  const transactiontypes = [
    { code: "*", description: "All" },
    { code: "Auth", description: "Authorization" },
    { code: "AuthIncr", description: "Incremental Authorization" },
    { code: "Sale", description: "Sale" },
    { code: "Void", description: "Void" },
    { code: "Refund", description: "Refund" },
    { code: "AuthOnly", description: "Authorization Only" },
  ];

  const stati = [
    { code: "*", description: "All" },
    { code: "STL", description: "Settled" },
    { code: " ", description: "Not Settled" },
  ];

  const columns = [
    { name: "guid", label: t("GUID"), options: { display: false } },
    { name: "transactionid", label: t("Transaction ID") },
    { name: "transactionts", label: t("Timestamp") },
    { name: "transactionnumber", label: t("STAN") },
    { name: "transactiontype", label: t("Type") },
    { name: "status", label: t("Status") },
    {
      name: "amount",
      label: t("Amount"),
      options: { customBodyRender: (value) => value.toFixed(2) },
    },
    {
      name: "freeamount",
      label: t(" Free Amount"),
      options: { customBodyRender: (value) => value.toFixed(2) },
    },
    { name: "result", label: t("Result Code") },
    { name: "resultmessage", label: t("Result Message") },
    { name: "profitcenter", label: t("Profit Center") },
    { name: "customernr", label: t("Customer") },
    { name: "outlet", label: t("Outlet") },
    { name: "serialnr", label: t("Serial") },
    { name: "deviceid", label: t("Device / Mac Address") },
    { name: "cardtype", label: t("Card Type") },
    { name: "lastfour", label: t("Last 4") },
    { name: "expiration", label: t("Expiration Date") },
    { name: "invoicenumber", label: t("Invoice") },
    { name: "batch", label: t("Batch") },
    { name: "description", label: t("Description") },
  ];

  const handleRowClick = (rowdata) => {
    navigate("/app/transaction/" + rowdata[0]);
    console.log(rowdata[0]);
  };

  const handleChange = (event) => {
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleDateFromChange = (event) => {
    const newDate = event._d;
    setData({ ...data, dateFrom: newDate });
  };

  const handleDateToChange = (event) => {
    const newDate = event._d;
    setData({ ...data, dateTo: newDate });
  };

  const handleSearch = () => {
    console.log(data);
    getData(data);
  };

  const handleReset = () => {
    setData(initData);
  };

  const getData = (data) => {
    axios
      .post("/api/transactionsearch", data)
      .then((response) => {
        setResult(response.data);
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  };

  useEffect(() => {
    codeDesc("en", ["sorg"]).then((res) => {
      setCodes(res);
    });
  }, []);

  if (!codes) {
    return null;
  }

  return (
    <StyledPage className={classes.root} title={t("Transactions")}>
      <Container maxWidth={false} className={classes.container}>
        <Header title={t("Payments")} subtitle={t("Transaction Search")} />

        <Card className={classes.searchbox}>
          <CardContent>
            <form>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xl={4} xs={4}>
                  <DateTimePicker
                    value={dayjs(data.dateFrom)}
                    onChange={handleDateFromChange}
                    label={t("Date From")}
                    showTodayButton
                    fullWidth
                  />
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={4}>
                  <DateTimePicker
                    value={dayjs(data.dateTo)}
                    onChange={handleDateToChange}
                    label={t("Date To")}
                    showTodayButton
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TSelect
                    label={t("Sales Org")}
                    name="sorg"
                    value={data.sorg || ""}
                    onChange={handleChange}
                    opt={selOptions(codes, "sorg")}
                    ro={false}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Profit Center")}
                    name="profitcenter"
                    value={data.profitcenter || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Customer")}
                    name="customernr"
                    value={data.customernr || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Outlet")}
                    name="outlet"
                    value={data.outlet || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TSelect
                    label={t("Transaction Type")}
                    name="transactiontype"
                    value={data.transactiontype || "*"}
                    onChange={handleChange}
                    opt={transactiontypes}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TSelect
                    label={t("Status")}
                    name="status"
                    value={data.status || "*"}
                    onChange={handleChange}
                    opt={stati}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Transaction ID")}
                    name="transactionid"
                    value={data.transactionid || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Serial Number")}
                    name="serialnr"
                    value={data.serialnr || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Device ID")}
                    name="deviceid"
                    value={data.deviceid || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Cardtype")}
                    name="cardtype"
                    value={data.cardtype || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "product")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Last 4")}
                    name="lastfour"
                    value={data.lastfour || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "product")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Expiration (mmyy)")}
                    name="expiration"
                    value={data.expiration || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "product")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Invoice Number")}
                    name="invoicenr"
                    value={data.invoicenr || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "product")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={handleSearch}
                    className={classes.button}
                  >
                    Search Transactions
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    variant="outlined"
                    onClick={handleReset}
                    className={classes.button}
                  >
                    Reset Form
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>

        <MUITableD
          data={result}
          cols={columns}
          sortOrder={{ name: "transactionnumber", diection: "desc" }}
          onRowClick={handleRowClick}
        />
      </Container>
    </StyledPage>
  );
}

export default TransactionList;
