import { styled } from '@mui/material/styles';
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Breadcrumbs, Button, Grid, Tooltip, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
//Better TransformIcon would be: import CurrencyExchangeIcon from '@mui/icons-material-material/CurrencyExchange';
//but it does not compile.
import TransformIcon from "@mui/icons-material/Transform";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import NavigateNextIcon from "@mui/icons-material/Edit";
import AttachMoneyIcon from "@mui/icons-material/Edit";

const PREFIX = 'Header';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.button}`]: { marginLeft: theme.spacing(3), marginBottom: theme.spacing(2) }
}));

function Header({
  className,
  area,
  title,
  subtitle,
  breadcrumb,
  apiData,
  backFunc,
  saveFunc,
  addFunc,
  deleteFunc,
  editFunc,
  refundFunc,
  changebalFunc,
  ro,
  ...rest
}) {


  let headerArea;
  if (breadcrumb) {
    headerArea = (
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography component="h2" variant="overline" color="textPrimary">
            {area}
          </Typography>
          <Typography
            variant="overline"
            color="textPrimary"
            to={{ pathname: breadcrumb, state: apiData }}
            component={RouterLink}
          >
            {title}
          </Typography>
        </Breadcrumbs>
        <Typography component="h1" variant="h3" color="textPrimary" gutterBottom>
          {subtitle}
        </Typography>
      </Grid>
    );
  } else {
    headerArea = (
      <Grid item>
        <Box mb={2}>
          <Typography
            variant="overline"
            color="GrayText"
          >
            {title}
          </Typography>
          <Typography component="h1" variant="h3" gutterBottom>
            {subtitle}
          </Typography>
        </Box>
      </Grid>
    );
  }

  let backButton;
  if (backFunc) {
    backButton = (
      <Tooltip title="Back">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={backFunc}
        >
          <ArrowBackIcon />
        </Button>
      </Tooltip>
    );
  }

  let addButton;
  if (addFunc) {
    addButton = (
      <Tooltip title="Add">
        <Button className={classes.button} variant="outlined" onClick={addFunc}>
          <AddIcon />
        </Button>
      </Tooltip>
    );
  }

  let editButton;
  if (editFunc) {
    if (!ro) {
      editButton = (
        <Tooltip title="Edit">
          <Button
            className={classes.button}
            variant="outlined"
            onClick={editFunc}
            color="primary"
          >
            <EditIcon />
          </Button>
        </Tooltip>
      );
    } else {
      editButton = (
        <Tooltip title="Edit">
          <Button
            className={classes.button}
            variant="outlined"
            onClick={editFunc}
          >
            <EditIcon />
          </Button>
        </Tooltip>
      );
    }
  }

  let saveButton;
  if (saveFunc) {
    saveButton = (
      <Tooltip title="Save">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={saveFunc}
        >
          <SaveIcon />
        </Button>
      </Tooltip>
    );
  }

  let deleteButton;
  if (deleteFunc) {
    deleteButton = (
      <Tooltip title="Delete">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={deleteFunc}
        >
          <DeleteIcon />
        </Button>
      </Tooltip>
    );
  }

  let refundButton;
  if (refundFunc) {
    refundButton = (
      <Tooltip title="Refund">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={refundFunc}
        >
          <AttachMoneyIcon />
        </Button>
      </Tooltip>
    );
  }

  let changebalButton;
  if (changebalFunc) {
    changebalButton = (
      <Tooltip title="Change Balance">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={changebalFunc}
        >
          <TransformIcon />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Root {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        {headerArea}
        <Grid item>
          {backButton}
          {addButton}
          {editButton}
          {saveButton}
          {deleteButton}
          {refundButton}
          {changebalButton}
        </Grid>
      </Grid>
    </Root>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  area: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  breadcrumb: PropTypes.string,
  apiData: PropTypes.object,
  backFunc: PropTypes.func,
  deleteFunc: PropTypes.func,
  saveFunc: PropTypes.func,
  editFunc: PropTypes.func,
  addFunc: PropTypes.func,
  refundFunc: PropTypes.func,
  changebalFunc: PropTypes.func,
  ro: PropTypes.bool,
};

export default Header;
