import { createTheme } from "@mui/material";
import { PRIMARY_MAIN } from "const";

// Custom MUI theme
// https://mui.com/material-ui/customization/theming/
const theme = createTheme({
  // Breakpoints
  // Change CSS and JS beakpoints ONLY together to match JS and CSS styles:
  // styles/scss/_variables.scss, styles/theme.ts
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      // Add here if need more. Remember to add types declaration:
      // https://mui.com/material-ui/customization/breakpoints/#custom-breakpoints
      // xxl: 1536, 
    },
  },


  // Palette
  palette: {
    primary: {
      main: PRIMARY_MAIN,
    },
  },

  // Typography
  typography: {
    h1: {
      fontSize: 35, // Old
    },
    h2: {
      fontSize: 29, // Old
    },
    h3: {
      fontSize: 24, // Old
    },
    h4: {
      fontSize: 20, // Old
    },
    h5: {
      fontSize: 16, // Old
    },
    h6: {
      fontSize: 14, // Old
    },
  },

  // Components. Override global styles
  components: {

    // MuiInputBase: {
    //   styleOverrides: {
    //     root: {
    //       height: 50,
    //       padding: "16px, 16px",
    //     },
    //   },
    // },

    // Buttons
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: "#fff",
        },
      },
    },

    // Chip
    MuiChip: {
      styleOverrides: {
        filledPrimary: {
          color: "#fff",
        },
      },
    },

    // Temporary styling for mui-datatables. TODO: Change after upgrade mui-datatables
    MUIDataTable: {
      styleOverrides: {
        root: {
          '&.MuiPaper-root': {
            border: "1px solid rgba(0, 0, 0, 0.12)",
            boxShadow: "none",

            "& .MuiTableCell-head": {
              whiteSpace: "nowrap"
            },

            "& .MuiTableCell-footer": {
              borderBottom: "none",
            },
          },
        },
      },
    },

    // MuiListItemButton: {
    //   styleOverrides: {
    //     root: {
    //       "&:hover": {
    //         backgroundColor: PRIMARY_MAIN,
    //         color: '#fff',
    //       },
    //     },
    //   },
    // },

    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       "&:hover": {
    //         backgroundColor: PRIMARY_MAIN,
    //         color: '#fff',
    //       },
    //     },
    //   },
    // },

  },


});

export default theme;
