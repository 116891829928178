import React, { useCallback, useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useParams, useLocation } from "react-router";
import { Box, Container, Divider, Tabs } from "@mui/material";
import { Page, Header } from "components";
import { axios, useIsMountedRef, codeDesc } from "utilities";
import Detail from "./Detail";
import { useTranslation } from "react-i18next";
import { setLocale } from "yup";

const PREFIX = 'ElavonlogDetail';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function ElavonlogDetail() {
  const { t } = useTranslation("elavonlog");

  const location = useLocation();
  const params = useParams();
  const [codes, setCodes] = useState(null);
  const isMountedRef = useIsMountedRef();
  const [data, setData] = useState({});
  const [currentTab, setCurrentTab] = useState("detail");

  setLocale({
    mixed: {
      default: "invalid",
    },
    string: {
      min: ({ min }) => ({ key: "min_long", values: { min } }),
      max: ({ max }) => ({ key: "max_long", values: { max } }),
    },
    number: {
      min: ({ min }) => ({ key: "min_long", values: { min } }),
      max: ({ max }) => ({ key: "max_long", values: { max } }),
    },
  });

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const getData = useCallback(() => {
    axios
      .get("/api/log?guid=" + params.guid)
      .then((response) => {
        if (isMountedRef.current) {
          console.log(response.data);
          setData(response.data);
        }
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  }, [isMountedRef, params]);

  /*   useEffect(() => {
    getData();
  }, [getData]);

  if (!(data)) {
    return null;
  } */

  useEffect(() => {
    codeDesc("en", ["AVS", "CVV2"]).then((res) => {
      setCodes(res);
      console.log(res);
    });
    getData();
  }, [getData]);

  return (
    <StyledPage className={classes.root} title="Elavon Log Details">
      <Container maxWidth={false}>
        <Header
          area="Management"
          breadcrumb="/app/elavonlog"
          apiData={location.state}
          title={t("Elavonlog")}
          subtitle={data.ssl_txn_id}
        />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
            className={classes.tabs}
          >
            {/* {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))} */}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === "detail" && <Detail data={data} codes={codes} />}{" "}
        </Box>
      </Container>
    </StyledPage>
  );
}

export default ElavonlogDetail;
