import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { DialogTextField } from "components";
import { useTranslation } from "react-i18next";
import { axios } from "utilities";

type Props = {
    show: boolean,
    handleChange: any
  };
  
  const AssignTLC = (props: Props) => {
    const { show, handleChange } = props;
    const [message, setMessage] = useState("");

    const applyConfig = async (serial: string) => {
      if (serial.length > 0) {
        axios.get("/api/equipmentdevice?identity=" + serial).then((response) => {
          if (response.data) {
            if (response.data.currentUsage) {
              setMessage("Already used by " + response.data.currentUsage + "!");
            } else {
              var macaddress = response.data.macaddress;
              if (macaddress.length == 12) {
                macaddress = macaddress.substr(0, 2) + ":"
                          + macaddress.substr(2, 2) + ":"
                          + macaddress.substr(4, 2) + ":"
                          + macaddress.substr(6, 2) + ":"
                          + macaddress.substr(8, 2) + ":"
                          + macaddress.substr(10, 2);
              }
              handleChange(response.data.identifier, response.data.identity, macaddress, true);
            }
          }
        });
      } else {
        handleChange("", "", "", true);
      }
    }

      const { t } = useTranslation("equipment");

      return(
        <>
            <Dialog
                open={show}
                onClose={() => { handleChange("", "", "", false) }}
                PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries());
                    applyConfig(formJson.tlcserialnr);
                },
                }}
            >
            <DialogTitle>Assign TLC</DialogTitle>
            <DialogContent dividers>
            <DialogContentText>
                {message}
            </DialogContentText>
            <Grid container spacing={3}>
                <Grid item xs>
                    <DialogTextField
                        name="tlcserialnr"
                        label={t("TLC Serial")}
                        defaultValue=""
                    />
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => handleChange("", "", "", false)}>Cancel</Button>
            <Button type="submit">Assign TLC Device</Button>
            </DialogActions>
        </Dialog>
        </>
      );

  }

export default AssignTLC;