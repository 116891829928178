import { axios } from 'utilities';
import jwtDecode from 'jwt-decode';

class Auth {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithClntUsernameAndPassword = (clnt, username, password, lang) => new Promise((resolve, reject) => {
    axios.post('/jwtauth', { clnt: clnt, username: username, password: password, lang: lang, bypassCache: true })
      .then((response) => {
        if (response.data.jwttoken) {
          this.setSession(response.data.jwttoken);
          resolve(response.data.jwttoken);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  renewToken = () => new Promise((resolve, reject) => {
    axios.post('/api/jwtauth', {})
      .then((response) => {
        if (response.data.jwttoken) {
          this.setSession(response.data.jwttoken);
          resolve(response.data.jwttoken);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  loadUserContext = () => new Promise((resolve, reject) => {
    axios.get('/api/account')
      .then((response) => {
        if (response.data.user) {
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  loginInWithToken = () => new Promise((resolve, reject) => {
    axios.get('/api/account')
      .then((response) => {
        if (response.data.user) {
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  getRemainingValidity = () => {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return 0;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    var remaining = decoded.exp - currentTime;
    if (remaining < 0) {
      remaining = 0;
    }
    return remaining;
  }

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const auth = new Auth();

export default auth;
