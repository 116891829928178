import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import { Page, Header, MUITable } from "components";
import { codeDesc /* , getDesc */ } from "utilities";
import { useTranslation } from "react-i18next";

const PREFIX = 'TransactionList';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function TransactionList() {

  const navigate = useNavigate();
  const location = useLocation();
  const [codes, setCodes] = useState(null);

  const { t } = useTranslation("transaction");

  const columns = [
    { name: "guid", label: t("GUID"), options: { display: false } },
    { name: "transactionid", label: t("Transaction ID") },
    { name: "transactionts", label: t("Timestamp") },
    { name: "transactionnumber", label: t("STAN") },
    { name: "transactiontype", label: t("Type") },
    {
      name: "amount",
      label: t("Amount"),
      options: { customBodyRender: (value) => value.toFixed(2) },
    },
    {
      name: "freeamount",
      label: t(" Free Amount"),
      options: { customBodyRender: (value) => value.toFixed(2) },
    },
    { name: "result", label: t("Result Code") },
    { name: "resultmessage", label: t("Result Message") },
    { name: "sorg", label: t("Sales Org") },
    { name: "profitcenter", label: t("Profit Center") },
    { name: "customernr", label: t("Customer") },
    { name: "outlet", label: t("Outlet") },
    { name: "serialnr", label: t("GPU Serial No.") },
    { name: "deviceid", label: t("Device / Mac Address") },
    { name: "cardtype", label: t("Card Type") },
    { name: "lastfour", label: t("Last 4") },
    { name: "invoicenumber", label: t("Invoice") },
    { name: "batch", label: t("Batch") }, //options: { customBodyRender: (value) => getDesc(codes, "custtype", value) } },
    { name: "status", label: t("Status") },
    { name: "description", label: t("Description") },
  ];

  const handleRowClick = (rowdata, rowMeta, apiData) => {
    navigate("/app/transaction/" + rowdata[0], apiData);
    console.log(rowdata[0]);
  };

  useEffect(() => {
    codeDesc("en", ["guid", "custtype", "tradechannel", "plant"]).then(
      (res) => {
        setCodes(res);
      }
    );
  }, []);

  if (!codes) {
    return null;
  }

  return (
    <StyledPage className={classes.root} title={t("Transactions")}>
      <Container maxWidth={false} className={classes.container}>
        <Header title={t("Payments")} subtitle={t("Transactions")} />
        <MUITable
          endpoint="/api/transactions"
          cols={columns}
          onRowClick={handleRowClick}
          preloadData={location.state}
        />
      </Container>
    </StyledPage>
  );
}

export default TransactionList;
