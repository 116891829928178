import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// Page loading spinner. Takes 100% height and width of parent
// Parent should have position: relative
// Usage: if (loading) return <LoadingPage />;
const LoadingPage = () => {
  const { t } = useTranslation("waitscr");

  return (
    <Box sx={{
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    }}>
      <CircularProgress size={32} sx={{ mb: 2 }} />
      <Typography variant="body1" color="primary">
        &nbsp;&nbsp;&nbsp;{t("Loading")}...
      </Typography>
    </Box>
  );
};

export default LoadingPage;
