import { ReactNode, createRef } from "react";
import { SnackbarProvider as NotistackProvider, SnackbarKey } from "notistack";
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  children: ReactNode;
};

const SnackbarProvider = (props: Props) => {
  const { children } = props;

  const notistackRef = createRef<NotistackProvider>();
  const closeSnackbar = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  const options = {
    maxSnack: 1,

    // Placement
    anchorOrigin: {
      horizontal: 'right' as 'right',
      vertical: 'top' as 'top',
    },

    // Custom close button
    action: (key: SnackbarKey) => (
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeSnackbar(key)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    ),

    // Hide timeout
    autoHideDuration: 5000, // miliseconds
  };

  return (
    <NotistackProvider
      ref={notistackRef}
      {...options}
    >
      {children}
    </NotistackProvider >
  );
};

export default SnackbarProvider;
