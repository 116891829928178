import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

// The goal of this component is ONLY redirect. Do not put UI wrappers here (like layout, template, etc)
const UserRoute = () => {
  // TODO: Delete any type after redo user state with typescript (and maybe context). Should define type automatically
  const user = useSelector((state: { user: any }) => state.user);

  // If logged out - navigate to login
  if (!user.user) {
    return <Navigate to="/login" replace />;
  };

  return <Outlet />;
};

export default UserRoute;
