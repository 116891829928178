import { Card, CardContent, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { STable, TField } from "components";

function Form({ t, ro, values, user, handleChange, handleTableUpdate }) {
  const columns = [
    { title: t("language"), field: "lang" },
    { title: t("description"), field: "description" },
  ];

  const tdata = values.checkConfigT;
  let editable;
  if (!ro) {
    editable = {
      onRowAdd: (newData) =>
        new Promise((resolve, reject) => {
          handleTableUpdate([...tdata, newData]);
          console.log(tdata);
          console.log(newData);
          resolve();
        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          const dataUpdate = [...tdata];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          handleTableUpdate([...dataUpdate]);
          resolve();
        }),
      onRowDelete: (oldData) =>
        new Promise((resolve, reject) => {
          const dataDelete = [...tdata];
          const index = oldData.tableData.id;
          dataDelete.splice(index, 1);
          handleTableUpdate([...dataDelete]);
          resolve();
        }),
    };
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <TField
              fullWidth
              helperText="Please specify a code"
              label="Code"
              name="code"
              required
              onChange={handleChange}
              value={values.code || ""}
              ro={ro}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <STable
              columns={columns}
              data={tdata}
              title="Translations"
              editable={editable}
            ></STable>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

Form.propTypes = {
  t: PropTypes.func,
  ro: PropTypes.bool,
  values: PropTypes.object,
  session: PropTypes.object,
  handleChange: PropTypes.func,
};

export default Form;
