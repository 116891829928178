import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

type FormSubsectionProps = {
  title: string;
  children: ReactNode;
};

const FormSubsection = (props: FormSubsectionProps) => {
  const { title, children } = props;

  return (
    <Box mb={5}>
      <Typography
        display="inline-block"
        variant="subtitle2"
        fontSize={16}
        mb={2}
        borderBottom={2}
        borderColor="primary.light"
      >
        {title}
      </Typography>
      <div>
        {children}
      </div>
    </Box>
  );
};

export default FormSubsection;
