import {
  AppBar,
  Grid,
  IconButton,
  Toolbar,
  useTheme
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';

import { Logo, TokenExpiration } from "components";
import { UserMenu } from "./components";
import { SetBooleanType } from "types";

type Props = {
  navOpen: boolean;
  setNavOpen: SetBooleanType;
};

const TopBar = (props: Props) => {
  const { navOpen, setNavOpen } = props;
  const theme = useTheme();
  const toggleNav = () => setNavOpen(!navOpen);

  return (
    <AppBar
      elevation={0}
      sx={{
        flex: "0 0 auto",
        borderBottom: `${theme.palette.grey[300]} 1px solid`,
        backgroundColor: `${theme.palette.common.white}`,
        color: "grey.700",
      }}
      position="static"
    >
      <Toolbar sx={{ alignItems: "center" }}>
        <Grid container spacing={2} alignItems="center" flexWrap="nowrap">

          {/* Menu Button */}
          <Grid item xs="auto">
            <IconButton onClick={toggleNav}>
              <MenuIcon />
            </IconButton>
          </Grid>

          {/* Logo */}
          <Grid item xs>
            <Logo />
          </Grid>

          {/* User Menu */}
          <Grid item xs="auto">

            <UserMenu />
          </Grid>
        </Grid>
      </Toolbar>
      <TokenExpiration />
    </AppBar>
  );
};

export default TopBar;
