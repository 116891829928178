const initialValues = {
  clnt: 0,
  active: true,
  demomode: false,
  sorg: "",
  equnr: "",
  serialnr: "",
  manufacturer: "",
  model: "",
  factoryserialnr: "",
  equtype: "",
  macaddress: "",
  note: "",
  label: "",
  profitcenter: "",
  outlet: "",
  outletname: "",
  outletaddress: "",
  paymenttermserialnr: "",
  campus: "",
  telemetrypath: "",
  telemetryid: ""
};

export default initialValues;
