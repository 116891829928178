import React, { useEffect, useState } from 'react';

type PromptProps = {
  when: boolean;
  message?: string;
};

// Custom Prompt component to replace react-router v5 Propmt. It's dropped in v6
const Prompt: React.FC<PromptProps> = ({ when, message = "Are you sure you want to leave?" }) => {
  const [isBlocking, setIsBlocking] = useState<boolean>(when);

  useEffect(() => {
    if (isBlocking) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = message;
      };

      // Register a browser event listener to prevent transitions
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [isBlocking, message]);

  useEffect(() => {
    setIsBlocking(when);
  }, [when]);

  return null;
}

export default Prompt;
