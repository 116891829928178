import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Box, Typography } from "@mui/material";

function Outlet({ outlet }) {
  //const[volumeperday, setVolumeperday] = useState();
  const [top1, setTop1] = useState(null);
  const [top2, setTop2] = useState(null);
  const [top3, setTop3] = useState(null);

  const calculate = (data) => {
    let outletlist = [];
    console.log("Outlet - calulate");
    //console.log(data)
    //console.log(data.length)
    if (data != null) {
      let keys = Object.keys(data);
      let values = Object.values(data);
      for (var i = 0; i < keys.length; i++) {
        let outletitem = {};
        outletitem.name = keys[i];
        outletitem.sum = values[i];
        outletlist.push(outletitem);
      }

      // console.log("sortet Outletlist")
      //outletlist = [].concat(outletlist).sort((a,b) => a.sum.localeCompare(b.sum));
      outletlist.sort((a, b) => b.sum - a.sum);
      console.log("Outletlist");
      console.log(outletlist);
      // in case there are less than 3 machines
      // let listlength = 3;
      // if (keys.length < 3) {listlength=keys.length}
      if (keys.length > 0) {
        setTop1(
          "1. " + outletlist[0].name + " => " + outletlist[0].sum.toFixed(2)
        );
      }
      if (keys.length > 1) {
        setTop2(
          "2. " + outletlist[1].name + " => " + outletlist[1].sum.toFixed(2)
        );
      }
      if (keys.length > 2) {
        setTop3(
          "3. " + outletlist[2].name + " => " + outletlist[2].sum.toFixed(2)
        );
      }
      // console.log(outletlist)
    }

    // if (data != null) {
    //   let outletlist = []
    //   for (var i = 0; i<Object.keys(data).length; i++) {
    //     const outletname = Object.values(data[i])
    //     console.log("in the loop")
    //     console.log(outletname)
    //   }

    //   //barchartdata = [].concat(barchartdata).sort((a,b) => a.date.localeCompare(b.date));
    // }
  };

  var cardStyle = {
    //display: 'block',
    //width: '30vw',
    //transitionDuration: '0.3s',
    //height: '10vw'
    height: 140,
  };

  useEffect(() => {
    calculate(outlet);
    console.log("topoutlet - useEffect");
    //console.log(outlet)
  }, [outlet]);

  return (
    <Card style={cardStyle}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" color="textSecondary">
              Top 3 Machines:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" color="inherit">
              {top1} {top2 ? <br /> : ""}
              {top2} {top3 ? <br /> : ""}
              {top3}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              for the selected timeframe
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Outlet;
