import React from "react";
import { styled } from '@mui/material/styles';
import { Container } from "@mui/material";

import { Page, Header, MUITable } from "components";
import { useTranslation } from "react-i18next";

const PREFIX = 'EquipmentUnknownList';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function EquipmentUnknownList() {

  const { t } = useTranslation("equipment");

  const columns = [
    { label: t("Timestamp"), name: "timestamp" },
    { label: t("Transaction Type"), name: "transactiontype" },
    { label: t("Device ID"), name: "deviceid" },
    { label: t("Customer ID"), name: "customernr" },
  ];

  const handleRowClick = (rowdata) => { };

  return (
    <StyledPage className={classes.root} title={t("Unknown Equipment Report")}>
      <Container maxWidth={false} className={classes.container}>
        <Header title={t("Management")} subtitle={t("Unknown Equipments")} />
        <MUITable
          endpoint="/api/unknownequipments"
          cols={columns}
          onRowClick={handleRowClick}
        />
      </Container>
    </StyledPage>
  );
}

export default EquipmentUnknownList;
