import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import {
  Button,
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";

import { Page, Header, MTable } from "components";

import { useTranslation } from "react-i18next";
import { axios } from "utilities";
import Entries from "./Entries";
import Form from "./Form";

const PREFIX = 'SelectOption';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  results: `${PREFIX}-results`,
  actions: `${PREFIX}-actions`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.container}`]: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.results}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.actions}`]: {
    marginTop: theme.spacing(3),
  }
}));

function SelectOption() {

  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation("selectoption");
  const [values, setValues] = React.useState({});
  const [ro, setRo] = React.useState(true);

  const columns = [
    { title: t("Code"), field: "code" },
    { title: t("Description"), field: "description" },
    { title: t("GUID"), field: "guid" },
  ];

  const handleRowClick = (event, rowdata) => {
    console.log(rowdata);
    navigate(
      "/app/config/selectoption/" + rowdata.context + "/" + rowdata.guid
    );
  };

  const handleNew = () => {
    navigate("/app/config/selectoption/" + params.key + "/-");
  };

  const handleBack = () => {
    navigate("/app/config/selectoption/");
  };

  const handleBackList = () => {
    navigate("/app/config/selectoption/" + params.key);
  };

  const handleEdit = () => {
    setRo(!ro);
  };

  const handleTableUpdate = (tdata) => {
    setValues((prevStyle) => ({
      ...prevStyle,
      checkConfigT: tdata,
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setValues({
      ...values,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSave = () => {
    console.log(values);
    let url = "/api/selectoption";
    axios.post(url, values).then((response) => {
      if (response.status === 200) {
        navigate("/app/config/selectoption/" + params.key);
      }
    });
  };

  const handleDelete = () => {
    let url = "/api/selectoption";
    url += "?guid=" + values.guid;
    axios.delete(url).then((response) => {
      if (response.status === 200) {
        navigate("/app/config/selectoption/" + params.key);
      }
    });
  };

  useEffect(() => {
    if (params.id === "-") {
      setValues({
        lang: user.user.lang,
        context: params.key,
        checkConfigT: [],
      });
      setRo(false);
    } else if (params.id) {
      let url = "/api/selectoption";
      url += "?guid=" + params.id;
      axios.get(url).then((response) => {
        if (response.status === 200) {
          setValues(response.data);
          //setValues({...values, code: response.data.code});
        }
      });
    }
  }, [params, user]);

  return (
    <StyledPage className={classes.root} title={t("settings")}>
      {!params.key ? (
        <Container maxWidth={false} className={classes.container}>
          <Header title={t("Administration")} subtitle={t("Configuration")} />
          <Entries />
        </Container>
      ) : !params.id ? (
        <Container maxWidth={false} className={classes.container}>
          <Header
            title={t("Administration")}
            subtitle={t("Configuration") + ": ''" + params.key + "''"}
            backFunc={handleBack}
            addFunc={handleNew}
          />
          <MTable
            endpoint="/api/selectoptions"
            cols={columns}
            context={params.key}
            onRowClick={handleRowClick}
            detailPanel={(rowData) => {
              return (
                <Table size="small">
                  <TableHead>
                    <TableCell />
                    <TableCell>Language</TableCell>
                    <TableCell>Description</TableCell>
                  </TableHead>
                  {rowData.descriptions.map((item) => (
                    <TableRow>
                      <TableCell />
                      <TableCell>{item.lang}</TableCell>
                      <TableCell>{item.description}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              );
            }}
          />
        </Container>
      ) : (
        <Container maxWidth={false} className={classes.container}>
          <Header
            title={t("Configuration")}
            subtitle={t("Configuration") + ": ''" + params.key + "''"}
            backFunc={handleBackList}
            editFunc={handleEdit}
            deleteFunc={handleDelete}
          />
          <Form
            t={t}
            ro={ro}
            values={values}
            user={user.user}
            handleChange={handleChange}
            handleTableUpdate={handleTableUpdate}
          />
          <div className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSave}
              disabled={ro}
            >
              {t("Save")}
            </Button>
          </div>
        </Container>
      )}
    </StyledPage>
  );
}

export default SelectOption;
