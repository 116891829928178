import { useAxios } from "hooks";
import { SearchForm, initialValues, SearchResult } from "./components";
import { FullHeight } from "components";
import { useFormik } from "formik";

const TransactionsSearch = () => {

  // Define search form handler
  const [post, { data: searchResult, loading: loadingSave }] = useAxios({
    method: "post",
    url: "/api/transactionsearch",
  });

  // Init Formik
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true, // enableReinitialize = Allow to re-render after form data fetched
    onSubmit: values => {
      post(values); // Call search
    },
    onReset: values => {
      
    }
  });

  return (
    <FullHeight>
      <SearchForm
        formik={formik}
        loadingSave={loadingSave}
      />
      { searchResult &&
        <SearchResult
          data={searchResult}
        />
      }
    </FullHeight>
  );
};

export default TransactionsSearch;
