import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
const PREFIX = 'External';

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  contentContainer: `${PREFIX}-contentContainer`,
  content: `${PREFIX}-content`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },

  [`& .${classes.wrapper}`]: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
  },

  [`& .${classes.contentContainer}`]: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },

  [`& .${classes.content}`]: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  }
}));

function GuestTemplate({ children }) {

  return (
    <Root className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </Root>
  );
}

GuestTemplate.propTypes = {
  children: PropTypes.any,
};

export default GuestTemplate;
