import { useState } from "react";
import {
  Button,
  Card,
  Grid,
  Checkbox,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Typography,
  Divider,
} from "@mui/material";
import { ContentContainer, TField } from "components";
import { useTranslation } from "react-i18next";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { useSnackbar } from 'notistack';
import { axios } from "utilities"; 

type Props = {
    equnr: string,
    selections: any,
    handleChange: any
  };

  interface SelRowProperties {
    index: number;
    dataIndex: number;
  }  

const Selections = (props: Props) => {
    const { equnr, selections, handleChange } = props;
  const [popups, setPopups] = useState({
    form: false,
  });

  const equipmentSelInit = {
    clnt: "",
    equnr: "",
    selnr: "",
    guid: "",
    label: "",
    price: 0,
    newprice: 0,
    assignments: "",
    oos: false,
    init: true,
  };

  const [selectedRows, setSelectedRows] = useState<SelRowProperties[]>([]);
  const [formdata, setFormdata] = useState(equipmentSelInit);
  const { t } = useTranslation("equipment");

  const { enqueueSnackbar } = useSnackbar();

  const handleAddSelection = () => {
    setFormdata(equipmentSelInit);
    setPopups({ ...popups, form: true });
  }

  const handleAddSelectionOk = () => {
    var newSels = selections;
    formdata.price = parseFloat(formdata.price.toString()) || 0;
    formdata.newprice = parseFloat(formdata.newprice.toString()) || 0;
    if (formdata.init) {
      formdata.init = false;
      newSels.push(formdata);
    } else {
      newSels[selectedRows[0].dataIndex] = formdata;
    }
    handleChange(newSels);
    setPopups({ ...popups, form: false });
  }

  const handleEditSelection = () => {
    const selected = selections[selectedRows[0].dataIndex];
    setFormdata(selected);
    setPopups({ ...popups, form: true });
  }

  const handleDelete = () => {
    var newSels = selections;
    for (var i = 0; i < selectedRows.length; i++) {
      newSels.splice(selectedRows[i].dataIndex, 1);
    }
    handleChange(newSels);
  };

  const handleFormChange = (event: any) => {
    event.persist();
    if (event.target.name === "oos") {
      event.target.value = event.target.checked;
    }
    console.log(event.target.name + ": " + event.target.value);
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });
  };

  const handleOOSChange = () => {
    var isOOS = true;
    if (formdata.oos) {
      isOOS = false;
    }
    setFormdata({
      ...formdata,
      oos: isOOS,
    });
  }

  const columns = [
    {
      label: t("Equipment No."),
      name: "equnr",
      options: {
        display: false,
      },
    },
    { label: t("Selection No."), name: "selnr" },
    { label: t("Assignments"), name: "assignments" },
    { label: t("Price"), name: "price", options: { customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
      var flag = "";
      if (selections[rowIndex].newprice > 0) {
        flag = " *";
      }
      return selections[rowIndex].price.toFixed(2) + flag;
    } } },
    {
      label: t("Out Of Stock"),
      name: "oos",
      options: {
        customBodyRender: (value: boolean) => (
          <FormControlLabel
            label=""
            control={<Checkbox checked={value} color="primary" />}
          />
        ),
      },
    },
    { label: t("Counter"), name: "counter" },
    { label: t("Label"), name: "label" },
  ];

  const options:MUIDataTableOptions = {
    filter: false,
    resizableColumns: true,
    responsive: "standard",
    download: false,
    print: false,
    draggableColumns: { enabled: false },
    selectableRows: "multiple",
    rowsPerPageOptions: [10, 50, 100],
    onRowSelectionChange: (currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[]) => {
        setSelectedRows(allRowsSelected);
      },
  };

  const clearOOS = () => {
    axios.get("/api/equipmentresetoos?equnr=" + equnr).then((response) => {
      var newSels = selections;
      for (var i = 0; i < newSels.length; i++) {
        newSels[i].oos = false;
      }
      handleChange(newSels);
      enqueueSnackbar("OOS flags cleared", { variant: "success" });
    });
  }

  var cardStyle = {
    padding: 20,
    spacing: 5,
  };

  return (
    <>
    <ContentContainer py={4}>
        <MUIDataTable
          title=""
          data={selections}
          columns={columns}
          options={options}
        />
      </ContentContainer>
      <ContentContainer py={4}>
        <Grid container spacing={3}>
                  <Grid item>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={() => handleAddSelection()}
        >
          Add Selection
        </Button>
        </Grid>
        <Grid item>
        <Button
          disabled={selectedRows.length != 1}
          color="primary"
          size="large"
          variant="contained"
          onClick={() => handleEditSelection()}
        >
          Edit Selection
        </Button>
        </Grid>
        <Grid item>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={() => clearOOS()}
        >
          Clear all OOS flags
        </Button>
        </Grid>
        <Grid item>
        <Button
          disabled={selectedRows.length === 0}
          color="primary"
          size="large"
          variant="outlined"
          onClick={() => handleDelete()}
        >
          Delete Selection
        </Button>
        </Grid>
        </Grid>
        </ContentContainer>
      <Dialog aria-labelledby="change-col-title" open={popups.form}>
        <DialogTitle id="change-col-title">
          Selection Configuration
        </DialogTitle>
        <DialogContent dividers>

                <Grid
                  container
                  spacing={3}
                >
                  <Grid item lg={2} md={2} xl={2} xs={2}>
                    <TField
                      fullWidth
                      label={t("Sel")}
                      name="selnr"
                      value={formdata.selnr || ""}
                      onChange={handleFormChange}
                      ro={!formdata.init}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} xs={4}>
                    <TField
                      fullWidth
                      label={t("Assignments")}
                      name="assignments"
                      value={formdata.assignments || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xl={6} xs={6}>
                    <TField
                      fullWidth
                      label={t("Label")}
                      name="label"
                      value={formdata.label || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} xs={4}>
                    <TField
                      fullWidth
                      label={t("Price")}
                      name="price"
                      value={formdata.price || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} xs={4}>
                    <TField
                      fullWidth
                      label={t("New Price")}
                      name="newprice"
                      value={formdata.newprice || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} xl={2} xs={2}>
                    <FormControlLabel
                      label={t("OOS")}
                      control={<Checkbox checked={formdata.oos} color="primary" name="oos" onClick={() => handleOOSChange()} />}
                    />
                  </Grid>
                </Grid>

        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setPopups({ ...popups, form: false })}>
            Cancel
          </Button>
          <Button autoFocus variant="contained" color="primary" onClick={() => handleAddSelectionOk()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </>
  );
}

export default Selections;

/*
          <Typography gutterBottom>
            <Container maxWidth={false}>
              <Card style={cardStyle}>

                            </Card>
            </Container>
          </Typography>
*/