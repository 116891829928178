import { SwipeableDrawer, useMediaQuery, useTheme } from "@mui/material";
import { SideNavLinks } from "./components";
import { SetBooleanType } from "types";
import styles from './style.module.scss';

type Props = {
  navOpen: boolean;
  setNavOpen: SetBooleanType;
};

const SideNav = (props: Props) => {
  const { navOpen, setNavOpen } = props;
  const toggleDrawer = () => setNavOpen(!navOpen);

  // Different styles for desktop and mobile
  const theme = useTheme();

  // Change JS and CSS breakpoints together to match:
  // src/templates/UserTemplate/components/SideNav/index.tsx
  // src/templates/UserTemplate/components/Main/style.module.scss
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const variant = isMobile ? "temporary" : "persistent";
  const className_ = isMobile ? "" : styles.drawerDesktop;

  return (
    <SwipeableDrawer
      variant={variant}
      open={navOpen}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      PaperProps={{
        className: className_,
        // sx: { bgcolor: "grey.100" },
        color: "grey.700",
      }}
    >
      <SideNavLinks />
    </SwipeableDrawer>
  );
};

export default SideNav;
