import { Box, BoxProps } from "@mui/material";
import styles from './style.module.scss';
import { ReactNode } from "react";

interface Props extends BoxProps {
  children: ReactNode;
};

/*
  Component add side padding
  Vertical padding may be added:
  <ContentContainer py={4}>

  Same as any MUI Box styling:
  https://mui.com/material-ui/react-box/
  Find: "all the style functions"
*/
const ContentContainer = (props: Props) => {
  const { children, ...boxProps } = props;

  return (
    <Box
      className={styles.contentContainer}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export default ContentContainer;

