import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { Card, Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAxios } from "hooks";
import { ContentContainer } from "components";
import TelemetrySearchForm from "./components/TelemetrySearchForm";
import initialValues from "./components/initialValues";
import { useSelector } from "react-redux";

const TelemetryMonitor = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("telemetry");

  // TODO: Change type any after redo auth
  const { user: { client: { clnt } } } = useSelector((state: any) => state.user);

  // Define search form handler
  const [post, { data: searchResult, loading: loadingSave }] = useAxios({
    method: "post",
    url: "/api/vvr/monitor",
  });

  // Init Formik
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: values => {
      post(values); // Call search
    },
    onReset: values => {

    }
  });

  const { values } = formik;

  return (
    <ContentContainer>
      <TelemetrySearchForm
        formik={formik}
        loadingSave={loadingSave}
      />
    </ContentContainer>

  );
};



export default TelemetryMonitor;

/*

*/