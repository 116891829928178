import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { WaitScr } from "components";
import { axios, tableTranslation } from "utilities";

import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import MUIDataTable from "mui-datatables";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function MTable({ endpoint, cols, context, ...rest }) {
  const { t } = useTranslation("mtable");

  return (
    <MUIDataTable
      {...rest}
      columns={cols}
      title=""
      icons={tableIcons}
      localization={tableTranslation(t)}
      options={{
        exportButton: true,
        padding: "dense",
        pageSize: 10,
        pageSizeOptions: [10, 50, 100],
        selection: true,
        rowStyle: { fontFamily: "Roboto" },
      }}
      components={{
        OverlayLoading: (props) => <WaitScr />,
      }}
      data={(query) =>
        new Promise((resolve, reject) => {
          let url = endpoint;
          url += "?page=" + query.page;
          url += "&pagesize=" + query.pageSize;
          url += "&searchterm=" + query.search;
          if (query.orderBy) {
            url += "&sortcol=" + query.orderBy.field;
            url += "&sortdir=" + query.orderDirection;
          }
          if (context) {
            url += "&context=" + context;
          }
          console.log(url);
          axios
            .get(url)
            .then((response) => {
              if (response.status === 200) {
                resolve({
                  data: response.data.content,
                  page: response.data.pageable.pageNumber,
                  totalCount: response.data.totalElements,
                });
              }
            })
            .catch((error) => console.log("fetch error: ", error));
        })
      }
    />
  );
}

MTable.propTypes = {
  endpoint: PropTypes.string,
  cols: PropTypes.array,
  context: PropTypes.string,
};

export default MTable;
