import { Box, Grid, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ContentContainer, FormSubsection, FormikTextField, FormikAutocomplete, FormikDateTimeField } from "components";
import { FormikContextType, FormikProvider } from "formik";
import { useCodes } from "hooks";
import { useTranslation } from "react-i18next";
import { selOptions } from "utilities";

type Props = {
  formik: FormikContextType<any>;
  loadingSave: boolean;
};

const TransactionSearchForm = (props: Props) => {
  const { formik, loadingSave } = props;
  const { handleSubmit, handleReset, dirty, isValid } = formik;
  const { t } = useTranslation("transaction"); // Get translation

  // Fetch autocomplete codes
  const { codes, loadingCodes } = useCodes(["sorg", "equtype"]);

  const transactiontypes = [
    { code: "*", description: "All" },
    { code: "Auth", description: "Authorization" },
    { code: "AuthIncr", description: "Incremental Authorization" },
    { code: "Sale", description: "Sale" },
    { code: "Void", description: "Void" },
    { code: "Refund", description: "Refund" },
    { code: "AuthOnly", description: "Authorization Only" },
  ];

  const stati = [
    { code: "*", description: "All" },
    { code: "STL", description: "Settled" },
    { code: " ", description: "Not Settled" },
  ];

  return (
    <>
      <FormikProvider value={formik}>
        <form className="h-100" onSubmit={handleSubmit}>

          {/* Fixed Header */}
          <Box sx={{
            py: 1.5,
            borderBottom: 1,
            borderBottomColor: "grey.300",
            backgroundColor: "grey.50",
          }}>
            <ContentContainer>
              <Grid container spacing={3} flexWrap="nowrap" alignItems="right">
                <Grid item xs="auto">

                  {/* Discard changes button */}
                  {dirty && !loadingSave &&
                    <Button
                      color="error"
                      startIcon={<CloseOutlinedIcon />}
                      sx={{
                        mr: 4
                      }}
                      onClick={handleReset}
                    >
                      Reset Form
                    </Button>
                  }

                  {/* Save button */}
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    startIcon={<SearchOutlinedIcon />}
                    disabled={loadingSave || loadingCodes}
                    loading={loadingSave}>
                    Search
                  </LoadingButton>

                </Grid>
              </Grid>
            </ContentContainer>
          </Box>

          <ContentContainer py={4}>

            <FormSubsection title={t("Date Range")}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormikDateTimeField
                    name="dateFrom"
                    label={t("Date From")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikDateTimeField
                    name="dateTo"
                    label={t("Date To")}
                  />
                </Grid>
              </Grid>
            </FormSubsection>

            <FormSubsection title={t("Search Criteria")}>
              <Grid container spacing={3}>
              <Grid item xs={4}>
                  <FormikTextField
                  type="text"
                  name="transactionid"
                  label={t("Transaction ID")}
                />
                </Grid>
                <Grid item xs={4}>
                  <FormikAutocomplete
                    name="sorg"
                    label={t("Sales Org")}
                    options={selOptions(codes, "sorg")}
                    loading={loadingCodes}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormikAutocomplete
                    name="transactiontype"
                    label={t("Transaction Type")}
                    options={transactiontypes}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikAutocomplete
                    name="status"
                    label={t("Settlement Status")}
                    options={stati}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikAutocomplete
                    name="equtype"
                    label={t("Equipment Type")}
                    options={selOptions(codes, "equtype")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="profitcenter"
                  label={t("Profit Center")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="outlet"
                  label={t("Outlet")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="customernr"
                  label={t("Customer")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="deviceid"
                  label={t("MAC Address")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="serialnr"
                  label={t("Serial")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="cardtype"
                  label={t("Card Type")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="lastfour"
                  label={t("Last 4")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="expiration"
                  label={t("Expiration (mmyy)")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="invoicenr"
                  label={t("Invoice")}
                />
                </Grid>
              </Grid>
            </FormSubsection>

          </ContentContainer>
        </form>
      </FormikProvider>
    </>
  );
};

export default TransactionSearchForm;
