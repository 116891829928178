import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import { useParams } from "react-router";

import {
  Button,
  Card,
  Grid,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import { Page, Machinegrid, TField, MUITable } from "components";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import clsx from "clsx";

const PREFIX = 'EquipmentCols';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  container: `${PREFIX}-container`,
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(3),
  },

  [`& .${classes.container}`]: {
    marginTop: theme.spacing(3),
  }
}));

function EquipmentCols({ data, columns, materials, handleChange, ...rest }) {

  const [popups, setPopups] = useState({
    form: false,
    material: false,
  });

  const equipmentColInit = {
    clnt: "",
    colnr: "",
    equnr: "",
    guid: "",
    label: "",
    matnr: "",
    maxfill: 0,
    maxcapacity: 0,
    sorts: "",
    init: true,
  };

  const [formdata, setFormdata] = useState(equipmentColInit);
  const { t } = useTranslation("equipment");
  const params = useParams();
  const [selectedRows, setSelectedRows] = useState([]);

  const colcolumns = [
    {
      label: t("Equipment No."),
      name: "equnr",
      options: {
        display: false,
      },
    },
    { label: t("Column No."), name: "colnr" },
    { label: t("Material No."), name: "matnr" },
    { label: t("max. Capacity"), name: "maxcapacity" },
    { label: t("max. Fillrate"), name: "maxfill" },
    { label: t("Label"), name: "label" },
  ];

  const matcolumns = [
    {
      label: t("Image"),
      name: "image",
      options: {
        customBodyRender: (value) => (
          <img
            id="mat_img"
            src={value}
            alt=""
            style={{ maxWidth: "25px", maxHeight: "25px" }}
          />
        ),
      },
    },
    { label: t("Material Number"), name: "matnr" },
    { label: t("Description"), name: "description" },
  ];

  const handleAddColumn = () => {
    setFormdata(equipmentColInit);
    setPopups({ ...popups, form: true });
  }

  const handleAddColumnOk = () => {
    var newCols = columns;
    if (formdata.init) {
      formdata.init = false;
      newCols.push(formdata);
    } else {
      newCols[selectedRows[0].dataIndex] = formdata;
    }
    handleChange(newCols);
    setPopups({ ...popups, form: false });
  }

  const handleEditColumn = () => {
    const selected = columns[selectedRows[0].dataIndex];
    setFormdata(selected);
    setPopups({ ...popups, form: true });
  }

  const handleAssignMaterial = () => {
    setPopups({ ...popups, material: true });
  }

  const handleMaterialSelection = (rowdata, rowmeta) => {
    var newCols = columns;
    for (var i = 0; i < selectedRows.length; i++) {
      newCols[selectedRows[i].dataIndex].matnr = rowdata[1];
    }
    handleChange(newCols, rowdata[1]);
    setPopups({ ...popups, material: false });
  };

  const handleFormChange = (event) => {
    event.persist();
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });
  };

  const handleDelete = () => {
    var newCols = columns;
    for (var i = 0; i < selectedRows.length; i++) {
      newCols.splice(selectedRows[i].dataIndex, 1);
    }
    handleChange(newCols);
  };

  const options = {
    filter: false,
    resizableColumns: true,
    responsive: "standard",
    download: false,
    print: false,
    draggableColumns: { enabled: false },
    selectableRows: "multiple",
    rowsPerPageOptions: [10, 50, 100],
    onRowSelectionChange: (currentSelect, allSelected) => {
      setSelectedRows(allSelected);
    },
  };

  const matoptions = {
    responsive: "standard",
    download: false,
    print: false,
    draggableColumns: { enabled: false },
    selectableRows: "none",
    rowsPerPageOptions: [10, 50, 100],
  };

  var cardStyle = {
    paddingBottom: 20,
    paddingTop: 20,
    spacing: 5,
  };

  return (
    <StyledPage className={classes.root} title={t("Equipment")}>
      <Container maxWidth={false} className={classes.container}>
        <Machinegrid
          key={materials.length}
          data={columns}
          materiallist={materials}
        />
      </Container>
      <Container maxWidth={false} className={classes.container}>
        <MUIDataTable
          title={t("Columns for Equipment No.") + " " + params.equnr}
          data={columns}
          columns={colcolumns}
          options={options}
        />
      </Container>
      <Container maxWidth={false} className={classes.container}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={() => handleAddColumn()}
          className={classes.button}
        >
          Add Column
        </Button>
        <Button
          disabled={selectedRows.length != 1}
          color="primary"
          size="large"
          variant="contained"
          onClick={() => handleEditColumn()}
          className={classes.button}
        >
          Edit Column Definition
        </Button>
        <Button
          disabled={selectedRows.length == 0}
          color="primary"
          size="large"
          variant="contained"
          onClick={() => handleAssignMaterial()}
          className={classes.button}
        >
          Assign Material
        </Button>
        <Button
          disabled={selectedRows.length == 0}
          color="primary"
          size="large"
          variant="outlined"
          onClick={() => handleDelete()}
          className={classes.button}
        >
          Delete Column
        </Button>
      </Container>

      <Dialog aria-labelledby="change-col-title" open={popups.form}>
        <DialogTitle id="change-col-title">
          Column Configuration
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Container maxWidth={false} className={classes.container}>
              <Card style={cardStyle}>
                <Grid
                  container
                  className={clsx(classes.root /* , className */)}
                  spacing={3}
                >
                  <Grid item lg={2} md={2} xl={2} xs={2}>
                    <TField
                      fullWidth
                      label={t("Col")}
                      name="colnr"
                      value={formdata.colnr || ""}
                      onChange={handleFormChange}
                      ro={!formdata.init}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} xl={2} xs={2}>
                    <TField
                      fullWidth
                      label={t("Capacity")}
                      name="maxcapacity"
                      value={formdata.maxcapacity || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} xl={2} xs={2}>
                    <TField
                      fullWidth
                      label={t("Fillrate")}
                      name="maxfill"
                      value={formdata.maxfill || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} xs={4}>
                    <TField
                      fullWidth
                      label={t("Label")}
                      name="label"
                      value={formdata.label || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setPopups({ ...popups, form: false })}>
            Cancel
          </Button>
          <Button autoFocus variant="contained" color="primary" onClick={() => handleAddColumnOk()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog aria-labelledby="change-material-title" open={popups.material}>
        <DialogTitle id="change-material-title">
          Select material to be assigned
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Container maxWidth={false} className={classes.container}>
              <MUITable
                endpoint="/api/materials"
                cols={matcolumns}
                onRowClick={handleMaterialSelection}
                options={matoptions}
              />
            </Container>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setPopups({ ...popups, material: false })}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </StyledPage>
  );
}

export default EquipmentCols;