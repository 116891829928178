import React, { useEffect, useState, useCallback } from "react";
import { styled } from '@mui/material/styles';
import { useParams } from "react-router";
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";

import { Page, TField } from "components";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { axios, useIsMountedRef, } from "utilities";
import { useSnackbar } from 'notistack';
import clsx from "clsx";

const PREFIX = 'EquipmentSels';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  container: `${PREFIX}-container`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(3),
  },

  [`& .${classes.container}`]: {
    marginTop: theme.spacing(3),
  }
}));

function EquipmentSels({ data, selections, handleChange, ...rest }) {

  const [popups, setPopups] = useState({
    form: false,
  });

  const equipmentSelInit = {
    clnt: "",
    equnr: "",
    selnr: "",
    guid: "",
    label: "",
    price: 0,
    assignments: "",
    oos: false,
    init: true,
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [formdata, setFormdata] = useState(equipmentSelInit);

  const [oosdata, setOosdata] = useState({ dialog: false, selections: "" });
  const isMountedRef = useIsMountedRef();
  const { t } = useTranslation("equipment");
  const params = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const handleAddSelection = () => {
    setFormdata(equipmentSelInit);
    setPopups({ ...popups, form: true });
  }

  const handleAddSelectionOk = () => {
    var newSels = selections;
    formdata.price = parseFloat(formdata.price) || 0;
    if (formdata.init) {
      formdata.init = false;
      newSels.push(formdata);
    } else {
      newSels[selectedRows[0].dataIndex] = formdata;
    }
    handleChange(newSels);
    setPopups({ ...popups, form: false });
  }

  const handleEditSelection = () => {
    const selected = selections[selectedRows[0].dataIndex];
    setFormdata(selected);
    setPopups({ ...popups, form: true });
  }

  const handleDelete = () => {
    var newSels = selections;
    for (var i = 0; i < selectedRows.length; i++) {
      newSels.splice(selectedRows[i].dataIndex, 1);
    }
    handleChange(newSels);
  };

  const handleFormChange = (event) => {
    event.persist();
    if (event.target.name === "oos") {
      event.target.value = event.target.checked;
    }
    console.log(event.target.name + ": " + event.target.value);
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
    });
  };

  const handleOOSChange = () => {
    var isOOS = true;
    if (formdata.oos) {
      isOOS = false;
    }
    setFormdata({
      ...formdata,
      oos: isOOS,
    });
  }

  const columns = [
    {
      label: t("Equipment No."),
      name: "equnr",
      options: {
        display: false,
      },
    },
    { label: t("Selection No."), name: "selnr" },
    { label: t("Assignments"), name: "assignments" },
    { label: t("Price"), name: "price", options: { customBodyRender: (value) => value.toFixed(2) } },
    {
      label: t("Out Of Stock"),
      name: "oos",
      options: {
        customBodyRender: (value) => (
          <FormControlLabel
            label=""
            control={<Checkbox checked={value} color="primary" />}
          />
        ),
      },
    },
    { label: t("Counter"), name: "counter" },
    { label: t("Label"), name: "label" },
  ];

  const options = {
    filter: false,
    resizableColumns: true,
    responsive: "standard",
    download: false,
    print: false,
    draggableColumns: { enabled: false },
    selectableRows: "multiple",
    rowsPerPageOptions: [10, 50, 100],
    onRowSelectionChange: (currentSelect, allSelected) => {
      setSelectedRows(allSelected);
    },
  };

  const clearOOS = () => {
    axios.get("/api/equipmentresetoos?equnr=" + params.equnr).then((response) => {
      var newSels = selections;
      for (var i = 0; i < newSels.length; i++) {
        newSels[i].oos = false;
      }
      handleChange(newSels);
      enqueueSnackbar("OOS flags cleared", { variant: "success" });
    });
  }

  var cardStyle = {
    paddingBottom: 20,
    paddingTop: 20,
    spacing: 5,
  };

  return (
    <StyledPage className={classes.root} title={t("Equipment Selection")}>
      <Container maxWidth={false} className={classes.container}>
        <MUIDataTable
          title={t("Selections for Equipment No.") + " " + params.equnr}
          data={selections}
          columns={columns}
          options={options}
        />
      </Container>
      <Container maxWidth={false} className={classes.container}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={() => handleAddSelection()}
          className={classes.button}
        >
          Add Selection
        </Button>
        <Button
          disabled={selectedRows.length != 1}
          color="primary"
          size="large"
          variant="contained"
          onClick={() => handleEditSelection()}
          className={classes.button}
        >
          Edit Selection
        </Button>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={() => clearOOS()}
          className={classes.button}
        >
          Clear all OOS flags
        </Button>
        <Button
          disabled={selectedRows.length == 0}
          color="primary"
          size="large"
          variant="outlined"
          onClick={() => handleDelete()}
          className={classes.button}
        >
          Delete Selection
        </Button>
      </Container>

      <Dialog aria-labelledby="change-col-title" open={popups.form}>
        <DialogTitle id="change-col-title">
          Selection Configuration
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Container maxWidth={false} className={classes.container}>
              <Card style={cardStyle}>
                <Grid
                  container
                  className={clsx(classes.root /* , className */)}
                  spacing={3}
                >
                  <Grid item lg={2} md={2} xl={2} xs={2}>
                    <TField
                      fullWidth
                      label={t("Sel")}
                      name="selnr"
                      value={formdata.selnr || ""}
                      onChange={handleFormChange}
                      ro={!formdata.init}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} xl={2} xs={2}>
                    <TField
                      fullWidth
                      label={t("Assignments")}
                      name="assignments"
                      value={formdata.assignments || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} xl={2} xs={2}>
                    <TField
                      fullWidth
                      label={t("Price")}
                      name="price"
                      value={formdata.price || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                  <Grid item lg={2} md={2} xl={2} xs={2}>
                    <FormControlLabel
                      label={t("OOS")}
                      control={<Checkbox checked={formdata.oos} color="primary" name="oos" onClick={() => handleOOSChange()} />}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} xs={4}>
                    <TField
                      fullWidth
                      label={t("Label")}
                      name="label"
                      value={formdata.label || ""}
                      onChange={handleFormChange}
                      ro={false}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setPopups({ ...popups, form: false })}>
            Cancel
          </Button>
          <Button autoFocus variant="contained" color="primary" onClick={() => handleAddSelectionOk()}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </StyledPage>
  );
}

export default EquipmentSels;
