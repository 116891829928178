import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";

function Volperday({ volperday, searcheddays }) {
  const [volumeperday, setVolumeperday] = useState();

  const calculate = (data) => {
    //console.log("calculate")
    console.log(data);
    if (data != null) {
      const values = Object.values(data);
      const sum = values.reduce((partialSum, a) => partialSum + a, 0);
      const vpd = sum / searcheddays;
      setVolumeperday(vpd.toFixed(2));
    }
  };

  useEffect(() => {
    calculate(volperday);
  }, [volperday]);

  var cardStyle = {
    //display: 'block',
    //width: '30vw',
    //transitionDuration: '0.3s',
    //height: "10vw",
    height: 140,
  };

  return (
    <Card style={cardStyle}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs="12">
            <Typography variant="h5" color="textSecondary">
              Average Volume per Day
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Typography variant="h4" color="inherit">
              {volumeperday} USD
            </Typography>
          </Grid>
          <Grid item xs="12">
            <Typography variant="caption" color="textSecondary">
              for the selected timeframe
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Volperday;
