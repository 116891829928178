import { Box, Grid, Breadcrumbs, Typography, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FullHeight, ContentContainer, DisplayField, Scrollable, FormSubsection, FormikChip, FormikImageInput, FormikTextField, FormikAutocomplete, LeavePopup } from "components";
import { FormikContextType, FormikProvider } from "formik";
import { useCodes } from "hooks";
import { useTranslation } from "react-i18next";
import { selOptions } from "utilities";
import { memo } from "react";

type Props = {
  formik: FormikContextType<any>;
  title: string;
  loadingSave: boolean;
};

const MaterialDetailForm = (props: Props) => {
  const { formik, title, loadingSave } = props;
  const { handleSubmit, handleReset, dirty, isValid, values } = formik;
  const { t } = useTranslation("material"); // Get tranlation

  // Fetch autocomplete codes
  const { codes, loadingCodes } = useCodes(["mattype", "matgroup", "packsize", "packtype", "product", "salesunit", "uom"]);

  let imageversion:String = formik.values ? " (v" + formik.values.imageversion + ")" : "";

  return (
    <>
      <FormikProvider value={formik}>
        <form className="h-100" onSubmit={handleSubmit}>
          <FullHeight>

            {/* Fixed Header */}
            <Box sx={{
              py: 1.5,
              borderBottom: 1,
              borderBottomColor: "grey.300",
              backgroundColor: "grey.50",
            }}>
              <ContentContainer>
                <Grid container spacing={3} flexWrap="nowrap" alignItems="center">
                  <Grid item xs>
                    <Breadcrumbs />
                    <Typography variant="h3">
                      {title}
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">

                    {/* Discard changes button */}
                    {dirty && !loadingSave &&
                      <Button
                        color="error"
                        startIcon={<CloseOutlinedIcon />}
                        sx={{
                          mr: 4
                        }}
                        onClick={handleReset}
                      >
                        Discard Changes
                      </Button>
                    }

                    {/* Save button */}
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      startIcon={<SaveOutlinedIcon />}
                      disabled={!dirty || !isValid || loadingSave || loadingCodes}
                      loading={loadingSave}>
                      Save
                    </LoadingButton>

                  </Grid>
                </Grid>
              </ContentContainer>
            </Box>

            {/* Scrollable Content */}
            <Scrollable>

              <ContentContainer py={4}>

                {/* Main column to limit width */}
                <Grid container spacing={3}>
                  <Grid item xl={10}>

                    <FormSubsection title={t("Active")}>
                      <FormikChip
                        name="active"
                        labelActive={t("Active")}
                        labelInactive={t("Inactive")}
                      />
                    </FormSubsection>

                    <FormSubsection title={t("Basic Data")}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md>
                          <FormikTextField
                            type="text"
                            name="matnr"
                            label={t("Material Number")}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md>
                          <FormikTextField
                          type="text"
                          name="description"
                          label={t("Description")}
                          multiline
                          maxRows={4}
                        />
                        </Grid>
                      </Grid>
                    </FormSubsection>

                    <FormSubsection title={t("Image") + imageversion}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} xl={4}>
                          <FormikImageInput
                            name="image"
                          />
                        </Grid>
                      </Grid>
                    </FormSubsection>

                    <FormSubsection title={t("Classification")}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md>
                          <FormikAutocomplete
                            name="mattype"
                            label={t("Material Type")}
                            options={selOptions(codes, "mattype")}
                            loading={loadingCodes}
                          />
                        </Grid>
                        <Grid item xs={12} md>
                          <FormikAutocomplete
                            name="matgroup"
                            options={selOptions(codes, "matgroup")}
                            label={t("Material Group")}
                            loading={loadingCodes}
                          />
                        </Grid>
                      </Grid>
                    </FormSubsection>

                    <FormSubsection title={t("Package")}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md>
                          <FormikTextField
                            type="number"
                            name="packsize"
                            label={t("Pack Size")}
                          />
                        </Grid>
                        <Grid item xs={12} md>
                          <FormikAutocomplete
                            name="packtype"
                            options={selOptions(codes, "packtype")}
                            label={t("Pack Type")}
                            loading={loadingCodes}
                          />
                        </Grid>
                      </Grid>
                    </FormSubsection>

                    <FormSubsection title={t("Product")}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                          <FormikAutocomplete
                            name="product"
                            options={selOptions(codes, "product")}
                            label={t("Product")}
                            loading={loadingCodes}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormikAutocomplete
                            name="salesunit"
                            options={selOptions(codes, "salesunit")}
                            label={t("Sales Unit")}
                            loading={loadingCodes}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormikTextField
                            type="number"
                            name="shelflife"
                            label={t("Shelf Life")}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormikTextField
                            type="text"
                            name="taxtype"
                            label={t("Tax Type")}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormikTextField
                            type="text"
                            name="upc"
                            label={t("EA UPC")}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormikAutocomplete
                            name="baseuom"
                            options={selOptions(codes, "uom")}
                            label={t("Base UOM")}
                            loading={loadingCodes}
                          />
                        </Grid>
                      </Grid>
                    </FormSubsection>

                    <FormSubsection title={t("Note")}>
                      <FormikTextField
                        type="text"
                        name="note"
                        label={t("Note")}
                        multiline
                        maxRows={4}
                      />
                    </FormSubsection>

                    <FormSubsection title={t("Status")}>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <DisplayField
                              data={values}
                              name="lastupdateat"
                              label={t("Last Update At")}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <DisplayField
                              data={values}
                              name="lastupdateby"
                              label={t("Last Update By")}
                            />
                          </Grid>
                      </Grid>
                    </FormSubsection>

                  </Grid>
                </Grid>

              </ContentContainer>

            </Scrollable>

          </FullHeight>
        </form>
      </FormikProvider>

      <LeavePopup dirty={dirty} />
    </>
  );
};

export default MaterialDetailForm;
