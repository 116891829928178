import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Container, Checkbox, FormControlLabel } from "@mui/material";

import { Page, Header, MUITable } from "components";

import { codeDesc /* , getDesc */ } from "utilities";
import { useTranslation } from "react-i18next";
const PREFIX = 'MaterialsList';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function MaterialsList() {
  //Localstorage
  /*   const [displayCol, setDisplayCol] = useLocalStorage(
    "SETTINGS.PBDisplayableColumns", []
  );
  const [colSequence, setColSequence] = useLocalStorage(
    "SETTINGS.PBColSequence", []
  ); */
  //End Localstorage


  const navigate = useNavigate();
  const location = useLocation();
  const [codes, setCodes] = useState(null);

  const { t /* , i18n */ } = useTranslation("material");

  // const getDescription = (value) => {
  //   var desc = "";
  //   if (value && value.length > 0) {
  //     const trans = value.find(elem => elem.lang === i18n.language.toUpperCase());
  //     if (trans) {
  //       desc = trans.description;
  //     }
  //   }
  //   return desc;
  // }

  const columns = [
    {
      label: t("Image"),
      name: "image",
      options: {
        customBodyRender: (value) => (
          <img
            id="mat_img"
            src={value}
            alt=""
            style={{ maxWidth: "25px", maxHeight: "25px" }}
          />
        ),
      },
    },
    { label: t("Material Number"), name: "matnr" },
    {
      label: t("Active"),
      name: "active",
      options: {
        customBodyRender: (value) => (
          <FormControlLabel
            label=""
            control={<Checkbox checked={value} color="primary" />}
          />
        ),
      },
    },
    { label: t("Description"), name: "description" },
    //{ label: t('Material Description'), name: 'description', options: { customBodyRender: (value) => getDescription(value) } },
    { label: t("Type"), name: "mattype" },
    //{ label: t('Note'), name: 'note'},
    { label: t("Packsize"), name: "packsize" },
    { label: t("Packtype"), name: "packtype" },
    { label: t("Product"), name: "product" },
    { label: t("Sales Unit"), name: "salesunit" },
  ];

  const handleRowClick = (rowdata, rowMeta, apiData) => {
    navigate("/app/materials/" + rowdata[1], apiData);
  };

  const handleAddClick = () => {
    navigate("/app/materials/add");
  };

  useEffect(() => {
    codeDesc("en", [
      "mattype",
      "matgroup",
      "packsize",
      "packtype",
      "product",
      "salesunit",
    ]).then((res) => {
      // console.log(res);
      setCodes(res);
    });
  }, []);

  if (!codes) {
    return null;
  }

  return (
    <StyledPage className={classes.root} title={t("Materials")}>
      <Container maxWidth={false} className={classes.container}>
        <Header
          title={t("Management")}
          subtitle={t("Materials")}
          addFunc={handleAddClick}
        />
        <MUITable
          endpoint="/api/materials"
          cols={columns}
          onRowClick={handleRowClick}
          preloadData={location.state}
        />
      </Container>
    </StyledPage>
  );
}

export default MaterialsList;
