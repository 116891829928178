import { ReactNode, ElementType } from "react";
import styles from './style.module.scss';

type Props = {
  children: ReactNode;
  component?: ElementType;
};

/*
  Full height flex parent
  Contains:
  1. Fixed top header
  2. Scrollable content

  Usage:
  1.
    <FullHeight>
      <FixedTopComponent>
      <Scrollable>
      <FixedBottomComponent>

  2. Custom component
    <FullHeight component={Form}>
*/
const FullHeight = (props: Props) => {
  const { children, component: Component } = props;

  return (
    Component ?
      <Component className={styles.fullHeight}>
        {children}
      </Component>
      :
      <div className={styles.fullHeight}>
        {children}
      </div>
  );
};

export default FullHeight;
