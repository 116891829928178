import { Chip, FormControlLabel } from "@mui/material";
import { useField } from 'formik';
import styles from './style.module.scss';
import { memo } from "react";

interface FormikChipProps {
  name: string;
  labelActive: string;
  labelInactive: string;
  // TODO: ?Implement and test readOnly prop if its needed
  readOnly?: boolean; // Shortened readOnly. To pass readOnly in parent. MUI passes InputProps={{ readOnly }}
};

const FormikChip = (props: FormikChipProps) => {
  const { name, labelActive, labelInactive, readOnly } = props;

  const [field] = useField(name);
  const { value: active } = field; // Value as active

  // console.log(field);

  return (
    <FormControlLabel
      // Real control
      control={
        <input
          {...field} // name, value, onChange, onBlur
          type="checkbox"
          checked={active}
          className={styles.formikChipInput}
        />
      }
      // UI component
      label={
        <Chip
          color={active ? "primary" : "default"}
          label={active ? labelActive : labelInactive}
          clickable={!readOnly}
        />
      }
    />
  );
};

export default memo(FormikChip);
