class Numeric {

    round2Dec(input) {
        return Math.round((input + Number.EPSILON) * 100) / 100;
    }

    convertToTime(mode, period) {
        const DAY = (24 * 60 * 60);
        const HR = (60 * 60);
        const MIN = 60;
        var remaining = period;
        var outStr = "";
        const days = Math.floor(remaining / DAY);
        remaining -= days * DAY;
        const hours = Math.floor(remaining / HR);
        remaining -= hours * HR;
        const mins = Math.floor(remaining / MIN);
        remaining -= mins * MIN;
        const seconds = remaining;
        if (mode === 1) {
            outStr = outStr + ("0" + hours.toFixed(0)).slice(-2) + ":";
            outStr = outStr + ("0" + mins.toFixed(0)).slice(-2) + ":";
            outStr = outStr + ("0" + seconds.toFixed(0)).slice(-2);
        } else {
            if (days) {
                outStr = outStr + days + "d ";
            }
            if (hours) {
                outStr = outStr + hours + "h ";
            }
            if (mins) {
                outStr = outStr + mins + "m ";
            }    
            if (seconds) {
                outStr = outStr + seconds + "s";
            }
        }
        return outStr;
      }
}

const numeric = new Numeric();

export default numeric;