import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";


import { Page, Header, MUITable } from "components";
import { codeDesc, getDesc } from "utilities";
import { useTranslation } from "react-i18next";

const PREFIX = 'ConsumerTransList';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function ConsumerTransList() {

  const navigate = useNavigate();
  const [codes, setCodes] = useState(null);

  const { t } = useTranslation("consumer");

  const columns = [
    { label: t("Date"), name: "transactiondate" },
    { label: t("Transaction Type"), name: "transactiontype" },
    {
      label: t("Transaction Amount"),
      name: "transactionamount",
      options: {
        customBodyRender: (value) => {
          return (value / 100).toFixed(2);
        },
      },
    },
    { label: t("Outlet"), name: "outlet" },
    { label: t("Device ID"), name: "deviceid" },
    { label: t("Points"), name: "points" },
    {
      label: t("New Balance"),
      name: "newbalance",
      options: {
        customBodyRender: (value) => {
          return (value / 100).toFixed(2);
        },
      },
    },
    { label: t("New Points"), name: "newpoints" },
    { label: t("Status"), name: "transactionstatus" },
  ];

  const handleRowClick = (rowdata) => {
    //navigate('/app/customer/' + rowdata[0]);
  };

  useEffect(() => {
    codeDesc("en", ["custtype"]).then((res) => {
      setCodes(res);
    });
  }, []);

  if (!codes) {
    return null;
  }

  return (
    <StyledPage className={classes.root} title={t("Consumer Transactions")}>
      <Container maxWidth={false} className={classes.container}>

        <Header
          title={t("Transactions")}
          subtitle={t("Consumer Transactions")}
        />
        <MUITable
          endpoint="/api/consumertransactions"
          cols={columns}
          onRowClick={handleRowClick}
        />
      </Container>
    </StyledPage>
  );
}

export default ConsumerTransList;
