import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import validationEN from "./locales/en/validation.json"
import validationDE from "./locales/de/validation.json"
import customerEN from "./locales/en/customer.json"
import customerDE from "./locales/de/customer.json"
import menuEN from "./locales/en/menu.json"
import menuDE from "./locales/de/menu.json"
import mtableEN from "./locales/en/mtable.json"
import mtableDE from "./locales/de/mtable.json"
import equipment_materialsEN from "./locales/en/equipment_materials.json"
import equipment_materialsDE from "./locales/de/equipment_materials.json"
import roadplanningEN from "./locales/en/roadplanning.json"
import roadplanningDE from "./locales/de/roadplanning.json"
import materialEN from "./locales/en/material.json"
import materialDE from "./locales/de/material.json"
import equipmentEN from "./locales/en/equipment.json"
import equipmentDE from "./locales/de/equipment.json"
import loginEN from "./locales/en/login.json"
import loginDE from "./locales/de/login.json"
import selectoptionEN from "./locales/en/selectoption.json"
import selectoptionDE from "./locales/de/selectoption.json"
import routeEN from "./locales/en/route.json"
import routeDE from "./locales/de/route.json"
import elavonlogEN from "./locales/en/elavonlog.json"
import elavonlogDE from "./locales/de/elavonlog.json"
import transactionEN from "./locales/en/transaction.json"
import transactionDE from "./locales/de/transaction.json"
import consumerEN from "./locales/en/consumer.json"
import consumerDE from "./locales/de/consumer.json"
import dashboardEN from "./locales/en/dashboard.json"
import dashboardDE from "./locales/de/dashboard.json"

i18n
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        validation: validationEN,
        customer: customerEN,
        messages: { "I000": "Success" },
        waitscr: { "Loading": "Loading" },
        menu: menuEN,
        mtable: mtableEN,
        login: loginEN,
        selectoption: selectoptionEN,
        equipment_materials: equipment_materialsEN,
        roadplanning: roadplanningEN,
        material: materialEN,
        equipment: equipmentEN,
        route: routeEN,
        elavonlog: elavonlogEN,
        transaction: transactionEN,
        consumer: consumerEN,
        dashboard: dashboardEN
      },
      de: {
        validation: validationDE,
        customer: customerDE,
        messages: { "I000": "Erfolg" },
        waitscr: { "Loading": "Lade", },
        menu: menuDE,
        mtable: mtableDE,
        login: loginDE,
        selectoption: selectoptionDE,
        equipment_materials: equipment_materialsDE,
        roadplanning: roadplanningDE,
        material: materialDE,
        equipment: equipmentDE,
        route: routeDE,
        elavonlog: elavonlogDE,
        transaction: transactionDE,
        consumer: consumerDE,
        dashboard: dashboardDE
      }
    },
    lng: "en",
    fallbackLng: "en",
    // debug: true, // Disabled to clean console for development

    keySeparator: false,

    ns: ["customer", "messages", "login", "mtable", "selectoption", "validation"],
    defaultNS: "messages",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
