const ONE_WEEK = 3600 * 24 * 7 * 1000;

const initialValues = {
  sorg: " ",
  profitcenter: "",
  outlet: "",
  transactionid: "",
  transactiontype: "*",
  equtype: " ",
  serialnr: "",
  deviceid: "",
  cardtype: "",
  lastfour: "",
  expiration: "",
  customernr: "",
  invoicenr: "",
  status: "*",
  dateFrom: new Date(new Date().valueOf() - ONE_WEEK),
  dateTo: new Date(),
};

export default initialValues;
