import { UserAuthRolesType, SideNavLinkType, SideNavSubLinkType, CollapsedLinkType } from 'types';

// Filter ADMIN pages if user has no authorizations = ADMIN
// authorizations may be change to array of roles. If so change check: .filter(link => !link.auth || link.auth === authorizations)
const filterLinks = (links: SideNavSubLinkType[], authorizations: UserAuthRolesType): SideNavSubLinkType[] => {
  return links
    .filter(link => !link.auth || link.auth.split(",").some((e: any) => e === authorizations)) // Ensure that the link either has no auth requirement or matches the user's authorization
    .map(link => {
      if (link.items) {
        const filteredItems: CollapsedLinkType[] = filterLinks(link.items, authorizations).filter(item => !!item.href) as CollapsedLinkType[]; // Recursively filter sub-items
        return {
          ...link,
          items: filteredItems
        };
      }
      return link;
    })
    .filter(link => link.href || (link.items && link.items.length > 0)); // Ensure we're not returning links without href and empty items
};

const filterSideNavLinks = (sideNavLinks: SideNavLinkType[], authorizations: UserAuthRolesType): SideNavLinkType[] => {
  return sideNavLinks.map(section => ({
    ...section,
    items: filterLinks(section.items, authorizations)
  }));
};

export default filterSideNavLinks;
