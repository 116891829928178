import { styled } from '@mui/material/styles';
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

import { Page, Header, MUITable } from "components";

import { useTranslation } from "react-i18next";
const PREFIX = 'TransactionsList';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function TransactionsList() {

  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation("transaction");

  const columns = [
    { name: "guid", label: t("GUID"), options: { display: false } },
    { name: "transactionid", label: t("Transaction ID") },
    { name: "transactionts", label: t("Timestamp") },
    { name: "sorg", label: t("Sales Org") },
    { name: "transactiontype", label: t("Type") },
    {
      name: "amount",
      label: t("Amount"),
      options: { customBodyRender: (value) => value.toFixed(2) },
    },
    {
      name: "freeamount",
      label: t(" Free Amount"),
      options: { customBodyRender: (value) => value.toFixed(2) },
    },
    { name: "resultmessage", label: t("Result Message") },
    { name: "outlet", label: t("Outlet") },
    { name: "serialnr", label: t("GPU Serial No.") },
    { name: "deviceid", label: t("Device / Mac Address") },
    { name: "equtype", label: t("Equipment Type") },
    { name: "entry", label: t("Entry") },
    { name: "status", label: t("Status") },
    { name: "description", label: t("Description") },
  ];

  const handleRowClick = (rowdata, rowMeta, apiData) => {
    navigate("/app/transactions/" + rowdata[0], apiData);
  };

  return (
    <StyledPage className={classes.root} title={t("Transactions")}>
      <Container maxWidth={false} className={classes.container}>
      <Header title={t("Payments")} subtitle={t("Transactions")} />
        <MUITable
          endpoint="/api/transactions"
          cols={columns}
          onRowClick={handleRowClick}
          preloadData={location.state}
        />
      </Container>
    </StyledPage>
  );
}

export default TransactionsList;
