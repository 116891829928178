import { useEffect } from 'react';

interface LeavePopupProps {
  dirty: boolean;
};

// Show popup when user change route but there are unsaved changes
// Only browser events for now (reload, back, forward)
// TODO: Add react-router-6 route change event (blocker)
const LeavePopup: React.FC<LeavePopupProps> = ({ dirty }) => {
  // Before close browser tab or use browser navigation
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (dirty) {
        const message = 'You have unsaved changes!';
        event.returnValue = message;
        return message;
      };
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dirty]);

  return null;
}

export default LeavePopup;
