import React, { forwardRef, useState } from "react";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import { read, utils } from "xlsx";
import MUIDataTable from "mui-datatables";
import { Page } from "components";
import { Container } from "@mui/material";

import AddBox from "@mui/icons-material/Edit";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";

const PREFIX = 'EquipmentImport';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.button}`]: {
    marginBottom: theme.spacing(3),
  }
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const EXTENSIONS = ["xlsx", "xls", "csv"];

function EquipmentImport() {

  const { t } = useTranslation("equipment");
  const [colDefs, setColDefs] = useState();
  const [data, setData] = useState();

  const getExention = (file) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];
    return EXTENSIONS.includes(extension); // return boolean
  };

  const convertToJson = (headers, data) => {
    const rows = [];
    data.forEach((row) => {
      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });
      rows.push(rowData);
    });
    return rows;
  };

  const importExcel = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      //parse data

      const bstr = event.target.result;
      const workBook = read(bstr, { type: "binary" });

      //get Equipment
      var equiData = [];
      var colData = [];
      var selData = [];

      const equiSheet = workBook.Sheets["Equipment"];
      if (equiSheet) {
        equiData = utils.sheet_to_json(equiSheet, {header: 1});
        equiData.splice(0,1);
      }
      const colSheet = workBook.Sheets["Columns"];
      if (colSheet) {
        colData = utils.sheet_to_json(colSheet, {header: 1});
        colData.splice(0,1);
      }
      const selSheet = workBook.Sheets["Selections"];
      if (equiSheet) {
        selData = utils.sheet_to_json(selSheet, {header: 1});
        selData.splice(0,1);
      }      

      console.log(equiData);
      console.log(colData);
      console.log(selData);

      //convert to array
      /*
      const headers = fileData[0];
      const heads = headers.map((head) => ({ title: head, field: head }));
      setColDefs(heads);

      //removing header
      fileData.splice(0, 1);

      setData(convertToJson(headers, fileData));
      */
    };

    if (file) {
      if (getExention(file)) {
        reader.readAsBinaryString(file);
      } else {
        alert("Invalid file input, Select Excel, CSV file");
      }
    } else {
      setData([]);
      setColDefs([]);
    }
    console.log(data);
  };

  return (
    <StyledPage className={classes.root} title={t("Equipment")}>
      <Container maxWidth={false} className={classes.container}>
        <input type="file" onChange={importExcel} className={classes.button} />

        <MUIDataTable
          title="New Equipments Import"
          icons={tableIcons}
          data={data}
          columns={colDefs}
        />
      </Container>
    </StyledPage>
  );
}

export default EquipmentImport;
