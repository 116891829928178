import React, { useEffect, useState /* , useCallback */ } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Container, Grid } from "@mui/material";

import { Page, Header, MUITableD, TField, TSelect } from "components";
import { codeDesc, selOptions, axios } from "utilities";
import { useTranslation } from "react-i18next";

const PREFIX = 'EquipmentList';

const classes = {
  root: `${PREFIX}-root`,
  searchbox: `${PREFIX}-searchbox`,
  button: `${PREFIX}-button`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.searchbox}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.button}`]: {
    marginRight: theme.spacing(3),
  }
}));

function EquipmentList() {
  const initData = {
    sorg: " ",
    profitcenter: "",
    outlet: "",
    macaddress: "",
    note: "",
    serialnr: "",
    manufacturer: "",
    model: "",
    label: "",
    equtype: "",
    factoryserialnr: "",
    equnr: "",
    outletname: "",
    outletaddress: "",
    paymenttermserialnr: "",
  };


  const navigate = useNavigate();
  const [codes, setCodes] = useState(null);
  const [data, setData] = useState(initData);
  const [result, setResult] = useState([]);
  const { t } = useTranslation("equipment");

  const columns = [
    { name: "equnr", label: t("Equipment Number") },
    { name: "sorg", label: t("Sales Org") },
    { name: "profitcenter", label: t("Profitcenter") },
    { name: "outlet", label: t("Outlet") },
    { name: "outletname", label: t("Outlet Name") },
    { name: "outletaddress", label: t("Outlet Address") },
    { name: "macaddress", label: t("MAC Address") },
    { name: "note", label: t("Note") },
    { name: "serialnr", label: t("Serial") },
    { name: "manufacturer", label: t("Manufacturer") },
    { name: "model", label: t("Model") },
    { name: "label", label: t("Label") },
    { name: "equtype", label: t("Equipment Type") },
    { name: "factoryserialnr", label: t("Factory Serial No.") },
    { name: "paymenttermserialnr", label: t("Payment Term") },
  ];

  const handleRowClick = (rowdata) => {
    navigate("/app/equipment/" + rowdata[0]);
  };

  const handleChange = (event) => {
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleDateFromChange = (event) => {
    const newDate = event._d;
    setData({ ...data, dateFrom: newDate });
  };

  const handleDateToChange = (event) => {
    const newDate = event._d;
    setData({ ...data, dateTo: newDate });
  };

  const handleSearch = () => {
    console.log(data);
    getData(data);
  };

  const handleReset = () => {
    setData(initData);
  };

  const getData = (data) => {
    axios
      .post("/api/equipmentsearch", data)
      .then((response) => {
        setResult(response.data);
      })
      .catch((err) => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  };

  useEffect(() => {
    codeDesc("en", ["sorg"]).then((res) => {
      setCodes(res);
    });
  }, []);

  if (!codes) {
    return null;
  }

  return (
    <StyledPage className={classes.root} title={t("Equipment")}>
      <Container maxWidth={false} className={classes.container}>
        <Header title={t("Equipment")} subtitle={t("Equipment Search")} />

        <Card className={classes.searchbox}>
          <CardContent>
            <form>
              <Grid container spacing={3}>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TSelect
                    label={t("Sales Org")}
                    name="sorg"
                    value={data.sorg || ""}
                    onChange={handleChange}
                    opt={selOptions(codes, "sorg")}
                    ro={false}
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Equipment Number")}
                    name="equnr"
                    value={data.equnr || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("MAC Address")}
                    name="macaddress"
                    value={data.macaddress || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Profit Center")}
                    name="profitcenter"
                    value={data.profitcenter || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Outlet")}
                    name="outlet"
                    value={data.outlet || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Outlet Name")}
                    name="outletname"
                    value={data.outletname || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Outlet Address")}
                    name="outletaddress"
                    value={data.outletaddress || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Manufacturer")}
                    name="manufacturer"
                    value={data.manufacturer || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Model")}
                    name="model"
                    value={data.model || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Equipment Type")}
                    name="equtype"
                    value={data.equtype || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "product")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Serial Number")}
                    name="serialnr"
                    value={data.serialnr || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Factory Serial No.")}
                    name="factoryserialnr"
                    value={data.factoryserialnr || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "product")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Payment Terminal Ser.")}
                    name="paymenttermserialnr"
                    value={data.paymenttermserialnr || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "product")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Label")}
                    name="label"
                    value={data.label || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "product")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={2} md={2} xl={2} xs={2}>
                  <TField
                    label={t("Note")}
                    name="note"
                    value={data.note || ""}
                    onChange={handleChange}
                    //opt={selOptions(codes, "uom")}
                    ro={false}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={handleSearch}
                    className={classes.button}
                  >
                    Search Equipments
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    variant="outlined"
                    onClick={handleReset}
                    className={classes.button}
                  >
                    Reset Form
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>

        <MUITableD
          data={result}
          cols={columns}
          sortOrder={{ name: "transactionnumber", diection: "desc" }}
          onRowClick={handleRowClick}
        />
      </Container>
    </StyledPage>
  );
}

export default EquipmentList;
