import { produce } from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE
} from 'actions';

// 1. Define the state shape and action types


/*
  User data from UserRoute console.log. May be used to create typescript user type
  {
    "user": {
      "authorizations": "ADMIN",
      "checkConfigs": null,
      "client": {
        "address1": "",
        "address2": "",
        "city": "Austin",
        "clnt": 101,
        "currency": "USD",
        "logo": "",
        "name1": "Touchless",
        "name2": "Cashless",
        "state": "TX",
        "zipcode": "78734"
      },
      "firstname": "Fedir",
      "lang": "en",
      "lastname": "Kopakov",
      "username": "fedir"
    }
  }
*/

type UserType = {
  firstname: string;
  lastname: string;
  authorizations: string;
  [key: string]: any; // Any other props. TODO: change after refactor auth
}

// Define the user state shape
export type UserStateType = {
  user: UserType | null;
};

// Define the action types for user actions
type UserAction =
  | { type: typeof LOGIN_REQUEST }
  | { type: typeof LOGIN_SUCCESS; payload: { user: any } }
  | { type: typeof LOGIN_FAILURE; /* add error payload here if needed */ }
  | { type: typeof LOGOUT }
  | { type: typeof SILENT_LOGIN; payload: { user: any } }
  | { type: typeof UPDATE_PROFILE; payload: { user: any } };

const initialState: UserStateType = {
  user: null
};

// 2. Update the userReducer function to utilize these types

const userReducer = (state: UserStateType = initialState, action: UserAction): UserStateType => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return produce(state, (draft) => {
        draft.user = null;
      });

    case LOGIN_SUCCESS:
      return produce(state, (draft) => {
        draft.user = action.payload.user;
      });

    case LOGIN_FAILURE:
      return produce(state, () => {
        // Maybe store error
      });

    case LOGOUT:
      return produce(state, (draft) => {
        draft.user = null;
      });

    case SILENT_LOGIN:
      return produce(state, (draft) => {
        draft.user = action.payload.user;
      });

    case UPDATE_PROFILE:
      return produce(state, (draft) => {
        draft.user = action.payload.user;
      });

    default:
      return state;
  }
};

export default userReducer;
