import { Box, Grid, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ContentContainer, FormSubsection, FormikTextField, FormikAutocomplete, FormikDateTimeField } from "components";
import { FormikContextType, FormikProvider } from "formik";
import { useCodes } from "hooks";
import { useTranslation } from "react-i18next";
import { selOptions } from "utilities";

type Props = {
  formik: FormikContextType<any>;
  loadingSave: boolean;
};

const EquipmentSearchForm = (props: Props) => {
  const { formik, loadingSave } = props;
  const { handleSubmit, handleReset, dirty, isValid } = formik;
  const { t } = useTranslation("equipment"); // Get translation

  // Fetch autocomplete codes
  const { codes, loadingCodes } = useCodes(["sorg", "equtype"]);

  return (
    <>
      <FormikProvider value={formik}>
        <form className="h-100" onSubmit={handleSubmit}>

          {/* Fixed Header */}
          <Box sx={{
            py: 1.5,
            borderBottom: 1,
            borderBottomColor: "grey.300",
            backgroundColor: "grey.50",
          }}>
            <ContentContainer>
              <Grid container spacing={3} flexWrap="nowrap" alignItems="right">
                <Grid item xs="auto">

                  {/* Discard changes button */}
                  {dirty && !loadingSave &&
                    <Button
                      color="error"
                      startIcon={<CloseOutlinedIcon />}
                      sx={{
                        mr: 4
                      }}
                      onClick={handleReset}
                    >
                      Reset Form
                    </Button>
                  }

                  {/* Save button */}
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    startIcon={<SearchOutlinedIcon />}
                    disabled={loadingSave || loadingCodes}
                    loading={loadingSave}>
                    Search
                  </LoadingButton>

                </Grid>
              </Grid>
            </ContentContainer>
          </Box>

          <ContentContainer py={1}>

            <FormSubsection title={t("Search Criteria")}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormikAutocomplete
                    name="sorg"
                    label={t("Sales Org")}
                    options={selOptions(codes, "sorg")}
                    loading={loadingCodes}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikAutocomplete
                    name="equtype"
                    label={t("Type")}
                    options={selOptions(codes, "equtype")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="profitcenter"
                  label={t("Profit Center")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="equnr"
                  label={t("Equipment Number")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="macaddress"
                  label={t("MAC Address")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="outlet"
                  label={t("Outlet")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="outletname"
                  label={t("Outlet Name")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="outletaddress"
                  label={t("Outlet Address")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="Manufacturer"
                  label={t("Manufacturer")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="model"
                  label={t("Model")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="factoryserialnr"
                  label={t("Factory Serial")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="serialnr"
                  label={t("GPU Serial")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="paymenttermserialnr"
                  label={t("Payment Term. Serial")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="label"
                  label={t("Label")}
                />
                </Grid>
                <Grid item xs={2}>
                  <FormikTextField
                  type="text"
                  name="note"
                  label={t("Note")}
                />
                </Grid>
              </Grid>
            </FormSubsection>

          </ContentContainer>
        </form>
      </FormikProvider>
    </>
  );
};

export default EquipmentSearchForm;
