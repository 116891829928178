import { useEffect } from "react";
import { useAxios } from "hooks";
import { useParams } from "react-router";
import { initialValues, SearchResult } from "./components";
import { FullHeight } from "components";

const EquipmentsSearchMac = () => {
  const { id } = useParams(); // Get item id

  // Define search form handler
  const [post, { data: searchResult, loading: loadingSave }] = useAxios({
    method: "post",
    url: "/api/equipmentsearch",
  });

  var query = initialValues;

  useEffect(() => {
    if (id) {
      query.macaddress = id;
      post(query);
    };
  }, [id]);

  return (
    <FullHeight>

      { searchResult &&
        <SearchResult
          data={searchResult}
        />
      }
    </FullHeight>
  );
};

export default EquipmentsSearchMac;
