import { useState, MouseEvent } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Box, Button, IconButton, ListItemIcon, Menu, MenuItem, ListItemText, Typography, Hidden } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import { logout } from "actions";

const UserMenu = () => {
  // TODO: Change type any after redo auth
  const user = useSelector((state: any) => state.user);

  // Dropdown
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  // Logout
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // TODO: Refactor after auth refactor
  const handleLogout = async () => {
    try {
      handleClose();
      // TODO: Delete @ts-ignore after redo auth
      // @ts-ignore
      await dispatch(logout());
      navigate("/");
    } catch (error) {
      // TODO: Change to MUI snackbar with auth refactor
      enqueueSnackbar("Unable to logout", {
        variant: "error",
      });
    }
  };

  return (
    <>
      {/* Desktop Button */}
      <Hidden mdDown>
        <Button
          onClick={handleOpen}
          color="inherit"
          sx={{
            textTransform: "none",
          }}
          size="large"
        >
          <Box alignItems="vertical">
            <Typography variant="h5" mr={0.5}>
              {user.user ?
              `${user.user.firstname} ${user.user.lastname}` :
              ""
              }
            </Typography>
            <Typography variant="h6" mr={0.5}>
              {user.user ?
              `${user.user.client.name1}` :
              ""
              }
            </Typography>
          </Box>
          <ExpandMoreIcon fontSize="inherit" />
        </Button>
      </Hidden>

      {/* Mobile Button */}
      <Hidden mdUp>
        <IconButton onClick={handleOpen}>
          <AccountCircleOutlinedIcon />
        </IconButton>
      </Hidden>

      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
      >
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          to="/app/account"
        >
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText>
            Account
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>
            Logout
          </ListItemText>
        </MenuItem>
      </Menu >
    </>
  );
};

export default UserMenu;
