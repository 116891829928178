import { Box, Grid, Breadcrumbs, Tooltip, Typography } from "@mui/material";
import { ContentContainer, FormSubsection, DisplayField } from "components";
import { DisplayFieldConversion } from "components/DisplayField";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any;
  title: string;
  loadingSave: boolean;
};

const TransadataionDetailForm = (props: Props) => {
  const { data, title, loadingSave } = props;
  const { t } = useTranslation("transaction"); // Get translation
  const navigate = useNavigate();

  return (
    <>

            {/* Fixed Header */}
            <Box sx={{
              py: 1.5,
              borderBottom: 1,
              borderBottomColor: "grey.300",
              backgroundColor: "grey.50",
            }}>
              <ContentContainer>
                <Grid container spacing={3} flexWrap="nowrap" alignItems="center">
                  <Grid item xs>
                    <Breadcrumbs />
                    <Typography variant="h3">
                      {title}
                    </Typography>
                  </Grid>
                </Grid>
              </ContentContainer>
            </Box>


            <ContentContainer py={4}>

              <FormSubsection title={t("Transaction")}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <DisplayField
                      data={data}
                      name="ssl_txn_id"
                      label={t("Transaction ID")}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Transaction Type")}
                    name="ssl_transaction_type"
                  />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayField
                    data={data}
                    label={t("Transaction Time")}
                    name="ssl_txn_time"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Amount")}
                    name="ssl_amount"
                    conversion={DisplayFieldConversion.Num2Digits}
                  />
                  </Grid>
                  <Grid item xs={10}>
                    <DisplayField
                    data={data}
                    label={t("Description")}
                    name="ssl_description"
                  />
                  </Grid>
                </Grid>
              </FormSubsection>

              <FormSubsection title={t("Result")}>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <DisplayField
                      data={data}
                      label={t("Result Message")}
                      name="ssl_result_message"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Result Code")}
                    name="ssl_result"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Approval Code")}
                    name="ssl_approval_code"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("AVS Response")}
                    name="ssl_avs_response"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("CVV2 Resp.")}
                    name="ssl_cvv2_response"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Token Response")}
                    name="ssl_token_response"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Add Token Response")}
                    name="ssl_add_token_response"
                  />
                  </Grid>
                </Grid>
              </FormSubsection>

              <FormSubsection title={t("Payment Media")}>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <DisplayField
                      data={data}
                      label={t("Card Type")}
                      name="ssl_card_type"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Card Short Descr.")}
                    name="ssl_card_short_description"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Card Number")}
                    name="ssl_card_number"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Card Token")}
                    name="ssl_token"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Expiration Date")}
                    name="ssl_exp_date"
                  />
                  </Grid>         
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("AVS ZIP")}
                    name="ssl_avs_zip"
                  />
                  </Grid>    
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("AVS Address")}
                    name="ssl_avs_address"
                  />
                  </Grid>       
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Acc. Balance")}
                    name="ssl_account_balance"
                  />
                  </Grid> 
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Bin No.")}
                    name="ssl_bin_no"
                  />
                  </Grid>       
                </Grid>
              </FormSubsection>

              <FormSubsection title={t("Processing")}>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <DisplayField
                      data={data}
                      label={t("Vendor ID")}
                      name="ssl_vendor_id"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("User ID")}
                    name="ssl_user_id"
                  />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayField
                    data={data}
                    label={t("Terminal ID")}
                    name="ssl_tid"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Invoice Number")}
                    name="ssl_invoice_number"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Customer Code")}
                    name="ssl_customer_code"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("PS2000 Data")}
                    name="ssl_ps2000_data"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Oar Data")}
                    name="ssl_oar_data"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Merchant IP")}
                    name="ssl_merchant_ip"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Card Holder IP")}
                    name="ssl_cardholder_ip"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Initiated unscheduled")}
                    name="ssl_merchant_initiated_unscheduled"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Batch Number")}
                    name="batch_number"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Settled Timestamp")}
                    name="settled_date"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Response Message")}
                    name="response_message"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Clnt")}
                    name="clnt"
                  />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayField
                    data={data}
                    label={t("Hostname")}
                    name="hostname"
                  />
                  </Grid>
                  <Grid item xs={4}>
                    <DisplayField
                    data={data}
                    label={t("GUID")}
                    name="guid"
                  />
                  </Grid>
                </Grid>
              </FormSubsection>

              <FormSubsection title={t("Errors")}>
                <Grid container spacing={3}>
                <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Error Code")}
                    name="errorCode"
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <DisplayField
                    data={data}
                    label={t("Error Name")}
                    name="errorName"
                  />
                  </Grid>
                  <Grid item xs={8}>
                    <DisplayField
                    data={data}
                    label={t("Error Message")}
                    name="errorMessage"
                  />
                  </Grid>
                </Grid>
              </FormSubsection>

            </ContentContainer>
    </>
  );
};

export default TransadataionDetailForm;
