import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import { LoadingPage } from "components";
import { useAxios } from "hooks";
import { validationSchema, Form, NotFound } from "./components";
import { ROUTE_MATERIALS } from "const";
import { getAbsLink } from "utilities";

const MaterialsDetail = () => {
  const { id } = useParams(); // Get item id
  const navigate = useNavigate();

  const navigateToList = () => {
    navigate(getAbsLink(ROUTE_MATERIALS));
  }

  // Fetch form data
  const { loading, data, error } = useAxios({
    method: 'get',
    url: `/api/material?matnr=${id}`,
  });

  // Define save form handler
  const [post, { data: saveSuccess, loading: loadingSave }] = useAxios({
    method: "post",
    url: "/api/material",
    notify: true
  });

  // Init Formik
  const formik = useFormik({
    initialValues: data,
    validationSchema: validationSchema,
    enableReinitialize: true, // enableReinitialize = Allow to re-render after form data fetched
    onSubmit: values => {
      post(values); // Call save
    },
  });

  // Save Success
  useEffect(() => {
    if (saveSuccess) {
      navigateToList();
    };
  }, [saveSuccess]);

  if (loading) return <LoadingPage />;

  if (!error && data === "") return <NotFound />;

  return (
    <Form
      formik={formik}
      title={data.description}
      loadingSave={loadingSave}
    />
  );
};

export default MaterialsDetail;
