import { ChangeEvent, FC, memo } from "react";
import Resizer from "react-image-file-resizer";
import { Card, CardMedia, Button, FormHelperText, useTheme } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import { useField, useFormikContext } from 'formik';
import styles from './style.module.scss';

interface FormikImageInputProps {
  name: string;
  readOnly?: boolean;
  disabled?: boolean;
};

const FormikImageInput: FC<FormikImageInputProps> = ({ name, readOnly, disabled }) => {
  const theme = useTheme();
  const [field, meta] = useField(name);
  const { setFieldValue, values } = useFormikContext<any>();
  const error = meta.touched && meta.error;

  const handleImgUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      Resizer.imageFileResizer(
        // Feature to customize options may be implemented later if Resizer options will be different
        event.target.files[0],
        200,
        200,
        "PNG",
        100,
        0,
        uri => {
          setFieldValue(name, uri); // Set image 
          setFieldValue("imageversion", values.imageversion + 1); // Increment image version
        },
        "base64",
      );
    };
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          marginTop: 1,
          // Error border color
          borderColor: error ? theme.palette.error.main : undefined,
        }}
      >
        {field.value &&
          <CardMedia
            component="img"
            image={field.value as string}
            alt="Image"
          />
        }
        <Button
          component="label"
          startIcon={<PhotoCamera />}
          fullWidth
          aria-label="Upload Picture"
          disabled={disabled || readOnly}
        >
          {/* TODO: Localize text */}
          Change
          <input
            type="file"
            accept="image/*"
            className={styles.visuallyHiddenInput}
            onChange={handleImgUpload}
            disabled={disabled || readOnly}
          />
        </Button>
      </Card>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default memo(FormikImageInput);
