import { ReactNode } from "react";
import { Box } from '@mui/material';

type Props = {
  children: ReactNode;
};

// Scrollable content inside FullHeight
const Scrollable = ({ children }: Props) => {
  return (
    <Box
      sx={{
        flex: "1 1",
        overflowY: "auto",
      }}
    >
      {children}
    </Box>
  );
};

export default Scrollable;
