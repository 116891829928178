import { TextField } from "@mui/material";

type Props = {
    name: string,
    label: string,
    defaultValue: any
  };

const DialogTextField = (props: Props) => {

    const { name, label, defaultValue, ...rest } = props;

    return (
        <TextField
        id={name}
        name={name}
        label={label}
        defaultValue={defaultValue}
        margin="dense"
        type="text"
        variant="standard"
        fullWidth
        {...rest}
        />
    )

}

export default DialogTextField;