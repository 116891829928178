import React, { Component } from "react";
import { styled } from '@mui/material/styles';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Page, Header } from "components";

const PREFIX = 'OpenSource';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function createData(id, packname, lictype, liclink) {
  return { id, packname, lictype, liclink };
}

const rows = [
  createData(
    2,
    "@material-ui",
    "MIT",
    "https://github.com/mui-org/material-ui/blob/next/LICENSE"
  ),
  createData(
    3,
    "@testing-library",
    "MIT",
    "https://github.com/testing-library/react-testing-library/blob/HEAD/LICENSE"
  ),
  createData(
    4,
    "Axios",
    "MIT",
    "https://github.com/axios/axios/blob/HEAD/LICENSE"
  ),
  createData(
    5,
    "Formik",
    "Apache 2.0 License",
    "https://github.com/jaredpalmer/formik/blob/master/LICENSE"
  ),
  createData(
    6,
    "history",
    "MIT",
    "https://github.com/ReactTraining/history/blob/master/LICENSE"
  ),
  createData(
    7,
    "i18next",
    "MIT",
    "https://github.com/i18next/i18next/blob/master/LICENSE"
  ),
  createData(
    8,
    "immer",
    "MIT",
    "https://github.com/immerjs/immer/blob/HEAD/LICENSE"
  ),
  createData(
    9,
    "jsonwebtoken",
    "MIT",
    "https://github.com/auth0/node-jsonwebtoken/blob/master/LICENSE"
  ),
  createData(
    10,
    "jss",
    "MIT",
    "https://github.com/cssinjs/jss/blob/master/LICENSE"
  ),
  createData(
    11,
    "jwt-decode",
    "MIT",
    "https://github.com/auth0/jwt-decode/blob/HEAD/LICENSE"
  ),

  createData(
    13,
    "moment",
    "MIT",
    "https://github.com/moment/moment/blob/HEAD/LICENSE"
  ),
  createData(
    14,
    "notistack",
    "MIT",
    "https://github.com/iamhosseindhv/notistack/blob/master/LICENSE.md"
  ),
  createData(
    15,
    "nprogress",
    "MIT",
    "https://github.com/rstacruz/nprogress/blob/master/License.md"
  ),
  createData(
    16,
    "prop-types",
    "MIT",
    "https://github.com/facebook/prop-types/blob/HEAD/LICENSE"
  ),
  createData(
    17,
    "react",
    "MIT",
    "https://github.com/facebook/react/blob/master/LICENSE"
  ),
  createData(
    18,
    "react-app-polyfill",
    "MIT",
    "https://github.com/facebook/create-react-app/blob/HEAD/LICENSE"
  ),
  createData(
    19,
    "react-dom",
    "MIT",
    "https://github.com/facebook/react/blob/HEAD/LICENSE"
  ),
  createData(
    20,
    "react-feather",
    "MIT",
    "https://github.com/feathericons/react-feather/blob/HEAD/LICENSE"
  ),
  createData(21, "react-helmet", "MIT", "https://github.com/nfl/react-helmet"),
  createData(
    22,
    "react-i18next",
    "MIT",
    "https://github.com/i18next/react-i18next/blob/HEAD/LICENSE"
  ),
  // createData(
  //   23,
  //   "react-perfect-scrollbar",
  //   "MIT",
  //   "https://github.com/goldenyz/react-perfect-scrollbar/blob/HEAD/LICENSE"
  // ),
  createData(
    24,
    "react-redux",
    "MIT",
    "https://github.com/reduxjs/react-redux/blob/HEAD/LICENSE.md"
  ),
  createData(
    25,
    "react-router",
    "MIT",
    "https://github.com/ReactTraining/react-router/blob/HEAD/LICENSE"
  ),
  createData(
    26,
    "react-router-dom",
    "MIT",
    "https://github.com/ReactTraining/react-router/blob/HEAD/LICENSE"
  ),
  createData(
    27,
    "react-scripts",
    "MIT",
    "https://github.com/facebook/create-react-app/blob/master/LICENSE"
  ),
  createData(
    28,
    "redux",
    "MIT",
    "https://github.com/reduxjs/redux/blob/master/LICENSE.md"
  ),
  createData(
    29,
    "redux-devtools-extension",
    "MIT",
    "https://github.com/zalmoxisus/redux-devtools-extension/blob/HEAD/LICENSE"
  ),
  createData(
    30,
    "redux-form",
    "MIT",
    "https://github.com/redux-form/redux-form/blob/HEAD/LICENSE"
  ),
  createData(
    31,
    "redux-thunk",
    "MIT",
    "https://github.com/reduxjs/redux-thunk/blob/master/LICENSE.md"
  ),
  createData(32, "typeface-roboto", "MIT", "n/a"),
  createData(
    33,
    "yup",
    "MIT",
    "https://github.com/jquense/yup/blob/master/LICENSE.md"
  ),
];

function OpenSource() {


  return (
    <StyledPage className={classes.root} title="OpenSource">
      <Container maxWidth={false} className={classes.container}>
        <Header title="Legal" subtitle="Open Source" />

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">package name</TableCell>
                <TableCell align="right">license type</TableCell>
                <TableCell align="left">license link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="right">{row.packname}</TableCell>
                  <TableCell align="right">{row.lictype}</TableCell>
                  <TableCell align="left">
                    <a href={row.liclink}>{row.liclink}</a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </StyledPage>
  );
}

export default OpenSource;
