function tableTranslation(t) {
  return (
    {
      body: {
        emptyDataSourceMessage: t('emptyDataSourceMessage'),
        addTooltip: t('addTooltip'),
        deleteTooltip: t('deleteTooltip'),
        editTooltip: t('editTooltip'),
        filterRow: {
          filterTooltip: t('filterTooltip')
        },
        editRow: {
          deleteText: t('deleteText'),
          cancelTooltip: t('cancelTooltip'),
          saveTooltip: t('saveTooltip')
        }
      },
      grouping: {
        placeholder: t('placeholder'),
        groupedBy: t('groupedBy')
      },
      header: {
        actions: t('actions')
      },
      pagination: {
        labelDisplayedRows: t('labelDisplayedRows'),
        labelRowsSelect: t('labelRowsSelect'),
        labelRowsPerPage: t('labelRowsPerPage'),
        firstAriaLabel: t('firstAriaLabel'),
        firstTooltip: t('firstTooltip'),
        previousAriaLabel: t('previousAriaLabel'),
        previousTooltip: t('previousTooltip'),
        nextAriaLabel: t('nextAriaLabel'),
        nextTooltip: t('nextTooltip'),
        lastAriaLabel: t('lastAriaLabel'),
        lastTooltip: t('lastTooltip')
      },
      toolbar: {
        addRemoveColumns: t('addRemoveColumns'),
        nRowsSelected: t('nRowsSelected'),
        showColumnsTitle: t('showColumnsTitle'),
        showColumnsAriaLabel: t('showColumnsAriaLabel'),
        exportTitle: t('exportTitle'),
        exportAriaLabel: t('exportAriaLabel'),
        exportName: t('exportName'),
        searchTooltip: t('searchTooltip'),
        searchPlaceholder: t('searchPlaceholder')
      }
    }
  );
}

export default tableTranslation;
