import React from "react";
import { styled } from '@mui/material/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import { Page, Copyright } from "components";

const PREFIX = 'E404';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.card}`]: {
    maxwidth: 682,
  }
}));

function E404() {


  return (
    <StyledPage className={classes.root} title="SuelVend">
      <Container>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              component="img"
              image="/img/coke-can.jpg"
              height="500"
              title=""
            />
            <CardContent>
              <Typography gutterBottom variant="h3" component="h1">
                ERROR 404
              </Typography>
              <Copyright />
            </CardContent>
          </CardActionArea>
        </Card>
      </Container>
    </StyledPage>
  );
}

export default E404;
