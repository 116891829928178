const ONE_MONTH = 3600 * 24 * 30 * 1000;

const initialValues = {
    dateFrom: new Date(new Date().valueOf() - ONE_MONTH),
    dateTo: new Date(),
    sorg: "",
    profitcenter: "",
    equtype: " ",
  };
  
  export default initialValues;