import {
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Machinegrid({ data, materiallist, handleAssign }) {

  const handleDrop = (event) => {
    const sourceIndex = event.source.index;
    const targetIndex = event.destination.index;
    const matnr = data[sourceIndex].matnr;
    handleAssign(targetIndex, matnr);
  }

  const findpic = (index) => {
    var pic = { src: '', alt: '' };
    for (var x = 0; x < materiallist.length; x++) {
      if (data[index].matnr === materiallist[x].matnr) {
        pic.src = materiallist[x].image;
        pic.alt = materiallist[x].description;
      }
    }
    return pic;
  };

  const createcolumncard = (dataobject) => {
    if (data.length > 0) {
      let pic = findpic(dataobject);
      return (
        <div>
          <b>{data[dataobject].colnr}</b>
          <br />
          {pic.src !== "" ?
            <Tooltip title={pic.alt}>
              <img
                id="mat_img"
                src={pic.src}
                alt={pic.alt}
                resizemode="contain"
                style={{ maxWidth: "50px", maxHeight: "50px" }}
              />
            </Tooltip>
            :
            <Typography variant="caption">{pic.alt}</Typography>
          }

          <br />
          {data[dataobject].matnr}
        </div>
      );
    }
  };

  var imgindex = 0;
  var lastPrefix = "";
  var ret = [];
  for (var i = 0; i < data.length; i++) {
    let item = data[i];
    var prefix = "";
    if (item.sorts) {
      prefix = item.sorts.substring(0, 1);
    }
    if (lastPrefix === "") {
      lastPrefix = prefix;
    }
    if (lastPrefix !== prefix) {
      lastPrefix = prefix;
      let remaining = 12 - (imgindex % 12);
      for (var j = 0; j < remaining; j++) {
        let obj = { index: 0, key: imgindex, display: false };
        ret.push(obj);
        imgindex++;
      }
    }
    let obj = { index: i, key: imgindex, display: true };
    ret.push(obj);
    imgindex++;
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleDrop}>
          {
            ret.map((item) => {
              let index = item.index;
              let key = item.key;
              if (item.display) {
                return (
                  <Droppable droppableId={index + ""} key={key}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                      style={{ border: snapshot.isDraggingOver ? '1px solid green' : '1px solid transparent', display:'inline-block' }}
                      {...provided.droppableProps}
                    >
                  <Draggable
                    draggableId={index + ""}
                    key={index + ""}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        key={index}
                      >
                    <Item>
                    {createcolumncard(index)}
                    </Item>
                      </div>
                    )}
                  </Draggable>
                  {provided.placeholder}
                  </div>)}
                  </Droppable>
                );
              } else {
                return (
                  <Droppable droppableId={index + ""} key={key}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                      style={{ backgroundColor: snapshot.isDraggingOver ? 'green' : 'white' }}
                      {...provided.droppableProps}
                    >
                      {provided.placeholder}
                  </div>)}
                  </Droppable>
                );
              }
            })
          }
      </DragDropContext>
    </div>
  );
}

export default Machinegrid;