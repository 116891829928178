import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { Page, Header, MUITable, MUITableD } from 'components';

import { axios, codeDesc /* , getDesc */ } from "utilities";
import { useTranslation } from "react-i18next";

const PREFIX = 'SettlementList';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function SettlementList() {

  const navigate = useNavigate();
  const [codes, setCodes] = useState(null);
  const [data, setData] = useState([]);

  const { t } = useTranslation("transaction");

  const columns = [
    { name: "settlementbatch", label: t("Batch") },
    { name: "sorg", label: t("Sales Org") },
    { name: "settlementts", label: t("Settlement TS") },
    { name: "backend", label: t("Backend") },
    { name: "numtransactions", label: t("Transactions") },
    {
      name: "sales",
      label: t("Sales"),
      options: { customBodyRender: (value) => value.toFixed(2) },
    },
    {
      name: "refunds",
      label: t("Refunds"),
      options: { customBodyRender: (value) => value.toFixed(2) },
    },
  ];
  const itemcolumns = [
    { name: "guid", label: t("GUID"), options: { display: false } },
    { name: "transactionid", label: t("Transaction ID") },
    { name: "referenceid", label: t("Reference ID") },
    { name: "settlementts", label: t("Settlement TS") },
    { name: "transactionts", label: t("Transaction TS") },
    { name: "transactiontype", label: t("Type") },
    {
      name: "amount",
      label: t("Amount"),
      options: { customBodyRender: (value) => value.toFixed(2) },
    },
    { name: "sorg", label: t("Sales Org") },
    { name: "outlet", label: t("Outlet") },
    { name: "settlementbatch", label: t("Batch") },
    { name: "status", label: t("Status") },
  ];

  const handleRowClick = (rowdata) => {
    axios
      .get("/api/settlements?settlementbatch=" + rowdata[0])
      .then((response) => {
        setData(response.data);
      });
  };

  const handleItemRowClick = (rowdata) => { };

  useEffect(() => {
    codeDesc("en", ["sorg"]).then((res) => {
      setCodes(res);
    });
  }, []);

  if (!codes) {
    return null;
  }

  return (
    <StyledPage className={classes.root} title={t("Settlements")}>
      <Container maxWidth={false} className={classes.container}>
        <Header title={t("Payments")} subtitle={t("Settlement Batches")} />
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <MUITable
              endpoint="/api/settlementbatches"
              cols={columns}
              onRowClick={handleRowClick}
            />
          </Grid>

          <Grid item lg={12} md={12} xl={12} xs={12}>
            <MUITableD
              title="Settlement Items"
              data={data}
              cols={itemcolumns}
              sortOrder={{ name: "transactionts", direction: "asc" }}
              onRowClick={handleItemRowClick}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledPage>
  );
}

export default SettlementList;
