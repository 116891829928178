import { ReactNode } from "react";
import clsx from "clsx";
import styles from "./style.module.scss";

type Props = {
  navOpen: boolean;
  children: ReactNode;
};

const Main = (props: Props) => {
  const { navOpen, children } = props;

  return (
    <div
      className={clsx(
        styles.main,
        navOpen && styles.open
      )}
    >
      {children}
    </div>
  );
};

export default Main;