import { useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { LoadingPage } from "components";
import { useAxios } from "hooks";
import { NotFound } from "./components";
import { Screen } from "./components";
import { ROUTE_EQUIPMENTS } from "const";
import { getAbsLink } from "utilities";

const EquipmentsDetail = () => {
  const { id } = useParams(); // Get item id
  const navigate = useNavigate();

  const navigateToList = () => {
    navigate(getAbsLink(ROUTE_EQUIPMENTS));
  }

  // Fetch form data
  const { loading, data, error } = useAxios({
    method: 'get',
    url: `/api/equipment?equnr=${id}`,
  });

  // Define save form handler
  const [post, { data: saveSuccess, loading: loadingSave }] = useAxios({
    method: "post",
    url: "/api/equipment",
    notify: true
  });

  const handleSave = (data: any) => {
    post(data);
  }

  // Save Success
  useEffect(() => {
    if (saveSuccess) {
      // 
      navigateToList();
    };
  }, [saveSuccess]);

  if (loading) return <LoadingPage />;

  if (!error && data.equipment == null) return <NotFound />;

  return (
    <Screen
      data={data}
      handleSave={handleSave}
      handleNavigate={navigateToList}
      loadingSave={loadingSave}
    />
  );
};

export default EquipmentsDetail;