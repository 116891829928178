import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useAxios } from "hooks";
import { Page } from "components";
import TelemetrySearchForm from "./components/TelemetrySearchForm";
import initialValues from "./components/initialValues";
import { useSelector } from "react-redux";
import { ROUTE_MATERIALS } from "const";
import { getAbsLink } from "utilities";

const TelemetryConfiguration = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("telemetry");

  // TODO: Change type any after redo auth
  const { user: { client: { clnt } } } = useSelector((state: any) => state.user);

  const { loading, data, error } = useAxios({
    url: `/api/vvr/configuration`,
    method: 'get',
  });

  console.log(data);

  return (

    <></>

  );
};



export default TelemetryConfiguration;

/*


[
    {
        "path": "config_3",
        "name": "Dev config",
        "clnt": 100,
        "destinations": [
            {
                "name": "dest_1",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            },
            {
                "name": "dest_2",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            },
            {
                "name": "dest_3",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            }
        ]
    },
    {
        "path": "config_1",
        "name": "Dev config",
        "clnt": 100,
        "destinations": [
            {
                "name": "dest_1",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            },
            {
                "name": "dest_2",
                "format": "vdi",
                "endpoint": "https://func-l-vdp-telemetry.azurewebsites.net/api/UploadDEX?code=gr-Ezd6Asrpn3u0-aFZYYBwnSgfHTjb-zhwztaOXsCCCAzFuI_b_NQ==",
                "username": "vendsmartL",
                "password": "sKDnZGDjX3772MWy"
            }
        ]
    }
]



    <Form
      formik={formik}
      title={values.description}
      loadingSave={loadingSave}
    />
*/