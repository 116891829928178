import { isCancel } from "axios"
import { axios } from "utilities"; // Custom axios instance
import { useState, useEffect, useCallback } from "react";
import { enqueueSnackbar } from "notistack";

/*
  Custom axios hook to re-use in pages and components
  Returns loading, data, error
  Any axios common logic may be written here
  
  Usage:
  const { loading, data, error } = useAxios({
    url: `/api/material?matnr=${id}`,
    method: 'get',
  });
*/

type UseAxiosProps = {
  method: "get" | "post" | "delete"; // Only methods used in project, not all HTTP or axios methods
  url: string;
  data?: any;
  notify?: boolean
};

// Inner useAxios function types
type LoadingType = boolean; // CAN NOT be undefined or null. Either loading or not
type DataType = any | null; // Any type from server response or null if server error
type ErrorType = boolean | null; // CAN be null for initial state while we don't know what server will return

// Return types - for pages and components
type UseAxiosGetReturnType<T> = {
  loading: boolean; // Either loading or done (false)
  data: T | null; // Data type coming from server or null. null means useAxios run succesfully but didn't get data from the server (received error)
  error: ErrorType;
};

type UseAxiosPostReturnType<T> = [
  (data: any) => void, // Post function to call as event handler. For example, <Formik onSubmit={post}
  UseAxiosGetReturnType<T> // Using the previously defined type for GET requests
];

// type UseAxiosPostReturnType<T> = UseAxiosGetReturnType<T> & {
//   post: (data: any) => void; // Post function to call as event handler. For example onSubmit={post}
// };

type UseAxiosStateType = {
  loading: LoadingType;
  data: DataType;
  error: ErrorType;
};

// function useAxios(props: UseAxiosProps): UseAxiosGetReturnType<any> | UseAxiosPostReturnType<any> {
function useAxios<T extends UseAxiosProps>(
  props: T
): T extends { method: "post" } ? UseAxiosPostReturnType<any> : UseAxiosGetReturnType<any> {
  const { url, method, data: postData, notify } = props;

  // Initial types - for inner useAxios calculations. Do not mess with UseAxiosReturnType used in pages and components
  const [state, setState] = useState<UseAxiosStateType>({
    // Initally loading: true for get request. So component can render loading UI immediatelly after call useAxios
    // Initally loading: false for post request. So nothing is loading until post is called
    loading: method === "get" ? true : false,
    data: null,
    error: null,
  });

  const runAxios = useCallback((postData?: any) => {

    // Used for cleanup at the end
    const controller = new AbortController();

    // Start loading
    setState(prevState => ({
      ...prevState,
      loading: true,
    }));

    // Custom axios instance from ./axios.ts
    axios({
      url,
      method,
      data: postData,
      signal: controller.signal,
    })
      .then(response => {

        console.log(response);

        setState(prevState => ({
          ...prevState,
          loading: false,
          data: response.data,
          error: false, // Success, no error
        }));

        // // Server response empty string - no item found
        // // TODO: Change this to handle possible server responses (axios error). After main form edit page development
        // if (response.data === "") {
        //   setState(prevState => ({
        //     ...prevState,
        //     data: null,
        //     loading: false,
        //     error: true,
        //   }));
        // };

        // Show snackbar for post only
        if (notify && method === "post") {
          // TODO: Localize
          // If serer response may be different from 200 - customize response handling here
          enqueueSnackbar("Saved", { variant: "success" });
        };

      })
      .catch(err => {
        // Custom axios server error handler may be added here. To reduce server error handling in pages and components
        // Handle the fetch being aborted by cleanup
        if (isCancel(err)) {
          // console.log('Request was canceled by useAxios.ts cleanup');
          return;
        };

        setState(prevState => ({
          ...prevState,
          loading: false,
          data: null, // Return data null to indicate that useAxios works but no data received from server
          error: true, // Error occurred
        }));

        // TODO: Localize
        enqueueSnackbar("Server Error", { variant: "error" });
      });

    // Cleanup - stop fetch and update page or component state on component unmount. For example fast click to another page
    // https://react.dev/learn/synchronizing-with-effects#step-3-add-cleanup-if-needed
    return () => {
      controller.abort();
    };
  }, [url, method]);

  // Get data
  useEffect(() => {
    if (method === 'get') {
      runAxios();
    }
  }, [runAxios, method]);

  // Post data (save)
  const post = (data: any) => {
    if (method === 'post') {
      runAxios(data);
    };
  };

  // To be changed later. TODO. Just quick useAxios variant to do pages
  return method === 'post'
    ? [post, { ...state }] as any
    : { ...state } as any;

};

export default useAxios;
