import { ReactNode, useEffect, useState } from "react";
import { TopBar, SideNav, Main } from './components';
import styles from './style.module.scss';
import { useMediaQuery, useTheme } from "@mui/material";

type Props = {
  children: ReactNode;
};

const UserTemplate = (props: Props) => {
  const { children } = props;

  const theme = useTheme();

  // Initial open. desktop: open, mobile: closed
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [navOpen, setNavOpen] = useState(isDesktop);

  // On rotate device that match both mobile and desktop
  useEffect(() => {
    setNavOpen(isDesktop);
  }, [isDesktop]);

  return (
    <div className={styles.userTemplate}>
      <TopBar navOpen={navOpen} setNavOpen={setNavOpen} />
      <div className={styles.userTemplateContent}>
        <SideNav navOpen={navOpen} setNavOpen={setNavOpen} />
        <Main navOpen={navOpen}>
          {children}
        </Main>
      </div>
    </div>
  );
};

export default UserTemplate;
