import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { codeDesc } from "utilities";

type CodeType = {
  [key: string]: string[];
};

type CodesType = CodeType[] | null;

type ReturnType = {
  codes: CodesType;
  loadingCodes: boolean;
};

/*
  Hook to simplify codes fetching in page or component. To drop useEffect and useState for codes
  Returns codes fetched from backend
  May be extended with loading, error return
*/
const useCodes = (keys: string[]): ReturnType => {
  const { i18n: { language } } = useTranslation(); // Get current site language

  const [loadingCodes, setLoadingCodes] = useState(true); // Loading state to render UI in page/component
  const [codes, setCodes] = useState<CodesType>(null);

  useEffect(() => {
    (async () => {
      const res = await codeDesc(language, keys);
      setCodes(res);
      setLoadingCodes(false);
    })();
  }, []);

  // Returned as partial to be able to extend return with { error, loading }
  return { codes, loadingCodes };
};

export default useCodes;
