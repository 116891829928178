import React, { useCallback, useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useParams, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Prompt, Page, Header } from "components";
import { axios, useIsMountedRef } from "utilities";
import Detail from "./Detail";
import Transactions from "./Transactions";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { setLocale } from "yup";
import { v4 as uuidv4 } from "uuid";

const PREFIX = 'ConsumerDetail';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function ConsumerDetail() {
  const { t /* , i18n */ } = useTranslation("consumer");
  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();
  const isMountedRef = useIsMountedRef();
  const [balChangePopupVisibilty, setBalChangePopupVisibilty] = useState({
    display: false,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [helper, setHelper] = useState({});
  const [codes, setCodes] = useState(null);
  const [leavePopup, setLeavePopup] = useState({
    dataChanged: false,
    display: false,
    navigate: "",
  });
  const [currentTab, setCurrentTab] = useState("detail");
  const tabs = [
    { value: "detail", label: t("Details") },
    { value: "transactions", label: t("Transactions") },
  ];

  setLocale({
    mixed: {
      default: "invalid",
    },
    string: {
      min: ({ min }) => ({ key: "min_long", values: { min } }),
      max: ({ max }) => ({ key: "max_long", values: { max } }),
    },
    number: {
      min: ({ min }) => ({ key: "min_long", values: { min } }),
      max: ({ max }) => ({ key: "max_long", values: { max } }),
    },
  });

  const validationSchema = yup.object({
    clnt: yup.number().required(),
    // matnr: yup.string().max(18, "Material number cannot be longer than 18 characters").required(),
    // guid: yup.string().uuid("GUID must be a valid UUID"),
    // /* guid: yup.string().matches(/ “^[{]?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}[}]?$”  /, "Input must be a valid UUID"), */
    // baeuom: yup.string().max(5, "BaseUOM cannot be longer than 5 characters").nullable(),
    // matgroup: yup.string().max(10, "Material Group cannot be longer than 10 characters").nullable(),
    // mattype: yup.string().max(5, "Material type cannot be longer than 5 characters").nullable(),
    // note: yup.string().max(100, "Note cannot be longer than 100 characters").nullable(),
    // packsize: yup.string().max(5, "Packsize cannot be longer than 5 characters").nullable(),
    // packtype: yup.string().max(5, "Packtype cannot be longer than 5 characters").nullable(),
    // product: yup.string().max(5, "Product cannot be longer than 5 characters").nullable(),
    // salesunit: yup.string().max(5, "Sales unit cannot be longer than 5 characters").nullable(),
    // shelflife: yup.number().default(0),
    // taxtype: yup.string().max(5, "Tax type cannot be longer than 5 characters").nullable(),
  });

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
    //if (value === 'transactions') { setBalChangePopupVisibilty}
  };

  const handleSave = async () => {
    // validate all subscreens and consolidate data
    // console.log();
    setLeavePopup({ ...leavePopup, dataChanged: false });
    await validationSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        setError({});
        setHelper({});

        let url = "/api/consumer";
        axios
          .post(url, data)
          .then((response) => {
            if (response.status === 200) {
              console.log("that worked!");
              console.log(data);
              enqueueSnackbar("Consumer saved successfully", {
                variant: "success",
              });
              navigate("/app/consumer");
            }
          })
          .catch((reason) => {
            console.log(reason);
            enqueueSnackbar("Error saving consumer: " + reason, {
              variant: "error",
            });
          });
      })
      .catch(function (err) {
        console.log(err);
        if (err.inner) {
          var errs = {};
          var hlps = {};
          err.inner.map((item) => {
            errs[item.path] = true;
            hlps[item.path] = item.message;
          });
          setError(errs);
          setHelper(hlps);
        }
      });

    /*       .catch(function (err) {
        console.log(err);
        if (err.inner) {
          var errs = {};
          var hlps = {};
          err.inner.map((item) => {
            const field = item.path;
            errs[field] = true;
            var num = 0;
            var helperMsg = "";
            if (item.message) {
              if (item.message.values) {
                if (item.message.values.max) {
                  num = item.message.values.max;
                  helperMsg = t("validation:" + item.message.key, {CharCount: num});
                  hlps[field] = helperMsg;
                }
                if (item.message.values.min) {
                  num = item.message.values.min;
                  helperMsg = t("validation:" + item.message.key, {CharCount: num});
                  hlps[field] = helperMsg;
                }
              } else {
                helperMsg = t('validation:invalid');
                hlps[field] = helperMsg;
              }
            }
          });
          setError(errs);
          setHelper(hlps);
        }
      }) */
  };

  const handleDataChange = (event) => {
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    //console.log("event.target")
    //console.log(event.target);
    setLeavePopup({ ...leavePopup, dataChanged: true });
    if (event.type === "click") {
      if (event.target.innerHTML === "Active") {
        name = "active";
        value = false;
      } else if (event.target.innerHTML === "Inactive") {
        name = "active";
        value = true;
      }
    }

    setData({
      ...data,
      [name]: value,
    });
    //console.log("data")
    //console.log(data);
  };

  // const handleDescriptionsChange = (descriptions) => {
  //   setData({
  //     ...data,
  //     "materialT": descriptions
  //   });
  // }

  // const handleUomsChange = (uoms) => {
  //   setData({
  //     ...data,
  //     "materialUom": uoms
  //   });
  // }

  // const handleBomsChange = (comps) => {
  //   setData({
  //     ...data,
  //     "materialComp": comps
  //   });
  // }

  const handleLeavePopupCancel = () => {
    setLeavePopup({ ...leavePopup, display: false });
    navigate(leavePopup.navigate);
  };

  const handleLeavePopupSave = () => {
    handleSave();
    //setLeavePopup({...leavePopup, display: false});
    setBalChangePopupVisibilty({ ...balChangePopupVisibilty, display: false });
  };

  const getData = useCallback(() => {
    axios.get("/api/consumer?guid=" + params.guid).then((response) => {
      if (isMountedRef.current) {
        // console.log(response.data);
        setData(response.data);
      }
    });
  }, [isMountedRef, params]);

  useEffect(() => {
    if (params.guid === "-") {
      setData({
        balance: 0,
        guid: uuidv4(),
        clnt: 1, //clnt cannot be empty but will be replaced by backend anyways
        birthday: new Date("2018-11-12"),
        active: true,
      });
    } else {
      getData();
    }
  }, [getData, params.guid]);

  // const handleBlockedRoute = (nextLocation) => {
  //     setLeavePopup({display: true, navigate: nextLocation.pathname, dataChanged: false});
  //     return false;
  // }

  const handleBalChangeCancel = () => {
    setBalChangePopupVisibilty({ ...balChangePopupVisibilty, display: false });
  };

  const handleConsUpdate = () => {
    getData();
  };

  if (!data) {
    return null;
  }

  //Begin - Show "Change Balance"-Icon in the upper right corner
  const showChangebal = () => {
    setBalChangePopupVisibilty({ ...balChangePopupVisibilty, display: true });
  };

  let showChangebalFunc = null;
  if (currentTab === "transactions") {
    showChangebalFunc = showChangebal;
  }
  //End - Show "Change Balance"-Icon in the upper right corner

  return (
    <StyledPage className={classes.root} title="Consumer Details">
      <Container maxWidth={false}>
        <Header
          area="Management"
          breadcrumb="/app/consumer"
          apiData={location.state}
          title={t("Consumers")}
          subtitle={""}
          changebalFunc={showChangebalFunc}
          saveFunc={handleSave}
        />
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
            className={classes.tabs}
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === "detail" && (
            <Detail
              data={data}
              error={error}
              helper={helper}
              codes={codes}
              handleChange={handleDataChange}
            />
          )}
          {currentTab === "transactions" && (
            <Transactions
              data={data}
              error={error}
              handleBalChangeCancel={handleBalChangeCancel}
              handleConsUpdate={handleConsUpdate}
              balChangePopupVisibilty={balChangePopupVisibilty}
              helper={helper}
              codes={codes}
              handleChange={handleDataChange}
            />
          )}
          {/*  {currentTab === 'uom' && <Uom data={data.materialUom} error={error} helper={helper} codes={codes} handleChange={handleUomsChange}/>}
          {currentTab === 'bom' && <Bom data={data.materialComp} error={error} helper={helper} codes={codes} handleChange={handleBomsChange}/>}                   */}
        </Box>
      </Container>

      <Prompt when={leavePopup.dataChanged} />
      <Dialog aria-labelledby="leave-popup-title" open={leavePopup.display}>
        <DialogTitle id="leave-popup-title">Leave without saving?</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleLeavePopupCancel} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleLeavePopupSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </StyledPage>
  );
}

export default ConsumerDetail;
