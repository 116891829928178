import { useState } from "react";
import { useSelector } from "react-redux";
import { ContentContainer, FormSubsection, DisplayField } from "components";
import PasswordIcon from '@mui/icons-material/Password';
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Changepassword } from "./components";
import { enqueueSnackbar } from "notistack";

const AccountScreen = () => {

    const { t } = useTranslation("account"); // Get tranlation
    const userdata = useSelector((state: any) => state.user);
    const [chpwd, setChpwd] = useState(false);

    const user = userdata ? userdata.user : {};
    const client = userdata ? userdata.user.client : {};

    const handleChangePassword = (success: boolean) => {
        if (success) {
            const msg = t("Please use your new password the next time you log in");
            enqueueSnackbar(msg, { variant: "success" });
        }
        setChpwd(false);
    }

    return (
        <>
              <Box sx={{
              py: 1.5,
              borderBottom: 1,
              borderBottomColor: "grey.300",
              backgroundColor: "grey.50",
            }}>
                <ContentContainer>
                <Grid container spacing={3} flexWrap="nowrap" alignItems="center">
                  <Grid item xs>
                    <Typography variant="h3">
                        Account Data
                    </Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Button
                        color="error"
                        startIcon={<PasswordIcon />}
                        sx={{
                            ml: 4
                        }}
                        onClick={() => setChpwd(true)}
                        >
                        Change Password
                    </Button>  
                  </Grid>
                </Grid>
                </ContentContainer>
            </Box>
                <ContentContainer style={{marginTop: "10px"}}>

                    <FormSubsection title={t("Account Data")}>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <DisplayField
                            data={user}
                            name="username"
                            label={t("Login")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <DisplayField
                            data={user}
                            name="authorizations"
                            label={t("Profile")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DisplayField
                            data={user}
                            name="firstname"
                            label={t("Firstname")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DisplayField
                            data={user}
                            name="lastname"
                            label={t("Lastname")}
                          />
                        </Grid>                        
                    </Grid>
                    </FormSubsection>

                    <FormSubsection title={t("Associated Client")}>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <DisplayField
                            data={client}
                            name="clnt"
                            label={t("Client")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <DisplayField
                            data={client}
                            name="name1"
                            label={t("Name 1")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <DisplayField
                            data={client}
                            name="name2"
                            label={t("Name 2")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DisplayField
                            data={client}
                            name="address1"
                            label={t("Address Line 1")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DisplayField
                            data={client}
                            name="address2"
                            label={t("Address Line 2")}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DisplayField
                            data={client}
                            name="city"
                            label={t("City")}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <DisplayField
                            data={client}
                            name="state"
                            label={t("State")}
                          />
                        </Grid>  
                        <Grid item xs={2}>
                          <DisplayField
                            data={client}
                            name="zipcode"
                            label={t("Zip Code")}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <DisplayField
                            data={client}
                            name="currency"
                            label={t("Currency")}
                          />
                        </Grid>                        
                    </Grid>
                    </FormSubsection>

                </ContentContainer>
            
            <Changepassword show={chpwd} handlePwdChange={handleChangePassword}/>

        </>
    );
};

export default AccountScreen;
