import { Link as RouterLink } from "react-router-dom";
import { Alert, Link } from "@mui/material";
import { ContentContainer } from "components";
import { ROUTE_EQUIPMENTS } from "const";

const NotFound = () => {
  return (
    <ContentContainer py={4}>
      <Alert severity="error" sx={{ mb: 3 }}>
        {/* TODO: Localize */}
        Equipment not found.
        <br />
        <Link
          to={ROUTE_EQUIPMENTS}
          component={RouterLink}
        >
          {/* TODO: Localize */}
          All Equipments
        </Link>
      </Alert>

    </ContentContainer>
  );
};

export default NotFound;
