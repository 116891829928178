import { useTranslation } from "react-i18next";

import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

import {
  ROUTE_EQUIPMENTS,
  ROUTE_EQUIPMENTS_ADD,
  ROUTE_EQUIPMENTS_SEARCH,
  ROUTE_EQUIPMENTS_UNKNOWN,
  ROUTE_EQUIPMENTS_DETAIL,
  ROUTE_EQUIPMENTS_IMPORT,
  ROUTE_MATERIALS,
  ROUTE_MATERIALS_ADD,
  ROUTE_CONSUMERS,
  ROUTE_CONSUMERS_DETAIL,
  ROUTE_CONSUMERS_TRANSACTIONS,
  ROUTE_CARDS,
  ROUTE_CARDS_GENERATE,
  ROUTE_TRANSACTIONS,
  ROUTE_TRANSACTIONS_SEARCH,
  ROUTE_SETTLEMENT,
  ROUTE_ELAVON_LOG,
  ROUTE_TELEMETRY_CONFIG,
  ROUTE_TELEMETRY_MONITOR
} from 'const';

import { SideNavLinkType } from "types";
import { ROUTE_CONFIG_OPTIONS } from "const";
import { ROUTE_DASHBOARD } from "const";
import { ROUTE_OPENSOURCE } from "const";

const useSideNavLinks = (): SideNavLinkType[] => {
  const { t } = useTranslation("menu");

  return [
    {
      subheader: t("Management"),
      items: [
        {
          title: t("Materials"),
          icon: ShoppingCartOutlinedIcon,
          disabled: false,
          items: [
            {
              title: t("List Materials"),
              href: ROUTE_MATERIALS,
              disabled: false,
              auth: "ADMIN",
            },
            {
              title: t("Create Material"),
              href: ROUTE_MATERIALS_ADD,
              disabled: false,
              auth: "ADMIN",
            },
          ],
        },
        {
          title: t("Equipments"),
          icon: HandymanOutlinedIcon,
          disabled: false,
          items: [
            {
              title: t("List Equipments"),
              href: ROUTE_EQUIPMENTS,
              disabled: false,
            },
            {
              title: t("Create Equipment"),
              href: ROUTE_EQUIPMENTS_ADD,
              disabled: false,
              auth: "ADMIN",
            },
            {
              title: t("Search Equipment"),
              href: ROUTE_EQUIPMENTS_SEARCH,
              disabled: false,
            },
            {
              title: t("Unknown Equipments"),
              href: ROUTE_EQUIPMENTS_UNKNOWN,
              disabled: false,
              auth: "ADMIN",
            },
            {
              title: t("Import Equipments"),
              href: ROUTE_EQUIPMENTS_IMPORT,
              disabled: false,
              auth: "ADMIN",
            },
          ],
        },
        {
          title: t("Transactions"),
          icon: PriceChangeOutlinedIcon,
          disabled: false,
          items: [
            {
              title: t("List Transactions"),
              href: ROUTE_TRANSACTIONS,
              disabled: false,
            },
            {
              title: t("Search Transactions"),
              href: ROUTE_TRANSACTIONS_SEARCH,
              disabled: false,
            },
            {
              title: t("List Settlements"),
              href: ROUTE_SETTLEMENT,
              disabled: false,
            },
          ],
        },
        {
          title: t("Payment Processor Log"),
          icon: FormatListBulletedOutlinedIcon,
          disabled: false,
          items: [
            {
              title: t("Elavon US"),
              href: ROUTE_ELAVON_LOG,
              disabled: false,
              auth: "ADMIN",
            },
          ],
        },
      ],
    },
    {
      subheader: t("Telemetry Routing"),
      items: [
        {
          title: t("Configuration"),
          href: ROUTE_TELEMETRY_CONFIG,
          disabled: false,
          auth: "VDIADMIN",
          icon: SettingsOutlinedIcon,
        },
        {
          title: t("Message Trace"),
          href: ROUTE_TELEMETRY_MONITOR,
          disabled: false,
          auth: "VDI,VDIADMIN",
          icon: SettingsOutlinedIcon,
        },
      ],
    },
    {
      subheader: t("Administration"),
      items: [
        {
          title: t("User Administration"),
          href: ROUTE_CONFIG_OPTIONS,
          disabled: true,
          auth: "ADMIN",
          icon: HttpsOutlinedIcon,
        },
        {
          title: t("Check Configuration"),
          href: ROUTE_CONFIG_OPTIONS,
          disabled: false,
          auth: "ADMIN",
          icon: SettingsOutlinedIcon,
        },
      ],
    },
    {
      subheader: t("Analytics"),
      items: [
        {
          title: t("Finance"),
          href: ROUTE_DASHBOARD,
          disabled: false,
          icon: MonetizationOnOutlinedIcon,
        },
      ],
    },
    {
      subheader: t("Legal"),
      items: [
        {
          title: t("Open Source"),
          href: ROUTE_OPENSOURCE,
          icon: DescriptionOutlinedIcon,
          disabled: false,
        },
      ],
    },
  ];
};

export default useSideNavLinks;

/*

        {
          title: t("Stored Value Cards"),
          icon: CreditScoreOutlinedIcon,
          disabled: false,
          items: [
            {
              title: t("List Cards"),
              href: ROUTE_CARDS,
              disabled: false,
              auth: "ADMIN",
            },
            {
              title: t("Generate Cards"),
              href: ROUTE_CARDS_GENERATE,
              disabled: false,
              auth: "ADMIN",
            },
          ],
        },
        {
          title: t("Consumers"),
          icon: GroupOutlinedIcon,
          disabled: false,
          items: [
            {
              title: t("List Consumers"),
              href: ROUTE_CONSUMERS,
              disabled: false,
              auth: "ADMIN",
            },
            {
              title: t("Create Consumer"),
              href: ROUTE_CONSUMERS_DETAIL,
              disabled: false,
              auth: "ADMIN",
            },
            {
              title: t("List Consumer Transactions"),
              href: ROUTE_CONSUMERS_TRANSACTIONS,
              disabled: false,
              auth: "ADMIN",
            },
          ],
        },

*/
