import React from "react";
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Logo } from "components";

const PREFIX = 'WaitScr';

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    padding: theme.spacing(1),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },

  [`& .${classes.logo}`]: {
    maxWidth: "100%",
  }
}));

function WaitScr() {
  const { t } = useTranslation("waitscr");

  return (
    <Root className={classes.root}>
      <Box display="flex" justifyContent="center" mb={6}>
        <Logo className={classes.logo} />
      </Box>
      <CircularProgress color="primary" />
      <Typography variant="body1" color="primary">
        &nbsp;&nbsp;&nbsp;{t("Loading")}...
      </Typography>
    </Root>
  );
}

export default WaitScr;
