import { axios, auth, CodeCache } from 'utilities';

export const LOGIN_REQUEST = '@user/login-request';
export const LOGIN_SUCCESS = '@user/login-success';
export const LOGIN_FAILURE = '@user/login-failure';
export const SILENT_LOGIN = '@user/silent-login';
export const LOGOUT = '@user/logout';
export const REGISTER = '@user/register';
export const UPDATE_PROFILE = '@user/update-profile';

export function login(clnt, username, password, lang) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      let codeCache = CodeCache.getInstance();
      codeCache.clearCodeCache();
      await auth.loginWithClntUsernameAndPassword(clnt, username, password, lang);
      const user = await auth.loadUserContext();

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function renewToken() {
  return async (dispatch) => {
    try {
      await auth.renewToken();
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function logout() {
  return async (dispatch) => {
    auth.logout();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(update) {
  const request = axios.post('/api/account/profile', { update });

  return (dispatch) => {
    request.then((response) => dispatch({
      type: UPDATE_PROFILE,
      payload: response.data
    }));
  };
}
