import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { ContentContainer, TField } from "components";
import { useTranslation } from "react-i18next";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import { axios } from "utilities"; 

type Props = {
    equnr: string
  };

const Transactions = (props: Props) => {
  const { equnr } = props;
  const { t } = useTranslation("equipment");
  const [trans, setTrans] = useState(Array<any>);
  const [rownr, setRownr] = useState(-1);

  const columns = [
    { label: t("Timestamp"), name: "transactiondate" },
    { label: t("Type"), name: "transactiontype" },
    { label: t("Amount"), name: "transactionamount" },
    { label: t("Status"), name: "transactionstatus" },
    { label: t("Selection"), name: "selection" },
    { label: t("Failed Selections"), name: "failedselections" },
    { label: t("Return Code"), name: "errorcode" },
    { label: t("Payment Transaction"), name: "externaltransactionid" },
  ];

  const options:MUIDataTableOptions = {
    filter: false,
    resizableColumns: true,
    responsive: "standard",
    download: false,
    print: false,
    search: false,
    draggableColumns: { enabled: false },
    selectableRows: "none",
    rowsPerPageOptions: [10, 50, 100],
    onCellClick(colData, cellMeta) {
      if (cellMeta.colIndex == 6) {
        setRownr(cellMeta.dataIndex);
      }
    },
  };

  const loadTrans = async (equnr: string) => {
    axios.get("/api/consumertransequi?equnr=" + equnr).then((response) => {
      if (response.data) {
        setTrans(response.data);
      }
    });
  }

  useEffect(() => {
    loadTrans(equnr);
  }, [equnr]);

  return (
    <>
    <ContentContainer py={4}>
        <MUIDataTable
          title=""
          data={trans}
          columns={columns}
          options={options}
        />
      </ContentContainer>

      <Dialog
                open={(rownr > -1)}
                onClose={() => { setRownr(-1) }}
            >
            <DialogTitle>Transaction Details</DialogTitle>
            <DialogContent dividers>
              {rownr > -1 &&
                <TField ro={true} multiline={true} fullWidth={true} value={trans[rownr].log}/>
              }
            </DialogContent>
        </Dialog>
      </>
  );
}

export default Transactions;
