import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { useAxios } from "hooks";
import { validationSchema, Form, initialValues } from "./components";
import { useSelector } from "react-redux";
import { ROUTE_MATERIALS } from "const";
import { getAbsLink } from "utilities";

const MaterialsAdd = () => {
  const navigate = useNavigate();

  const navigateToList = () => {
    navigate(getAbsLink(ROUTE_MATERIALS));
  }

  // TODO: Change type any after redo auth
  const { user: { client: { clnt } } } = useSelector((state: any) => state.user);

  // Add clnt to initial values. Server throw error if no clnt
  initialValues.clnt = clnt;

  // Define save form handler
  const [post, { data: saveSuccess, loading: loadingSave }] = useAxios({
    method: "post",
    url: "/api/material",
  });

  // Init Formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true, // enableReinitialize = Allow to re-render after form data fetched
    onSubmit: values => {
      post(values); // Call save
    },
  });

  // Formik helpers
  const { resetForm, values } = formik;

  // Save Success
  useEffect(() => {
    if (saveSuccess) {
      // Set new values as initial values and reset dirty
      navigateToList();
    };
  }, [saveSuccess]);

  return (
    <Form
      formik={formik}
      title={values.description}
      loadingSave={loadingSave}
    />
  );
};

export default MaterialsAdd;
