import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import clsx from "clsx";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { TField } from "components";
import { useTranslation } from "react-i18next";
import { getDesc } from "utilities";

const PREFIX = 'Detail';

const classes = {
  root: `${PREFIX}-root`,
  fields: `${PREFIX}-fields`,
  input: `${PREFIX}-input`
};

const StyledCard = styled(Card)(() => ({
  [`& .${classes.root}`]: {},
  [`& .${classes.fields}`]: {},

  [`& .${classes.input}`]: {
    display: "none",
  }
}));

function Detail({
  data,
  error,
  helper,
  codes,
  handleChange,
  className,
  ...rest
}) {

  const { t } = useTranslation("elavonlog");

  return (
    <StyledCard>
      <CardContent>
        <form>
          <Grid
            container
            className={clsx(classes.root, className)}
            spacing={3}
            {...rest}
          >
            <Grid item lg={4} md={4} xl={4} xs={4}>
              <TField
                fullWidth
                label={t("Transaction ID")}
                name="ssl_txn_id"
                value={data.ssl_txn_id || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                label={t("Transaction Type")}
                name="ssl_transaction_type"
                value={data.ssl_transaction_type || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                fullWidth
                label={t("Timestamp")}
                name="timestamp"
                value={data.timestamp || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                fullWidth
                label={t("Transaction Time")}
                name="ssl_txn_time"
                value={data.ssl_txn_time || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                fullWidth
                label={t("Result Message")}
                name="ssl_result_message"
                value={data.ssl_result_message || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                fullWidth
                label={t("Result Code")}
                name="ssl_result"
                value={data.ssl_result != null ? data.ssl_result + "" : ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                fullWidth
                label={t("Card Type")}
                name="ssl_card_type"
                value={data.ssl_card_type || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Amount")}
                name="ssl_amount"
                value={
                  data.ssl_amount != null ? data.ssl_amount.toFixed(2) : ""
                }
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                label={t("Approval Code")}
                name="ssl_approval_code"
                value={data.ssl_approval_code || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Card Token")}
                name="ssl_token"
                value={data.ssl_token || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                label={t("User ID")}
                name="ssl_user_id"
                value={data.ssl_user_id || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                label={t("Vendor ID")}
                name="ssl_vendor_id"
                value={data.ssl_vendor_id || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                label={t("Acc. Balance")}
                name="ssl_account_balance"
                value={data.ssl_account_balance || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                label={t("Invoice Number")}
                name="ssl_invoice_number"
                value={data.ssl_invoice_number || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={6}>
              <TField
                fullWidth
                label={t("AVS Response")}
                name="ssl_avs_response"
                value={
                  data.ssl_avs_response
                    ? data.ssl_avs_response +
                    ": " +
                    getDesc(codes, "AVS", data.ssl_avs_response)
                    : ""
                }
                //value={data.ssl_avs_response || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={6}>
              <TField
                fullWidth
                label={t("CVV2 Resp.")}
                name="ssl_cvv2_response"
                value={
                  data.ssl_cvv2_response
                    ? data.ssl_cvv2_response +
                    ": " +
                    getDesc(codes, "CVV2", data.ssl_cvv2_response)
                    : ""
                }
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                fullWidth
                label={t("Card Number")}
                name="ssl_card_number"
                value={data.ssl_card_number || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                fullWidth
                label={t("Card Short Descr.")}
                name="ssl_card_short_description"
                value={data.ssl_card_short_description || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                fullWidth
                label={t("Expiration Date")}
                name="ssl_exp_date"
                value={data.ssl_exp_date || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                fullWidth
                label={t("AVS ZIP")}
                name="ssl_avs_zip"
                value={data.ssl_avs_zip || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={6}>
              <TField
                fullWidth
                label={t("AVS Address")}
                name="ssl_avs_address"
                value={data.ssl_avs_address || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={5} md={5} xl={5} xs={5}>
              <TField
                label={t("Oar Data")}
                name="ssl_oar_data"
                value={data.ssl_oar_data || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={4} md={4} xl={4} xs={4}>
              <TField
                label={t("PS2000 Data")}
                name="ssl_ps2000_data"
                value={data.ssl_ps2000_data || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={3} md={3} xl={3} xs={3}>
              <TField
                label={t("Token Response")}
                name="ssl_token_response"
                value={data.ssl_token_response || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={3} md={3} xl={3} xs={3}>
              <TField
                label={t("Add Token Response")}
                name="ssl_add_token_response"
                value={data.ssl_add_token_response || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                fullWidth
                label={t("Customer Code")}
                name="ssl_customer_code"
                value={data.ssl_customer_code || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                fullWidth
                label={t("Merchant ID")}
                name="ssl_merchant_id"
                value={data.ssl_merchant_id || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Merchant IP")}
                name="ssl_merchant_ip"
                value={data.ssl_merchant_ip || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                fullWidth
                label={t("Card Holder IP")}
                name="ssl_cardholder_ip"
                value={data.ssl_cardholder_ip || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                fullWidth
                label={t("Bin No.")}
                name="ssl_bin_no"
                value={data.ssl_bin_no || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={4} md={4} xl={4} xs={4}>
              <TField
                label={t("Token")}
                name="ssl_token"
                value={data.ssl_token || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={1} md={1} xl={1} xs={1}>
              <TField
                label={t("Initiated unscheduled")}
                name="ssl_merchant_initiated_unscheduled"
                value={data.ssl_merchant_initiated_unscheduled || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Terminal ID")}
                name="ssl_tid"
                value={data.ssl_tid || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Error Code")}
                name="errorCode"
                value={data.errorCode || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Error Name")}
                name="errorName"
                value={data.errorName || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={8} md={8} xl={8} xs={8}>
              <TField
                fullWidth
                label={t("Error Message")}
                name="errorMessage"
                value={data.errorMessage || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Batch Number")}
                name="batch_number"
                value={data.batch_number || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Settled Timestamp")}
                name="settled_date"
                value={data.settled_date || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={8} md={8} xl={8} xs={8}>
              <TField
                fullWidth
                label={t("Response Message")}
                name="response_message"
                value={data.response_message || ""}
                onChange={handleChange}
                ro={true}
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Clnt")}
                name="clnt"
                value={data.clnt != null ? data.clnt + "" : ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={2} md={2} xl={2} xs={2}>
              <TField
                label={t("Hostname")}
                name="hostname"
                value={data.hostname || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={8} md={8} xl={8} xs={8}>
              <TField
                label={t("GUID")}
                name="guid"
                value={data.guid || ""}
                onChange={handleChange}
                ro={true}
                fullWidth
              />
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <TextField
                label={t("Description")}
                multiline
                rows={4}
                name="ssl_description"
                value={data.ssl_description || ""}
                onChange={handleChange}
                variant="filled"
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </StyledCard>
  );
}

Detail.propTypes = {
  data: PropTypes.object.isRequired,
  error: PropTypes.object,
  helper: PropTypes.object,
  codes: PropTypes.array,
  handleChange: PropTypes.func,
  className: PropTypes.string,
};

export default Detail;
