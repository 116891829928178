import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Theme,
  Divider,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PREFIX = 'Release';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  cont: `${PREFIX}-cont`,
  block: `${PREFIX}-block`,
  submit: `${PREFIX}-submit`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {},
  [`& .${classes.table}`]: {},

  [`&.${classes.cont}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.block}`]: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3),
  }
}));

export default function Release(props) {
  const ADJUSTMENT_FACTOR = 1.2;


  const [confirm, setConfirm] = useState(false);
  const [state, setState] = useState({
    isLoaded: false,
    isSubmitted: false,
    data: [],
    summary: [],
    matDetails: [],
  });

  useEffect(() => {
    if (state.isLoaded) return;

    const items = props.data.planningData.filter(
      (elem) =>
        elem.status === "S" &&
        elem.nextDeliveryAct ===
          props.data.planningDate.toISOString().split("T")[0]
    );
    const summary = updateSummary(items, props.data.matDetails);
    setState({
      isLoaded: true,
      isSubmitted: false,
      data: items,
      summary: summary,
      matDetails: props.data.matDetails,
    });
  }, [state]);

  const handleQtyChange = (event) => {
    event.persist();
    const identifier = event.target.name.split(";");
    const i = parseInt(identifier[0]);
    const j = parseInt(identifier[1]);

    if (!isNaN(i) && !isNaN(j) && state.data) {
      var planningItems = state.data;
      planningItems[i].details[j].refill_qty =
        parseInt(event.target.value) || 0;
      var summary = [];
      summary = updateSummary(planningItems, state.matDetails);
      setState({ ...state, data: planningItems, summary: summary });
    }
  };

  const handleSummaryChange = (event) => {
    event.persist();
    const i = parseInt(event.target.name);

    if (!isNaN(i) && state.summary) {
      var summaryItems = state.summary;
      summaryItems[i].totalCS = parseInt(event.target.value) || 0;
      setState({ ...state, summary: summaryItems });
      updateTotalCS(summaryItems);
    }
  };

  const updateTotalCS = (summary) => {
    // update total cases
    var totalCS = 0;
    summary.map((item) => {
      totalCS += item.totalCS;
    });
    props.updateCS(totalCS);
  };

  const fillConsignment = (i, j) => {
    if (state.data) {
      var planningItems = state.data;
      planningItems[i].details[j].refill_qty =
        planningItems[i].details[j].consignment;
      const summary = updateSummary(planningItems, state.matDetails);
      setState({ ...state, data: planningItems, summary: summary });
    }
  };

  const updateSummary = (items, matDetails) => {
    var summaryMap = new Map();
    for (var i = 0; i < items.length; i++) {
      const item = items[i];
      for (var j = 0; j < item.details.length; j++) {
        const mat = item.details[j];
        if (item.activity === "NORM" || item.activity === "INIT") {
          if (summaryMap.has(mat.material_num)) {
            var totalUnits = summaryMap.get(mat.material_num) || 0;
            totalUnits += mat.refill_qty;
            summaryMap.set(mat.material_num, totalUnits);
          } else {
            summaryMap.set(mat.material_num, mat.refill_qty);
          }
        }
      }
    }

    const matnrs = Array.from(summaryMap.keys());
    var summaryItems = new Array();
    for (var i = 0; i < matnrs.length; i++) {
      const matnr = matnrs[i];
      const matDetail = matDetails.find((elem) => elem.materialNum === matnr);
      var matDesc = matnr.replace(/^0+/, "");
      const refillQty = summaryMap.get(matnr) || 0;
      const adjusted = Math.ceil(refillQty * ADJUSTMENT_FACTOR);
      var cs = 0;
      if (matDetail) {
        matDesc = matDesc + ": " + matDetail.description;
        cs = Math.ceil(
          (adjusted / matDetail.denominator) * matDetail.numerator
        );
      }
      var summaryItem = {
        materialNum: matnr,
        description: matDesc,
        totalUnits: refillQty,
        adjustedUnits: adjusted,
        totalCS: cs,
      };
      summaryItems.push(summaryItem);
    }
    updateTotalCS(summaryItems);
    return summaryItems;
  };

  const getMatConv = (matnr) => {
    var factor = 1;
    const matDetail = props.data.matDetails.find(
      (elem) => elem.materialNum === matnr
    );
    if (matDetail && matDetail.denominator && matDetail.numerator) {
      factor = matDetail.denominator / matDetail.numerator;
    }
    return factor;
  };

  const submitToBackend = () => {
    if (state.data && state.summary) {
      // disable submit button
      setState({ ...state, isSubmitted: true });
      // predictive data
      var customerList = new Array();
      const sourceData = state.data;
      for (var i = 0; i < sourceData.length; i++) {
        const pli = sourceData[i];
        var predictionList = new Array();
        for (var j = 0; j < pli.details.length; j++) {
          const item = pli.details[j];
          const predictionData = {
            materialNum: item.material_num,
            consignment: item.consignment,
            averageSales: item.average_consumption,
            totalEA: item.refill_qty,
            convFactor: getMatConv(item.material_num),
          };
          predictionList.push(predictionData);
        }
        var deliveryType = "0";
        if (pli.activity === "NOPH") {
          deliveryType = "1";
        }
        if (pli.activity === "DISP") {
          deliveryType = "2";
        }
        const customerData = {
          route: pli.routeAct,
          customerNum: pli.customerNum,
          equipmentNum: pli.equipmentNum,
          predictionList: predictionList,
          deliveryType: deliveryType,
        };
        customerList.push(customerData);
      }

      // load data
      var loadList = new Array();
      const summary = state.summary;
      for (var n = 0; n < summary.length; n++) {
        const sum = summary[n];
        const loadData = {
          materialNum: sum.materialNum,
          loadCS: sum.totalCS,
          convFactor: getMatConv(sum.materialNum),
        };
        loadList.push(loadData);
      }

      const releaseData = {
        salesOffice: props.data.selectedSalesOffice,
        route: props.data.selectedRoute,
        loadDate: props.data.planningDate,
        customerList: customerList,
        loadList: loadList,
      };

      props.change();
      closeConfirm();
    }
  };

  const closeConfirm = () => {
    setConfirm(false);
    setState({ ...state, isSubmitted: false });
  };

  const openConfirm = () => {
    setConfirm(true);
  };

  if (!state) {
    return null;
  }

  var totalCS = 0;
  console.log(state.data);

  return (
    <StyledPaper className={classes.cont}>
      <Grid container justifyContent="flex-end">
        <Button
          className={classes.submit}
          variant="outlined"
          color="primary"
          onClick={() => openConfirm()}
        >
          SUBMIT
        </Button>
      </Grid>
      <div className={classes.block}>
        <Divider />
        <Typography variant="h4" className={classes.cont}>
          Truck Load Summary
        </Typography>
        <Divider />
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Material</TableCell>
                <TableCell align="right">Load Qty</TableCell>
                <TableCell align="right">
                  Adjusted Load Qty (* {ADJUSTMENT_FACTOR})
                </TableCell>
                <TableCell align="right">CS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.summary.map((summary, j) => {
                const identifier = "" + j;
                totalCS += summary.totalCS;
                return (
                  <TableRow key={j}>
                    <TableCell component="th" scope="row">
                      {summary.description}
                    </TableCell>
                    <TableCell align="right">{summary.totalUnits}</TableCell>
                    <TableCell align="right">{summary.adjustedUnits}</TableCell>
                    <TableCell align="right">
                      <TextField
                        color="secondary"
                        label=""
                        value={summary.totalCS}
                        name={identifier}
                        onChange={handleSummaryChange}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Divider />

      {state.data.map((item, i) => {
        return (
          <Accordion className={classes.cont}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              area-control="dexpanel-content"
              id="dexpanel-header"
            >
              <Typography variant="h6" className={classes.cont}>
                {item.routeAct} / {item.customerNum} / {item.name} /{" "}
                {item.assetNum} / {item.street} {item.location}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table className={classes.table} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Material</TableCell>
                      <TableCell align="right">Consignment</TableCell>
                      <TableCell align="right">Fill Qty</TableCell>
                      <TableCell align="right">CS</TableCell>
                      <TableCell align="right">EA</TableCell>
                    </TableRow>
                    {item.details.map((mat, j) => {
                      const matnr = mat.material_num;
                      const matDetail = state.matDetails.find(
                        (elem) => elem.materialNum === matnr
                      );
                      var matDesc = matnr.replace(/^0+/, "");
                      var cs = 0;
                      var ea = mat.refill_qty;
                      if (matDetail) {
                        matDesc = matDesc + ": " + matDetail.description;
                        cs = Math.floor(
                          (mat.refill_qty / matDetail.denominator) *
                            matDetail.numerator
                        );
                        ea =
                          mat.refill_qty -
                          cs * (matDetail.denominator / matDetail.numerator);
                      }
                      const itemIdent = i + ";" + j;
                      return (
                        <TableRow key={j}>
                          <TableCell component="th" scope="row">
                            {matDesc}
                          </TableCell>
                          <TableCell align="right">
                            <Button
                              variant="outlined"
                              onClick={() => fillConsignment(i, j)}
                            >
                              {mat.consignment}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            <TextField
                              color="secondary"
                              label=""
                              value={mat.refill_qty}
                              name={itemIdent}
                              onChange={handleQtyChange}
                            />
                          </TableCell>
                          <TableCell align="right">{cs}</TableCell>
                          <TableCell align="right">{ea}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}

      <Dialog
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="dri-title"
        open={confirm}
        onClose={closeConfirm}
      >
        <DialogTitle id="dri-title">Confirm Activity</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to release route {props.data.selectedRoute}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeConfirm()} color="primary">
            No
          </Button>
          <Button
            disabled={state.isSubmitted}
            onClick={() => submitToBackend()}
            color="primary"
            autoFocus
          >
            Yes, proceed
          </Button>
        </DialogActions>
      </Dialog>
    </StyledPaper>
  );
}
