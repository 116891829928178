import { Link as RouterLink } from "react-router-dom";
import styles from './style.module.scss';

const Logo = () => {
  return (
    <RouterLink to="/" className={styles.logo}>
      <img src="/img/logo.png" alt="Logo" />
    </RouterLink>
  );
};

export default Logo;
