export const ROUTE_ROOT = "/";
export const ROUTE_INDEX = "index";
export const ROUTE_LOGIN = "login";
export const ROUTE_APP = "app";
export const ROUTE_DASHBOARD = "dashboard";
export const ROUTE_OPENSOURCE = "opensource";

export const ROUTE_EQUIPMENTS = "equipments";
export const ROUTE_EQUIPMENTS_ADD = "equipments/add";
export const ROUTE_EQUIPMENTS_DETAIL = "equipments/:id";
export const ROUTE_EQUIPMENTS_SEARCH = "equipments/search";
export const ROUTE_EQUIPMENTS_SEARCHMAC = "equipments/searchmac/:id";
export const ROUTE_EQUIPMENTS_UNKNOWN = "equipments/unknown";
export const ROUTE_EQUIPMENTS_IMPORT = "equipments/import";

export const ROUTE_CUSTOMERS = "customers";
export const ROUTE_CUSTOMERS_DETAIL = "customers/:id";

export const ROUTE_MATERIALS = "materials";
export const ROUTE_MATERIALS_DETAIL = "materials/:id";
export const ROUTE_MATERIALS_ADD = "materials/add"; // Ex app/material/-

export const ROUTE_CONSUMERS = "consumers";
export const ROUTE_CONSUMERS_DETAIL = "consumers/:id";
export const ROUTE_CONSUMERS_TRANSACTIONS = "consumers/transactions";

export const ROUTE_CARDS = "cards";
export const ROUTE_CARDS_DETAIL = "cards/:id";
export const ROUTE_CARDS_GENERATE = "cards/generate";

/* Old:
<Route path="config/selectoption" element={<SelectOption />} />
<Route path="config/selectoption/:key" element={<SelectOption />} />
<Route path="config/selectoption/:key/:id" element={<SelectOption />} />
*/
// TODO: Check CONFIG_OPTIONS naming after redo pages/SelectOption/index. To understand what is :key and :id
export const ROUTE_CONFIG_OPTIONS = "config/options";
export const ROUTE_CONFIG_OPTIONS_KEY = "config/options/:key";
export const ROUTE_CONFIG_OPTIONS_KEY_DETAIL = "config/options/:key/:id";

export const ROUTE_TRANSACTIONS = "transactions";
export const ROUTE_TRANSACTIONS_SEARCH = "transactions/search";
export const ROUTE_TRANSACTIONS_DETAIL = "transactions/:id";

export const ROUTE_SETTLEMENT = "settlement";

export const ROUTE_ELAVON_LOG = "elavonlog";
export const ROUTE_ELAVON_LOG_DETAIL = "elavonlog/:id";

// export const ROUTE_NOT_FOUND = "/404";
export const ROUTE_NOT_FOUND = "*";
export const ROUTE_ACCOUNT = "account";

export const ROUTE_TELEMETRY_CONFIG = "telemetry/config";
export const ROUTE_TELEMETRY_MONITOR = "telemetry/monitor";
