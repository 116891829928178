import React from "react";
import { styled } from '@mui/material/styles';
import { useDispatch } from "react-redux";
import clsx from "clsx";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { login } from "actions";
const PREFIX = 'LoginForm';

const classes = {
  root: `${PREFIX}-root`,
  flag: `${PREFIX}-flag`
};

const StyledFormik = styled(Formik)(() => ({
  [`& .${classes.root}`]: {},
  [`& .${classes.flag}`]: { marginRight: "10px" }
}));

function LoginForm({ className, onSubmitSuccess, ...rest }) {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("login");

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <StyledFormik
      initialValues={{
        clnt: 0,
        username: "",
        password: "",
        lang: i18n.language,
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().max(255).required(t("userrequired")),
        password: Yup.string().max(255).required(t("passwordrequired")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (values.lang !== i18n.language) {
          changeLanguage(values.lang);
        }
        try {
          await dispatch(
            login(values.clnt, values.username, values.password, values.lang)
          );
          onSubmitSuccess();
        } catch (error) {
          const message =
            (error.response && error.response.data.message) ||
            "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
          {...rest}
        >
          <TextField
            error={Boolean(touched.clnt && errors.clnt)}
            autoFocus
            helperText={touched.clnt && errors.clnt}
            label={t("client")}
            margin="normal"
            name="clnt"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.clnt}
            variant="outlined"
            color="primary"
          />
          <TextField
            error={Boolean(touched.username && errors.username)}
            fullWidth
            helperText={touched.username && errors.username}
            label={t("username")}
            margin="normal"
            name="username"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.username}
            variant="outlined"
            color="primary"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={t("password")}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            color="primary"
          />
          <Select
            name="lang"
            value={values.lang}
            onChange={handleChange}
            label={t("language")}
            color="primary"
          >
            <MenuItem value="en">
              <img className={classes.flag} src="/img/en.png" alt="English" />
              English
            </MenuItem>
            <MenuItem value="de">
              <img className={classes.flag} src="/img/de.png" alt="German" />
              Deutsch
            </MenuItem>
          </Select>
          <Box mt={2}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
          </Box>
        </form>
      )}
    </StyledFormik>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
};

LoginForm.defaultProps = {
  onSubmitSuccess: () => { },
};

export default LoginForm;
