import React, {
  //  useEffect,
  useState,
} from "react";
import { styled } from '@mui/material/styles';
import {
  Button,
  Icon,
  Checkbox,
  Chip,
  FormControlLabel,
  Link,
  //  Theme,
  Tooltip,
  Typography,
  //  PropTypes,
  IconButton,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
/*
import Inventory from "./Inventory";
import Activity from "./Activity";
import DateAct from "./DateAct";
import Route from "./Route";
*/
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import AddAlert from "@mui/icons-material/AddAlert";
import UndoIcon from "@mui/icons-material/Undo";
import { useTranslation } from "react-i18next";

const PREFIX = 'Planning';

const classes = {
  root: `${PREFIX}-root`,
  tablediv: `${PREFIX}-tablediv`
};

const Root = styled('oute\n          open={route ')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.tablediv}`]: {
    marginTop: theme.spacing(3),
  }
}));

export default function Planning(props) {
  const { t } = useTranslation("roadplanning");

  /*jochen
  const [detail, setDetail] = React.useState({
    planningItem: null,
    matDetails: [],
    settlementData: {},
  });
*/

  const [showInv, setShowInv] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [colSort, setColSort] = useState({ field: "sort", dir: "asc" });
  const [activity, setActivity] = useState(-1);
  const [dateAct, setDateAct] = useState(-1);
  const [route, setRoute] = useState(-1);

  const state = props.data;

  const handleStatusClick = (item, dataIndex) => {
    var newStatus = "P";
    var newDate = new Date(state.planningDate);
    var isValid = false;
    // what is the current status?
    if (item.status === "P" || item.status === "C") {
      newStatus = "S";
      isValid = true;
    }
    if (item.status === "S") {
      // this item is already scheduled. If we are planning for another day, change the date. If not, unschedule it
      if (
        item.nextDeliveryAct === state.planningDate.toISOString().split("T")[0]
      ) {
        newDate = new Date(item.nextDeliveryPlan);
        newStatus = "P";
      } else {
        newStatus = "S";
      }
      isValid = true;
    }
    if (isValid) {
      //  const customerArr = [item.customerNum];
      //  setPlanningStatus(salesOrg.toString(), customerArr, newStatus, newDate).then(
      //    (v) => {
      //      if (v === "S") {
      // update successful, now update local structure w/o reloading everything
      item.status = newStatus;
      item.nextDeliveryAct = newDate.toISOString().split("T")[0];
      props.change(item, dataIndex);
      //      }
      //    }
      //  )
    }
  };

  const handleScheduleTodayClick = () => {
    var newStatus = "S";
    var newDate = new Date(state.planningDate);
    var customerNums = [];
    var itemArr = [];
    for (var i = 0; i < state.planningData.length; i++) {
      var item = state.planningData[i];
      if (
        item.nextDeliveryAct.toString() ===
        state.planningDate.toISOString().split("T")[0]
      ) {
        if (canBeVisited(item.accessDays)) {
          customerNums.push(item.customerNum);
          item.status = newStatus;
          item.nextDeliveryAct = newDate.toISOString().split("T")[0];
        }
      }
      itemArr.push(item);
    }
    if (customerNums.length > 0) {
      //  setPlanningStatus(salesOrg.toString(), customerNums, newStatus, newDate).then(
      //    (v) => {
      //      if (v === "S") {
      // update successful, now update local structure w/o reloading everything
      props.changeAll(itemArr);
      //      }
      //    }
      //  )
    }
  };

  const handleUnscheduleAllClick = () => {
    var newStatus = "P";
    //  var newDate = new Date(state.planningDate);
    var customerNums = [];
    var itemArr = [];
    for (var i = 0; i < state.planningData.length; i++) {
      var item = state.planningData[i];
      const dt = item.nextDeliveryAct.toString();
      if (dt === state.planningDate.toISOString().split("T")[0]) {
        customerNums.push(item.customerNum);
        item.status = newStatus;
        item.nextDeliveryAct = item.nextDeliveryPlan;
      }
      itemArr.push(item);
    }
    if (customerNums.length > 0) {
      //  resetPlanningStatus(salesOrg.toString(), customerNums, newStatus, newDate).then(
      //    (v) => {
      //      if (v === "S") {
      // update successful, now update local structure w/o reloading everything
      props.changeAll(itemArr);
      //      }
      //    }
      //  )
    }
  };

  const handleFTCClick = (item, dataIndex) => {
    var newFTC = !item.ftc;
    //setPlanningFTC(salesOrg.toString(), item.customerNum, item.equipmentNum, newFTC).then(
    //  (v) => {
    //    if (v === "S") {
    // update successful, now update local structure w/o reloading everything
    item.ftc = newFTC;
    props.change(item, dataIndex);
    //    }
    //  }
    // )
  };

  const handleInvClick = (planningItem) => {
    //getPlanningSettlement(salesOrg.toString(), planningItem.customerNum).then(
    //  (v) => {
    //    setDetail({planningItem: planningItem, matDetails: state.matDetails, settlementData: v});
    //    setShowInv(true);
    //  });
  };

  /*
  const handleInvClose = () => {
    setShowInv(false);
  };
  */

  const handleActClick = (rowIndex) => {
    setActivity(rowIndex);
  };

  /*
  const handleActSel = (newActivity) => {
    if (newActivity != "") {
      const dataIndex = activity;
      if (dataIndex > -1) {
        var item = state.planningData[dataIndex];
        //setPlanningActivity(salesOrg.toString(), item.customerNum, item.equipmentNum, newActivity).then(
        //  (v) => {
        //    if (v === "S") {
              // update successful, now update local structure w/o reloading everything
              item.activity = newActivity;
              props.change(item, dataIndex);
        //    }
        //  }
        //)
      }
    }
    setActivity(-1);
  };
  */

  /*
  const handleActClose = () => {
    setActivity(-1);
  };
  */

  const handleDateActClick = (rowIndex) => {
    setDateAct(rowIndex);
  };

  /*
  const handleDateActSel = (date) => {
    const newStatus = 'S';
    const newDate = new Date(Date.parse(date));
    const dataIndex = dateAct;
    var item = state.planningData[dataIndex];
    if (item && newDate) {
      //const customerArr = [item.customerNum];
      //setPlanningStatus(salesOrg.toString(), customerArr, newStatus, newDate).then(
      //  (v) => {
      //    if (v === "S") {
            // update successful, now update local structure w/o reloading everything
            item.status = newStatus;
            item.nextDeliveryAct = newDate.toISOString().split("T")[0];
            props.change(item, dataIndex);
       //   }
       // }
     // )
    }
    setDateAct(-1);
  };
  */

  /*
  const handleDateActClose = () => {
    setDateAct(-1);
  };
  */

  const handleRouteClick = (rowIndex) => {
    setRoute(rowIndex);
  };

  /*
  const handleRouteSel = (newRoute) => {
    if (newRoute !== "") {
      const dataIndex = route;
      if (dataIndex > -1) {
      //  var item = state.planningData[dataIndex];
        var items = state.planningData;
        //setPlanningRoute(salesOrg.toString(), item.customerNum, item.equipmentNum, newRoute).then(
        //  (v) => {
        //    if (v === "S") {
              // update successful, now update local structure w/o reloading everything
              items.splice(dataIndex, 1);
              props.changeAll(items);
        //    }
        //  }
       // )
      }
    }
    setRoute(-1);
  };
  */

  /*
  const handleRouteClose = () => {
    setRoute(-1);
  };
  */

  const handleCustNumClick = (customerNum) => {
    const custnr = customerNum.replace(/^0+/, "");
    window.open(`/app/customer/${custnr}`, "_blank");
  };

  const canBeVisited = (visitDays) => {
    const weekday = state.planningDate.getDay();
    var abbrev = "";
    switch (weekday) {
      case 0:
        abbrev = "Su";
        break;
      case 1:
        abbrev = "M";
        break;
      case 2:
        abbrev = "Tu";
        break;
      case 3:
        abbrev = "W";
        break;
      case 4:
        abbrev = "Th";
        break;
      case 5:
        abbrev = "F";
        break;
      case 6:
        abbrev = "Sa";
        break;
    }
    return visitDays.includes(abbrev);
  };

  const formatAccessDays = (item) => {
    var formatted = "";
    if (item) {
      if (item.accessDays) {
        if (item.prefDays !== "") {
          // we have preferred access days
          const weekdays = ["M", "Tu", "W", "Th", "F", "Sa", "Su"];
          weekdays.foreach((weekday) => {
            if (
              item.accessDays.includes(weekday) &&
              item.prefDays.includes(weekday)
            ) {
              formatted =
                formatted +
                "<font color='green' size='+1'>" +
                weekday +
                "</font>";
            } else {
              if (item.accessDays.includes(weekday)) {
                formatted = formatted + weekday;
              }
            }
          });
        } else {
          // no preferred days
          formatted = item.accessDays;
        }
      }
    }
    return formatted;
  };

  /*
  const onColumnOrderChange = (newColumnOrder, columnIndex, newPosition) => {
    //setColSequence(newColumnOrder);
  }
  */
  /*
  const onViewColumnsChange = (changedColumn, action) => {

    var current = displayCol;
    if (action === "remove") {
      if (!current.includes(changedColumn)) {
        current.push(changedColumn);
      }
    }
    if (action === "add") {
      current = current.filter(function(ele){ 
        return ele != changedColumn; 
    });
    }
    setDisplayCol(current);

}*/

  const isVisible = (column) => {
    // return !displayCol.includes(column)
    return true;
  };

  const tableHeight = window.innerHeight - 450 + "px";

  /*
 window.addEventListener('resize', ()=>{
    const header =  document.querySelector('ResponsiveDrawer')
    const headerAdj = header?header.clientHeight:200;
    setTABLE_BODY_HEIGHT(`${window.innerHeight - headerAdj }px`);
  })
  */

  const columns = [
    {
      label: "Sort",
      name: "sort",
      options: {
        filter: false,
        searchable: false,
        customBodyRenderLite: (dataIndex) => {
          return <div />;
        },
      },
    },
    {
      label: t("Customer"),
      name: "customerNum",
      options: {
        filter: false,
        searchable: true,
        display: isVisible("customerNum"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.suspended !== "") {
              return (
                <Tooltip title={"suspended until " + item.suspended}>
                  <Chip
                    label={item.customerNum}
                    icon={<HighlightOffIcon />}
                    variant="outlined"
                    onClick={() => handleCustNumClick(item.customerNum)}
                  />
                </Tooltip>
              );
            } else {
              return (
                <Chip
                  label={item.customerNum}
                  variant="outlined"
                  onClick={() => handleCustNumClick(item.customerNum)}
                />
              );
            }
          }
        },
      },
    },
    {
      label: t("Status"),
      name: "status",
      options: {
        searchable: false,
        display: isVisible("status"),
        customBodyRenderLite: (dataIndex) => {
          let planningDate = state.planningDate.toISOString().split("T")[0];
          let item = state.planningData[dataIndex];

          let color = "default";
          let variant = "outlined";
          let icon = <NotificationImportantIcon />;
          var toolt = "Overdue";

          if (item) {
            const nextDelAct = new Date(Date.parse(item.nextDeliveryAct))
              .toISOString()
              .split("T")[0];
            if (item.nextDeliveryAct <= planningDate) {
              // Needs to roll
              color = "primary";
            }
            if (item.nextDeliveryAct === planningDate) {
              // current day
              toolt = "Planned for " + item.nextDeliveryAct;
              icon = <AccessTimeIcon />;
              variant = "contained";
              if (item.status === "S") {
                toolt =
                  "Planned for selected planning date " + item.nextDeliveryAct;
              }
            }
            if (nextDelAct > planningDate) {
              // in the future
              icon = <AccessTimeIcon />;
              toolt = `Future (click to pull forward)`;
            }
            if (item.status === "S") {
              icon = <LocalShippingIcon />;
              toolt = `Scheduled for ${item.nextDeliveryAct}`;
            }
            if (item.status === "C") {
              icon = <DoneOutlineIcon />;
              toolt = `Confirmed for ${item.nextDeliveryAct}`;
            }
            return (
              <Tooltip title={toolt}>
                <Button
                  variant={variant}
                  color={color}
                  disableElevation
                  onClick={() => handleStatusClick(item, dataIndex)}
                >
                  <Icon>{icon}</Icon>
                </Button>
              </Tooltip>
            );
          }
        },
      },
    },
    {
      label: t("Activity"),
      name: "activity",
      options: {
        searchable: false,
        display: isVisible("activity"),
        hint: t("activityhint"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            const act = item.activity;
            let color = "green";
            switch (act) {
              case "INIT": {
                color = "yellow";
                break;
              }
              case "DISP": {
                color = "red";
                break;
              }
              case "NOPH": {
                color = "red";
                break;
              }
            }
            return (
              <Button
                variant="outlined"
                style={{ color: color }}
                disableElevation
                onClick={() => handleActClick(dataIndex)}
              >
                {item.activity}
              </Button>
            );
          }
        },
      },
    },
    {
      label: t("Route"),
      name: "routeAct",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("routeAct"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.routeAct) {
              return (
                <Link
                  component="button"
                  onClick={() => handleRouteClick(dataIndex)}
                >
                  {item.routeAct}
                </Link>
              );
            }
          }
        },
      },
    },
    {
      label: t("Next Delivery"),
      name: "nextDeliveryAct",
      options: {
        searchable: false,
        display: isVisible("nextDeliveryPlan"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.nextDeliveryAct) {
              return (
                <Link
                  component="button"
                  onClick={() => handleDateActClick(dataIndex)}
                >
                  {item.nextDeliveryAct.toString().split("T")[0]}
                </Link>
              );
            }
          }
        },
      },
    },
    {
      label: t("Proposed Del"),
      name: "nextDeliveryPlan",
      options: {
        searchable: false,
        display: isVisible("nextDeliveryPlan"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.nextDeliveryPlan) {
              return (
                <Link component="button" onClick={() => handleInvClick(item)}>
                  {item.nextDeliveryPlan.toString().split("T")[0]}
                </Link>
              );
            }
          }
        },
      },
    },
    {
      label: t("Last Del"),
      name: "lastDelivery",
      options: {
        searchable: false,
        display: isVisible("lastDelivery"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.lastDelivery) {
              return item.lastDelivery.toString().split("T")[0];
            }
          }
        },
      },
    },
    {
      label: t("DRI"),
      name: "dri",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("dri"),
        hint: t("DRIhint"),
      },
    },
    {
      label: t("PFR"),
      name: "pfr",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("prf"),
        hint: t("Projected Fill Rate"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.pfr) {
              return item.pfr + "%";
            }
          }
        },
      },
    },
    {
      label: t("PFR@OOS"),
      name: "pfrc",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("pfrc"),
        hint: t("PFR@OOShint"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.pfrc) {
              return item.pfrc + "%";
            }
          }
        },
      },
    },
    {
      label: t("PFU"),
      name: "pfu",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("pfu"),
        hint: t("Projected Fill Units"),
      },
    },
    {
      label: t("INV"),
      name: "inv",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("inv"),
        hint: t("Projected Inventory"),
      },
    },
    {
      label: t("CAP"),
      name: "cap",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("cap"),
        hint: t("Total Consigned Capacity"),
      },
    },
    {
      label: t("UPSD"),
      name: "materialConsumption",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("materialConsumption"),
        hint: t("Units per Sales Day"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.materialConsumption) {
              return item.materialConsumption.toFixed(2);
            }
          }
        },
      },
    },
    {
      label: t("LFR"),
      name: "lastFillRate",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("lastFillRate"),
        hint: t("Last Settlement Fill Rate"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.lastFillRate) {
              const perc = item.lastFillRate * 100;
              return perc.toFixed(0) + "%";
            }
          }
        },
      },
    },
    {
      label: t("FTC"),
      name: "ftc",
      options: {
        filter: false,
        searchable: false,
        hint: t("Fill To Capacity"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            return (
              <FormControlLabel
                label=""
                control={
                  <Checkbox
                    checked={item.ftc}
                    onClick={() => handleFTCClick(item, dataIndex)}
                  />
                }
              />
            );
          }
        },
      },
    },
    {
      label: t("Equipment"),
      name: "equipmentNum",
      options: {
        filter: false,
        searchable: true,
        display: isVisible("equipmentNum"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.equipmentNum) {
              return item.equipmentNum.replace(/^0+/, "");
            }
          }
        },
      },
    },
    {
      label: t("Asset"),
      name: "assetNum",
      options: {
        filter: false,
        searchable: true,
        display: isVisible("assetNum"),
      },
    },
    {
      label: t("Online"),
      name: "online",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("online"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            if (item.online && item.online !== "") {
              var color = "default";
              if (item.dexErrors) {
                color = "primary";
              }

              return (
                <div>
                  <Tooltip title={item.online}>
                    <SettingsInputAntennaIcon color={color} />
                  </Tooltip>
                </div>
              );
            }
          }
        },
      },
    },
    {
      label: t("Bank"),
      name: "bank",
      options: {
        searchable: false,
        display: isVisible("bank"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            const isBanked = item.bank !== "";
            return (
              <FormControlLabel
                label=""
                control={<Checkbox disabled checked={isBanked} />}
              />
            );
          }
        },
      },
    },
    {
      label: t("Name"),
      name: "name",
      options: {
        searchable: true,
        display: isVisible("name"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            return <Typography>{item.name}</Typography>;
          }
        },
      },
    },
    {
      label: t("Address"),
      name: "street",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("street"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            return (
              <Tooltip title={item.location}>
                <Typography>{item.street}</Typography>
              </Tooltip>
            );
          }
        },
      },
    },
    {
      label: t("City"),
      name: "city",
      options: {
        filter: false,
        searchable: false,
        display: isVisible("city"),
      },
    },
    {
      label: t("Schedule"),
      name: "schedulingMethod",
      options: {
        searchable: false,
        display: isVisible("schedulingMethod"),
      },
    },
    {
      label: t("Access Days"),
      name: "accessDays",
      options: {
        searchable: false,
        display: isVisible("accessDays"),
        customBodyRenderLite: (dataIndex) => {
          let item = state.planningData[dataIndex];
          if (item) {
            const formatted = formatAccessDays(item);
            return <span dangerouslySetInnerHTML={{ __html: formatted }} />;
          }
        },
      },
    },
    {
      label: t("Sales Days"),
      name: "salesDays",
      options: {
        searchable: false,
        display: isVisible("salesDays"),
      },
    },
  ];

  const customToolbar = () => {
    return (
      <React.Fragment>
        <Tooltip title={t("Schedule planned")}>
          <IconButton onClick={() => handleScheduleTodayClick()} size="large">
            <AddAlert />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("Unschedule planned")}>
          <IconButton onClick={() => handleUnscheduleAllClick()} size="large">
            <UndoIcon />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    );
  };

  // if we change the table, the stored sequence becomes invalid. Fix this here.
  /*
  if (colSequence.length != columns.length) {
    var arr = new Array();
    for (var i=0; i<columns.length; i++) {
      arr.push(i);
    }
    setColSequence(arr);
  }
   */
  return (
    <div className={classes.tablediv}>
      <MUIDataTable
        options={{
          serverSide: false,
          responsive: "standard",
          selectableRows: "none",
          rowsPerPage: rowsPerPage,
          rowsPerPageOptions: [200, 500, 1000],
          draggableColumns: { enabled: true },
          sortOrder: { name: colSort.field, direction: colSort.dir },
          fixedHeader: true,
          print: false,
          tableBodyHeight: tableHeight,
          //columnOrder: colSequence,
          //onColumnOrderChange: onColumnOrderChange,
          //onViewColumnsChange: onViewColumnsChange,
          customToolbar: customToolbar,
          //onColumnSortChange: (changedColumn, direction) => {
          //  setColSort({field: changedColumn, dir: direction})
          //},
          onChangeRowsPerPage: (numberOfRows) => {
            setRowsPerPage(numberOfRows);
          },
        }}
        title={t("Planning Board")}
        columns={columns}
        data={state.planningData}
      />
    </div>
  );
}

/*

        <Inventory
          data={detail}
          open={showInv}
          onClose={handleInvClose}
        />

        <Activity
          open={activity > -1}
          onClose={handleActClose}
          onSelection={handleActSel}
        />

        <DateAct
          open={dateAct > -1}
          onClose={handleDateActClose}
          onSelection={handleDateActSel}
        />

        <Route
          open={route > -1}
          onClose={handleRouteClose}
          onSelection={handleRouteSel}
          routeList={state.filteredRoutes}
        />

*/
