import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {
  Chip,
  Container,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Theme,
} from "@mui/material";

const PREFIX = 'Overview';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  chart: `${PREFIX}-chart`,
  chipActual: `${PREFIX}-chipActual`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {},

  [`&.${classes.container}`]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.chart}`]: {
    paddingTop: theme.spacing(3),
  },

  [`& .${classes.chipActual}`]: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

const v = {
  data: {
    "2021-05-24;404": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 37,
    },
    "2021-05-24;407": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 13,
    },
    "2021-05-24;410": {
      proposed: 4,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 38,
    },
    "2021-05-24;415": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 8,
    },
    "2021-05-24;414": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 41,
    },
    "2021-05-24;409": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 10,
    },
    "2021-05-24;412": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 19,
    },
    "2021-05-24;403": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 14,
    },
    "2021-05-24;405": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 42,
    },
    "2021-05-24;406": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 13,
    },
    "2021-05-24;402": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 14,
    },
    "2021-05-25;401": {
      proposed: 3,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 19,
    },
    "2021-05-25;411": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 12,
    },
    "2021-05-25;405": {
      proposed: 8,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 82,
    },
    "2021-05-25;415": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 16,
    },
    "2021-05-25;407": {
      proposed: 8,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 56,
    },
    "2021-05-25;404": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 51,
    },
    "2021-05-25;410": {
      proposed: 7,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 49,
    },
    "2021-05-25;406": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 27,
    },
    "2021-05-25;402": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 21,
    },
    "2021-05-25;403": {
      proposed: 4,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 32,
    },
    "2021-05-25;414": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 25,
    },
    "2021-05-25;408": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 26,
    },
    "2021-05-25;413": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 10,
    },
    "2021-05-25;409": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 8,
    },
    "2021-05-26;403": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 45,
    },
    "2021-05-26;405": {
      proposed: 7,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 64,
    },
    "2021-05-26;413": {
      proposed: 4,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 38,
    },
    "2021-05-26;404": {
      proposed: 7,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 63,
    },
    "2021-05-26;414": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 23,
    },
    "2021-05-26;410": {
      proposed: 3,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 48,
    },
    "2021-05-26;408": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 23,
    },
    "2021-05-26;411": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 12,
    },
    "2021-05-26;415": {
      proposed: 3,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 27,
    },
    "2021-05-26;407": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 16,
    },
    "2021-05-26;401": {
      proposed: 3,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 31,
    },
    "2021-05-26;402": {
      proposed: 1,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 16,
    },
    "2021-05-27;412": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 44,
    },
    "2021-05-27;402": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 59,
    },
    "2021-05-27;414": {
      proposed: 3,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 27,
    },
    "2021-05-27;413": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 24,
    },
    "2021-05-27;410": {
      proposed: 7,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 66,
    },
    "2021-05-27;404": {
      proposed: 4,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 38,
    },
    "2021-05-27;415": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 45,
    },
    "2021-05-27;407": {
      proposed: 8,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 66,
    },
    "2021-05-27;403": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 43,
    },
    "2021-05-27;401": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 20,
    },
    "2021-05-27;408": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 60,
    },
    "2021-05-27;405": {
      proposed: 6,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 64,
    },
    "2021-05-27;409": {
      proposed: 3,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 30,
    },
    "2021-05-28;406": {
      proposed: 12,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 83,
    },
    "2021-05-28;409": {
      proposed: 7,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 67,
    },
    "2021-05-28;405": {
      proposed: 22,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 181,
    },
    "2021-05-28;413": {
      proposed: 14,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 122,
    },
    "2021-05-28;402": {
      proposed: 15,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 124,
    },
    "2021-05-28;408": {
      proposed: 17,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 119,
    },
    "2021-05-28;410": {
      proposed: 19,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 137,
    },
    "2021-05-28;407": {
      proposed: 16,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 117,
    },
    "2021-05-28;401": {
      proposed: 19,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 137,
    },
    "2021-05-28;404": {
      proposed: 20,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 165,
    },
    "2021-05-28;403": {
      proposed: 17,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 143,
    },
    "2021-05-28;414": {
      proposed: 14,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 96,
    },
    "2021-05-28;415": {
      proposed: 13,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 100,
    },
    "2021-05-28;411": {
      proposed: 10,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 97,
    },
    "2021-05-28;412": {
      proposed: 6,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 53,
    },
    "2021-05-31;407": {
      proposed: 6,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 45,
    },
    "2021-05-31;402": {
      proposed: 13,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 118,
    },
    "2021-05-31;413": {
      proposed: 8,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 67,
    },
    "2021-05-31;405": {
      proposed: 11,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 131,
    },
    "2021-05-31;403": {
      proposed: 6,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 57,
    },
    "2021-05-31;411": {
      proposed: 10,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 109,
    },
    "2021-05-31;412": {
      proposed: 3,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 26,
    },
    "2021-05-31;408": {
      proposed: 6,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 48,
    },
    "2021-05-31;401": {
      proposed: 5,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 46,
    },
    "2021-05-31;414": {
      proposed: 13,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 119,
    },
    "2021-05-31;410": {
      proposed: 7,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 48,
    },
    "2021-05-31;404": {
      proposed: 7,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 67,
    },
    "2021-05-31;415": {
      proposed: 6,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 48,
    },
    "2021-05-31;406": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 23,
    },
    "2021-05-31;409": {
      proposed: 2,
      scheduled: 0,
      confirmed: 0,
      proposedCS: 12,
    },
  },
  dateFrom: "2021-05-25T00:00:00.000Z",
  dateTo: "2021-06-01T00:00:00.000Z",
};

export default function Overview(props) {

  const [state, setState] = useState({
    isLoaded: false,
    data: [],
  });

  useEffect(() => {
    if (state.isLoaded) return;

    setState({
      isLoaded: true,
      data: v,
    });
  }, [state]);

  var dateRange = new Array();
  if (state.data.dateFrom && state.data.dateTo) {
    const dateFrom = new Date(Date.parse(state.data.dateFrom));
    const dateTo = new Date(Date.parse(state.data.dateTo));
    var currDate = new Date(dateFrom);
    while (currDate.valueOf() < dateTo.valueOf()) {
      const dateStr = currDate.toISOString().split("T")[0];
      dateRange.push(dateStr);
      currDate.setDate(currDate.getDate() + 1);
    }
  }

  const title = "Planned Visits for " + props.data.selectedSalesOffice;

  return (
    <StyledContainer maxWidth={false} className={classes.container}>
      {state.data.data && (
        <div>
          <Chip label={title} className={classes.chipActual} color="primary" />
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="right">Route</TableCell>
                  {dateRange.map((item, index) => {
                    return <TableCell align="right">{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.filteredRoutes.map((route, rindex) => {
                  return (
                    <TableRow key={rindex}>
                      <TableCell align="right">{route.route}</TableCell>
                      {dateRange.map((dateStr, dindex) => {
                        const identifier = dateStr + ";" + route.route;
                        const cnt = state.data.data[identifier];
                        var proposed = 0;
                        var scheduled = 0;
                        var confirmed = 0;
                        var proposedCS = 0;
                        var scheduledCS = 0;
                        var confirmedCS = 0;
                        if (cnt) {
                          proposed = cnt.proposed || 0;
                          scheduled = cnt.scheduled || 0;
                          confirmed = cnt.confirmed || 0;
                          proposedCS = cnt.proposedCS || 0;
                          scheduledCS = cnt.scheduledCS || 0;
                          confirmedCS = cnt.confirmedCS || 0;
                        }
                        return (
                          <TableCell align="right" key={dindex}>
                            <b>{proposed}</b> ({proposedCS}) /{" "}
                            <b>{scheduled}</b> ({scheduledCS}) /{" "}
                            <b>{confirmed}</b> ({confirmedCS})
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </StyledContainer>
  );
}
