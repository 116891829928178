import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import { useAxios } from "hooks";
import { initialValues, Screen } from "./components";
import { useSelector } from "react-redux";
import { ContentContainer } from "components";
import { ROUTE_EQUIPMENTS } from "const";
import { getAbsLink } from "utilities";
import { read, utils } from "xlsx";

const EquipmentsImport = () => {
  const navigate = useNavigate();
  const [equipmentAll, setEquipmentAll] = useState({equipment: initialValues, equipmentCols: Array<any>(), equipmentSels: Array<any>(), materialList: []});
  const [uploadDone, setUploadDone] = useState(false);

  const navigateToList = () => {
    navigate(getAbsLink(ROUTE_EQUIPMENTS));
  }

  // TODO: Change type any after redo auth
  const { user: { client: { clnt } } } = useSelector((state: any) => state.user);

  // Add clnt to initial values. Server throw error if no clnt
  initialValues.clnt = clnt;

  // Define save form handler
  const [post, { data: saveSuccess, loading: loadingSave }] = useAxios({
    method: "post",
    url: "/api/equipment",
    notify: true
  });

  const handleSave = (data: any) => {
    post(data);
  }

  const importExcel = (e:any) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      //parse data

      const bstr = event.target ? event.target.result : "";
      const workBook = read(bstr, { type: "binary" });

      //get Equipment
      var equiData:any  = [];
      var colData:any   = [];
      var selData:any   = [];

      const equiSheet = workBook.Sheets["Equipment"];
      if (equiSheet) {
        equiData = utils.sheet_to_json(equiSheet, {header: 1});
        equiData.splice(0,1);
      }
      const colSheet = workBook.Sheets["Columns"];
      if (colSheet) {
        colData = utils.sheet_to_json(colSheet, {header: 1});
        colData.splice(0,1);
      }
      const selSheet = workBook.Sheets["Selections"];
      if (equiSheet) {
        selData = utils.sheet_to_json(selSheet, {header: 1});
        selData.splice(0,1);
      }      

      console.log(equiData);
      console.log(colData);
      console.log(selData);

      var all = equipmentAll;

      all.equipment.equnr                = equiData[0][0];
      all.equipment.sorg                 = equiData[0][1];
      all.equipment.active               = equiData[0][2] === "true" ? true : false;
      all.equipment.equtype              = equiData[0][3];
      all.equipment.factoryserialnr      = equiData[0][4];
      all.equipment.macaddress           = equiData[0][5];
      all.equipment.manufacturer         = equiData[0][6];
      all.equipment.model                = equiData[0][7];
      all.equipment.note                 = equiData[0][8];
      all.equipment.outlet               = equiData[0][9];
      all.equipment.profitcenter         = equiData[0][10];
      all.equipment.serialnr             = equiData[0][11];
      all.equipment.demomode             = equiData[0][12] === "true" ? true : false;
      all.equipment.label                = equiData[0][13];
      all.equipment.outletname           = equiData[0][14];
      all.equipment.outletaddress        = equiData[0][15];
      all.equipment.paymenttermserialnr  = equiData[0][16];
      all.equipment.campus               = equiData[0][17];

      for (var i=0; i<colData.length; i++) {
        const rec = {
          clnt:         clnt,
          equnr:        colData[i][0],
          colnr:        colData[i][1],
          matnr:        colData[i][2],
          maxcapacity:  colData[i][3],
          maxfill:      colData[i][4],
          label:        colData[i][5],
        };
        all.equipmentCols.push(rec);
      };

      for (var i=0; i<selData.length; i++) {
        const rec = {
          clnt:         clnt,
          equnr:        selData[i][0],
          selnr:        selData[i][1],
          assignments:  selData[i][2],
          price:        selData[i][3],
          label:        selData[i][4],
          oos:          selData[i][5] === "true" ? true : false,
        };
        all.equipmentSels.push(rec);
      };

      setEquipmentAll(all);
      setUploadDone(true);
    };

    if (file) {
      reader.readAsBinaryString(file);
    }
  }

  // Save Success
  useEffect(() => {
    if (saveSuccess) {
      // 
      navigateToList();
    };
  }, [saveSuccess]);

  return (
    <>
    {!uploadDone ?
      <Box sx={{
        py: 1.5,
        borderBottom: 1,
        borderBottomColor: "grey.300",
        backgroundColor: "grey.50",
      }}>
        <ContentContainer>
          <input type="file" onChange={importExcel} />
        </ContentContainer>
      </Box>
    :
      <Screen
        data={equipmentAll}
        handleSave={handleSave}
        handleNavigate={navigateToList}
        loadingSave={loadingSave}
      />
    }
    </>
  );
};

export default EquipmentsImport;
