import { ContentContainer, FormSubsection, MUITableD } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any;
};

const SearchResult = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation("equipment"); // Get translation
  const navigate = useNavigate();

  const columns = [
    { name: "equnr", label: t("Equipment Number") },
    { name: "sorg", label: t("Sales Org") },
    { name: "profitcenter", label: t("Profitcenter") },
    { name: "outlet", label: t("Outlet") },
    { name: "outletname", label: t("Outlet Name") },
    { name: "outletaddress", label: t("Outlet Address") },
    { name: "macaddress", label: t("MAC Address") },
    { name: "tlcserialnr", label: t("TLC") },
    { name: "note", label: t("Note") },
    { name: "serialnr", label: t("Serial") },
    { name: "manufacturer", label: t("Manufacturer") },
    { name: "model", label: t("Model") },
    { name: "label", label: t("Label") },
    { name: "equtype", label: t("Equipment Type") },
    { name: "factoryserialnr", label: t("Factory Serial No.") },
    { name: "paymenttermserialnr", label: t("Payment Term") },
];

  const handleRelRowClick = (rowdata: Array<String>) => {
    navigate("/app/equipments/" + rowdata[0]);
  };

  return (
    <>
      <ContentContainer py={4}>
        <FormSubsection title={t("Equipments")}>
          <MUITableD
            title=""
            data={data}
            cols={columns}
            sortOrder={{ name: "equnr", direction: "desc" }}
            onRowClick={handleRelRowClick}
          />
        </FormSubsection>
      </ContentContainer>
    </>
  );
};

export default SearchResult;
