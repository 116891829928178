import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Paper, Typography, Button } from "@mui/material";
import { auth, numeric } from "utilities";
import { renewToken, logout } from "actions";
import { Clock as ClockIcon } from "react-feather";

const PREFIX = 'TokenExpiration';

const classes = {
  root: `${PREFIX}-root`,
  media: `${PREFIX}-media`,
  timer: `${PREFIX}-timer`,
  text: `${PREFIX}-text`,
  content: `${PREFIX}-content`,
  actions: `${PREFIX}-actions`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    maxWidth: 400,
    position: "fixed",
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: "none",
    zIndex: 2000,
  },

  [`& .${classes.media}`]: {
    padding: theme.spacing(1, 2),
    textAlign: "center",
    "& > img": {
      height: "100%",
      width: "auto",
    },
  },

  [`& .${classes.timer}`]: {
    textAlign: "center",
    margin: theme.spacing(3),
  },

  [`& .${classes.text}`]: {
    textAlign: "center",
  },

  [`& .${classes.content}`]: {
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.actions}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
  }
}));

function TokenExpiration() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [remaining, setRemaining] = useState(1000);

  const handleRenew = () => {
    try {
      dispatch(renewToken());
    } catch (error) { }
  };

  const doLogout = async () => {
    try {
      await dispatch(logout());
      navigate("/");
    } catch (error) { }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const rem = auth.getRemainingValidity();
      setRemaining(rem);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (remaining > 120) {
    return null;
  }

  if (remaining === 0) {
    doLogout();
    return null;
  }

  const remFormatted = numeric.convertToTime(1, remaining);

  return (
    <StyledPaper className={classes.root} elevation={3}>
      <div className={classes.media}>
        <ClockIcon size={30} />
      </div>
      <div className={classes.content}>
        <Typography variant="body2" className={classes.text}>
          Your session is about to expire in
        </Typography>
        <Typography variant="body1" className={classes.timer}>
          {remFormatted}
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Please extend your session or you will be logged out automatically.
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          className={classes.agreeButton}
          color="primary"
          onClick={handleRenew}
          variant="contained"
        >
          Extend Session
        </Button>
      </div>
    </StyledPaper>
  );
}

export default TokenExpiration;
