import React, { useCallback, useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useParams, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";

import { Prompt, Page, Header } from "components";

import { axios, useIsMountedRef, codeDesc, selOptions } from "utilities";
import Detail from "./Detail";
import Equimats from "./equimats";
import Equisels from "./equisels";
/* import Description from './Description'; */
/* import Uom from './Uom';
import Bom from './Bom'; */
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { setLocale } from "yup";

const PREFIX = 'EquipmentDetail';

const classes = {
  root: `${PREFIX}-root`
};

const StyledPage = styled(Page)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

function EquipmentDetail() {
  const { t/* , i18n */ } = useTranslation("equipment");
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({});
  const [columns, setColumns] = useState([]);
  const [selections, setSelections] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [error, setError] = useState({});
  const [helper, setHelper] = useState({});
  const [codes, setCodes] = useState(null);
  const [leavePopup, setLeavePopup] = useState({ dataChanged: false, display: false, navigate: "" });
  const [currentTab, setCurrentTab] = useState('detail');
  const tabs = [
    { value: 'detail', label: t('Details') },
    { value: 'equimats', label: t('Materials') },
    { value: 'equisels', label: t('Selections') },
    /*  { value: 'bom', label: t('Bill of Materials') }, */
  ];

  setLocale({
    mixed: {
      default: 'invalid',
    },
    string: {
      min: ({ min }) => ({ key: 'min_long', values: { min } }),
      max: ({ max }) => ({ key: 'max_long', values: { max } }),
    },
    number: {
      min: ({ min }) => ({ key: 'min_long', values: { min } }),
      max: ({ max }) => ({ key: 'max_long', values: { max } }),
    },
  });

  const validationSchema = yup.object({
    equnr: yup.string().max(18, "Equipment number cannot have more than 18 characters").nullable(),
    serialnr: yup.string().max(18, "Serial number cannot have more than 18 characters").nullable(),
    manufacturer: yup.string().max(30, "Manufacturer cannot have more than 30 characters").nullable(),
    model: yup.string().max(20, "Model cannot have more than 20 characters").nullable(),
    factoryserialnr: yup.string().max(30, "Factory serial number cannot have more than 30 characters").nullable(),
    equtype: yup.string().max(8, "Equipment Type cannot have more than 8 characters").nullable(),
    macaddress: yup.string().required("mac address cannot be empty"),
    note: yup.string().max(100, "Note cannot have more than 100 characters").nullable(),
    label: yup.string().max(20, "Label cannot have more than 20 characters").nullable(),
    participantid: yup.string().max(25, "Participant-ID cannot have more than 25 characters").nullable(),
    profitcenter: yup.string().max(15, "Profitcenter cannot have more than 15 characters").nullable(),
    outlet: yup.string().max(15, "Outlet cannot have more than 15 characters").nullable(),
    outletname: yup.string().max(30, "Outlet Name cannot have more than 15 characters").nullable(),
    outletaddress: yup.string().max(100, "Outlet Address cannot have more than 15 characters").nullable(),
    paymenttermserialnr: yup.string().max(30, "Payment Terminal Serial cannot have more than 15 characters").nullable(),
    clnt: yup.number().typeError("Client number must be a number").nullable(),
    dexoffset: yup.number().typeError("DEX Offset must be a number"),
  });

  //     macaddress: yup.string().matches(/^[0-9A-F]{1,2}(:)(?:[0-9A-F]{1,2}\1){4}[0-9A-F]{1,2}$/, "not a valid mac address format").required("mac address cannot be empty"),

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleSave = async () => {
    // validate all subscreens and consolidate data
    setLeavePopup({ ...leavePopup, dataChanged: false });
    await validationSchema.validate(data, { abortEarly: false })
      .then(() => {
        setError({});
        setHelper({});

        let url = "/api/equipment";
        let equipmentAll = { equipment: data, equipmentCols: columns, equipmentSels: selections };
        axios.post(url, equipmentAll)
          .then(response => {
            if (response.status === 200) {
              enqueueSnackbar("Equipment saved successfully", { variant: "success" });
              navigate("/app/equipment");
            }
          })
          .catch(reason => {
            enqueueSnackbar("Error saving equipment: " + reason, { variant: "error" });
          })

      })
      .catch(function (err) {
        if (err.inner) {
          var errs = {};
          var hlps = {};
          err.inner.forEach((item) => {
            errs[item.path] = true;
            hlps[item.path] = item.message;
          });
          setError(errs);
          setHelper(hlps);
          setCurrentTab('detail');
        }
      })
  }

  const handleDataChange = (event) => {
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    setLeavePopup({ ...leavePopup, dataChanged: true });
    if (event.type === 'click') {
      if (event.target.innerHTML === 'Active') {
        name = 'active';
        value = false;
      } else if (event.target.innerHTML === 'Inactive') {
        name = 'active';
        value = true;
      }
    }

    if (name === "demomode" || name === "tlcoos") {
      value = event.target.checked;
    }

    // convert mac address to upper case
    if (name === "macaddress" && value) {
      value = value.toUpperCase();
    }

    setData({
      ...data,
      [name]: value
    });
  };

  const handleColumnsChange = (newColumns, matnr) => {
    setLeavePopup({ ...leavePopup, dataChanged: true });
    var found = false;
    for (var i = 0; i < materials.length; i++) {
      if (materials[i].matnr === matnr) {
        found = true;
        break;
      }
    }
    if (found) {
      setColumns(newColumns);
    } else {
      axios
        .get('/api/material?matnr=' + matnr)
        .then((response) => {
          setMaterials(prevState => [...prevState, response.data]);
          setColumns(newColumns);
        });
    }
  }

  const handleSelectionsChange = (selections) => {
    setLeavePopup({ ...leavePopup, dataChanged: true });
    setSelections(selections);
  }

  const handleAssign = (serialnr, identity, macaddress) => {
    setData({
      ...data,
      tlcserialnr: serialnr,
      tlcidentity: identity,
      macaddress: macaddress
    });
  }

  const handleLeavePopupCancel = () => {
    setLeavePopup({ ...leavePopup, display: false });
    navigate(leavePopup.navigate);
  }

  const handleLeavePopupSave = () => {
    handleSave();
    setLeavePopup({ ...leavePopup, display: false });
  }

  const handleCopyConf = (equnr) => {
    axios
      .get('/api/equipment?equnr=' + equnr)
      .then((response) => {
        setLeavePopup({ ...leavePopup, dataChanged: true });
        setSelections(response.data.equipmentSels);
        setColumns(response.data.equipmentCols);
        setMaterials(response.data.materialList);
      }
      );
  }

  const getData = useCallback(() => {
    axios
      .get('/api/equipment?equnr=' + params.equnr)
      .then((response) => {
        if (isMountedRef.current) {
          setData(response.data.equipment);
          setSelections(response.data.equipmentSels);
          setColumns(response.data.equipmentCols);
          setMaterials(response.data.materialList);
        }
      });
  }, [isMountedRef, params]);

  useEffect(() => {
    codeDesc("en", ["sorg", "equtype", "manufacturer"]).then(res => {
      setCodes(res);
      if (params.equnr === '-') {
        // new equipment
        var newEquipment = {};
        let sorgOpt = selOptions(res, "sorg");
        if (sorgOpt.length > 0) {
          newEquipment.sorg = sorgOpt[0].code;
        }
        let equtypeOpt = selOptions(res, "equtype");
        if (equtypeOpt.length > 0) {
          newEquipment.equtype = equtypeOpt[0].code;
        }
        setData(newEquipment);
      } else {
        getData();
      }
    });
  }, [getData, params.equnr]);

  const handleBlockedRoute = (nextLocation) => {
    setLeavePopup({ display: true, navigate: nextLocation.pathname, dataChanged: false });
    return false;
  }

  /*   const getDescription = (value) => {
      var desc = "";
      if (value && value.length > 0) {
        const trans = value.find(elem => elem.lang === i18n.language.toUpperCase());
        if (trans) {
          desc = trans.description;
        }
      }
      return desc;
    } */

  if (!(data && codes)) {
    return null;
  }

  console.log(materials);

  return (
    <StyledPage
      className={classes.root}
      title="Equipment Details"
    >
      <Container maxWidth={false}>
        <Header area='Management' breadcrumb='/app/equipment' apiData={location.state} title={t('Equipments')} subtitle={data.equnr /* + " - " + getDescription(data.materialT) */} saveFunc={handleSave} />
        {<Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
            className={classes.tabs}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>}
        <Divider />
        <Box mt={3}>
          {currentTab === 'detail' && <Detail data={data} error={error} helper={helper} codes={codes} handleChange={handleDataChange} handleAssign={handleAssign} handleCopyConf={handleCopyConf} />}
          {currentTab === 'equimats' && <Equimats data={data} columns={columns} materials={materials} handleChange={handleColumnsChange} />}
          {currentTab === 'equisels' && <Equisels data={data} selections={selections} handleChange={handleSelectionsChange} />}
          {/*  {currentTab === 'bom' && <Bom data={data.materialComp} error={error} helper={helper} codes={codes} handleChange={handleBomsChange}/>}                   */}
        </Box>
      </Container>

      <Prompt when={leavePopup.dataChanged} message={handleBlockedRoute} />
      <Dialog aria-labelledby="leave-popup-title" open={leavePopup.display}>
        <DialogTitle id="leave-popup-title">
          Leave without saving?
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleLeavePopupCancel} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={handleLeavePopupSave} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </StyledPage>
  );
}

export default EquipmentDetail;
