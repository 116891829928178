import { TextField, TextFieldProps } from "@mui/material";

export enum DisplayFieldConversion {
  "Num2Digits"          = "0",
  "RemoveLeadingZeros"  = "1"
}

type DisplayFieldProps = TextFieldProps & {
  data: any;
  name: string;
  label: string;
  conversion?: DisplayFieldConversion;
};

const DisplayField = (props: DisplayFieldProps) => {
  const { data, name, label, conversion, ...rest } = props;

  // Fallback for null and undefined values
  var fieldValue = "";
  if (data && data[name] != null) {
    fieldValue = data[name] + "";
  }

  if (conversion) {
    if (conversion === DisplayFieldConversion.Num2Digits) {
      const i = parseFloat(fieldValue);
      fieldValue = i.toFixed(2);
    }
  }

  return (
    <TextField
      value={fieldValue}
      type="text"
      label={label}
      margin="dense"
      InputProps={{ readOnly: true }}
      variant="standard"
      fullWidth
      {...rest} // handles MUI props - disabled, rows, multiline, required etc
    />
  );
};

export default DisplayField;
