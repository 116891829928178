import React from "react";
import { styled } from '@mui/material/styles';
import { Avatar, Paper, Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";

import { Copyright } from "components";
import LoginForm from "./LoginForm";

const PREFIX = 'Login';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    height: "100vh",
  },

  [`& .${classes.image}`]: {
    backgroundImage: "url(/img/vm1.png)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  }
}));

function Login() {

  const navigate = useNavigate();

  const handleSubmitSuccess = () => {
    navigate("/app");
  };

  return (
    <StyledGrid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <LoginForm onSubmitSuccess={handleSubmitSuccess} />
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </StyledGrid>
  );
}

export default Login;
