import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Card, Container, Grid } from "@mui/material";
import { useAxios } from "hooks";
import { Page } from "components";
import { useTranslation } from "react-i18next";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import Volume from "./components/volume";
import Volperday from "./components/volperday";
import Topoutlet from "./components/topoutlet";
import DashboardSearchForm from "./components/SearchForm";
import initialValues from "./components/initialValues";
import { useFormik } from "formik";

const PREFIX = "Dashboard";

const classes = {
  root: `${PREFIX}-root`,
  dashboard: `${PREFIX}-dashboard`,
  button: `${PREFIX}-button`,
  chartbox: `${PREFIX}-chartbox`,
};

const StyledPage = styled(Page)(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  [`& .${classes.dashboard}`]: {
    marginBottom: theme.spacing(1),
    fontFamily: "Roboto",
  },

  [`& .${classes.button}`]: {
    marginLeft: theme.spacing(3),
  },

  [`& .${classes.chartbox}`]: {
    height: 350,
    marginBottom: theme.spacing(1),
    fontFamily: "Roboto",
  },
}));

const initPie = [
  {
    id: "authRequests",
    label: "authRequests",
    value: 0,
    color: "hsl(355, 70%, 50%)",
  },
  {
    id: "completeRequests",
    label: "completeRequests",
    value: 0,
    color: "hsl(237, 70%, 50%)",
  },
  {
    id: "deleteRequests",
    label: "deleteRequests",
    value: 0,
    color: "hsl(48, 70%, 50%)",
  },
  {
    id: "volume",
    label: "volume",
    value: 0,
    color: "hsl(214, 70%, 50%)",
  },
];

const initBar = [
  {
    date: "2022-10-10",
    CCBSS: 18,
    CCBSSColor: "hsl(171, 70%, 50%)",
    SWB: 91,
    SWBColor: "hsl(38, 70%, 50%)",
  },
  {
    date: "2022-10-09",
    CCBSS: 6,
    CCBSSColor: "hsl(171, 70%, 50%)",
    SWB: 97,
    SWBColor: "hsl(38, 70%, 50%)",
  },
];

function Dashboard() {
  const ONE_MONTH = 3600 * 24 * 31 * 1000;

  const { t } = useTranslation("dashboard");

  const [piedata, setPiedata] = useState(initPie);
  const [bardata, setBardata] = useState(initBar);
  const [keylist, setKeylist] = useState([]);
  const [volume, setVolume] = useState();
  const [volperday, setVolperday] = useState();
  const [outlet, setOutlet] = useState();
  const [searcheddays, setSearcheddays] = useState(0);

  // Define search form handler
  const [post, { data: searchResult, loading: loadingSave }] = useAxios({
    method: "post",
    url: "/api/statistics",
  });

  // Init Formik
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: values => {
      post(values); // Call search
    },
    onReset: values => {
      
    }
  });

  const { values } = formik;

  const writePiedata = (result) => {
    //console.log(piedata);
    //console.log(result);
    let warpData = [
      {
        id: "authRequests",
        label: "authRequests",
        value: result.authRequests,
        color: "hsl(355, 70%, 50%)",
      },
      {
        id: "completeRequests",
        label: "completeRequests",
        value: result.completeRequests,
        color: "hsl(237, 70%, 50%)",
      },
      {
        id: "deleteRequests",
        label: "deleteRequests",
        value: result.deleteRequests,
        color: "hsl(48, 70%, 50%)",
      },
      {
        id: "refundRequests",
        label: "refundRequests",
        value: result.refundRequests,
        color: "hsl(148, 70%, 50%)",
      },
    ];
    setPiedata(warpData);
  };

  const writeBardata = (result) => {
    //Write Keylist - START
    let profitCenter = [];
    const outer = Object.values(result.barchart);
    for (var i = 0; i < outer.length; i++) {
      const inner = Object.keys(outer[i]);
      for (var x = 0; x < inner.length; x++) {
        profitCenter.push(inner[x]);
      }
    }
    profitCenter = [...new Set(profitCenter)];
    setKeylist(profitCenter);
    //Write Keylist - END
    //Write BarChart data - START
    let barchartdata = [];
    const dates = Object.keys(result.barchart);
    for (var y = 0; y < dates.length; y++) {
      let bcdp = {};
      let total = 0;
      const bdatakeys = Object.keys(outer[y]);
      const bdatavalues = Object.values(outer[y]);
      for (var z = 0; z < bdatakeys.length; z++) {
        bcdp[bdatakeys[z]] = bdatavalues[z];
        total = total + bdatavalues[z];
      }
      bcdp.date = dates[y];
      bcdp.total = total;
      barchartdata.push(bcdp);
    }
    barchartdata = []
      .concat(barchartdata)
      .sort((a, b) => a.date.localeCompare(b.date));
    setBardata(barchartdata);
    //Write BarChart data - END
  };

  useEffect(() => {
    if (searchResult) {
      setSearcheddays(
        (values.dateTo - values.dateFrom) / (1000 * 3600 * 24) + 1
      );
      writePiedata(searchResult);
      writeBardata(searchResult);
      setVolume(searchResult.volume);
      setVolperday(searchResult.volperday);
      setOutlet(searchResult.outlet);
    }
  }, [searchResult]);

  return (
    <StyledPage className={classes.root} title={t("Transactions")}>
      <Container maxWidth={false} className={classes.container}>

        <DashboardSearchForm
          formik={formik}
          loadingSave={loadingSave}
        />
        {searchResult &&
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <Card className={classes.chartbox}>
              <ResponsiveBar
                data={bardata}
                keys={keylist}
                indexBy="date"
                margin={{ top: 50, right: 130, bottom: 80, left: 60 }}
                padding={0.3}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={{ scheme: "nivo" }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: "fries",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "sandwich",
                    },
                    id: "lines",
                  },
                ]}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -90,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: 32,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "volume",
                  legendPosition: "middle",
                  legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={function (e) {
                  return (
                    e.id +
                    ": " +
                    e.formattedValue +
                    " in country: " +
                    e.indexValue
                  );
                }}
              />
            </Card>
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            <Card className={classes.chartbox}>
              <ResponsivePie
                data={piedata}
                margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: "color",
                  modifiers: [["darker", 2]],
                }}
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "rgba(255, 255, 255, 0.3)",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: "ruby",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "c",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "go",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "python",
                    },
                    id: "dots",
                  },
                  {
                    match: {
                      id: "scala",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "lisp",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "elixir",
                    },
                    id: "lines",
                  },
                  {
                    match: {
                      id: "javascript",
                    },
                    id: "lines",
                  },
                ]}
              />
            </Card>
          </Grid>

          <Grid item xs={6} md={4} lg={3} xl={3}>
            <Topoutlet outlet={outlet} />
          </Grid>
          <Grid item xs={6} md={4} lg={3} xl={3}>
            <Volume volume={volume} />
          </Grid>
          <Grid item xs={6} md={4} lg={3} xl={3}>
            <Volperday volperday={volperday} searcheddays={searcheddays} />
          </Grid>
        </Grid>
        }
      </Container>
    </StyledPage>
  );
}

export default Dashboard;
