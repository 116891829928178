import React from "react";
import { styled } from '@mui/material/styles';
import PropTypes from "prop-types";
import clsx from "clsx";
import { Button, Grid, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Edit";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Edit";
import CheckOutlinedIcon from "@mui/icons-material/Edit";

const PREFIX = 'TFunction';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.button}`]: { marginLeft: theme.spacing(3), marginBottom: theme.spacing(2) }
}));

function TFunction({
  className,
  backFunc,
  checkFunc,
  saveFunc,
  addFunc,
  deleteFunc,
  editFunc,
  ro,
  ...rest
}) {


  let backButton;
  if (backFunc) {
    backButton = (
      <Tooltip title="Back">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={backFunc}
        >
          <ArrowBackIcon />
        </Button>
      </Tooltip>
    );
  }

  let checkButton;
  if (checkFunc) {
    checkButton = (
      <Tooltip title="Check and Save">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={checkFunc}
        >
          <CheckOutlinedIcon />
        </Button>
      </Tooltip>
    );
  }

  let addButton;
  if (addFunc) {
    addButton = (
      <Tooltip title="Add">
        <Button className={classes.button} variant="outlined" onClick={addFunc}>
          <AddIcon />
        </Button>
      </Tooltip>
    );
  }

  let editButton;
  if (editFunc) {
    if (!ro) {
      editButton = (
        <Tooltip title="Edit">
          <Button
            className={classes.button}
            variant="outlined"
            onClick={editFunc}
            color="primary"
          >
            <EditIcon />
          </Button>
        </Tooltip>
      );
    } else {
      editButton = (
        <Tooltip title="Edit">
          <Button
            className={classes.button}
            variant="outlined"
            onClick={editFunc}
          >
            <EditIcon />
          </Button>
        </Tooltip>
      );
    }
  }

  let saveButton;
  if (saveFunc && !ro) {
    saveButton = (
      <Tooltip title="Save">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={saveFunc}
        >
          <SaveIcon />
        </Button>
      </Tooltip>
    );
  }

  let deleteButton;
  if (deleteFunc) {
    deleteButton = (
      <Tooltip title="Delete">
        <Button
          className={classes.button}
          variant="outlined"
          onClick={deleteFunc}
        >
          <DeleteIcon />
        </Button>
      </Tooltip>
    );
  }

  return (
    <Root {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item></Grid>
        <Grid item>
          {backButton}
          {checkButton}
          {addButton}
          {editButton}
          {saveButton}
          {deleteButton}
        </Grid>
      </Grid>
    </Root>
  );
}

TFunction.propTypes = {
  className: PropTypes.string,
  backFunc: PropTypes.func,
  checkFunc: PropTypes.func,
  deleteFunc: PropTypes.func,
  saveFunc: PropTypes.func,
  editFunc: PropTypes.func,
  addFunc: PropTypes.func,
  ro: PropTypes.bool,
};

export default TFunction;
