import { TextField, TextFieldProps } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useField, useFormikContext } from 'formik';
import { memo } from "react";
import dayjs from "dayjs";

// Docs:
// https://formik.org/docs/examples/with-material-ui
// https://formik.org/docs/api/useFormik
// https://formik.org/docs/api/useField
type FormikDateTimeFieldProps = TextFieldProps & {
  name: string;
  label: string;
};

const FormikDateTimeField = (props: FormikDateTimeFieldProps) => {
  const { name, label, disabled, multiline, ...rest } = props;

  const [field, meta, helpers] = useField(name);
  const { value: fieldValue } = field;
  const { setFieldValue } = useFormikContext();

  // Fallback for null and undefined values
  const value = fieldValue !== null ? fieldValue : "";

  return (
    <DateTimePicker
    {...field}
    value={dayjs(value)}
    label={label}
    onChange={val => {
      setFieldValue(field.name, val);
    }}
  />
  );
};

export default memo(FormikDateTimeField);