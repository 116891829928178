import { useParams } from "react-router";
import { LoadingPage } from "components";
import { useAxios } from "hooks";
import { Display, NotFound } from "./components";

const TransactionsDetail = () => {
  const { id } = useParams(); // Get item id

  // Fetch form data
  const { loading, data, error } = useAxios({
    method: 'get',
    url: `/api/transaction?guid=${id}`,
  });

  // Refund
  const [post, { data: refundResult, loading: loadingRefund }] = useAxios({
    method: "post",
    url: "/api/elavon-v1/refund",
    notify: true
  });

  const cardTransaction = data ? data.cardTransaction : {transactionid: ""};

  if (loading) return <LoadingPage />;

  if (cardTransaction.transactionid === "") return <NotFound />;

  return (
    <Display
      data={data}
      title={cardTransaction.transactionid}
      loadingSave={loading}
      refund={post}
    />
  );
};

export default TransactionsDetail;
