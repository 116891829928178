import React from "react";
import { SideNavLinkType, SideNavSubLinkStrictType } from "types";
import { SideNavCollapse, SideNavSubLink, filterSideNavLinks, useSideNavLinks } from '.';
import { List, ListSubheader } from "@mui/material";

// TODO: Fix to: from "types" after add constants.d.ts
import { CollapsedLinkStrictType } from "types/side-nav";
import { useSelector } from "react-redux";

const SideNavLinks: React.FC = () => {
  // Get links
  const sideNavLinks = useSideNavLinks();
  const user = useSelector((state: { user: any }) => state.user); // TODO: Change any type and user.user.authorizations after redo user state with typescript (and maybe context). Should define type automatically
  const authorizations = user.user ? user.user.authorizations : "";

  const links: SideNavLinkType[] = filterSideNavLinks(sideNavLinks, authorizations);

  return (
    <List component="nav" sx={{ width: '300px' }}>
      {links.map((level1, index) => (
        <React.Fragment key={index}>
          {/* Level 1 - Subheader */}
          <ListSubheader component="div">{level1.subheader}</ListSubheader>

          {/* Level 2*/}
          {
            level1.items.map((level2, index) =>
              // If has items - render collapse
              level2.items ?
                // Typescript fix. TODO: Change after re-think links data and change types
                <SideNavCollapse key={index} links={level2 as CollapsedLinkStrictType} />
                :
                // If has no items - render link
                // Typescript fix. TODO: Change after re-think links data and change types
                <SideNavSubLink key={index} link={level2 as SideNavSubLinkStrictType} />
            )
          }
        </React.Fragment >
      ))}
    </List >
  );
};

export default SideNavLinks;
